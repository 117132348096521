import { useState } from 'react';
import { Container, Stack, Typography, Card, Box, Button, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { COLORS, IMAGES } from '../../constants';
import AuthLayout from '../../layouts/AuthLayout';
import { BASE_URL } from '../../apis';
import { useNavigate } from 'react-router-dom';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 700,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  //margin: theme.spacing(2, 0, 2, 2),
  borderRadius: 0,
  height: "100vh",
  backgroundImage: `url(${IMAGES.welcome})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(12, 0),
}));

const LinkStyled = styled('a')(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main,
}));

function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const theme = useTheme();

  const onChangeEmail = (value) => {
    setEmail(value);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsEmailValid(isValid);
  };

  const onSubmit = async () => {
    setLoading(true);
    if (isEmailValid) {
      const endpoint = BASE_URL.baseUrl + 'common/resetPassword';
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
          response: 'json' 
        }).then(()=> {
          setLoading(false);
       
          toast.success('We will send a password reset link if an account is found.');

        }).catch((res)=> {
          toast.error(res || 'Something went wrong');

        });
        
        setLoading(false);
       
      } catch (error) {
        setLoading(false);
        toast.error('Something went wrong');
      }
    } else {
      toast.error('Invalid email syntax');
      setLoading(false);
    }
  };

  

  const isButtonDisabled = !isEmailValid || loading;

  return (
    <RootStyle title="Forgot Password | Hair Scout">
      <AuthLayout />
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            variant="h2"
            sx={{
              color: COLORS.white,
              fontFamily: "PlayfairDisplay-Bold",
            }}
          >
            Welcome Back!
          </Typography>
          <Typography
            sx={{
              color: COLORS.white,
              fontSize: 20,
              textAlign: "center",
              fontFamily: "PTSans-Regular",
            }}
          >
            We are glad to see you again! Get access to your Orders, Wishlists and Recommendation
          </Typography>
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "center",
                color: COLORS.secondary,
                fontFamily: "PlayfairDisplay-Bold",
              }}
            >
              Forgot Password
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "center",
                fontFamily: "PTSans-Regular",
              }}
            >
              No worries, we'll send you reset instructions
            </Typography>
          </Stack>
          <Box sx={{ mb: 4 }}>
            <TextField
              fullWidth
              type="email"
              disabled={loading}
              label="Email"
              value={email}
              placeholder="john.doe@example.com"
              onChange={(e) => onChangeEmail(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Button
              fullWidth
              size="large"
              type="button"
              variant="contained"
              disabled={isButtonDisabled}
              onClick={onSubmit}
            >
              Reset
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Typography variant="body2">
              <Button variant="text" sx={{ fontFamily: "PTSans-Bold",  fontSize: 16 }} onClick={() => navigate("/login")}>
                Back to login
              </Button>
            </Typography>
          </Box>
        </ContentStyle>
        <ToastContainer />
      </Container>
    </RootStyle>
  );
}

export default ForgotPassword;
