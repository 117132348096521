// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Card,
  CardHeader,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Icon } from "@iconify/react";
import cross from "@iconify/icons-eva/close-fill";
// ----------------------------------------------------------------------
// Imports
import { toast, ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProductTag from "../../../components/ProductTag";
import { connect } from "react-redux";
import { setProductDetails } from "../../../redux/actions";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function ProductForm(props) {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [imagesError, setImagesError] = useState(false);
  const [imagesHelperText, setImagesHelperText] = useState("");
  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState(false);
  const [productNameHelperText, setProductNameHelperText] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productDescriptionError, setProductDescriptionError] = useState(false);
  const [productDescriptionHelperText, setProductDescriptionHelperText] =
    useState("");
  const [productTags, setProductTags] = useState([]);
  const [tagString, setTagString] = useState("");

  const handleChangeGallery = function (event) {
    let gallery = [...images];
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        console.log(event.target.files[i]);
        gallery.push({
          uri: URL.createObjectURL(event.target.files[i]),
          name: event.target.files[i]?.name,
          type: event.target.files[i]?.type,
        });
        // gallery.push(event.target.files[i]);
      }
      setImages(gallery);
      setImagesError(false);
      setImagesHelperText("");
    }
  };
  const handleRemoveImage = (img) => {
    const filteredImages = images.filter((image) => image.name !== img);
    setImages(filteredImages);
  };
  const handleTagsString = (tagsString) => {
    setTagString(tagsString);
    if (tagsString.includes(",")) {
      var myArray = tagsString.split(",");
      setProductTags((productTags) => [...productTags, myArray[0]]);
      setTagString("");
    }
  };
  const handleRemoveTag = (tag) => {
    console.log(tag);
    const remainingTags = productTags.filter((item) => {
      return item !== tag;
    });
    setProductTags(remainingTags);
  };

  const handleValidation = () => {
    if (productName === null || productName === "" || productName === " ") {
      setProductNameError(true);
      setProductNameHelperText("Please enter product name");
      return false;
    } else if (
      productDescription === null ||
      productDescription === "" ||
      productDescription === " "
    ) {
      setProductDescriptionError(true);
      setProductDescriptionHelperText("Please enter product description");
      return false;
    } else if (images.length === 0) {
      setImagesError(true);
      setImagesHelperText("Please add at least one image");
      return false;
    } else {
      return true;
    }
  };
  const handleSetProductDetails = async () => {
    const { productCategory } = props.setupProduct;
    const isValidated = handleValidation();
    if (isValidated) {
      await props.setProductDetails(
        productName,
        productDescription,
        images,
        productTags,
        null,
        [],
        []
      );
      if (productCategory.categoryName === "Wigs") {
        navigate("/product-care");
      } else {
        navigate("/product-lengths");
      }
    }
  };
  return (
    <RootStyle title="Product Form | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card sx={{ mb: 5 }}>
          <CardHeader title="Product Listing" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <TextField
                error={productNameError}
                type="text"
                fullWidth
                required={true}
                label="Product Name"
                onChange={(e) => {
                  setProductName(e.target.value);
                  setProductNameError(false);
                  setProductNameHelperText("");
                }}
                value={productName}
                helperText={productNameHelperText}
              />
              <TextField
                error={productDescriptionError}
                type="text"
                required={true}
                fullWidth
                label="Product Description"
                onChange={(e) => {
                  setProductDescription(e.target.value);
                  setProductDescriptionError(false);
                  setProductDescriptionHelperText("");
                }}
                value={productDescription}
                helperText={productDescriptionHelperText}
              />
            </Stack>
            {/* <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="processed"
                    control={<Radio />}
                    label="Processed"
                  />
                  <FormControlLabel
                    value="unprocessed"  
                    control={<Radio />}
                    label="Un Processed"
                  />
                </RadioGroup>
              </FormControl>  
            </Stack> */}
            <Typography sx={{ mt: 3, mb: 1, fontFamily: "PTSans-Bold" }}>
              Upload Photos
            </Typography>
            <Typography sx={{ mt: 0, mb: 2, fontFamily: "PTSans-Regular" }}>
              Please enter maximum 3 images of your product
            </Typography>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={2}
              sx={{ mb: imagesError ? 1 : 5 }}
              overflow="auto"
            >
              <Button
                sx={{
                  border: "1px solid black",
                  borderRadius: 0,
                  height: 150,
                  width: 150,
                  minWidth: 150,
                  flexDirection: "column",
                }}
                component="label"
              >
                <Typography>+</Typography>
                <Typography>Add Photos</Typography>
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleChangeGallery}
                  id="upload"
                  accept="image/*"
                  style={{
                    border: "#f5f5f5",
                    padding: 10,
                    borderRadius: 5,
                  }}
                />
              </Button>

              {images.map(
                (item, index) => (
                  console.log(item?.name),
                  (
                    // console.log(URL.createObjectURL(item)),
                    <Stack
                      key={index}
                      style={{
                        marginRight: 10,
                        borderRadius: 0,
                      }}
                    >
                      <Avatar
                        key={index}
                        variant="square"
                        src={item.uri}
                        style={{ width: 150, height: 150 }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveImage(item.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  )
                )
              )}
            </Stack>
            {imagesError && (
              <Typography sx={{ mb: 4, color: "red", fontSize: 12 }}>
                {imagesHelperText}
              </Typography>
            )}
            <Typography sx={{ mb: 2 }}>Product Tags</Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              {productTags.map((item, index) => (
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "center",
                    // border: 1,
                    p: 2,
                    borderRadius: 1,
                    boxShadow: 4,
                    height: 50,
                  }}
                  key={index}
                >
                  <Typography>{item}</Typography>
                  <IconButton
                    sx={{ color: "text.primary", zIndex: 1 }}
                    onClick={() => handleRemoveTag(item)}
                  >
                    <Icon icon={cross} />
                  </IconButton>
                </Stack>
              ))}
              <TextField
                type="text"
                fullWidth
                required={true}
                label="Product Tag (e-g: Curly Hair, Kinky Hair,)"
                onChange={(e) => {
                  handleTagsString(e.target.value);
                }}
                value={tagString}
              />
              {/* <ProductTag /> <ProductTag /> <ProductTag /> */}
            </Stack>
            <Button
              sx={{ width: 200, mb: 2, height: 50 }}
              variant="contained"
              // onClick={() => navigate("/product-lengths")}
              onClick={() => handleSetProductDetails()}
            >
              Continue
            </Button>
          </Box>
        </Card>
      </Container>
      <ToastContainer />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  setupProduct: state.setupProduct,
});
const mapDispatchToProps = (dispatch) => ({
  setProductDetails: (
    productName,
    productDescription,
    images,
    tags,
    processed,
    oldImages,
    newImages
  ) =>
    dispatch(
      setProductDetails(
        productName,
        productDescription,
        images,
        tags,
        processed,
        oldImages,
        newImages
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
