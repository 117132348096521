import { Box, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { COLORS } from "../../../constants";
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { connect } from "react-redux";
import { getFaqs } from "../../../redux/actions";
import { useEffect } from "react";
import { toast } from "react-toastify";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function Faqs(props) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState("panel1");

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = () => {
    const { accessToken } = props.authentication;
    props
      .getFaqs(accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { faqsLoading, faqs } = props.common;
  return (
    <Page title="Dashboard | FAQs">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          Frequently Asked Questions
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.primary" }}
          >
            FAQs
          </Typography>
          <Box sx={{ mt: 3 }}>
            {faqs.map((item, index) => (
              <Accordion
                expanded={expanded === "panel" + index + 1}
                onChange={handleChange("panel" + index + 1)}
                sx={{
                  background:
                    expanded === "panel" + index + 1
                      ? COLORS.other
                      : COLORS.white,
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{item?.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item?.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  getFaqs: (token) => dispatch(getFaqs(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
