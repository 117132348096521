import { Box, Modal, Button, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../constants";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  justifyContent: "center",
  alignItems: "center",
};

export default function AddMoreProductModal({ open, handleClose }) {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <CloseIcon
          sx={{ position: "absolute", right: 20 }}
          onClick={handleClose}
        />
        <Typography
          id="keep-mounted-modal-title"
          variant="h4"
          component="h2"
          sx={{
            textAlign: "center",
            color: COLORS.secondary,
            fontFamily: "PTSans-Bold",
            mt: 5,
          }}
        >
          Congratulations on setting up your first product
        </Typography>
        <Typography
          id="keep-mounted-modal-description"
          sx={{ mt: 2, textAlign: "center", fontFamily: "PTSans-Regular" }}
        >
          You are ready to sell and much more
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "column" }}
          spacing={2}
          sx={{ justifyContent: "center", mt: 4, mb: 4 }}
        >
          <Button
            variant="contained"
            sx={{ alignSelf: "center", mb: 2, width: 200 }}
            onClick={() => navigate("/product-categories")}
          >
            Add Another Product
          </Button>
          <Button
            variant="contained"
            sx={{
              alignSelf: "center",
              bgcolor: COLORS.primary,
              width: 200,
            }}
            onClick={() => navigate("/dashboard/vendor-dashboard")}
          >
            Go To Store
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
