import React, { useState } from "react";
import { Icon } from "@iconify/react";
import goldFilled from "@iconify/icons-ant-design/gold-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";
import { COLORS } from "../constants";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// ----------------------------------------------------------------------
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment/moment";

const RootStyle = {
  p: 5,
  color: COLORS.primary,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    COLORS.secondary,
    0
  )} 0%, ${alpha(COLORS.secondary, 0.24)} 100%)`,
  mt: 2,
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 1,
  boxShadow: 2,
};

const MONTHS = [
  { id: 1, value: "01", name: "January" },
  { id: 2, value: "02", name: "February" },
  { id: 3, value: "03", name: "March" },
  { id: 4, value: "04", name: "April" },
  { id: 5, value: "05", name: "May" },
  { id: 6, value: "06", name: "June" },
  { id: 7, value: "07", name: "July" },
  { id: 8, value: "08", name: "August" },
  { id: 9, value: "09", name: "September" },
  { id: 10, value: "10", name: "October" },
  { id: 11, value: "11", name: "November" },
  { id: 12, value: "12", name: "December" },
];

const YEAR = [
  { id: 1, value: "2020", name: "2020" },
  { id: 2, value: "2021", name: "2021" },
  { id: 3, value: "2022", name: "2022" },
  { id: 4, value: "2023", name: "2023" },
  { id: 5, value: "2024", name: "2024" },
  { id: 6, value: "2025", name: "2025" },
  { id: 7, value: "2026", name: "2026" },
  { id: 8, value: "2027", name: "2027" },
  { id: 9, value: "2028", name: "2028" },
  { id: 10, value: "2029", name: "2029" },
  { id: 11, value: "2030", name: "2030" },
];
// ----------------------------------------------------------------------

export default function Banner({
  image,
  title,
  value,
  withDate,
  onChangeStartDate,
  onChangeEndDate,
  onPressSearch,
  onPressSearchByMonth,
  onChangeMonth,
  onChangeYear,
  withMonth,
}) {
  const [startDate, setStartDate] = useState(dayjs(moment().format("L")));
  const [endDate, setEndDate] = useState(dayjs(moment().format("L")));
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const handleChangeStartDate = (newValue) => {
    console.log(newValue);
    setStartDate(newValue);
    onChangeStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
    onChangeEndDate(newValue);
  };
  const handleChangeMonth = (e) => {
    setMonth(e.target.value);
    onChangeMonth(e.target.value);
  };
  const handleChangeYear = (e) => {
    setYear(e.target.value);
    onChangeYear(e.target.value);
  };
  return (
    <Stack direction={{ xs: "row", sm: "row" }} sx={RootStyle}>
      <Box>
        <Typography
          variant="subtitle2"
          sx={{ opacity: 0.72, fontFamily: "PTSans-Regular" }}
        >
          {title}
        </Typography>
        <Typography variant="h4">{value}</Typography>

        {withDate && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container sx={{ mt: 3 }}>
              <Grid item>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="YYYY-MM-DD"
                  value={startDate}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="YYYY-MM-DD"
                  value={endDate}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    height: 50,
                    width: 100,
                  }}
                  onClick={onPressSearch}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        )}
        {withMonth && (
          <Grid container sx={{ mt: 3 }}>
            <Grid item>
              <FormControl fullWidth sx={{ width: 200 }}>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={month}
                  label="Month"
                  onChange={handleChangeMonth}
                >
                  {MONTHS.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth sx={{ width: 200, ml: 2 }}>
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={year}
                  label="Year"
                  onChange={handleChangeYear}
                >
                  {YEAR.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ ml: 2 }}>
              <Button
                variant="contained"
                sx={{
                  height: 50,
                  width: 100,
                }}
                onClick={onPressSearchByMonth}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>

      <img src={image} width={70} height={70} />
    </Stack>
  );
}
