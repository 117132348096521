import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Page from "../../../components/Page";
import { COLORS } from "../../../constants";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { changePassword } from "../../../redux/actions";

function ChangePassword(props) {
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword((showCurrentPassword) => !showCurrentPassword);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword((showNewPassword) => !showNewPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
  };
  const handleChangePassword = () => {
    const { accessToken } = props.authentication;
    if (
      currentPassword === null ||
      currentPassword === "" ||
      currentPassword === " "
    ) {
      toast.error("Please enter your current password");
    } else if (
      newPassword === null ||
      newPassword === "" ||
      newPassword === " "
    ) {
      toast.error("Please enter your new password");
    } else if (
      confirmNewPassword === null ||
      confirmNewPassword === "" ||
      confirmNewPassword === " " ||
      confirmNewPassword !== newPassword
    ) {
      toast.error("Please enter same value for password and confirm password");
    } else {
      props
        .changePassword(currentPassword, newPassword, accessToken)
        .then(() => {
          const { changePasswordStatus } = props.authentication;
          if (changePasswordStatus === true) {
            toast.error("Your password has been changed");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  return (
    <Page title="Dashboard | Notification Setting">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          Change Password
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Please create a new password that you dont used previously
          </Typography>
          <Grid container sx={{ mt: 2 }} spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                type={showCurrentPassword ? "text" : "password"}
                fullWidth
                label="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleShowCurrentPassword}
                      >
                        <Icon
                          icon={showCurrentPassword ? eyeFill : eyeOffFill}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                type={showNewPassword ? "text" : "password"}
                fullWidth
                label="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleShowNewPassword}>
                        <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                label="Confirm Your Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleShowConfirmPassword}
                      >
                        <Icon
                          icon={showConfirmPassword ? eyeFill : eyeOffFill}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 4, backgroundColor: COLORS.primary, width: 200 }}
            onClick={() => handleChangePassword()}
          >
            Change Password
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  changePassword: (currentPassword, newPassword, token) =>
    dispatch(changePassword(currentPassword, newPassword, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
