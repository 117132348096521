import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Banner from "../../../components/Banner";
import AddSaleModal from "../../../components/modals/AddSaleModal";
import Page from "../../../components/Page";
import { COLORS, FONTS, ICONS, IMAGES } from "../../../constants";
import {
  deleteDiscount,
  getDiscount,
} from "../../../redux/actions/vendor/vendorSaleActions";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { NoItem } from "../../../components";

function SaleProducts(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [discountId, setDiscountId] = useState(null);

  useEffect(() => {
    handleGetDiscount();
  }, []);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleGetDiscount = async () => {
    const { accessToken } = props.authentication;
    // const { page, pageSize } = this.state;

    await props
      .getDiscount(accessToken)
      .then(() => {
        // const { saleProducts } = this.props.vendorStore;
        // this.setState({ data: this.state.data.concat(saleProducts) });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleDeleteDiscount = async (discountId) => {
    const { accessToken } = props.authentication;

    await props.deleteDiscount(discountId, accessToken).then(() => {
      const { deleteDiscountStatus } = props.sale;
      if (deleteDiscountStatus) {
        setOpen(false);
        handleGetDiscount();
        toast.success("Success", "Sale deleted successfully");
      }
    });
  };

  const { discounts, discountsLoading } = props.sale;
  return (
    <Page title="Dashboard | Sale Products">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>

            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Sale & Discounts
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Stack
            direction={{ sx: "column", md: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
            >
              Store Analytics
            </Typography>

            <Button
              variant="contained"
              onClick={() => navigate("/dashboard/add-discount")}
            >
              + Add New Discount
            </Button>
          </Stack>
          <Banner image={ICONS.sale} title="Products On Sale" value="12" />

          {discountsLoading ? (
            <Stack
              sx={{
                height: 200,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Stack>
          ) : discounts?.length === 0 ? (
            <NoItem image={ICONS.noProduct} title="No Discount Available" />
          ) : (
            discounts?.map((item, index) => (
              <Grid container spacing={3} sx={{ mt: 1 }} key={index}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ boxShadow: 2 }}>
                    <Box sx={{ p: 2 }}>
                      <Stack
                        direction={{ sm: "row", md: "row" }}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Typography sx={{ mt: 2, fontFamily: FONTS.subtitle }}>
                          Discount Name:
                        </Typography>
                        <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                          {item?.discount_name}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ sm: "row", md: "row" }}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Typography sx={{ mt: 2, fontFamily: FONTS.subtitle }}>
                          Discount Type:
                        </Typography>
                        <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                          {item?.discoun_type}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ sm: "row", md: "row" }}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Typography sx={{ mt: 2, fontFamily: FONTS.subtitle }}>
                          Discount:
                        </Typography>
                        <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                          ${item?.discount_amount}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ sm: "row", md: "row" }}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Typography sx={{ mt: 2, fontFamily: FONTS.subtitle }}>
                          Minimum Amount Spent:
                        </Typography>
                        <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                          ${item?.minimumAmoun_spent}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ sm: "row", md: "row" }}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Typography sx={{ mt: 2, fontFamily: FONTS.subtitle }}>
                          Start Date:
                        </Typography>
                        <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                          {moment(item?.start_date).format("LLL")}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ sm: "row", md: "row" }}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Typography sx={{ mt: 2, fontFamily: FONTS.subtitle }}>
                          End Date:
                        </Typography>
                        <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                          {moment(item?.end_date).format("LLL")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Stack
                      direction={{ sm: "row", md: "row" }}
                      sx={{ justifyContent: "space-between", mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        sx={{ width: "48%", borderRadius: 0 }}
                        onClick={() =>
                          navigate("/dashboard/update-discount", {
                            state: { discount: item },
                          })
                        }
                      >
                        Update
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ width: "48%", borderRadius: 0 }}
                        onClick={() => {
                          setDiscountId(item?.id);
                          handleOpenModal();
                        }}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            ))
          )}
        </Box>
        {/* <AddSaleModal open={open} handleClose={handleCloseModal} /> */}
        <ConfirmationModal
          open={open}
          title="Delete Discount"
          text="Do you really want to delete this discount?"
          handleClose={() => setOpen(false)}
          onPress={() => handleDeleteDiscount(discountId)}
        />
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  sale: state.sale,
});
const mapDispatchToProps = (dispatch) => ({
  getDiscount: (token) => dispatch(getDiscount(token)),
  deleteDiscount: (discountId, token) =>
    dispatch(deleteDiscount(discountId, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SaleProducts);
