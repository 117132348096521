import { BASE_URL, SALE } from "../../../apis";
import {
  handleDeleteApi,
  handleGetApi,
  handlePostApi,
  handlePutApi,
} from "../../../services";
import { vendorSaleTypes } from "../../types";

export const createDiscount = (
  categoryId,
  discountName,
  discountType,
  discountAmount,
  minimumAmountSpent,
  discountCode,
  startDate,
  endDate,
  accessToken
) => {
  const body = JSON.stringify({
    category_id: categoryId,
    discount_name: discountName,
    discoun_type: discountType,
    discount_amount: discountAmount,
    minimumAmoun_spent: minimumAmountSpent,
    discount_code: discountCode,
    start_date: startDate,
    end_date: endDate,
    active: "true",
  });
  return async (dispatch) => {
    dispatch({
      type: vendorSaleTypes.ADD_DISCOUNT_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + SALE.createDiscount,
      body,
      accessToken
    );
    console.log("Add Discount Response", response?.data);
    if (response) {
      dispatch({
        type: vendorSaleTypes.ADD_DISCOUNT_SUCCESS,
        payload: response?.data,
      });
    } else {
      dispatch({ type: vendorSaleTypes.ADD_DISCOUNT_FAILURE });
    }
  };
};

export const getDiscount = (accessToken) => {
  return async (dispatch) => {
    dispatch({ type: vendorSaleTypes.GET_DISCOUNT_LOADING });
    const response = await handleGetApi(
      BASE_URL.baseUrl + SALE.getDiscounts,
      accessToken
    );
    console.log(response?.data);
    if (response) {
      dispatch({
        type: vendorSaleTypes.GET_DISCOUNT_SUCCESS,
        payload: response?.data?.rootResponseModel,
      });
    } else {
      dispatch({ type: vendorSaleTypes.GET_DISCOUNT_FAILURE });
    }
  };
};

export const deleteDiscount = (discountId, accessToken) => {
  return async (dispatch) => {
    dispatch({ type: vendorSaleTypes.DELETE_DISCOUNT_LOADING });
    const response = await handleDeleteApi(
      BASE_URL.baseUrl +
        SALE.deleteDiscount +
        "discountAndSaleId=" +
        discountId,
      null,
      accessToken
    );
    console.log("Delete Discount Response", response?.data);
    if (response) {
      dispatch({
        type: vendorSaleTypes.DELETE_DISCOUNT_SUCCESS,
        payload: response?.data,
      });
    } else {
      dispatch({ type: vendorSaleTypes.DELETE_DISCOUNT_FAILURE });
    }
  };
};

export const updateDiscount = (
  categoryId,
  discountName,
  discountType,
  discountAmount,
  minimumAmountSpent,
  discountCode,
  startDate,
  endDate,
  id,
  accessToken
) => {
  const body = JSON.stringify({
    category_id: categoryId,
    discount_name: discountName,
    discoun_type: discountType,
    discount_amount: discountAmount,
    minimumAmoun_spent: minimumAmountSpent,
    discount_code: discountCode,
    start_date: startDate,
    end_date: endDate,
    id: id,
    active: "true",
  });
  return async (dispatch) => {
    dispatch({ type: vendorSaleTypes.UPDATE_DISCOUNT_LOADING });
    const response = await handlePutApi(
      BASE_URL.baseUrl + SALE.updateDiscount,
      body,
      accessToken
    );
    console.log("Update Discount Response: ", response?.data);
    if (response) {
      dispatch({
        type: vendorSaleTypes.UPDATE_DISCOUNT_SUCCESS,
        payload: response?.data,
      });
    } else {
      dispatch({ type: vendorSaleTypes.UPDATE_DISCOUNT_FAILURE });
    }
  };
};
