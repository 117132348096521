export const CUSTOMER_PROFILE = {
  getCustomerProfile: "customers/profile?",
  getCustomerAddresses: "customers/address?",
  addCustomerAddress: "customers/address",
  updateCustomerAddress: "customers/address",
  deleteCustomerAddress: "customers/address/",
  enableCustomerNotifications: "customers/profile",
};
export const CUSTOMER_PRODUCTS = {
  getCustomerProducts: "customer/products?",
  getProductDetails: "customer/products",
  getProductReviews: "customer/products/review?",
  addProductReview: "customer/products/review",
  addQuery: "customer/products/question",
  getQueries: "customer/products/question?",
};
export const SHOPPING_CART = {
  getShoppingCart: "cart/items?",
  addToCart: "cart/items",
  updateCart: "cart/items",
  deleteFromCart: "cart/items",
  confirmOrder: "customer/orders/confirm",
  addToCartWithoutCheckout:"cart/itemsWithoutCheckout",
};

export const CUSTOMER_ORDERS = {
  orderHistory: "customer/orders/history?",
  orderDetails: "customer/orders/",
};
export const CUSTOMER_STORES = {
  followStore: "customers/setstorewishliststatus",
  getStoreDetails: "customers/getallstoredatabyvendorid",
  getFollowedStores: "customers/getfollowedstoredata",
};
export const CUSTOMER_ACCOUNTS = {
  getAccounts: "customers/getbankaccountbycustomer",
  addAccount: "customers/insertcustomerbankaccount",
  updateAccount: "customers/updatebankaccountdetail",
  deleteAccount: "customers/deletecustomerbank?",
};
