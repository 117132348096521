import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem(state, action) {
      state.items.push(action.payload);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    updateItem(state, action) {
      const index = state.items.findIndex(item => item.product_id === action.payload.product_id);
      if (index !== -1) {
        state.items[index] = {
          ...state.items[index],
          quantity:action.payload.quantity,
          product_attributes: action.payload.product_attributes
        };
        localStorage.setItem('cart', JSON.stringify(state.items));
      }
    },
    removeItem(state, action) {
      state.items = state.items.filter(item => item.id !== action.payload.id);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    clearCart(state) {
      state.items = [];
      localStorage.removeItem('cart');
    },
  },
});

export const { addItem, updateItem, removeItem, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
