import { handleFormApi, handleGetApi, handlePutApi } from "../../../services";
import { commonTypes } from "../../types";
import { BASE_URL, COMMON } from "../../../apis";

export const setInternetStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.SET_NETWORK_STATUS,
      payload: status,
    });
  };
};

export const showAlert = (msg) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.SHOW_ALERT,
      payload: msg,
    });
  };
};
export const hideAlert = () => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.HIDE_ALERT,
    });
  };
};
export const getStates = (token) => {
  console.log(token);
  return async (dispatch) => {
    dispatch({
      type: commonTypes.STATES_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + COMMON.getStates,
      token
    );
    console.log("States Response", response);
    if (response) {
      dispatch({
        type: commonTypes.STATES_SUCCESS,
        payload: response.data.states,
      });
    } else {
      dispatch({
        type: commonTypes.STATES_FAILURE,
      });
    }
  };
};

export const uploadImages = (image, token) => {
  // console.log(image);
  const data = new FormData();
  data.append("File", image);
  // console.log(JSON.stringify(data.get("File")));
  return async (dispatch) => {
    dispatch({
      type: commonTypes.UPLOAD_IMAGE_LOADING,
    });
    const response = await handleFormApi(
      BASE_URL.baseUrl + COMMON.uploadImage,
      data,
      token
    );
    //console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.UPLOAD_IMAGE_SUCCESS,
        payload: { url: response.data.thumb_image_url, id: response.data.id },
      });
    } else {
      dispatch({
        type: commonTypes.UPLOAD_IMAGE_FAILURE,
      });
    }
  };
};

export const getCategories = (
  limit,
  page,
  sinceId,
  fields,
  createdAtMin,
  createdAtMax,
  updatedAtMin,
  updatedAtMax,
  publishedStatus,
  productId,
  parentCategoryId,
  token
) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.CATEGORIES_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        COMMON.getCategories +
        "Limit=" +
        limit +
        "&Page=" +
        page +
        "&SinceId=" +
        sinceId +
        "&Fields=" +
        fields +
        "&CreatedAtMin=" +
        createdAtMin +
        "&CreatedAtMax=" +
        createdAtMax +
        "&UpdatedAtMin=" +
        updatedAtMin +
        "&UpdatedAtMax=" +
        updatedAtMax +
        "&PublishedStatus=" +
        publishedStatus +
        "&ProductId=" +
        productId +
        "&ParentCategoryId=" +
        parentCategoryId,
      token
    );
    //console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.CATEGORIES_SUCCESS,
        payload: response.data.categories,
      });
    } else {
      dispatch({
        type: commonTypes.CATEGORIES_FAILURE,
      });
    }
  };
};

export const getSubCategories = (
  limit,
  page,
  sinceId,
  fields,
  createdAtMin,
  createdAtMax,
  updatedAtMin,
  updatedAtMax,
  publishedStatus,
  productId,
  parentCategoryId,
  token
) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.SUB_CATEGORIES_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        COMMON.getCategories +
        "Limit=" +
        limit +
        "&Page=" +
        page +
        "&SinceId=" +
        sinceId +
        "&Fields=" +
        fields +
        "&CreatedAtMin=" +
        createdAtMin +
        "&CreatedAtMax=" +
        createdAtMax +
        "&UpdatedAtMin=" +
        updatedAtMin +
        "&UpdatedAtMax=" +
        updatedAtMax +
        "&PublishedStatus=" +
        publishedStatus +
        "&ProductId=" +
        productId +
        "&ParentCategoryId=" +
        parentCategoryId,
      token
    );
    //console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.SUB_CATEGORIES_SUCCESS,
        payload: response.data.categories,
      });
    } else {
      dispatch({
        type: commonTypes.SUB_CATEGORIES_FAILURE,
      });
    }
  };
};
export const searchCategories = (
  ids,
  limit,
  page,
  sinceId,
  fields,
  createdAtMin,
  createdAtMax,
  updatedAtMin,
  updatedAtMax,
  publishedStatus,
  productId,
  parentCategoryId,
  token
) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.CATEGORIES_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        COMMON.getCategories +
        "Ids=" +
        ids +
        "&Limit=" +
        limit +
        "&Page=" +
        page +
        "&SinceId=" +
        sinceId +
        "&Fields=" +
        fields +
        "&CreatedAtMin=" +
        createdAtMin +
        "&CreatedAtMax=" +
        createdAtMax +
        "&UpdatedAtMin=" +
        updatedAtMin +
        "&UpdatedAtMax=" +
        updatedAtMax +
        "&PublishedStatus=" +
        publishedStatus +
        "&ProductId=" +
        productId +
        "&ParentCategoryId=" +
        parentCategoryId,
      token
    );
    //console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.CATEGORIES_SUCCESS,
        payload: response.data.categories,
      });
    } else {
      dispatch({
        type: commonTypes.CATEGORIES_FAILURE,
      });
    }
  };
};

export const searchSubCategories = (
  ids,
  limit,
  page,
  sinceId,
  fields,
  createdAtMin,
  createdAtMax,
  updatedAtMin,
  updatedAtMax,
  publishedStatus,
  productId,
  parentCategoryId,
  token
) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.SUB_CATEGORIES_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        COMMON.getCategories +
        "Ids=" +
        ids +
        "&Limit=" +
        limit +
        "&Page=" +
        page +
        "&SinceId=" +
        sinceId +
        "&Fields=" +
        fields +
        "&CreatedAtMin=" +
        createdAtMin +
        "&CreatedAtMax=" +
        createdAtMax +
        "&UpdatedAtMin=" +
        updatedAtMin +
        "&UpdatedAtMax=" +
        updatedAtMax +
        "&PublishedStatus=" +
        publishedStatus +
        "&ProductId=" +
        productId +
        "&ParentCategoryId=" +
        parentCategoryId,
      token
    );
    //console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.SUB_CATEGORIES_SUCCESS,
        payload: response.data.categories,
      });
    } else {
      dispatch({
        type: commonTypes.SUB_CATEGORIES_FAILURE,
      });
    }
  };
};
export const setDeviceFcmToken = (fcmToken, token) => {
  const body = JSON.stringify({
    firebase_token: fcmToken,
  });
  return async (dispatch) => {
    dispatch({
      type: commonTypes.SET_FCM_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + COMMON.setFcmToken,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.SET_FCM_SUCCESS,
      });
    } else {
      dispatch({
        type: commonTypes.SET_FCM_FAILURE,
      });
    }
  };
};

export const sendMessage = (message, to, token) => {
  const body = JSON.stringify({
    message: message,
    to: to,
  });
  return async (dispatch) => {
    dispatch({
      type: commonTypes.SEND_MESSAGE_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + COMMON.sendMessage,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.SEND_MESSAGE_SUCCESS,
      });
    } else {
      dispatch({
        type: commonTypes.SEND_MESSAGE_FAILURE,
      });
    }
  };
};

export const getChatHistory = (limit, page, targetCustomerId, token) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.CHAT_HISTORY_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        COMMON.getChatHistory +
        "Limit=" +
        limit +
        "&Page=" +
        page +
        "&TargetCustomerId=" +
        targetCustomerId,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.CHAT_HISTORY_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: commonTypes.CHAT_HISTORY_FAILURE,
      });
    }
  };
};

export const getFaqs = (token) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.FAQS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + COMMON.getFaqs,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.FAQS_SUCCESS,
        payload: response.data.faqlist,
      });
    } else {
      dispatch({
        type: commonTypes.FAQS_FAILURE,
      });
    }
  };
};

export const getTermsAndConditions = (token) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.TERMS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + COMMON.termsAndConditions,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.TERMS_SUCCESS,
        payload: response.data.body,
      });
    } else {
      dispatch({
        type: commonTypes.TERMS_FAILURE,
      });
    }
  };
};

export const getContactInfo = (token) => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.CONTACT_INFO_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + COMMON.contactInfo,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: commonTypes.CONTACT_INFO_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: commonTypes.CONTACT_INFO_FAILURE,
      });
    }
  };
};
