import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NoItem } from "../../../components/NoItem";
import Page from "../../../components/Page";
import StaffCard from "../../../components/StaffCard";
import { COLORS, ICONS } from "../../../constants";
import { getInvitedStaff } from "../../../redux/actions/vendor/staffActions";

function InvitedStaff(props) {
  const navigate = useNavigate();

  const handleGetInvitedStaff = () => {
    const { accessToken } = props.authentication;
    props
      .getInvitedStaff(accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };

  useEffect(() => {
    handleGetInvitedStaff();
  }, []);
  const { staff, staffLoading } = props.staff;
  return (
    <Page title="Dashboard | Staff">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Invited Staff
          </Typography>
          {staffLoading ? (
            <Stack
              sx={{
                height: 200,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Stack>
          ) : staff?.length === 0 ? (
            <Stack
              sx={{
                height: 200,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NoItem image={ICONS.noProduct} title="No invite sent" />
            </Stack>
          ) : (
            <Grid container spacing={3} sx={{ mt: 1 }}>
              {staff?.map((item, index) => (
                <StaffCard
                  key={index}
                  name={item?.name}
                  invitedOn={moment(item?.invited_on).format("LLL")}
                  emailAddress={item?.email}
                  onPress={() =>
                    navigate("/dashboard/delete-staff", {
                      state: { id: item.id },
                    })
                  }
                />
              ))}
            </Grid>
          )}
          <Button
            variant="contained"
            sx={{ mt: 5, background: COLORS.primary, width: 200 }}
            onClick={() => navigate("/dashboard/invite-staff")}
          >
            Invite Staff Member
          </Button>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  staff: state.staff,
});
const mapDispatchToProps = (dispatch) => ({
  getInvitedStaff: (token) => dispatch(getInvitedStaff(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvitedStaff);
