// ----------------------------------------------------------------------
// Material
import React from "react";
import {
  Container,
  Stack,
  Card,
  CardHeader,
  Box,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
// ----------------------------------------------------------------------
// Imports
import { toast, ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProductTag from "../../../components/ProductTag";
import { connect } from "react-redux";
import { setProductLengths } from "../../../redux/actions";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function ProductLengths(props) {
  const navigate = useNavigate();
  const [selectedLengths, setSelectedLengths] = React.useState([]);
  const [lengthsError, setLengthsError] = React.useState(false);
  const [lengthsHelperText, setLengthsHelperText] = React.useState("");
  const [customLength, setCustomLength] = useState(0);
  const [openLength, setOpenLength] = useState(false);
  const [lengths, setLengths] = useState([
    { id: 6, value: "6 Inches" },
    { id: 8, value: "8 Inches" },
    { id: 10, value: "10 Inches" },
    { id: 12, value: "12 Inches" },
    { id: 14, value: "14 Inches" },
    { id: 16, value: "16 Inches" },
  ]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLengths(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleIncreaseLength = () => {
    let length = customLength + 2;
    setCustomLength(length);
  };
  const handleDecreaseLength = () => {
    if (customLength > 0) {
      let length = customLength - 2;
      setCustomLength(length);
    }
  };
  const handleAddCustomLength = () => {
    const newLengths = [...lengths];
    newLengths.push({ id: customLength, value: customLength + " Inches" });
    setLengths(newLengths);
    toast.success("Custom length added successfully");
  };
  const handleValidation = () => {
    if (selectedLengths.length === 0) {
      setLengthsError(true);
      setLengthsHelperText("Please select at least one length");
      return false;
    } else {
      return true;
    }
  };
  const handleSetLengths = async () => {
    const isValidated = handleValidation();
    if (isValidated) {
      props.setProductLengths(selectedLengths);
      navigate("/product-pricing");
    }
  };
  return (
    <RootStyle title="Product Lengths | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card>
          <CardHeader title="Choose Lengths" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <FormControl required={true} fullWidth error={lengthsError}>
                <InputLabel id="state-select">Lengths you offer</InputLabel>
                <Select
                  multiple
                  required
                  value={selectedLengths}
                  onChange={handleChange}
                  labelId="state-select"
                  id="state-select"
                  label="Lengths you offer"
                >
                  {lengths.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{lengthsHelperText}</FormHelperText>
              </FormControl>
            </Stack>

            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={() => setOpenLength(!openLength)}
            >
              + Add Custom Length
            </Button>
            {openLength && (
              <Box sx={{ mt: 5, width: 200 }}>
                <Typography>Add Custom Length</Typography>
                <Stack
                  sx={{
                    mt: 2,
                    width: 200,
                    justifyContent: "space-between",
                    borderBottom: "2px solid",
                  }}
                  direction={{ xs: "row", sm: "row" }}
                >
                  <Button onClick={handleDecreaseLength}>
                    <RemoveSharpIcon />
                  </Button>
                  <Box
                    sx={{
                      width: 100,

                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {customLength}
                    </Typography>
                  </Box>
                  <Button onClick={handleIncreaseLength}>
                    <AddSharpIcon />
                  </Button>
                </Stack>
                <Button
                  variant="contained"
                  sx={{ align: "center", mt: 3 }}
                  onClick={() => {
                    handleAddCustomLength();
                    setOpenLength(!openLength);
                  }}
                >
                  + Add Length
                </Button>
              </Box>
            )}
          </Box>
          <Box sx={{ mx: 3, my: 3 }}>
            <Button
              variant="contained"
              size="large"
              sx={{ width: 200, mb: 2, height: 50 }}
              // onClick={() => navigate("/product-pricing")}
              onClick={() => handleSetLengths()}
            >
              Next
            </Button>
          </Box>
        </Card>
      </Container>
      <ToastContainer />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
});
const mapDispatchToProps = (dispatch) => ({
  setProductLengths: (lengths) => dispatch(setProductLengths(lengths)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductLengths);
