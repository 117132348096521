import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import { IMAGES } from "../constants";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src={IMAGES.logo}
      sx={{ width: 150, height: 50, ...sx }}
    />
  );
}
