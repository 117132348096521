import { customerOrdersTypes } from "../../types";

const initialState = {
  customerOrdersLoading: false,
  customerOrdersHistory: [],

  orderDetails: [],
  orderDetailsLoading: false,
};

const customerOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerOrdersTypes.ORDER_HISTORY_LOADING:
      return {
        ...state,
        customerOrdersLoading: true,
        customerOrdersHistory: [],
      };
    case customerOrdersTypes.ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        customerOrdersHistory: action.payload,
        customerOrdersLoading: false,
      };
    case customerOrdersTypes.ORDER_HISTORY_FAILURE:
      return {
        ...state,
        customerOrdersLoading: false,
        customerOrdersHistory: [],
      };
    case customerOrdersTypes.ORDER_DETAILS_LOADING:
      return {
        ...state,
        orderDetailsLoading: true,
        orderDetails: [],
      };
    case customerOrdersTypes.ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        orderDetailsLoading: false,
      };
    case customerOrdersTypes.ORDER_DETAILS_FAILURE:
      return {
        ...state,
        orderDetailsLoading: false,
        orderDetails: [],
      };
    default:
      return state;
  }
};
export default customerOrdersReducer;
