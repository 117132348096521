import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import StarIcon from "@mui/icons-material/Star";

import { Box, Typography } from "@mui/material";
import BreadCrumb from "../../components/website/BreadCrumb";
import { COLORS, FONTS } from "../../constants";

const CaliforniaPolicy = (props) => {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <BreadCrumb
        page="Privacy Policy for California Residents"
        from="Home"
        fromLink="/home"
      />

      <Box
        className="container"
        sx={{
          mt: 15,
        }}
      >
        <Typography sx={[FONTS.title]}>
          Hair Scout LLC Privacy Policy for California Residents
        </Typography>
        <Typography sx={[FONTS.subtitle, { mt: 2, fontSize: 16 }]}>
          Effective Date: April 07, 2023
        </Typography>
        <Typography sx={[FONTS.subtitle, { mt: 2, fontSize: 16 }]}>
          Last Updated on: April 07, 2023
        </Typography>

        <Typography sx={[FONTS.subtitle, { mt: 2, fontSize: 16 }]}>
          This Privacy Policy for California Residents supplements the
          information contained in Hair Scout's Mobile App Privacy Policy
          (“Privacy Policy”) and applies solely to all visitors, users, and
          others who reside in the State of California ("consumers" or "you").
          We adopt this notice to comply with the California Consumer Privacy
          Act of 2018 (CCPA) and any terms defined in the CCPA have the same
          meaning when used in this Policy.
        </Typography>

        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Information We CollectYea
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We collect information that identifies, relates to, describes,
          references, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular
          consumer, household, or device ("personal information"). Personal
          information does not include:
        </Typography>

        <ul>
          <li>Publicly available information from government records.</li>
          <li>Deidentified or aggregated consumer information.</li>
          <li>
            Information excluded from the CCPA's scope, like: personal
            information covered by certain sector-specific privacy laws,
            including the Fair Credit Reporting Act (FCRA), the
            Gramm-Leach-Bliley Act (GLBA) or California Financial Information
            Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          In particular, we have collected the following categories of personal
          information from consumers within the last twelve (12) months:
        </Typography>
        <table>
          <tr>
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
          </tr>
          <tr>
            <td>A. Identifiers.</td>
            <td>
              A real name, alias, postal address, unique personal identifier,
              online identifier, Internet Protocol address, email address,
              account name, Social Security number, driver's license number,
              passport number, or other similar identifiers.
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California
              Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </td>
            <td>
              A name, signature, Social Security number, physical
              characteristics or description, address, telephone number,
              passport number, driver's license or state identification card
              number, insurance policy number, education, employment, employment
              history, bank account number, credit card number, debit card
              number, or any other financial information, medical information,
              or health insurance information. Some personal information
              included in this category may overlap with other categories.
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              C. Protected classification characteristics under California or
              federal law
            </td>
            <td>
              Age (40 years or older), race, color, ancestry, national origin,
              citizenship, religion or creed, marital status, medical condition,
              physical or mental disability, sex (including gender, gender
              identity, gender expression, pregnancy or childbirth and related
              medical conditions), sexual orientation, veteran or military
              status, genetic information (including familial genetic
              information).
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>D. Commercial information.</td>
            <td>
              Records of personal property, products or services purchased,
              obtained, or considered, or other purchasing or consuming
              histories or tendencies.
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>E. Biometric information.</td>
            <td>
              Genetic, physiological, behavioral, and biological
              characteristics, or activity patterns used to extract a template
              or other identifier or identifying information, such as,
              fingerprints, faceprints, and voiceprints, iris or retina scans,
              keystroke, gait, or other physical patterns, and sleep, health, or
              exercise data.
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity.</td>
            <td>
              Browsing history, search history, information on a consumer's
              interaction with a website, application, or advertisement.
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>G. Geolocation data.</td>
            <td>Physical location or movements. </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>H. Sensory data.</td>
            <td>
              Audio, electronic, visual, thermal, olfactory, or similar
              information.{" "}
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information.</td>
            <td>Current or past job history or performance evaluations. </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>
              J. Non-public education information (per the Family Educational
              Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
              99)).
            </td>
            <td>
              Education records directly related to a student maintained by an
              educational institution or party acting on its behalf, such as
              grades, transcripts, class lists, student schedules, student
              identification codes, student financial information, or student
              disciplinary records.{" "}
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information.</td>
            <td>
              Profile reflecting a person's preferences, characteristics,
              psychological trends, predispositions, behavior, attitudes,
              intelligence, abilities, and aptitudes.
            </td>
            <td>YES</td>
          </tr>
        </table>

        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We obtain the categories of personal information listed above from the
          following categories of sources:
        </Typography>
        <ul>
          <li>
            Directly from you. For example, from forms you complete or products
            and services you purchase.
          </li>
          <li>
            Indirectly from you. For example, from observing your actions on our
            App.
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Use of Personal Information
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We may use or disclose the personal information we collect for one or
          more of the following purposes:
        </Typography>
        <ul>
          <li>
            To fulfill or meet the reason you provided the information. For
            example, if you share your name and contact information to request a
            price quote or ask a question about our products or services, we
            will use that personal information to respond to your inquiry. If
            you provide your personal information to purchase a product or
            service, we will use that information to process your payment and
            facilitate delivery. We may also save your information to facilitate
            new product orders or process returns.
          </li>
          <li>
            To provide, support, personalize, and develop our App, products, and
            services.
          </li>
          <li>
            To create, maintain, customize, and secure your account with us.
          </li>
          <li>
            To process your requests, purchases, transactions, and payments and
            prevent transactional fraud.
          </li>
          <li>
            To provide you with support and to respond to your inquiries,
            including to investigate and address your concerns and monitor and
            improve our responses.
          </li>
          <li>
            To personalize your experience and to deliver content and product
            and service offerings relevant to your interests, including targeted
            offers and ads through our App, third-party sites, and via email or
            text message (with your consent, where required by law).
          </li>
          <li>
            To help maintain the safety, security, and integrity of our App,
            products and services, databases and other technology assets, and
            business.
          </li>
          <li>
            For testing, research, analysis, and product development, including
            to develop and improve our App, products, and services.
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </li>
          <li>
            As described to you when collecting your personal information or as
            otherwise set forth in the CCPA.
          </li>
          <li>
            To evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which personal
            information held by us about our users/consumers is among the assets
            transferred.
          </li>
        </ul>

        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We will not collect additional categories of personal information or
          use the personal information we collected for materially different,
          unrelated, or incompatible purposes without providing you notice.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Sharing Personal Information
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We may share your personal information by disclosing it to a third
          party for a business purpose. We only make these business purpose
          disclosures under written contracts that describe the purposes,
          require the recipient to keep the personal information confidential,
          and prohibit using the disclosed information for any purpose except
          performing the contract. In the preceding twelve (12) months, Company
          has not disclosed personal information for a business purpose. We do
          not sell personal information. In the preceding twelve (12) months,
          Company has not sold personal information.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Your Rights and Choices
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          The CCPA provides consumers (California residents) with specific
          rights regarding their personal information. This section describes
          your CCPA rights and explains how to exercise those rights.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Right to Know and Data Portability
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information over the
          past 12 months (the "right to know"). Once we receive your request and
          confirm your identity we will disclose to you:
        </Typography>

        <ul>
          <li>
            The categories of personal information we collected about you.
          </li>
          <li>
            The categories of sources for the personal information we collected
            about you.
          </li>
          <li>
            Our business or commercial purpose for collecting or selling that
            personal information.
          </li>
          <li>
            The categories of third parties with whom we share that personal
            information.
          </li>
          <li>
            If we sold or disclosed your personal information for a business
            purpose, two separate lists disclosing:
            <ul>
              <li>
                sales, identifying the personal information categories that each
                category of recipient purchased; and{" "}
              </li>
              <li>
                disclosures for a business purpose, identifying the personal
                information categories that each category of recipient obtained.
              </li>
            </ul>
          </li>
          <li>
            The specific pieces of personal information we collected about you
            (also called a data portability request).
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We do not provide a right to know or data portability disclosure for
          B2B personal information.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Right to Delete
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          You have the right to request that we delete any of your personal
          information that we collected from you and retained, subject to
          certain exceptions (the "right to delete"). Once we receive your
          request and confirm your identity, we will review your request to see
          if an exception allowing us to retain the information applies. We may
          deny your deletion request if retaining the information is necessary
          for us or our service provider(s) to:
        </Typography>

        <ul>
          <li>
            Complete the transaction for which we collected the personal
            information, provide a good or service that you requested, take
            actions reasonably anticipated within the context of our ongoing
            business relationship with you, fulfill the terms of a written
            warranty or product recall conducted in accordance with federal law,
            or otherwise perform our contract with you.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity, or prosecute those responsible for
            such activities.
          </li>
          <li>
            Debug products to identify and repair errors that impair existing
            intended functionality.
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise their free speech rights, or exercise another right
            provided for by law.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act
            (Cal. Penal Code § 1546 et. seq.).
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when the information's
            deletion may likely render impossible or seriously impair the
            research's achievement, if you previously provided informed consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with
            consumer expectations based on your relationship with us.
          </li>
          <li>Comply with a legal obligation.</li>
          <li>
            Make other internal and lawful uses of that information that are
            compatible with the context in which you provided it.
          </li>
        </ul>

        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We will delete or deidentify personal information not subject to one
          of these exceptions from our records and will direct our service
          providers to take similar action. <br /> We do not provide these
          deletion rights for B2B personal information.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Exercising Your Rights to Know or Delete
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          To exercise your rights to know or delete described above, please
          submit a request by:
        </Typography>
        <ul>
          <li>Emailing us at contact@hairscoutapp.com</li>
        </ul>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          Only you, or someone legally authorized to act on your behalf, may
          make a request to know or delete related to your personal information.
          <br />
          You may also make a request to know or delete on behalf of your child
          by contacting us at contact@hairscoutapp.com.
          <br /> You may only submit a request to know twice within a 12-month
          period. Your request to know or delete must:
        </Typography>
        <ul>
          <li>
            Provide sufficient information that allows us to reasonably verify
            you are the person about whom we collected personal information or
            an authorized representative, which may include:
            <ul>
              <li>Name</li>
              <li>Email</li>
              <li>Phone Number</li>
              <li>Address</li>
              <li>Username</li>
            </ul>
          </li>
          <li>
            Describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </li>
        </ul>

        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you.
          <br /> You do not need to create an account with us to submit a
          request to know or delete.
          <br /> We will only use personal information provided in the request
          to verify the requestor's identity or authority to make it.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Response Timing and Format
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We will confirm receipt of your request within ten (10) business days.
          If you do not receive confirmation within the 10-day timeframe, please
          send an additional email to contact@hairscout.com.
          <br /> We endeavor to substantively respond to a verifiable consumer
          request within forty-five (45) days of its receipt. If we require more
          time (up to another 45 days), we will inform you of the reason and
          extension period in writing. If you have an account with us, we will
          deliver our written response to that account. If you do not have an
          account with us, we will deliver our written response by mail or
          electronically, at your option.
          <br /> Any disclosures we provide will only cover the 12-month period
          preceding our receipt of your request. The response we provide will
          also explain the reasons we cannot comply with a request, if
          applicable. For data portability requests, we will select a format to
          provide your personal information that is readily useable and should
          allow you to transmit the information from one entity to another
          entity without hindrance.
          <br /> We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Personal Information Sales Opt-Out and Opt-In Rights
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          If you are age 16 or older, you have the right to direct us to not
          sell your personal information at any time (the "right to opt-out").
          We do not sell the personal information of consumers we actually know
          are less than 16 years old. Consumers who opt-in to personal
          information sales may opt-out of future sales at any time.
          <br /> To exercise the right to opt-out, you (or your authorized
          representative) may submit a request to us by email at
          contact@hairscout.com.
          <br />
          <br />
          You do not need to create an account with us to exercise your opt-out
          rights. We will only use personal information provided in an opt-out
          request to review and comply with the request.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Non-Discrimination
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We will not discriminate against you for exercising any of your CCPA
          rights. Unless permitted by the CCPA, we will not:
        </Typography>
        <ul>
          <li>Deny you goods or services.</li>
          <li>
            Charge you different prices or rates for goods or services,
            including through granting discounts or other benefits, or imposing
            penalties.
          </li>
          <li>
            Provide you a different level or quality of goods or services.
          </li>
          <li>
            Suggest that you may receive a different price or rate for goods or
            services or a different level or quality of goods or services.
          </li>
        </ul>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          However, we may offer you certain financial incentives permitted by
          the CCPA that can result in different prices, rates, or quality
          levels. Any CCPA-permitted financial incentive we offer will
          reasonably relate to your personal information's value and contain
          written terms that describe the program's material aspects.
          Participation in a financial incentive program requires your prior
          opt-in consent, which you may revoke at any time.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Changes to Our Privacy Policy
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          We reserve the right to amend this privacy policy at our discretion
          and at any time. When we make changes to this privacy policy, we will
          post the updated notice on the App and update the notice's effective
          date. Your continued use of our App following the posting of changes
          constitutes your acceptance of such changes.
        </Typography>
        <Typography sx={[FONTS.subtitle2, { mt: 2, fontSize: 18 }]}>
          Contact Information
        </Typography>
        <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
          If you have any questions or comments about this notice, the ways in
          which Hair Scout collects and uses your information described here and
          in the Privacy Policy, your choices and rights regarding such use, or
          wish to exercise your rights under California law, please do not
          hesitate to contact us at: <br />
          Email: contact@hairscout.com <br />
          Postal Address: Hair Scout LLC [PHYSICAL ADDRESS YOU WOULD LIKE FOR
          MAIL] <br /> If you need to access this Policy in an alternative
          format due to having a disability, please contact us by email as
          listed above.
        </Typography>
      </Box>

      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CaliforniaPolicy);
