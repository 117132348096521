import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import GlobalStyles from "@mui/material/GlobalStyles";

import IMAGES from "../../constants/images";

import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import BreadCrumb from "../../components/website/BreadCrumb";
import { COLORS, FONTS } from "../../constants";
import CardView from "../../components/website/CardView";

const BlogDetails = (props) => {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />

      <BreadCrumb page="Blog Details" from="Blogs" fromLink="/blog" />
      <Box className="container" sx={{ mt: 10 }}>
        <Grid container spacing={2}>
          <Typography sx={[FONTS.title, { fontSize: 30 }]}>
            How To Start Your Hair Business
          </Typography>
          <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 3 }]}>
            The idea of starting a hair business can be both exciting and
            overwhelming. While a passion for hair may give you the push you
            need to get started, you will need to take a lot more into
            consideration in order to ultimately be successful. We are going to
            share a few tips for anyone interested in starting their own hair
            business.
          </Typography>
          <Typography sx={[FONTS.subtitle2, { fontSize: 16, mt: 5 }]}>
            Do Your Research
          </Typography>
          <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
            Customers want to shop with brands that are considered experts in
            their field. You cannot successfully run a hair business unless you
            do the necessary research to ensure that you are up-to-date on all
            of the latest trends, understand the terminology, know how to
            perform quality assurance and answer any and all questions that may
            arise from your customer base. You will need to be knowledgeable
            about all of the different types of hair that are available and
            their benefits. You will also need to do market research to
            determine where is the best wholesale source for your products and
            what margin to set your prices to be both fair/competitive and
            profitable.
          </Typography>
          <Grid sm={12} md={8} lg={8}>
            <Typography sx={[FONTS.subtitle2, { fontSize: 16, mt: 5 }]}>
              Determine Your Selling Medium
            </Typography>
            <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
              Once you have determined what you want to sell you then have to
              decide how you want to sell it. If you choose to go with a brick
              and mortar storefront you will need to research the best options
              for location, renting vs buying, and factoring the cost of
              maintaining a store. If you choose to sell via an online platform
              you will need to decide if you want to build your own or utilize
              one that is already in place. Building your own online platform
              will come with designing a website, setting up hosting, and
              purchasing a domain. With Hair Scout you can avoid the hassle of
              those logistics and sell your products under your brand name with
              the ability to manage your inventory, except online payments, and
              ensure fraud protection. If you choose to go with an online form
              of selling you will need to take into consideration how you are
              going to store your products to upkeep the best level of quality.
              You will want a space that is free of dust and protected from the
              elements to prevent mold.
            </Typography>
            <Typography sx={[FONTS.subtitle2, { fontSize: 16, mt: 5 }]}>
              Choose your target audience
            </Typography>
            <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
              A common misconception when it comes to selling hair is that
              everyone can be a potential customer. And while that is somewhat
              true, it’s important to identify who exactly you want to target.
              Do you want to sell to younger women who are into the nightlife,
              clubs, and exotic outfits? Or would you want your customers to be
              older women who like to keep it simple and are in the corporate
              world? This can also help you choose which products to push and
              promote more as well. And it can determine which influencers you
              use, how you’ll run your ads, etc. But we’ll talk more about
              marketing on our next point.
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={4}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <img alt="img1" src={IMAGES.blog1} style={{ height: 480 }} />
          </Grid>
          <Typography sx={[FONTS.subtitle2, { fontSize: 16, mt: 5 }]}>
            Solidify Your Branding and Marketing
          </Typography>
          <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
            You want your brand to be easily recognizable and that comes with
            creating a brand name, logo, color scheme and fonts that speak to
            the target clients you want to attract. You will also want to make
            sure that your name is available for your social media handles so
            that you are easily searchable. When you utilize the hair scout
            platform you also get free promotion on our social media platforms
            which increases your audience exposure. Once all of your social
            media platforms are solidified you can begin creating content to
            speak to your audience and even curating ads to target them
            directly.
          </Typography>
          <Grid
            sm={12}
            md={4}
            lg={4}
            sx={{
              mt: 5,
              //   display: "flex",
              //   justifyContent: "right",
              //   alignItems: "center",
            }}
          >
            <img alt="img2" src={IMAGES.blog11} style={{ height: 480 }} />
          </Grid>
          <Grid
            item
            sm={12}
            md={8}
            lg={8}
            sx={{
              mt: 5,
              //   display: "flex",
              //   justifyContent: "right",
              //   alignItems: "center",
            }}
          >
            <Typography sx={[FONTS.subtitle2, { fontSize: 16 }]}>
              Budget
            </Typography>
            <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
              Creating a budget is absolutely necessary if you want to be
              successful. Oftentimes we look at how much money another wig owner
              is making but we don’t see the investment they truly had to make.
              Your budget should include how much you’ll spend on inventory,
              marketing, branding, and your additional supplies. This can range
              anywhere from $3000-$20,000 depending on how big you want your
              launch to be. Just remember that the more you invest, the greater
              your return will be. However, you don’t want to overspend if
              you’re new to the game. It will take a lot of trial and error to
              really get in the flow of your new business. So creating a budget
              and staying disciplined will ensure that you eliminate as much
              risk as possible while you build your new successful business!
            </Typography>
            <Typography sx={[FONTS.subtitle2, { fontSize: 16, mt: 5 }]}>
              Sweeten The Deal
            </Typography>
            <Typography sx={[FONTS.subtitle, { fontSize: 16, mt: 2 }]}>
              It’s important to set yourself apart from your competitors and
              give your customers a reason to keep coming back.You can do this
              by providing subscription incentives, quality accessories,
              customer features on your social media accounts, occasional
              discounts, and anything you can think of that would be beneficial
              to both you and your loyal customers. If you take the time to set
              yourself up for success there is no reason your hair business
              won’t be everything you’ve dreamed of. Once you have established
              your business we would be happy to chat with you about being a
              featured Hair Scout brand!
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
