import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { COLORS, FONTS, IMAGES } from "../../constants";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system"
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BASE_URL } from "../../apis";
import { getLocalStorageKey, handleGetApi } from "../../services";
import { toast } from "react-toastify";
import { addItem } from "../../redux/Slice/CartSlice";
import { useSelector } from "react-redux";

export function NavStrip(props) {
  const token = localStorage.getItem("token");
  const cartItems = useSelector((state) => state.cart.items);
  const [catogeries, setCatogeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    let url = `${BASE_URL.baseUrl}categories?Limit=100&Page=1&PublishedStatus=true&ParentCategoryId=0`;
    try {
      const response = await handleGetApi(url, token);
      sessionStorage.setItem('categories', JSON.stringify(response.data.categories));
      setCatogeries(response.data.categories);
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedCategories = sessionStorage.getItem('categories');
    if (storedCategories) {
      setCatogeries(JSON.parse(storedCategories));
      setLoading(false);
    } else {
      fetchCategories();
    }
  }, []);

  const handleOrderHistoryClick = () => {
    navigate('/customer-history');
  };

  const handleClick = (item) => {
    navigate("/sub-categories-list", { state: item });
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <Box>
      <Navbar
        collapseOnSelect
        // bg="light"
        // fixed="top"
        expand="lg"
        style={{ backgroundColor: COLORS.white, height: 80 }}
      >
        <Container>
          <Navbar.Brand href="/home" sx={{ color: COLORS.black }}>
            <img
              src={IMAGES.logo}
              alt="logo"
              style={{ height: 50, marginTop: 10 }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ background: "white", zIndex: 5 }}
          >
            <Nav
              className=" me-auto justify-content-end flex-grow-1 pe-3"
              style={{ padding: "20px" }}
            >
              <Nav.Link
                href="/home"
                style={{
                  fontFamily: "PTSans-Bold",
                  // color: COLORS.black,

                }}
              >
                Home
              </Nav.Link>
              {/* <NavDropdown
              title="Categories"
              id="collasible-nav-dropdown"
              color={COLORS.white}
              style={{
                marginRight: 20,
                fontFamily: "PTSans-Bold",
              }}
            >
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> 
            </NavDropdown> */}
              {/* <Nav.Link
                href="/brands"
                style={{
                  fontFamily: "PTSans-Bold",
                  // color: COLORS.lightgrey,
                }}
              >
                Our Brands
              </Nav.Link> */}
              {/* <Nav.Link
                href="./shop"
                style={{
                  fontFamily: "PTSans-Bold",
                  // color: COLORS.lightgrey,
                }}
              >
                Shop
              </Nav.Link> */}
              <Nav.Link
                href="./about-us"
                style={{
                  fontFamily: "PTSans-Bold",
                  // color: COLORS.lightgrey,
                }}
              >
                About Us
              </Nav.Link>
              {/* <Nav.Link
                href="./blog"
                style={{
                  fontFamily: "PTSans-Bold",
                  // color: COLORS.lightgrey,
                }}
              >
                Blog
              </Nav.Link> */}
              {/* <NavDropdown
              title="Dropdown"
              id="collasible-nav-dropdown"
              style={{ fontFamily: "PTSans-Bold", color: COLORS.secondary }}
            >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>  
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            </Nav>
            <Nav
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0px 20px",
              }}
            >
              {/* <Nav.Link href="#deets">
                <FavoriteBorderOutlinedIcon
                  sx={{ mr: 0.5, color: COLORS.primary }}
                />
              </Nav.Link> */}
              <Nav.Link href="./cart" style={{ position: "relative" }}>
                <Typography sx={{ mr: 0.5, color: COLORS.darkgrey, position: "absolute", top: -6 }}>
                  {cartItems.length > 0 ? cartItems?.length : ""}
                </Typography>
                <ShoppingBagOutlinedIcon
                  sx={{ mr: 0, color: COLORS.primary }}
                />
              </Nav.Link>
              {!getLocalStorageKey('token') && <Nav.Link eventKey={2} href="./login" >
                <PersonOutlineOutlinedIcon
                  sx={{ mr: 1, color: COLORS.primary }}
                />

              </Nav.Link>}
              {getLocalStorageKey('token') && <NavDropdown
                title={
                  <PersonOutlineOutlinedIcon
                    sx={{ mr: 1, color: COLORS.primary }}
                  />
                }

                id="collasible-nav-dropdown"
                style={{ fontFamily: "PTSans-Bold", color: COLORS.secondary }}
              >
                <NavDropdown.Item onClick={handleOrderHistoryClick}>
                  Order History
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Stack
        style={{
          backgroundColor: COLORS.secondary,
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 10px",
        }}
      >
        <Box
          style={{
            backgroundColor: COLORS.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            zIndex: 3
          }}
        >
          {loading ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              {catogeries?.length > 0 ? (
                catogeries?.map((data) => (
                  <Typography
                    key={data.id}
                    style={{
                      fontFamily: "PTSans-Bold",
                      color: COLORS.white,
                      padding: "5px 9px",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: 17,
                    }}
                    onClick={() => handleClick(data)}
                  >
                    {data?.name}
                  </Typography>
                ))
              ) : (
                <Typography
                  style={{
                    fontFamily: "PTSans-Bold",
                    color: COLORS.white,
                    padding: "5px 9px",
                    fontSize: 18,
                  }}
                >
                  No data found
                </Typography>
              )}
            </>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
