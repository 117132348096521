import { ICONS } from "../../constants";
// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const getImage = (name) => (
  <img src={name} alt="img" style={{ height: 17, width: 17 }} />
);

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/customer/customer-dashboard",
    icon: getImage(ICONS.dashboard),
  },
  // {
  //   title: "Jewel Hair",
  //   path: "/customer/customer-profile",
  //   icon: getImage(ICONS.user),
  // },

  {
    title: "Payment Method",
    path: "/customer/customer-payment-method",
    icon: getImage(ICONS.payment),
  },
  {
    title: "Cart",
    path: "/customer/customer-cart",
    icon: getImage(ICONS.package),
  },
  {
    title: "Wishlist",
    path: "/customer/customer-orders-history",
    icon: getImage(ICONS.history),
  },
  {
    title: "Order History",
    path: "/customer/customer-orders-history",
    icon: getImage(ICONS.history),
  },
  {
    title: "Notifications",
    path: "/customer/customer-contact-us",
    icon: getImage(ICONS.notebook),
  },
  {
    title: "Notification Settings",
    path: "/customer/customer-notification-setting",
    icon: getImage(ICONS.notifications),
  },
  {
    title: "Security Settings",
    path: "/customer/customer-security-settings",
    icon: getImage(ICONS.shield),
  },
  // {
  //   title: "Change Password",
  //   path: "/customer/customer-change-password",
  //   icon: getImage(ICONS.lock),
  // },
  {
    title: "FAQs",
    path: "/customer/customer-faqs",
    icon: getImage(ICONS.faq),
  },
  {
    title: "Policies, Terms & Conditions",
    path: "/customer/customer-terms",
    icon: getImage(ICONS.terms),
  },
  {
    title: "Hair Scout Contact Info",
    path: "/customer/customer-contact-us",
    icon: getImage(ICONS.notebook),
  },

  // {
  //   title: "Chat With Customer Service",
  //   path: "/customer/review-ads",
  //   icon: getImage(ICONS.chat),
  // },
];

export default sidebarConfig;
