import React from "react";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { COLORS, IMAGES } from "../../constants";

function BreadCrumb({ page, from, handleClickProducts }) {
  const navigate = useNavigate();

  const handleFromLinkClick = (event) => {
    event.preventDefault();
    handleClickProducts(); // Call the handleClickProducts function
  };

  return (
    <Box
      style={{
        display: "flex",
        backgroundImage: `url(${IMAGES.breadcrumb})`,
        height: 300,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        className=" breadcrumb-option container"
        style={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "PlayfairDisplay-Bold",
              fontSize: 36,
              color: COLORS.primary,
            }}
          >
            {page}
          </Typography>
          <Breadcrumbs
            separator="›"
            sx={{ color: COLORS.white, fontFamily: "PTSans-Bold" }}
            aria-label="breadcrumb"
          >
            <Link
              className="link"
              sx={{
                fontFamily: "PTSans-Bold",
                color: COLORS.secondary,
                textDecoration: "none",
              }}
              color="inherit"
              onClick={handleFromLinkClick} // Handle click event
            >
              {from}
            </Link>
            <Typography
              sx={{ color: COLORS.secondary, fontFamily: "PTSans-Bold" }}
            >
              {page}
            </Typography>
          </Breadcrumbs>
        </Box>
        <img src={IMAGES.breadcrumb_img} style={{ maxHeight: 300 }} />
      </Box>
    </Box>
  );
}

export default BreadCrumb;
