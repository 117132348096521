import { BASE_URL, STAFF } from "../../../apis";
import {
  handleDeleteApi,
  handleGetApi,
  handlePostApi,
} from "../../../services";
import { staffTypes } from "../../types";

export const inviteStaff = (emailAddress, firstName, lastName, token) => {
  const body = JSON.stringify({
    email: emailAddress,
    first_name: firstName,
    last_name: lastName,
  });
  return async (dispatch) => {
    dispatch({
      type: staffTypes.INVITE_STAFF_LOADING,
    });

    const response = await handlePostApi(
      BASE_URL.baseUrl + STAFF.inviteStaffMember,
      body,
      token
    );
    console.log("Invite Staff Response", response?.data);
    if (response) {
      dispatch({
        type: staffTypes.INVITE_STAFF_SUCCESS,
        payload: response?.data,
      });
    } else {
      dispatch({
        type: staffTypes.INVITE_STAFF_FAILURE,
      });
    }
  };
};

export const getInvitedStaff = (token) => {
  return async (dispatch) => {
    dispatch({
      type: staffTypes.STAFF_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + STAFF.invitedStaff,
      token
    );
    console.log("Staff Response", response?.data);
    if (response) {
      dispatch({
        type: staffTypes.STAFF_SUCCESS,
        payload: response?.data,
      });
    } else {
      dispatch({
        type: staffTypes.STAFF_FAILURE,
      });
    }
  };
};

export const getInvitedStaffById = (staffId, token) => {
  return async (dispatch) => {
    dispatch({
      type: staffTypes.STAFF_MEMBER_LOADING,
    });

    const response = await handleGetApi(
      BASE_URL.baseUrl + STAFF.staffById + "staffMemberId=" + staffId,
      token
    );
    console.log("Staff Member Response", response?.data);
    if (response) {
      dispatch({
        type: staffTypes.STAFF_MEMBER_SUCCESS,
        payload: response?.data,
      });
    } else {
      dispatch({ type: staffTypes.STAFF_MEMBER_FAILURE });
    }
  };
};

export const deleteStaffMember = (memberId, token) => {
  return async (dispatch) => {
    dispatch({ type: staffTypes.DELETE_STAFF_MEMBER_LOADING });

    const response = await handleDeleteApi(
      BASE_URL.baseUrl + STAFF.deleteStaff + "staffMemberId=" + memberId,
      null,
      token
    );
    console.log("Delete Member Response", response?.data);
    if (response) {
      dispatch({
        type: staffTypes?.DELETE_STAFF_MEMBER_SUCCESS,
      });
    } else {
      dispatch({ type: staffTypes?.DELETE_STAFF_MEMBER_FAILURE });
    }
  };
};
