import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { COLORS, ICONS, IMAGES } from "../constants";
import StarIcon from "@mui/icons-material/Star";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
function OrderCard({ isDelivered }) {
  return (
    <Box sx={{ my: 2 }}>
      <Stack
        direction={{ xs: "row", sm: "row" }}
        sx={{
          boxShadow: 2,
          padding: 0,
          alignItems: "center",
        }}
      >
        <Avatar
          variant="square"
          src={IMAGES.dummy}
          sx={{ height: 210, width: 230 }}
        />
        <Stack sx={{ justifyContent: "space-between", width: "100%", p: 2 }}>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontFamily: "PTSans-Bold", fontSize: 22 }}>
              Curly Hair
            </Typography>
            {isDelivered && (
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Typography
                  sx={{
                    color: COLORS.secondary,
                    mr: 2,
                    fontFamily: "PTSans-Regular",
                  }}
                >
                  Delivered
                </Typography>
                <Avatar
                  variant="square"
                  src={ICONS.package}
                  sx={{ height: 30, width: 30 }}
                />
              </Stack>
            )}
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ alignItems: "center" }}
          >
            <StarIcon sx={{ height: 15, width: 15, color: COLORS.secondary }} />
            <StarIcon sx={{ height: 15, width: 15, color: COLORS.secondary }} />
            <StarIcon sx={{ height: 15, width: 15, color: COLORS.secondary }} />
            <StarIcon sx={{ height: 15, width: 15, color: COLORS.secondary }} />
            <StarIcon sx={{ height: 15, width: 15, color: COLORS.secondary }} />
            <Typography sx={{ fontSize: 12, fontFamily: "PTSans-Bold" }}>
              (1.2K)
            </Typography>
          </Stack>
          <Stack direction={{ xs: "row", sm: "row" }} sx={{ my: 2 }}>
            <StoreMallDirectoryIcon sx={{ color: COLORS.secondary }} />
            <Typography
              sx={{
                color: COLORS.grey,
                ml: 2,
                fontFamily: "PTSans-Regular",
              }}
            >
              Laiba Saloon
            </Typography>
          </Stack>
          <Stack direction={{ xs: "row", sm: "row" }} sx={{ my: 1 }}>
            <Stack sx={{ width: 100 }}>
              <Typography
                sx={{
                  color: COLORS.grey,
                  mr: 2,
                  fontFamily: "PTSans-Regular",
                }}
              >
                Color:
              </Typography>
            </Stack>
            <Typography sx={{ color: COLORS.black, fontFamily: "PTSans-Bold" }}>
              Black
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <Stack direction={{ xs: "row", sm: "row" }} sx={{ width: "50%" }}>
              <Stack sx={{ width: 100 }}>
                <Typography
                  sx={{
                    color: COLORS.grey,
                    mr: 2,
                    fontFamily: "PTSans-Regular",
                  }}
                >
                  Length:
                </Typography>
              </Stack>
              <Typography
                sx={{ color: COLORS.black, fontFamily: "PTSans-Bold" }}
              >
                10"
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{ width: "50%", alignItems: "center" }}
            >
              <Stack sx={{ width: 100 }}>
                <Typography
                  sx={{
                    color: COLORS.grey,
                    mr: 2,
                    fontFamily: "PTSans-Regular",
                  }}
                >
                  Price:
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: COLORS.secondary,
                  fontFamily: "PTSans-Bold",
                  fontSize: 20,
                }}
              >
                $56.87
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Button fullWidth variant="contained" sx={{ mt: 0, borderRadius: 0 }}>
        View Product Details
      </Button>
    </Box>
  );
}
export default OrderCard;
