import { Box, Card, Grid, Rating, Stack, Typography } from "@mui/material";

import { COLORS, FONTS, IMAGES } from "../../constants";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { RatingStars } from "../Rating";
function Product({
  image,
  productName,
  discountPrice,
  price,
  brand,
  rating,
  onPressProduct,
}) {
  return (
    <Grid item x={12} sm={6} md={4} lg={3} onClick={onPressProduct}>
      <Card
        sx={{
          height: 380,

          backgroundColor: COLORS.white,
          // elevation: 3,
          borderRadius: 0,
          border: 5,
          borderColor: COLORS.border,
        }}
      >
        <Stack>
          <img src={image} style={{ height: 230 }} />
          <Typography
            sx={{
              fontFamily: FONTS.subtitle,
              backgroundColor: COLORS.primary,
              color: COLORS.white,
              width: 90,
              borderRadius: 1,
              textAlign: "center",
              fontSize: 12,
              p: 0.5,
              position: "absolute",
              left: 7,
              top: 7,
            }}
          >
            {brand}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              mt: 2,
              fontFamily: FONTS.title,
              fontSize: 14,
              color: COLORS.secondary,
            }}
          >
            {productName}
          </Typography>
          <Box
            sx={{
              borderColor: COLORS.lightgrey,
              border: "0.7px solid #f1f1f1",
              mt: 2,
            }}
          />
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{
              justifyContent: "space-between",
              paddingLeft: 2,
              paddingRight: 2,
              mt: 1,
            }}
          >
            <Box>
              <Typography
                sx={{
                  mt: 2,
                  fontFamily: FONTS.subtitle2,
                  fontSize: 16,
                  color: COLORS.secondary,
                }}
              >
                ${discountPrice && discountPrice?.toFixed(2)}
                {discountPrice !== price && (
                  <span
                    style={{
                      mt: 2,
                      fontFamily: FONTS.subtitle2,
                      fontSize: 12,
                      textDecoration: "none",
                      color: COLORS.grey,
                      textDecoration: "line-through",
                      marginLeft: 10,
                    }}
                  >
                    ${price.toFixed(2)}
                  </span>
                )}
              </Typography>
              <RatingStars ratingValue={rating} />
              {/* <StarIcon
                sx={{
                  color: COLORS.primary,
                  height: 16,
                  width: 16,
                }}
              />
              <StarIcon
                sx={{
                  color: COLORS.primary,
                  height: 16,
                  width: 16,
                }}
              />
              <StarIcon
                sx={{
                  color: COLORS.primary,
                  height: 16,
                  width: 16,
                }}
              />
              <StarIcon
                sx={{
                  color: COLORS.primary,
                  height: 16,
                  width: 16,
                }}
              />
              <StarBorderIcon
                sx={{
                  color: COLORS.primary,
                  height: 16,
                  width: 16,
                }}
              /> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ml: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ml: 1,
                  mt: 2,
                  backgroundColor: COLORS.primary,
                  padding: 1,
                  borderRadius: 30,
                }}
              >
                <ShoppingBasketIcon
                  sx={{
                    color: COLORS.white,
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
}
export default Product;
