// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Card,
  CardHeader,
  Box,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
// ----------------------------------------------------------------------
// Imports
import { toast, ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProductTag from "../../../components/ProductTag";
import { connect } from "react-redux";
import { setProductPrices } from "../../../redux/actions";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function ProductPricing(props) {
  const navigate = useNavigate();
  const [colors, setColors] = useState([
    { id: "1", name: "1" },
    { id: "1B", name: "1B" },
    { id: "2", name: "2" },
    { id: "27/30", name: "27/30" },
    { id: "4", name: "4" },
    { id: "613", name: "613" },
  ]);

  const [lengths, setLengths] = useState([]);
  const [attributes, setAttributes] = useState([]);
  useEffect(() => {
    const { productLengths } = props.setupProduct;
    const lengths = [];
    const attributes = [];
    productLengths.forEach((element) => {
      let length = {};
      length = { length: element, fields: [1] };
      // console.log(item);
      let attribute = {
        id: 1,
        length: element,
        color: "",
        price: "",
        stock: 0,
        open: false,
      };
      lengths.push(length);
      attributes.push(attribute);
    });
    setLengths(lengths);
    setAttributes(attributes);
  }, []);

  const handleFields = (len) => {
    let newLengths = [...lengths];
    let element = lengths.find((item) => {
      return item.length === len.length;
    });
    element = element.fields.push(element.fields.length + 1);
    let index = newLengths.findIndex((el) => el.length === len.length);
    newLengths[index] = { ...newLengths[index] };
    let attribute = {
      id: len.fields.length,
      length: len.length,
      color: "",
      price: "",
      stock: 0,
    };
    setLengths(newLengths);
    setAttributes((attributes) => [...attributes, attribute]);
  };
  const setColor = (lengthObj, color) => {
    console.log(lengthObj, color);
    const result = attributes.filter((element) => {
      return element.color === color && element.length === lengthObj.length;
    });
    if (result.length > 0) {
    } else {
      // this.setState({ color: color });
      let newAttributes = [...attributes];
      console.log(newAttributes);
      const obj = newAttributes.find((item) => {
        return (
          item.id === lengthObj.fields.length &&
          item.length === lengthObj.length
        );
      });
      console.log(obj);
      obj.color = color;
      setAttributes(newAttributes);
      // console.log(this.state.attributes);
    }
  };
  const setPrice = (lengthObj, price) => {
    console.log(price);
    console.log(lengthObj.fields, lengthObj.length);
    let newAttributes = [...attributes];
    const obj = newAttributes.find((item) => {
      return (
        item.id === lengthObj.fields.length && item.length === lengthObj.length
      );
    });
    obj.price = price.replace(/[^0-9]/g, "");
    setAttributes(newAttributes);
  };
  const setStock = (len, stock) => {
    console.log(len.fields, len.length);
    let newAttributes = [...attributes];
    const obj = newAttributes.find((item) => {
      return item.id === len.fields.length && item.length === len.length;
    });
    obj.stock = stock;
    setAttributes(newAttributes);
    // console.log(this.state.attributes);
  };
  const handleSetPrices = async () => {
    const { productCategory } = props.setupProduct;
    let isColorEmpty = false;
    let isPriceEmpty = false;
    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i++) {
        if (
          attributes[i].color === undefined ||
          attributes[i].color === null ||
          attributes[i].color === ""
        ) {
          isColorEmpty = true;
          console.log("color is empty");
        } else if (
          attributes[i].price === undefined ||
          attributes[i].price === null ||
          attributes[i].price === "" ||
          attributes[i].price === " "
        ) {
          isPriceEmpty = true;
          console.log("price is empty");
        }
      }
    }
    if (isColorEmpty === true) {
      toast.error("One or more color fields are empty");
    } else if (isPriceEmpty === true) {
      toast.error("One or more price fields are empty");
    } else {
      console.log("Product listing done");
      await props.setProductPrices(attributes);
      // this.props.navigation.navigate("ProductDimensions");
      if (productCategory?.categoryName === "Wigs") {
        // this.props.navigation.navigate("DensitySize");
        navigate("/product-dimensions");
      } else {
        // this.props.navigation.navigate("ProductDimensions");
        navigate("/product-dimensions");
      }
    }
    // this.props.navigation.navigate("SetupShipping");
  };
  const deleteColor = (index, element) => {
    console.log("element:", index, element);
    console.log("Attributes:", attributes);
    let filteredArray = attributes.filter(
      (item) => item.id !== index + 1 && item.length === element.length
    );
    setAttributes(filteredArray);
    // console.log("Length", this.state.lengths[index]);
    let newLengths = [...lengths];
    let obj = newLengths.find((item) => {
      return item.length === element.length;
    });
    console.log(obj);
    if (obj.fields.length > 1) {
      let fields = obj?.fields.filter((item, i) => {
        return i !== index;
      });
      obj.fields = fields;
    }
    console.log("ObjectAfter", obj);
    setLengths(newLengths);
  };
  return (
    <RootStyle title="Product Pricing | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card>
          <CardHeader
            title="Choose Colors"
            subheader="What color will you offer?"
          />

          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            {lengths?.map((item, index) => (
              <Box key={index}>
                <Typography sx={{ mb: 2, mt: 5 }}>
                  {item?.length} Inches Straight Hair
                </Typography>
                {item.fields &&
                  item.fields.map((element, index) => (
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      marginBottom={2}
                      key={index}
                    >
                      <FormControl required={true} fullWidth>
                        <InputLabel id="color-select">Select Color</InputLabel>
                        <Select
                          required
                          labelId="color-select"
                          id="color-select"
                          label="Select Color"
                          onChange={(e) => setColor(item, e.target.value)}
                        >
                          {colors.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        type="number"
                        required={true}
                        fullWidth
                        label="Price"
                        onChange={(e) => setPrice(item, e.target.value)}
                      />
                      <TextField
                        type="number"
                        required={true}
                        fullWidth
                        label="# in stock"
                        onChange={(e) => setStock(item, e.target.value)}
                      />
                      {index > 0 && (
                        <IconButton onClick={() => deleteColor(index, item)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
                <Button variant="contained" onClick={() => handleFields(item)}>
                  + Add Color
                </Button>
              </Box>
            ))}
          </Box>
          <Box sx={{ mx: 3, my: 3 }}>
            <Button
              variant="contained"
              size="large"
              sx={{ width: 200, mb: 2, height: 50 }}
              // onClick={() => navigate("/product-dimensions")}
              onClick={() => handleSetPrices()}
            >
              Next
            </Button>
          </Box>
        </Card>
      </Container>
      <ToastContainer />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  setupProduct: state.setupProduct,
});
const mapDispatchToProps = (dispatch) => ({
  setProductPrices: (prices) => dispatch(setProductPrices(prices)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductPricing);
