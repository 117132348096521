import { Box, Grid, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { COLORS, FONTS } from "../../constants";

export const Payment = ({ name, accountNumber }) => {
  <Box sx={{ background: COLORS.white, my: 3, boxShadow: 4, p: 3 }}>
    <Grid container>
      <Grid item sx={11} md={11} lg={11}>
        <Stack
          direction={{ xs: "row", sm: "row", lg: "row" }}
          // sx={{ justifyContent: "space-between" }}
        >
          <PersonIcon sx={{ color: COLORS.secondary }} />
          <Typography sx={{ ml: 2, fontFamily: FONTS.subtitle2, fontSize: 18 }}>
            Williams Credit Card
          </Typography>
        </Stack>
        <Stack direction={{ xs: "row", sm: "row", lg: "row" }} sx={{ mt: 2 }}>
          <CreditCardIcon sx={{ color: COLORS.secondary }} />
          <Typography sx={{ ml: 2, fontFamily: FONTS.subtitle }}>
            Visa 12432324234324
          </Typography>
        </Stack>
      </Grid>
      <Grid
        sm={1}
        md={1}
        lg={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DeleteIcon sx={{ color: COLORS.primary }} />
        <BorderColorIcon sx={{ color: COLORS.primary, ml: 2 }} />
      </Grid>
    </Grid>
  </Box>;
};
