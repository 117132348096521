import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingModal from "../../../components/modals/LoadingModal";
import Page from "../../../components/Page";
import { COLORS, FONTS, IMAGES } from "../../../constants";
import { store } from "../../../redux/store/store";
import {
  getCategories,
  getProductDetails,
  getSubCategories,
  deleteVendorProduct,
} from "../../../redux/actions";
import Carousel from "react-bootstrap/Carousel";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
function ProductDetails(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [subCategory, setSubCategory] = useState("");
  const [category, setCategory] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function func() {
      await handleGetProductDetail();
      await getProductCategories();
      await getProductSubCategories();
      const { productDetails } = props.vendorStore;
      findProductSubCategory(productDetails[0]?.category_ids[0]);
      findProductCategory(subCategory?.parent_category_id);
    }
    func();
  }, []);
  const handleGetProductDetail = async () => {
    const { accessToken } = props.authentication;
    await props
      .getProductDetails(id, accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const getProductCategories = async () => {
    const { accessToken } = props.authentication;
    await props
      .getProductCategories(
        pageSize,
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        accessToken
      )
      .then(() => {
        console.log("Categories found");
      })
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };
  const getProductSubCategories = async () => {
    const { accessToken } = props.authentication;
    await props
      .getProductSubCategories(
        pageSize,
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        accessToken
      )
      .then(() => {
        console.log("Categories found");
      })
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };
  const getUniqueJsonArray = (array, key) => {
    const uniqueArray = [
      ...new Map(array.map((item) => [item[key], item])).values(),
    ];
    return uniqueArray;
  };
  const findProductCategory = (categoryId) => {
    const { categories } = props.common;
    // console.log("categories", categories);
    const categoryObj = categories.find((item) => item.id === categoryId);
    // console.log("Category Object", categoryObj);
    setCategory(categoryObj);
  };
  const findProductSubCategory = (categoryId) => {
    const { subCategories } = props.common;
    // console.log("categories", subCategories);
    const subCategoryObj = subCategories?.find(
      (item) => item?.id === categoryId
    );
    // console.log("Sub Category Object", subCategoryObj);
    setSubCategory(subCategoryObj);
  };
  const handleDelete = () => {
    setOpen(true);
  };
  const handleDeleteProduct = (productId) => {
    const { accessToken } = props.authentication;
    props
      .deleteProduct(productId, accessToken)
      .then(() => {
        const { deleteProductStatus } = store.getState().vendorStore;
        if (deleteProductStatus) {
          toast.error("Product is deleted successfully");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const { productDetailsLoading, productDetails, deleteProductLoading } =
    props.vendorStore;
  return (
    <Page title="Dashboard | Product Details">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: FONTS.title }}>
          Product Preview
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="./active-products"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Active Products
            </Link>
            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Product Preview
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          {/* <Typography
            variant="h6"
            sx={{ fontFamily: FONTS.subtitle2, color: "text.primary" }}
          >
            Product Details
          </Typography> */}
          <Stack
            overflow={"auto"}
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 2 }}
          >
            {productDetails[0]?.images?.map((image, index) => (
              <img
                key={index}
                // className="d-block w-100"
                src={image?.src}
                alt="First slide"
                style={{ height: 300, marginRight: 20 }}
              />
            ))}
          </Stack>
          {/* <Stack   
            overflow={"auto"}
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 2 }}
          >
            <Carousel style={{ width: "100%" }}>
              {productDetails[0]?.images?.map((image, index) => (
                <Carousel.Item key={index} style={{ width: "100%" }}>
                  <img
                    className="d-block w-100"
                    src={image?.src}
                    alt="First slide"
                    style={{ height: 500, objectFit: "contain" }}
                  /> */}
          {/* <Carousel.Caption>  
                    <h3>First slide label</h3>
                    <p>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </p>
                  </Carousel.Caption> */}
          {/* </Carousel.Item>
              ))}
            </Carousel> */}
          {/* <Avatar
              variant="square"
              src={IMAGES.dummy}
              sx={{ mr: 1, height: 150, width: 150 }}
            />
            <Avatar
              variant="square"
              src={IMAGES.dummy}
              sx={{ mx: 1, height: 150, width: 150 }}
            /> 
            <Avatar
              variant="square"
              src={IMAGES.dummy}
              sx={{ mx: 1, height: 150, width: 150 }}
            /> */}
          {/* </Stack> */}
          <Typography sx={{ fontFamily: "PTSans-Bold", mt: 3, fontSize: 22 }}>
            {productDetails[0]?.name}
          </Typography>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 2, alignItems: "center" }}
          >
            <Typography
              sx={{
                fontFamily: "PTSans-Regular",
                mr: 2,
                color: COLORS.grey,
              }}
            >
              Price:
            </Typography>
            <Typography
              sx={{
                fontFamily: "PTSans-Bold",
                color: COLORS.secondary,
                fontSize: 20,
              }}
            >
              ${(productDetails[0]?.attributes[0]?.price).toFixed(2)}
            </Typography>
          </Stack>
          <Typography
            sx={{ mt: 2, color: COLORS.grey, fontFamily: "PTSans-Regular" }}
          >
            {productDetails[0]?.short_description}
          </Typography>
          {getUniqueJsonArray(
            productDetails[0]?.attributes,
            "length_inches"
          ).map((product, index) => (
            <Box key={index}>
              <Stack direction={{ xs: "row", sm: "row" }} sx={{ mt: 5 }}>
                <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
                  Length{":    "}
                </Typography>

                <Typography
                  sx={{
                    color: COLORS.secondary,
                    alignSelf: "flex-start",
                    fontFamily: FONTS.subtitle2,
                    ml: 1,
                  }}
                >
                  {product?.length_inches} Inches
                </Typography>
              </Stack>
              {productDetails[0].attributes
                .filter((item) => {
                  return item.length_inches === product.length_inches;
                })
                .map((item, index) => (
                  <Grid
                    key={index}
                    container
                    spacing={2}
                    sx={{ mt: 2, justifyContent: "space-between" }}
                  >
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Stack
                        direction={{ xs: "row", sm: "row" }}
                        sx={{
                          justifyContent: "space-between",
                          border: 1,
                          borderColor: COLORS.lightgrey,
                          borderRadius: 1,
                          alignItems: "center",
                          height: 40,
                          pl: 2,
                          pr: 2,
                        }}
                      >
                        <Typography sx={{ fontFamily: "PTSans-Bold" }}>
                          Color
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "PTSans-Bold",
                            color: COLORS.secondary,
                          }}
                        >
                          {item?.color}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Stack
                        direction={{ xs: "row", sm: "row" }}
                        sx={{
                          justifyContent: "space-between",
                          border: 1,
                          borderColor: COLORS.lightgrey,
                          borderRadius: 1,
                          alignItems: "center",
                          height: 40,
                          pl: 2,
                          pr: 2,
                        }}
                      >
                        <Typography sx={{ fontFamily: "PTSans-Bold" }}>
                          Length
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "PTSans-Bold",
                            color: COLORS.secondary,
                          }}
                        >
                          {item?.length_inches}"
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Stack
                        direction={{ xs: "row", sm: "row" }}
                        sx={{
                          justifyContent: "space-between",
                          border: 1,
                          borderColor: COLORS.lightgrey,
                          borderRadius: 1,
                          alignItems: "center",
                          height: 40,
                          pl: 2,
                          pr: 2,
                        }}
                      >
                        <Typography sx={{ fontFamily: "PTSans-Bold" }}>
                          Price
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "PTSans-Bold",
                            color: COLORS.secondary,
                          }}
                        >
                          ${item?.price}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Stack
                        direction={{ xs: "row", sm: "row" }}
                        sx={{
                          justifyContent: "space-between",
                          border: 1,
                          borderColor: COLORS.lightgrey,
                          borderRadius: 1,
                          alignItems: "center",
                          height: 40,
                          pl: 2,
                          pr: 2,
                        }}
                      >
                        <Typography sx={{ fontFamily: "PTSans-Bold" }}>
                          Stock
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "PTSans-Bold",
                            color: COLORS.secondary,
                          }}
                        >
                          {item?.stock}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          ))}
          <Stack direction={{ xs: "row", sm: "row" }} sx={{}}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                border: 1,
                borderRadius: 1,
                mt: 7,
                height: 40,
                width: "50%",
                justifyContent: "space-between",
                alignItems: "center",
                pl: 2,
                pr: 2,
              }}
            >
              <Typography sx={{ fontFamily: "PTSans-Bold" }}>
                Hair Type
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PTSans-Bold",
                  color: COLORS.secondary,
                  ml: 3,
                }}
              >
                {category?.name}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                border: 1,
                borderRadius: 1,
                mt: 7,
                height: 40,
                width: "49%",
                justifyContent: "space-between",
                alignItems: "center",
                pl: 2,
                pr: 2,
                ml: 2,
              }}
            >
              <Typography sx={{ fontFamily: "PTSans-Bold" }}>
                Hair Category
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PTSans-Bold",
                  color: COLORS.secondary,
                  ml: 3,
                }}
              >
                {subCategory?.name}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            sx={{ my: 3 }}
          >
            {category?.name === "Wigs" && (
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ mt: 2, alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontFamily: "PTSans-Regular",
                    mr: 2,
                    color: COLORS.grey,
                  }}
                >
                  Care Instruction Period:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PTSans-Bold",
                    color: COLORS.secondary,
                    fontSize: 20,
                  }}
                >
                  {productDetails[0]?.care_instruction_no}
                  {productDetails[0]?.care_instruction_period}
                </Typography>
              </Stack>
            )}
            {/* <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="processed"
                  control={<Radio />}
                  label="Processed"
                />
                <FormControlLabel
                  value="unprocessed"
                  control={<Radio />}
                  label="Un Processed"
                />
              </RadioGroup>
            </FormControl> */}
          </Stack>
          <Button variant="contained" sx={{ width: 200 }}>
            Update Product
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 2, width: 200 }}
            onClick={() => handleDelete()}
          >
            Delete Product
          </Button>
        </Box>
      </Container>
      <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        title="Delete Product"
        text="Do you really want to delete this product?"
        onPress={() => handleDeleteProduct(productDetails[0]?.id)}
      />
      <LoadingModal open={productDetailsLoading || deleteProductLoading} />
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorStore: state.vendorStore,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  getProductDetails: (id, token) => dispatch(getProductDetails(id, token)),
  deleteProduct: (productId, token) =>
    dispatch(deleteVendorProduct(productId, token)),
  getProductCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
  getProductSubCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getSubCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
