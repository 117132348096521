// ----------------------------------------------------------------------
// Material
import { Container, Stack, Typography, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
// ----------------------------------------------------------------------
// Imports
import { ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../components/Page";
import RegisterForm from "../../components/authentication/register/RegisterForm";
import { MHidden } from "../../components/@material-extend";
import { COLORS, IMAGES } from "../../constants";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 700,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  //margin: theme.spacing(2, 0, 2, 2),
  borderRadius: 0,
  height: "100vh",
  backgroundImage: `url(${IMAGES.welcome})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(12, 0),
}));
function Register() {
  return (
    <RootStyle title="Register | Hair Scout">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            variant="h2"
            sx={{
              color: COLORS.white,
              fontFamily: "PlayfairDisplay-Bold",
            }}
          >
            Looks like You'r new here!
          </Typography>
          <Typography
            sx={{
              color: COLORS.white,
              fontSize: 18,
              textAlign: "center",
              fontFamily: "PTSans-Regular",
            }}
          >
            Join our group in few minutes! Sign up with your details to get
            started
          </Typography>
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "center",
                color: COLORS.secondary,
                fontFamily: "PlayfairDisplay-Bold",
              }}
            >
              Sign Up
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "center",
                fontFamily: "PTSans-Regular",
              }}
            >
              Enter your credentials to continue
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <RegisterForm />
        </ContentStyle>
        <ToastContainer />
      </Container>
    </RootStyle>
  );
}

export default Register;
