import { commonTypes } from "../../types";

const initialState = {
  networkStatus: true,
  statesLoading: false,
  statesStatus: false,
  states: null,

  uploadImageLoading: false,
  uploadImageStatus: false,
  uploadImageLink: null,
  uploadImageId: 0,

  categoriesLoading: false,
  categories: [],

  subCategoriesLoading: false,
  subCategories: null,

  setFcmTokenLoading: false,
  setFcmTokenStatus: false,

  messageSendLoading: false,
  messageSendStatus: false,

  chatHistoryLoading: false,
  chatHistory: undefined,

  faqsLoading: false,
  faqs: undefined,

  termsLoading: false,
  terms: undefined,

  contactInfoLoading: false,
  contactInfo: undefined,

  alertMessage: "",
  isAlertVisible: false,
};
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.SET_NETWORK_STATUS:
      return {
        ...state,
        networkStatus: action.payload,
      };
    case commonTypes.SHOW_ALERT:
      return {
        ...state,
        isAlertVisible: true,
        alertMessage: action.payload,
      };
    case commonTypes.HIDE_ALERT:
      return {
        ...state,
        isAlertVisible: false,
        alertMessage: "",
      };
    case commonTypes.STATES_LOADING:
      return {
        ...state,
        statesLoading: true,
        statesStatus: false,
      };
    case commonTypes.STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        statesLoading: false,
        statesStatus: true,
      };
    case commonTypes.STATES_FAILURE:
      return {
        ...state,
        statesLoading: false,
        statesStatus: false,
      };
    case commonTypes.UPLOAD_IMAGE_LOADING:
      return {
        ...state,
        uploadImageLoading: true,
        uploadImageStatus: false,
      };
    case commonTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageLink: action.payload.url,
        uploadImageId: action.payload.id,
        uploadImageStatus: true,
        uploadImageLoading: false,
      };
    case commonTypes.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        uploadImageStatus: false,
        uploadImageLoading: false,
      };
    case commonTypes.CATEGORIES_LOADING:
      return {
        ...state,
        categories: undefined,
        categoriesLoading: true,
      };
    case commonTypes.CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        categoriesLoading: false,
      };
    case commonTypes.CATEGORIES_FAILURE:
      return {
        ...state,
        categoriesLoading: false,
      };
    case commonTypes.SUB_CATEGORIES_LOADING:
      return {
        ...state,
        subCategoriesLoading: true,
        subCategories: undefined,
      };
    case commonTypes.SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: action.payload,
        subCategoriesLoading: false,
      };
    case commonTypes.SUB_CATEGORIES_FAILURE:
      return {
        ...state,
        subCategoriesLoading: false,
      };
    case commonTypes.SET_FCM_LOADING:
      return {
        ...state,
        setFcmTokenLoading: true,
        setFcmTokenStatus: false,
      };
    case commonTypes.SET_FCM_SUCCESS:
      return {
        ...state,
        setFcmTokenSuccess: true,
        setFcmTokenLoading: false,
      };
    case commonTypes.SET_FCM_FAILURE:
      return {
        ...state,
        setFcmTokenSuccess: false,
        setFcmTokenLoading: false,
      };
    case commonTypes.SEND_MESSAGE_LOADING:
      return {
        ...state,
        messageSendLoading: true,
        messageSendStatus: false,
      };
    case commonTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSendStatus: true,
        messageSendLoading: false,
      };
    case commonTypes.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        messageSendStatus: false,
        messageSendLoading: false,
      };
    case commonTypes.CHAT_HISTORY_LOADING:
      return {
        ...state,
        chatHistoryLoading: true,
      };
    case commonTypes.CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        chatHistory: action.payload,
        chatHistoryLoading: false,
      };
    case commonTypes.CHAT_HISTORY_FAILURE:
      return {
        ...state,
        chatHistoryLoading: false,
      };
    case commonTypes.FAQS_LOADING:
      return {
        ...state,
        faqsLoading: true,
      };
    case commonTypes.FAQS_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
        faqsLoading: false,
      };
    case commonTypes.FAQS_FAILURE:
      return {
        ...state,
        faqsLoading: false,
      };
    case commonTypes.TERMS_LOADING:
      return {
        ...state,
        termsLoading: true,
      };
    case commonTypes.TERMS_SUCCESS:
      return {
        ...state,
        terms: action.payload,
        termsLoading: false,
      };
    case commonTypes.TERMS_FAILURE:
      return {
        ...state,
        termsLoading: false,
      };
    case commonTypes.CONTACT_INFO_LOADING:
      return {
        ...state,
        contactInfoLoading: true,
      };
    case commonTypes.CONTACT_INFO_SUCCESS:
      return {
        ...state,
        contactInfo: action.payload,
        contactInfoLoading: false,
      };
    case commonTypes.CONTACT_INFO_FAILURE:
      return {
        ...state,
        contactInfoLoading: false,
      };
    default:
      return state;
  }
};
export default commonReducer;
