import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Carousel from "react-bootstrap/Carousel";
import { COLORS, IMAGES } from "../../constants";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../apis";
import { handleGetApi } from "../../services";
import { toast } from "react-toastify";
function ImageSlider() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    let url = `${BASE_URL.baseUrl}categories?Limit=100&Page=1&PublishedStatus=true&ParentCategoryId=0`;
    try {
      const response = await handleGetApi(url, token);
      setCategories(response.data.categories);
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  const handleClick = () => {
    navigate("/categories-list", { state: categories });
  };

  return (
    <Carousel fade={false} indicators={true} controls={false} interval={5000} style={{ zIndex: "" }}>
      {/* <Carousel.Item>
          {/* <h2
            style={{
              // position: "absolute",
              // top: 50,
              color: "#fff",
              fontFamily: "PlayFairDisplay-Bold",
            }}
          >
            First slide label
          </h2> 
          <img
            className="d-block w-100"
            src={IMAGES.slide1}
            alt="First slide"
            style={{ height: 600 }}
          />
          <Carousel.Caption style={{ position: "absolute" }}>
            <h1
              style={{
                fontFamily: "PlayFairDisplay-Bold",
                fontSize: 50,
              }}
            >
              First slide label
            </h1>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> 
          </Carousel.Caption>
        </Carousel.Item> */}
      <Carousel.Item>
        <Box
          container
          sx={{
            backgroundImage: `url(${IMAGES.slide2})`,
            height: isMobile ? 500 : 550,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',


          }}
        >
          <Stack
            sx={{
              height: isMobile ? 500 : 550,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(30,30,30,0.5)",
            }}
          >
            <Typography
              sx={{
                fontSize: isMobile ? 45 : 64,
                fontFamily: "PlayfairDisplay-Regular",
                color: COLORS.white,
                textAlign: "center",
                padding: " 0px 10px"
              }}
            >
              The Hair Marketplace
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? 20 : 24,
                fontFamily: "PTSans-Regular",
                color: "#e0e0e0",
                textAlign: "center",
                margin: "20px",
                maxWidth: 450,
                padding: " 0px 10px"
              }}
            >
              Shop quality wigs, bundles, and extensions  from verified
              brands
            </Typography>
            <Button
              onClick={handleClick}
              sx={{
                backgroundColor: COLORS.secondary,
                width: 180,
                height: 50,
                mt: 2,
                borderRadius: 0,
                "&:hover": {
                  backgroundColor: COLORS.secondary,
                },
              }}
            >
              <Typography
                sx={{ color: COLORS.primary, fontFamily: "PTSans-Bold" }}
              >
                Shop Now
              </Typography>
            </Button>

          </Stack>
        </Box>
      </Carousel.Item>
      <Carousel.Item>
        <Box
          container
          sx={{
            backgroundImage: `url(${IMAGES.slide3})`,
            height: isMobile ? 500 : 550,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
          }}
        >
          <Stack
            sx={{
              height: isMobile ? 500 : 550,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(30,30,30,0.5)",
            }}
          >
            <Typography
              sx={{
                fontSize: isMobile ? 45 : 64,
                fontFamily: "PlayfairDisplay-Regular",
                color: COLORS.white,
                textAlign: "center",
                padding: " 0px 10px"
              }}
            >
              Natural Hair Extensions
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? 20 : 24,
                fontFamily: "PTSans-Regular",
                color: COLORS.white,
                textAlign: "center",
                margin: "20px",
                maxWidth: 450,
                padding: " 0px 10px"
              }}
            >
              Shop quality wigs, bundles, and extensions  from verified
              brands
            </Typography>
            <Button
              onClick={handleClick}
              sx={{
                backgroundColor: COLORS.secondary,
                width: 180,
                height: 50,
                mt: 2,
                borderRadius: 0,
                "&:hover": {
                  backgroundColor: COLORS.secondary,
                },
              }}
            >
              <Typography
                sx={{ color: COLORS.primary, fontFamily: "PTSans-Bold" }}
              >
                Shop Now
              </Typography>
            </Button>
          </Stack>
          {/* <img
          className="d-block w-100"
          src={IMAGES.slide1}
          alt="Second slide"
          style={{ height: 600 }}
        /> */}
          {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            
          </Carousel.Caption> */}
        </Box>
      </Carousel.Item>
    </Carousel>
  );
}
export default ImageSlider;
