import { Navigate, useRoutes } from "react-router-dom";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// ----------------------------------------------------------------------
// Screens
import Login from "../pages/common/Login";
import Register from "../pages/common/Register";
import ForgotPassword from "../pages/common/ForgotPassword";
import ForgotSuccess from "../pages/common/ForgotSuccess";
import DashboardLayout from "../layouts/dashboard";
import NotFound from "../pages/common/Page404";
import VendorDashboard from "../pages/vendor/dashboard/VendorDashboard";
import SetupStore from "../pages/vendor/setupStore/setupStore";
import ProductCategories from "../pages/vendor/setupProduct/productCategories";
import ProductSubCategories from "../pages/vendor/setupProduct/productSubCategories";
import ProductForm from "../pages/vendor/setupProduct/productForm";
import ProductLengths from "../pages/vendor/setupProduct/productLengths";
import ProductPricing from "../pages/vendor/setupProduct/productPricing";
import ProductDimensions from "../pages/vendor/setupProduct/productDimensions";
import ProductConfirmation from "../pages/vendor/setupProduct/productConfirmation";
import TotalEarning from "../pages/vendor/store/totalEarning";
import MonthlyEarning from "../pages/vendor/store/monthlyEarning";
import ActiveProductsCategories from "../pages/vendor/store/activeProductsCategories";
import ActiveProductsSubCategories from "../pages/vendor/store/activeProductsSubCategories";
import ActiveProducts from "../pages/vendor/store/activeProducts";
import PendingOrders from "../pages/vendor/store/pendingOrders";
import BestSelling from "../pages/vendor/store/bestSelling";
import WorstSelling from "../pages/vendor/store/worstSelling";
import SaleProducts from "../pages/vendor/store/saleProducts";
import OutOfStock from "../pages/vendor/store/outOfStock";
import InvitedStaff from "../pages/vendor/staff/staffInvited";
import InviteStaff from "../pages/vendor/staff/inviteForm";
import PaymentMethod from "../pages/vendor/profile/paymentMethod";
import VendorProfile from "../pages/vendor/profile/vendorProfile";
import UpdateVendorProfile from "../pages/vendor/profile/updateProfile";
import NotificationSetting from "../pages/vendor/profile/NotificationSetting";
import TermsAndConditions from "../pages/vendor/help/termsAndConditions";
import ContactUs from "../pages/vendor/help/contactUs";
import Faqs from "../pages/vendor/help/faqs";
import OrdersHistory from "../pages/vendor/orders/ordersHistory";
import ProductDetails from "../pages/vendor/store/productDetails";
import ToBeShipped from "../pages/vendor/store/toBeShipped";
import ChangePassword from "../pages/vendor/profile/ChangePassword";
import SecuritySettings from "../pages/vendor/profile/SecuritySettings";
import Home from "../pages/website/home";
import AboutUs from "../pages/website/aboutUs";
import Shop from "../pages/website/shop";
import Contact from "../pages/website/contactUs";
import Details from "../pages/website/productDetails";
import Cart from "../pages/website/cart";
import RegisterCustomer from "../pages/customer/register/Register";
import CustomerCart from "../pages/customer/cart/cart";
import CustomerDashboard from "../pages/customer/dashboard/CustomerDashboard";
import CustomerDashboardLayout from "../layouts/customerDashboard";
import RegisterSuccess from "../pages/vendor/registerSuccess/registerSuccess";
import StorePolicies from "../pages/vendor/storePolicies/storePolicies";
import PayoutAccount from "../pages/vendor/payoutAccount/payoutAccount";
import OrderDetails from "../pages/vendor/store/orderDetails";
import CareInstructions from "../pages/vendor/setupProduct/careInstructions";
import Brands from "../pages/website/brands";
import Blog from "../pages/website/blog";
import BlogDetails from "../pages/website/blogDetails";
import DeleteStaffMember from "../pages/vendor/staff/deleteStaffMember";
import AddDiscount from "../pages/vendor/store/addDiscount";
import UpdateDiscount from "../pages/vendor/store/updateDiscount";
import ProcessOrder from "../pages/vendor/orders/processOrder";
import Checkout from "../pages/website/checkout";
import PrivacyPolicy from "../pages/website/privacyPolicy";
import UserAgreement from "../pages/website/userAgreements";
import CaliforniaPolicy from "../pages/website/californiaPolicy";
import ActiveAccount from "../pages/common/ActiveAccount";
import DashboardList from "../pages/vendor/setupProduct/dashboardList";
import CategoriesList from "../pages/vendor/setupProduct/categoriesList";
import TermsConditions from "../components/authentication/privacypPolicy/termsConditions";
import PrivacyPolicies from "../components/authentication/privacypPolicy/privacyPolicy";
import Checkin from "../pages/website/Checkin";
import SubCategoyList from "../pages/vendor/setupProduct/SubCategoyList";
import CustomerHistory from "../pages/website/customerHistory";
import CustomerOrderHistory from "../pages/customer/customerOrders/customerOrderHistory";
import SeeAllProducts from "../pages/vendor/setupProduct/seeAllProducts";
import OrderDetail from "../pages/customer/orderDetails/OrderDetail";
import OrderHistory from "../pages/website/orderHistory";
// ----------------------------------------------------------------------

export default function Router() {
  // const isLoggedIn = localStorage.getItem("isLoggedIn");
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/home" replace /> },
        { path: "vendor-dashboard", element: <VendorDashboard /> },
        { path: "total-earning", element: <TotalEarning /> },
        { path: "monthly-earning", element: <MonthlyEarning /> },
        {
          path: "active-products-categories",
          element: <ActiveProductsCategories />,
        },
        {
          path: "active-products-sub-categories",
          element: <ActiveProductsSubCategories />,
        },
        { path: "active-products", element: <ActiveProducts /> },
        { path: "pending-orders", element: <PendingOrders /> },
        { path: "order-details", element: <OrderDetails /> },
        { path: "process-order", element: <ProcessOrder /> },
        { path: "best-selling", element: <BestSelling /> },
        { path: "worst-selling", element: <WorstSelling /> },
        { path: "sale-products", element: <SaleProducts /> },
        { path: "out-of-stock", element: <OutOfStock /> },
        { path: "invited-staff", element: <InvitedStaff /> },
        { path: "invite-staff", element: <InviteStaff /> },
        { path: "delete-staff", element: <DeleteStaffMember /> },
        { path: "payment-method", element: <PaymentMethod /> },
        { path: "vendor-profile", element: <VendorProfile /> },
        { path: "update-vendor-profile", element: <UpdateVendorProfile /> },
        { path: "notification-setting", element: <NotificationSetting /> },
        { path: "terms", element: <TermsAndConditions /> },
        { path: "contact-us", element: <ContactUs /> },
        { path: "faqs", element: <Faqs /> },
        { path: "orders-history", element: <OrdersHistory /> },
        { path: "product-details", element: <ProductDetails /> },
        { path: "to-be-shipped", element: <ToBeShipped /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "security-settings", element: <SecuritySettings /> },
        { path: "add-discount", element: <AddDiscount /> },
        { path: "update-discount", element: <UpdateDiscount /> },
      ],
    },
    {
      path: "/customer",
      element: <CustomerDashboardLayout />,
      children: [
        { element: <Navigate to="/home" replace /> },
        { path: "customer-dashboard", element: <CustomerDashboard /> },
        { path: "customer-profile", element: <VendorProfile /> },
        {
          path: "customer-notification-setting",
          element: <NotificationSetting />,
        },
        { path: "customer-cart", element: <CustomerCart /> },
        { path: "customer-terms", element: <TermsAndConditions /> },
        { path: "customer-contact-us", element: <ContactUs /> },
        { path: "customer-faqs", element: <Faqs /> },
        { path: "customer-payment-method", element: <PaymentMethod /> },
        { path: "customer-orders-history", element: <CustomerOrderHistory /> },
        { path: "customer-order-detail", element: < OrderDetail /> },
        { path: "customer-change-password", element: <ChangePassword /> },
        { path: "customer-security-settings", element: <SecuritySettings /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register-vendor", element: <Register /> },
        { path: "register-success", element: <RegisterSuccess /> },
        { path: "store-policies", element: <StorePolicies /> },
        { path: "payout-account", element: <PayoutAccount /> },
        { path: "register-customer", element: <RegisterCustomer /> },
        { path: "terms-conditions", element: <TermsConditions /> },
        { path: "privacy-policies", element: <PrivacyPolicies /> },
        { path: "forgot", element: <ForgotPassword /> },
        { path: "active-account", element: <ActiveAccount /> },
        { path: "forgot-success", element: <ForgotSuccess /> },
        { path: "setup-store", element: <SetupStore /> },
        { path: "product-categories", element: <ProductCategories /> },
        { path: "product-sub-categories", element: <ProductSubCategories /> },
        { path: "product-form", element: <ProductForm /> },
        { path: "product-care", element: <CareInstructions /> },
        { path: "product-lengths", element: <ProductLengths /> },
        { path: "product-pricing", element: <ProductPricing /> },
        { path: "product-dimensions", element: <ProductDimensions /> },
        { path: "product-confirmation", element: <ProductConfirmation /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/home" /> },
        // { path:  "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/",
      children: [
        { path: "home", element: <Home /> },
        { path: "customer-history", element: <CustomerHistory /> },
        { path: "order-history", element: <OrderHistory /> },
        
        { path: "brands", element: <Brands /> },
        { path: "about-us", element: <AboutUs /> },
        { path: "shop", element: <Shop /> },
        { path: "contact-us", element: <Contact /> },
        { path: "product-details", element: <Details /> },
        // { path: "cart", element: <Cart /> },
        { path: "cart", element: <Checkin /> },
        { path: "checkout", element: <Checkout /> },
        { path: "blog", element: <Blog /> },
        { path: "blog-details", element: <BlogDetails /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        { path: "user-agreement", element: <UserAgreement /> },
        { path: "california-policy", element: <CaliforniaPolicy /> },
        { path: "dashboard-list", element: <DashboardList /> },
        { path: "all-products", element: <SeeAllProducts /> },
        { path: "categories-list", element: <CategoriesList /> },
        { path: "sub-categories-list", element: <SubCategoyList /> }
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
