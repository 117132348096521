import { Box, Modal, Button, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../constants";
import { useNavigate } from "react-router-dom";

export default function ConfirmationModal({
  open,
  handleClose,
  title,
  text,
  onPress,
}) {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={container}>
        <CloseIcon sx={icon} onClick={handleClose} />
        <Typography
          id="keep-mounted-modal-title"
          variant="h4"
          component="h2"
          sx={title}
        >
          {title}
        </Typography>
        <Typography id="keep-mounted-modal-description" sx={text}>
          {text}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ justifyContent: "center", mt: 4, mb: 4 }}
        >
          <Button variant="contained" sx={button} onClick={onPress}>
            Delete
          </Button>
          <Button
            variant="contained"
            sx={{ ...button, background: COLORS.secondary }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
const container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  justifyContent: "center",
  alignItems: "center",
};
const icon = {
  position: "absolute",
  right: 20,
  top: 20,
  background: COLORS.other,
  borderRadius: 10,
};
const title = {
  textAlign: "center",
  color: COLORS.secondary,
  fontFamily: "PTSans-Bold",
  mt: 5,
};
const text = { mt: 2, textAlign: "center", fontFamily: "PTSans-Regular" };
const button = {
  alignSelf: "center",
  bgcolor: COLORS.primary,
  width: 200,
  height: 45,
};
