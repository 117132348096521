import { BASE_URL, CUSTOMER_PRODUCTS } from "../../../apis";
import { handleGetApi, handlePostApi } from "../../../services";
import { customerProductsTypes } from "../../types";

export const getCustomerProducts = (
  pageSize,
  page,
  categoryId,
  keyword,
  showOnHomePage,
  token
) => {
  console.log("Token", token);
  return async (dispatch) => {
    dispatch({
      type: customerProductsTypes.CUSTOMER_PRODUCTS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        CUSTOMER_PRODUCTS.getCustomerProducts +
        "PageSize=" +
        pageSize +
        "&Page=" +
        page +
        "&CategoryId=" +
        categoryId +
        "&Keyword=" +
        keyword +
        "&ShowOnHomePage=" +
        showOnHomePage,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerProductsTypes.CUSTOMER_PRODUCTS_SUCCESS,
        payload: {
          products: response.data.products,
          total: response.data.total_count,
        },
      });
    } else {
      dispatch({
        type: customerProductsTypes.CUSTOMER_PRODUCTS_FAILURE,
      });
    }
  };
};

export const getCustomerProductDetails = (id, token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProductsTypes.PRODUCT_DETAILS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + CUSTOMER_PRODUCTS.getProductDetails + "/" + id,
      token
    );
    console.log("Product Details Response", response?.data);
    if (response) {
      dispatch({
        type: customerProductsTypes.PRODUCT_DETAILS_SUCCESS,
        payload: response?.data?.products[0],
      });
    } else {
      dispatch({ type: customerProductsTypes.PRODUCT_DETAILS_FAILURE });
    }
  };
};
export const addProductReview = (
  productId,
  reviewText,
  longevity,
  tangle,
  texture,
  shine,
  isAnonymous,
  pictureOne,
  pictureTwo,
  pictureThree,
  captionOne,
  captionTwo,
  captionThree,
  token
) => {
  const body = JSON.stringify({
    product_id: productId,
    review_text: reviewText,
    longevity_rating: longevity,
    tangle_rating: tangle,
    texture_rating: texture,
    shine_rating: shine,
    is_anonymous: isAnonymous,
    picture_id_1: pictureOne,
    picture_id_2: pictureTwo,
    picture_id_3: pictureThree,
    picture_1_caption: captionOne,
    picture_2_caption: captionTwo,
    picture_3_caption: captionThree,
  });
  console.log(body);
  return async (dispatch) => {
    dispatch({
      type: customerProductsTypes.ADD_PRODUCT_REVIEW_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + CUSTOMER_PRODUCTS.addProductReview,
      body,
      token
    );
    if (response) {
      dispatch({
        type: customerProductsTypes.ADD_PRODUCT_REVIEW_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({ type: customerProductsTypes.ADD_PRODUCT_REVIEW_FAILURE });
    }
  };
};

export const getProductReviews = (productId, pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProductsTypes.PRODUCT_REVIEWS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        CUSTOMER_PRODUCTS.getProductReviews +
        "productId=" +
        productId +
        "&pageSize=" +
        pageSize +
        "&page=" +
        page,
      token
    );
    console.log("Reviews Response", response);
    if (response) {
      dispatch({
        type: customerProductsTypes.PRODUCT_REVIEWS_SUCCESS,
        payload: response?.data,
      });
    } else {
      dispatch({ type: customerProductsTypes.PRODUCT_REVIEWS_FAILURE });
    }
  };
};

export const getProductQueries = (productId, pageSize, page, status, token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProductsTypes.QUERIES_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        CUSTOMER_PRODUCTS.getQueries +
        "ProductId=" +
        productId +
        "&PageSize=" +
        pageSize +
        "&Page=" +
        page +
        "&Status=" +
        "",
      token
    );
    if (response) {
      dispatch({
        type: customerProductsTypes.QUERIES_SUCCESS,
        payload: response.data,
      });
    } else
      dispatch({
        type: customerProductsTypes.QUERIES_FAILURE,
      });
  };
};

export const addQuestion = (productId, question, token) => {
  const body = JSON.stringify({
    question_text: question,
    product_id: productId,
  });
  return async (dispatch) => {
    dispatch({
      type: customerProductsTypes.ADD_QUERY_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + CUSTOMER_PRODUCTS.addQuery,
      body,
      token
    );

    if (response) {
      dispatch({
        type: customerProductsTypes.ADD_QUERY_SUCCESS,
      });
    } else {
      dispatch({ type: customerProductsTypes.ADD_QUERY_FAILURE });
    }
  };
};
