import React, { useState, useEffect } from 'react';
import { Container, Stack, Typography, Card, TextField, Button, IconButton, InputAdornment, FormControl, InputLabel, OutlinedInput, FormHelperText, LinearProgress, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { COLORS, IMAGES } from "../../constants";
import AuthLayout from "../../layouts/AuthLayout";
import { BASE_URL } from '../../apis';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 700,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundImage: `url(${IMAGES.welcome})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(12, 0),
}));

const ActiveAccount = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [token, setToken] = useState();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('Email');
    const tokenParam = urlParams.get('Token');
    const userIdParam = urlParams.get('UserId');

    if (emailParam) setEmail(emailParam);
    if (tokenParam) setToken(tokenParam);
    if (userIdParam) setUserId(Number(userIdParam));
  }, []);

  const validate = () => {
    let tempErrors = {};
    tempErrors.password = password ? "" : "Password is required";
    tempErrors.confirmPassword = confirmPassword ? "" : "Confirm Password is required";
    if (password && confirmPassword && password !== confirmPassword) {
      tempErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
        setLoading(true);
        const requestData = {
            userId: Number(userId),
            token,
            email,
            password
        };

        const endpoint = BASE_URL.baseUrl + 'common/activateCustomer';

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData),
                response: 'json' // Setting response type to json
            }).then((response) => {
                setLoading(false);

                if (response.ok) {
                    toast.success('Your account has been activated. You can now log in.');
                    window.location.href = '/login';
                } else {
                    return response.json().then(data => {
                        if (response.status === 400) {
                            toast.error(data.message);
                        } else if (data.Error === 'HttpException') {
                            toast.error(data.Message);
                        } else {
                            toast.error('An unknown error occurred.');
                        }
                    });
                }
            }).catch((res) => {
                toast.error(res || 'Something went wrong');
            });
        } catch (error) {
            setLoading(false);
            toast.error('Something went wrong');
        } finally {
            setLoading(false);
        }
    }
};


  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    return strength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(evaluatePasswordStrength(newPassword));
  };

  const getPasswordStrengthLabel = (strength) => {
    switch (strength) {
      case 1:
        return "Weak";
      case 2:
      case 3:
        return "Medium";
      case 4:
      case 5:
      case 6:
        return "Strong";
      default:
        return "Strong";
    }
  };
  

  return (
    <RootStyle title="Activate Account | Hair Scout">
      <AuthLayout />
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            variant="h2"
            sx={{
              color: COLORS.white,
              fontFamily: "PlayfairDisplay-Bold",
            }}
          >
            Welcome Back!
          </Typography>
          <Typography
            sx={{
              color: COLORS.white,
              fontSize: 20,
              textAlign: "center",
              fontFamily: "PTSans-Regular",
            }}
          >
            We are glad to see you again! Get access to your Orders, Wishlists and Recommendation
          </Typography>
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "center",
                color: COLORS.secondary,
                fontFamily: "PlayfairDisplay-Bold",
              }}
            >
              Activate my account
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "center",
                fontFamily: "PTSans-Regular",
                fontSize: "1.1rem"
              }}
            >
              Please enter your password
            </Typography>
          </Stack>

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="email"
              label="Email"
              value={email}
              disabled
              variant="outlined"
              sx={{ mb: 3 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel htmlFor="password" error={Boolean(errors.password)}>
                Password
              </InputLabel>
              <OutlinedInput
                value={password}
                onChange={handlePasswordChange}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                     <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                error={Boolean(errors.password)}
              />
              {errors.password && (
                <FormHelperText error>
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel htmlFor="confirmPassword" error={Boolean(errors.confirmPassword)}>
                Confirm Password
              </InputLabel>
              <OutlinedInput
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                   <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                error={Boolean(errors.confirmPassword)}
              />
              {errors.confirmPassword && (
                <FormHelperText error>
                  {errors.confirmPassword}
                </FormHelperText>
              )}
              
              {confirmPassword && (
                <FormHelperText error={Boolean(errors.confirmPassword)}>
                  {password !== confirmPassword
                    ? "Please enter the same password"
                    : "Passwords match"}
                </FormHelperText>
              )}
               {password && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={(passwordStrength / 5) * 100}
                    sx={{ flex: 1, mr: 1, height: 8 }}
                  />
                  <Typography variant="body2">
                    {getPasswordStrengthLabel(passwordStrength)}
                  </Typography>
                </Box>
              )}
              <Typography 
                variant="body2" 
                sx={{
                color: 'gray',
                mt: 2, 
                fontSize: '0.8rem', 
                maxWidth: '400px'
             }}
>
    Your password is at least 8-12 characters long (8+ preferred) and includes uppercase and lowercase letters, numbers, and symbols.
</Typography>

            </FormControl>

            <Button fullWidth size="large" type="submit" variant="contained" color="primary" sx={{ mb: 3 }}>
              Activate Account
            </Button>
          </form>
        </ContentStyle>
        <ToastContainer />
      </Container>
    </RootStyle>
  );
};

export default ActiveAccount;
