import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { COLORS, FONTS, ICONS, IMAGES } from "../../../constants";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { updateProfile } from "../../../redux/actions";
import { toast } from "react-toastify";

function UpdateVendorProfile(props) {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const { vendorProfile } = props.vendorStore;
    console.log("Vendor Profile", vendorProfile);
    setProfileImage(vendorProfile?.images);
    setFirstName(vendorProfile?.first_name);
    setLastName(vendorProfile?.last_name);
    setEmail(vendorProfile?.email);
  }, []);

  const handleChangeImage = function loadFile(event) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        console.log(event.target.files[i]);
        setProfileImage({
          src: URL.createObjectURL(event.target.files[i]),
          name: event.target.files[i].name,
          type: event.target.files[i].type,
        });
        // gallery.push(event.target.files[i]);
      }
    }
  };
  const handleUploadImage = async (image) => {
    const { accessToken } = this.props.authentication;
    const { networkStatus } = this.props.common;
    if (networkStatus) {
      await this.props
        .uploadImage(image, accessToken)
        .then(() => {
          const { uploadImageId, uploadImageLink, uploadImageStatus } =
            this.props.common;
          if (uploadImageStatus) {
            this.setState({
              imageId: uploadImageId,
              imageLink: uploadImageLink,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  const handleUpdateProfile = async () => {
    const { accessToken } = props.authentication;

    if (profileImage) {
      await handleUploadImage(profileImage);
    }
    const { firstName, lastName, imageId } = this.state;
    props
      .updateProfile(firstName, lastName, imageId, accessToken)
      .then(async () => {
        const { updateProfileStatus } = props.authentication;
        if (updateProfileStatus) {
          props
            .getVendorProfile(accessToken)
            .then(() => {})
            .catch((error) => {
              toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <Page title="Dashboard | Profile">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          Update Profile
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="./vendor-profile"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Profile
            </Link>
            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Update Profile
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          {/* <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Profile
          </Typography> */}
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Avatar
              variant="square"
              src={profileImage ? profileImage?.src : ICONS.avatar}
              alt="profile"
              sx={{ height: 160, width: 160 }}
            />
            <Button
              sx={{
                borderRadius: 0,
                height: 40,
                width: 30,
                maxWidth: 2,
                mt: -2,
                backgroundColor: COLORS.primary,
              }}
              component="label"
            >
              <CameraAltIcon />
              <input
                type="file"
                hidden
                // multiple
                onChange={handleChangeImage}
                id="upload"
                accept="image/*"
                style={{
                  border: "#f5f5f5",
                  borderRadius: 5,
                }}
              />
            </Button>
          </Grid>
          <Stack sx={{ mt: 5 }}>
            {/* <Typography sx={{ mb: 2, mt: 2, fontFamily: "PTSans-Bold" }}>
                First Name
              </Typography> */}
            <TextField
              label="First Name"
              sx={{ height: 50, mb: 4 }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {/* <Typography sx={{ mb: 1, mt: 2, fontFamily: "PTSans-Bold" }}>
                Last Name
              </Typography> */}
            <TextField
              fullWidth
              label="Last Name"
              sx={{ height: 50, mb: 4 }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {/* <Typography sx={{ mb: 1, mt: 2, fontFamily: "PTSans-Bold" }}>
                Email Address
              </Typography> */}
            <TextField
              fullWidth
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Stack>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 4, backgroundColor: COLORS.primary, width: 200 }}
          >
            Update Profile
          </Button>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  updateProfile: (firstName, lastName, imageId, token) =>
    dispatch(updateProfile(firstName, lastName, imageId, token)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateVendorProfile);
