import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NoItem } from "../../../components";
import Banner from "../../../components/Banner";
import Earning from "../../../components/Earning";
import Page from "../../../components/Page";
import { ICONS, FONTS } from "../../../constants";
import { getMonthlyIncome } from "../../../redux/actions";

function MonthlyEarning(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const handleGetMonthlyIncome = () => {
    const { accessToken } = props.authentication;
    if (month === "") {
      toast.error("Please select month");
    } else if (year === "") {
      toast.error("Please select year");
    } else {
      props
        .getMonthlyIncome(month, year, pageSize, page, accessToken)
        .then(() => {})
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  // useEffect(() => {
  //   handleGetMonthlyIncome();
  // }, []);
  const { monthlyEarning, monthlyEarningLoading } = props.vendorStore;
  return (
    <Page title="Dashboard | Monthly Earning">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: FONTS.title }}>
          My Store
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>

            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Monthly Earning
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography>
          <Banner
            image={ICONS.monthly}
            title="Monthly Earning"
            value="$"
            withMonth={true}
            onChangeMonth={(value) => setMonth(value)}
            onChangeYear={(value) => setYear(value)}
            onPressSearchByMonth={() => handleGetMonthlyIncome()}
          />
          {monthlyEarningLoading ? (
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </Stack>
          ) : monthlyEarning?.length === 0 ? (
            // console.log(item),
            <Stack>
              <NoItem image={ICONS.noProduct} title="No Invoice Available" />
            </Stack>
          ) : (
            monthlyEarning?.map((item, index) => (
              <Earning
                key={index}
                orderId={item?.order_id}
                orderAmount={(item?.order_grand_total).toFixed(2)}
                dateCreated={moment(item?.order_date).format("LLL")}
                orderStatus={item?.order_status}
                onPress={() =>
                  navigate("/dashboard/order-details", {
                    state: { orderId: item?.order_id },
                  })
                }
              />
            ))
          )}
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  getMonthlyIncome: (month, year, pageSize, page, token) =>
    dispatch(getMonthlyIncome(month, year, pageSize, page, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyEarning);
