// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Typography,
  Card,
  TextField,
  Box,
  CardHeader,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS, IMAGES } from "../../../constants";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setupPayoutAccount } from "../../../redux/actions";
import { toast } from "react-toastify";
import LoadingModal from "../../../components/modals/LoadingModal";
var CryptoJS = require("crypto-js");
// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function PayoutAccount(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberError, setAccountNumberError] = useState(false);
  const [accountNumberHelperText, setAccountNumberHelperText] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankNameError, setBankNameError] = useState(false);
  const [bankNameHelperText, setBankNameHelperText] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [branchCodeError, setBranchCodeError] = useState(false);
  const [branchCodeHelperText, setBranchCodeHelperText] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [expiryDateHelperText, setExpiryDateHelperText] = useState("");

  // useEffect(() => {
  //   encryptData();
  // }, []);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleValidation = () => {
    if (
      accountNumber === "" ||
      accountNumber === null ||
      accountNumber === " "
    ) {
      setAccountNumberError(true);
      setAccountNumberHelperText("Please enter bank account");
      return false;
    } else if (bankName === "" || bankName === null || bankName === " ") {
      setBankNameError(true);
      setBankNameHelperText("Please enter bank name");
      return false;
    } else if (branchCode === "" || branchCode === null || branchCode === " ") {
      setBranchCodeError(true);
      setBranchCodeHelperText("Please enter bank branch code");
      return false;
    } else if (expiryDate === "" || expiryDate === null || expiryDate === " ") {
      setExpiryDateError(true);
      setExpiryDateHelperText("Please enter account expiry date");
      return false;
    } else {
      // navigate("product-categories");
      return true;
    }
  };

  const handlePayoutAccount = async () => {
    const { accessToken } = props.authentication;
    const isValidated = handleValidation();
    if (isValidated) {
      const account = encryptData(accountNumber);
      await props
        .setupPayoutAccount(
          account,
          bankName,
          branchCode,
          expiryDate,
          accessToken
        )
        .then(() => {
          const { setupPayoutAccountStatus } = props.setupVendor;
          if (setupPayoutAccountStatus) {
            navigate("/product-categories");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  const encryptData = (accountNumber) => {
    var ciphertext = CryptoJS.AES.encrypt(
      accountNumber,
      "HairScout"
    ).toString();
    console.log(ciphertext);
    return ciphertext;
  };
  const { setupPayoutAccountLoading } = props.setupVendor;
  return (
    <RootStyle title="Setup Store | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card sx={{ my: 3 }}>
          <CardHeader
            title="Payout Account"
            subheader=" Enter the information of the account you'd like your payments
              deposited to"
          />

          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <TextField
                error={accountNumberError}
                type={showPassword ? "text" : "password"}
                fullWidth
                required={true}
                label="Account Number"
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                }}
                value={accountNumber}
                helperText={accountNumberHelperText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleShowPassword}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                error={bankNameError}
                type="text"
                required={true}
                fullWidth
                label="Bank Name"
                onChange={(e) => {
                  setBankName(e.target.value);
                }}
                value={bankName}
                helperText={bankNameHelperText}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <TextField
                error={branchCodeError}
                type="text"
                fullWidth
                required={true}
                label="Branch Code"
                onChange={(e) => {
                  setBranchCode(e.target.value);
                }}
                value={branchCode}
                helperText={branchCodeHelperText}
              />
              <TextField
                error={expiryDateError}
                type="text"
                required={true}
                fullWidth
                label="Account Expiry Date"
                onChange={(e) => {
                  setExpiryDate(e.target.value);
                }}
                value={expiryDate}
                helperText={expiryDateHelperText}
              />
            </Stack>

            <LoadingButton
              variant="contained"
              size="large"
              sx={{ width: 200, mb: 2, mt: 2 }}
              onClick={() => handlePayoutAccount()}
              // onClick={() => navigate("/product-categories")}
            >
              Next
            </LoadingButton>
          </Box>
        </Card>
      </Container>
      <LoadingModal open={setupPayoutAccountLoading} />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  setupVendor: state.setupVendor,
});
const mapDispatchToProps = (dispatch) => ({
  setupPayoutAccount: (
    accountNumber,
    bankName,
    branchCode,
    accountExpiry,
    token
  ) =>
    dispatch(
      setupPayoutAccount(
        accountNumber,
        bankName,
        branchCode,
        accountExpiry,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(PayoutAccount);
