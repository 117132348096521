import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";

import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../components/website/BreadCrumb";
import { COLORS, FONTS } from "../../constants";

const Contact = (props) => {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <BreadCrumb page="Contact Us" from="Home" fromLink="/home" />
      <Box
        className="container"
        sx={{
          mt: 15,
        }}
      >
        <Grid container sx={{ mt: 5 }} spacing={4}>
          <Grid item sx={12} md={6} lg={6}>
            <Box sx={{ boxShadow: 3, height: 300, p: 5 }}>
              <Typography sx={{ textAlign: "center", fontFamily: FONTS.title }}>
                Want to Talk to a Specialist <br /> About Hair Scout?
              </Typography>
            </Box>
          </Grid>
          <Grid item sx={12} md={6} lg={6}>
            <Stack
              alignItems={"center"}
              sx={{ boxShadow: 3, height: 300, p: 5 }}
            >
              <Typography sx={{ textAlign: "center", fontFamily: FONTS.title }}>
                Need help with <br /> Your Account?
              </Typography>
              {/* <Stack justifyContent={"center"} alignItems={"center"}> */}
              <Button
                variant="contained"
                sx={{ height: 45, mt: 3, width: 200 }}
              >
                Visit Our Help Center
              </Button>
              {/* </Stack> */}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mt: 15,
          mb: 15,
        }}
      >
        <Stack
          sx={{
            width: "50%",
            alignSelf: "center",
          }}
          spacing={2}
        >
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField fullWidth label="Full name" type="text" />
            <TextField fullWidth label="Email Address" type="email" />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField fullWidth label="Phone Number" type="text" />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="What types of hair are you interested in?"
              type="text"
            />
          </Stack>
          <Button
            sx={{
              mt: 15,
              backgroundColor: COLORS.secondary,
              color: COLORS.primary,

              "&:hover": {
                backgroundColor: COLORS.primary,
                color: "#fff",
              },
              width: 180,
              height: 50,
            }}
          >
            Send
          </Button>
        </Stack>
      </Box>
      {/* <section class="contact spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="contact__text">
                <div class="section-title">
                  <span>Information</span>
                  <h2>Contact Us</h2>
                  <p>
                    As you might expect of a company that began as a high-end
                    interiors contractor, we pay strict attention.
                  </p>
                </div>
                <ul>
                  <li>
                    <h4>America</h4>
                    <p>
                      195 E Parker Square Dr, Parker, CO 801 <br />
                      +43 982-314-0958
                    </p>
                  </li>
                  <li>
                    <h4>France</h4>
                    <p>
                      109 Avenue Léon, 63 Clermont-Ferrand <br />
                      +12 345-423-9893
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="contact__form">
                <form action="#">
                  <div class="row">
                    <div class="col-lg-6">
                      <input type="text" placeholder="Name" />
                    </div>
                    <div class="col-lg-6">
                      <input type="text" placeholder="Email" />
                    </div>
                    <div class="col-lg-12">
                      <textarea placeholder="Message"></textarea>
                      <button type="submit" class="site-btn">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
