import { Box, Typography } from "@mui/material";
import { COLORS, FONTS } from "../../constants";

export const SideBarTitle = ({ title }) => {
  return (
    <Box
      sx={{
        background: COLORS.primary,
        height: 50,
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        p: 2,
        borderLeft: 7,
        borderColor: COLORS.secondary,
      }}
    >
      <Typography
        sx={{
          fontFamily: FONTS.title,
          fontSize: 20,
          color: COLORS.white,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
