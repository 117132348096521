import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { connect } from "react-redux";
import { NavStrip } from "../../components/website/Navbar";
import Footer from "../../components/website/Footer";
import { makeStyles } from "@mui/styles";
import { BASE_URL } from "../../apis";
import { handleGetApi } from "../../services";
import { toast } from "react-toastify";
import CustomerOrderHistory from "../customer/customerOrders/customerOrderHistory";

const CustomerHistory = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [catogeries, setCatogeries] = useState([]);

  useEffect(() => {
    getcategories();
  }, []);
  const getcategories = async () => {
    setLoading(true)
    let url = `${BASE_URL.baseUrl}categories?Limit=100&Page=1&PublishedStatus=true&ParentCategoryId=0`;
    try {
     
      const response = await handleGetApi(url, token);
      setCatogeries(response.data.categories);
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false)
    }
  };


 

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
     <CustomerOrderHistory />
     
        
      <Footer />
    </React.Fragment>
  );
};

export default connect()(CustomerHistory);
