import { Stack, Typography } from "@mui/material";
import { COLORS, ICONS } from "../constants";

export const NoItem = ({ image, title, text }) => {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "100%",
        height: 300,
      }}
    >
      <img src={image} alt={"no item"} style={{ height: 80, width: 80 }} />
      <Typography
        sx={{ textAlign: "center", fontFamily: "PTSans-Bold", marginTop: 3 }}
      >
        {title}
      </Typography>
    </Stack>
  );
};
