import { Link as RouterLink, useLocation } from "react-router-dom";

// material
import { Link, Container, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { connect } from "react-redux";
import { forgotPassword } from "../../../redux/actions";
import { toast } from "react-toastify";
import LoadingModal from "../../modals/LoadingModal";

// ----------------------------------------------------------------------

function ForgetSuccess(props) {
  const location = useLocation();
  const handleForgotPassword = () => {
    console.log(location.state.email);
    props
      .forgotPassword(location.state.email)
      .then(() => {
        const { forgotPasswordStatus } = props.authentication;
        if (forgotPasswordStatus) {
          toast.success(
            "We sent you a link to reset your password, please check your inbox",
            {
              position: "top-right",
              autoClose: 5000,
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
        });
      });
  };
  const { forgotPasswordLoading } = props.authentication;
  return (
    <Container>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() =>
          window.open("https://mail.google.com/mail/u/0/#inbox", "_blank")
        }
      >
        Open Email App
      </LoadingButton>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          textAlign: "center",
          fontSize: 16,
          color: "text.secondary",
          fontFamily: "PTSans-Regular",
        }}
      >
        Didn't received the email? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          // component={RouterLink}
          // to="/login"
          sx={{ fontSize: 16 }}
          onClick={() => handleForgotPassword()}
        >
          Click To Resend
        </Link>
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: { md: 2 },
          textAlign: "center",
          fontSize: 16,
          color: "text.secondary",
          fontFamily: "PTSans-Regular",
        }}
      >
        Go back to &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/login"
          sx={{ fontSize: 16 }}
        >
          Sign In
        </Link>
      </Typography>
      <LoadingModal open={forgotPasswordLoading} />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});
const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(forgotPassword(email)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgetSuccess);
