import { vendorSetupTypes } from "../../types";

const initialState = {
  setupVendorLoading: false,
  setupVendorStatus: false,

  vendor: undefined,

  tagsLoading: false,
  tagsStatus: false,
  tags: undefined,

  setupTagsLoading: false,
  setupTagsStatus: false,

  setupDeliveryProcessLoading: false,
  setupDeliveryProcessStatus: false,

  setupRefundPolicyLoading: false,
  setupRefundPolicyStatus: false,

  setupFraudProtectionLoading: false,
  setupFraudProtectionStatus: false,
  isUpdating: false,

  setupPayoutAccountLoading: false,
  setupPayoutAccountStatus: false,
};
const setupVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case vendorSetupTypes.VENDOR_TAGS_LOADING:
      return {
        ...state,
        tagsLoading: true,
        tagsStatus: false,
      };
    case vendorSetupTypes.VENDOR_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        tagsStatus: true,
        tagsLoading: false,
      };
    case vendorSetupTypes.VENDOR_TAGS_FAILURE:
      return {
        ...state,
        tagsStatus: false,
        tagsLoading: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_LOADING:
      return {
        ...state,
        setupVendorLoading: true,
        setupVendorStatus: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_SUCCESS:
      return {
        ...state,
        setupVendorStatus: true,
        vendor: action.payload,
        setupVendorLoading: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_FAILURE:
      return {
        ...state,
        setupVendorLoading: false,
        setupVendorStatus: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_TAGS_LOADING:
      return {
        ...state,
        setupTagsLoading: true,
        setupTagsStatus: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_TAGS_SUCCESS:
      return {
        ...state,
        setupTagsStatus: true,
        vendor: action.payload,
        setupTagsLoading: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_TAGS_FAILURE:
      return {
        ...state,
        setupTagsLoading: false,
        setupTagsStatus: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_DELIVERY_PROCESS_LOADING:
      return {
        ...state,
        setupDeliveryProcessLoading: true,
        setupDeliveryProcessStatus: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_DELIVERY_PROCESS_SUCCESS:
      return {
        ...state,
        setupDeliveryProcessStatus: true,
        vendor: action.payload,
        setupDeliveryProcessLoading: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_DELIVERY_PROCESS_FAILURE:
      return {
        ...state,
        setupDeliveryProcessLoading: false,
        setupDeliveryProcessStatus: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_REFUND_POLICY_LOADING:
      return {
        ...state,
        setupRefundPolicyLoading: true,
        setupRefundPolicyStatus: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_REFUND_POLICY_SUCCESS:
      return {
        ...state,
        setupRefundPolicyStatus: true,
        vendor: action.payload,
        setupRefundPolicyLoading: false,
      };
    case vendorSetupTypes.SETUP_VENDOR_REFUND_POLICY_FAILURE:
      return {
        ...state,
        setupRefundPolicyLoading: false,
        setupRefundPolicyStatus: false,
      };
    case vendorSetupTypes.SETUP_FRAUD_PROTECTION_LOADING:
      return {
        ...state,
        setupFraudProtectionLoading: true,
        setupFraudProtectionStatus: false,
      };
    case vendorSetupTypes.SETUP_FRAUD_PROTECTION_SUCCESS:
      return {
        ...state,
        setupFraudProtectionStatus: true,
        vendor: action.payload,
        setupFraudProtectionLoading: false,
      };
    case vendorSetupTypes.SETUP_FRAUD_PROTECTION_FAILURE:
      return {
        ...state,
        setupFraudProtectionLoading: false,
        setupFraudProtectionStatus: false,
      };
    case vendorSetupTypes.SETUP_PAYOUT_ACCOUNT_LOADING:
      return {
        ...state,
        setupPayoutAccountLoading: true,
        setupPayoutAccountStatus: false,
      };
    case vendorSetupTypes.SETUP_PAYOUT_ACCOUNT_SUCCESS:
      return {
        ...state,
        setupPayoutAccountStatus: true,
        setupPayoutAccountLoading: false,
      };
    case vendorSetupTypes.SETUP_PAYOUT_ACCOUNT_FAILURE:
      return {
        ...state,
        setupPayoutAccountStatus: false,
        setupPayoutAccountLoading: false,
      };
    case vendorSetupTypes.SET_IS_UPDATING_SUCCESS:
      return {
        ...state,
        isUpdating: action.payload,
      };
    default:
      return state;
  }
};
export default setupVendorReducer;
