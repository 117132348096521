import { vendorStoreTypes } from "../../types";

const initialState = {
  vendorProductsLoading: undefined,
  vendorProducts: [],
  vendorProductsTotal: 0,

  productDetailsLoading: undefined,
  productDetails: undefined,

  outOfStockProducts: undefined,
  outOfStockProductsTotal: 0,
  outOfStockProductsLoading: undefined,

  bestSellingProducts: undefined,
  bestSellingProductsTotal: 0,
  bestSellingProductsLoading: undefined,

  worstSellingProducts: undefined,
  worstSellingProductsTotal: 0,
  worstSellingProductsLoading: undefined,

  saleProducts: undefined,
  saleProductsTotal: 0,
  saleProductsLoading: undefined,

  vendorProfile: undefined,
  vendorProfileLoading: false,

  vendorDashboardDetails: undefined,
  vendorDashboardDetailsLoading: false,

  vendorProductDetails: undefined,
  vendorProductDetailsLoading: undefined,

  deleteProductLoading: false,
  deleteProductStatus: false,

  monthlyEarningLoading: false,
  monthlyEarning: [],

  totalEarningLoading: false,
  totalEarning: [],
};
const vendorStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case vendorStoreTypes.VENDOR_PRODUCTS_LOADING:
      return {
        ...state,
        vendorProductsLoading: true,
      };
    case vendorStoreTypes.VENDOR_PRODUCTS_SUCCESS:
      return {
        ...state,
        vendorProducts: action.payload.products,
        vendorProductsTotal: action.payload.totalCount,
        vendorProductsLoading: false,
      };
    case vendorStoreTypes.VENDOR_PRODUCTS_FAILURE:
      return {
        ...state,
        vendorProductsLoading: false,
      };
    case vendorStoreTypes.VENDOR_PRODUCT_DETAILS_LOADING:
      return {
        ...state,
        productDetailsLoading: true,
      };
    case vendorStoreTypes.VENDOR_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetails: action.payload,
        productDetailsLoading: false,
      };
    case vendorStoreTypes.VENDOR_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        productDetailsLoading: false,
      };
    case vendorStoreTypes.OUT_OF_STOCK_PRODUCTS_LOADING:
      return {
        ...state,
        outOfStockProductsLoading: true,
      };
    case vendorStoreTypes.OUT_OF_STOCK_PRODUCTS_SUCCESS:
      return {
        ...state,
        outOfStockProducts: action.payload.products,
        outOfStockProductsTotal: action.payload.totalCount,
        outOfStockProductsLoading: false,
      };
    case vendorStoreTypes.OUT_OF_STOCK_PRODUCTS_FAILURE:
      return {
        ...state,
        outOfStockProductsLoading: false,
      };
    case vendorStoreTypes.BEST_SELLING_PRODUCTS_LOADING:
      return {
        ...state,
        bestSellingProductsLoading: true,
      };
    case vendorStoreTypes.BEST_SELLING_PRODUCTS_SUCCESS:
      return {
        ...state,
        bestSellingProducts: action.payload.products,
        bestSellingProductsTotal: action.payload.totalCount,
        bestSellingProductsLoading: false,
      };
    case vendorStoreTypes.BEST_SELLING_PRODUCTS_FAILURE:
      return {
        ...state,
        bestSellingProductsLoading: false,
      };
    case vendorStoreTypes.WORST_SELLING_PRODUCTS_LOADING:
      return {
        ...state,
        worstSellingProductsLoading: true,
      };
    case vendorStoreTypes.WORST_SELLING_PRODUCTS_SUCCESS:
      return {
        ...state,
        worstSellingProducts: action.payload.products,
        worstSellingProductsTotal: action.payload.totalCount,
        worstSellingProductsLoading: false,
      };
    case vendorStoreTypes.WORST_SELLING_PRODUCTS_FAILURE:
      return {
        ...state,
        worstSellingProductsLoading: false,
      };
    case vendorStoreTypes.SALE_PRODUCTS_LOADING:
      return {
        ...state,
        saleProductsLoading: true,
      };
    case vendorStoreTypes.SALE_PRODUCTS_SUCCESS:
      return {
        ...state,
        saleProducts: action.payload.products,
        saleProductsTotal: action.payload.totalCount,
        saleProductsLoading: false,
      };
    case vendorStoreTypes.SALE_PRODUCTS_FAILURE:
      return {
        ...state,
        saleProductsLoading: false,
      };
    case vendorStoreTypes.VENDOR_PROFILE_LOADING:
      return {
        ...state,
        vendorProfileLoading: true,
      };
    case vendorStoreTypes.VENDOR_PROFILE_SUCCESS:
      return {
        ...state,
        vendorProfile: action.payload?.profile,
        vendorProfileLoading: false,
      };
    case vendorStoreTypes.VENDOR_PROFILE_FAILURE:
      return {
        ...state,
        vendorProfileLoading: false,
      };
    case vendorStoreTypes.VENDOR_DASHBOARD_DETAILS_LOADING:
      return {
        ...state,
        vendorDashboardDetailsLoading: true,
      };
    case vendorStoreTypes.VENDOR_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        vendorDashboardDetailsLoading: false,
        vendorDashboardDetails: action.payload,
      };
    case vendorStoreTypes.VENDOR_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state,
        vendorDashboardDetailsLoading: false,
      };
    // case vendorStoreTypes.VENDOR_PRODUCT_DETAILS_LOADING:
    //   return {
    //     ...state,
    //     vendorProductDetailsLoading: true,
    //   };
    // case vendorStoreTypes.VENDOR_PRODUCT_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     vendorProductDetails: action.payload,
    //     vendorProductDetailsLoading: false,
    //   };
    // case vendorStoreTypes.VENDOR_PRODUCT_DETAILS_FAILURE:
    //   return {
    //     ...state,

    //     vendorProductDetailsLoading: false,
    //   };
    case vendorStoreTypes.DELETE_PRODUCT_LOADING:
      return {
        ...state,
        deleteProductLoading: true,
        deleteProductStatus: false,
      };
    case vendorStoreTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deleteProductStatus: true,
        deleteProductLoading: false,
      };
    case vendorStoreTypes.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        deleteProductStatus: false,
        deleteProductLoading: false,
      };
    case vendorStoreTypes.TOTAL_EARNING_LOADING:
      return {
        ...state,
        totalEarningLoading: true,
        // totalEarning: [],
      };
    case vendorStoreTypes.TOTAL_EARNING_SUCCESS:
      return {
        ...state,
        totalEarning: action.payload,
        totalEarningLoading: false,
      };
    case vendorStoreTypes.TOTAL_EARNING_FAILURE:
      return {
        ...state,
        totalEarning: [],
        totalEarningLoading: false,
      };
    case vendorStoreTypes.MONTHLY_EARNING_LOADING:
      return {
        ...state,
        monthlyEarningLoading: true,
        monthlyEarning: [],
      };
    case vendorStoreTypes.MONTHLY_EARNING_SUCCESS:
      return {
        ...state,
        monthlyEarning: action.payload,
        monthlyEarningLoading: false,
      };
    case vendorStoreTypes.MONTHLY_EARNING_FAILURE:
      return {
        ...state,
        monthlyEarningLoading: false,
        monthlyEarning: [],
      };
    default:
      return state;
  }
};
export default vendorStoreReducer;
