import React from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import { COLORS, FONTS } from "../constants";

export const Product = ({
  image,
  name,
  color,
  length,
  price,
  onPress,
  onPressEdit,
  onPressDelete,
}) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box sx={{ boxShadow: 2, borderRadius: 1 }}>
        <Avatar
          variant="square"
          src={image}
          sx={{
            width: "100%",
            height: 200,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        />

        <Box sx={{ p: 1 }}>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography sx={{ fontFamily: FONTS.subtitle2 }}>{name}</Typography>
            <Stack direction={{ xs: "row", sm: "row" }}>
              <ModeEditIcon
                sx={{ mr: 1, color: COLORS.secondary, height: 20, width: 20 }}
                onClick={onPressEdit}
              />
              <DeleteIcon
                sx={{ color: COLORS.secondary, height: 20, width: 20 }}
                onClick={onPressDelete}
              />
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ justifyContent: "space-between", my: 1 }}
            spacing={2}
          >
            <Stack direction={{ xs: "row", sm: "row" }}>
              <Typography
                sx={{
                  fontFamily: "PTSans-Regular",
                  fontSize: 14,
                  color: COLORS.grey,
                }}
              >
                Color:
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PTSans-Regular",
                  fontSize: 14,
                  ml: 2,
                }}
              >
                {color}
              </Typography>
            </Stack>
            <Stack direction={{ xs: "row", sm: "row" }}>
              <Typography
                sx={{
                  fontFamily: "PTSans-Regular",
                  fontSize: 14,
                  color: COLORS.grey,
                }}
              >
                price:
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ justifyContent: "space-between" }}
            spacing={2}
          >
            <Stack direction={{ xs: "row", sm: "row" }}>
              <Typography
                sx={{
                  fontFamily: "PTSans-Regular",
                  fontSize: 14,
                  color: COLORS.grey,
                }}
              >
                Length:
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PTSans-Regular",
                  fontSize: 14,
                  ml: 2,
                }}
              >
                {length}"
              </Typography>
            </Stack>
            <Stack direction={{ xs: "row", sm: "row" }}>
              <Typography
                sx={{
                  fontFamily: "PTSans-Bold",
                  fontSize: 17,
                  color: COLORS.secondary,
                }}
              >
                ${price}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Button
          variant="contained"
          sx={{ width: "100%", borderRadius: 0 }}
          onClick={onPress}
        >
          View Product Details
        </Button>
      </Box>
    </Grid>
  );
};
