import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import BreadCrumb from "../../components/website/BreadCrumb";
import {
  deleteProductFromCart,
  getShoppingCart,
  updateShoppingCart,
} from "../../redux/actions";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { COLORS, FONTS, IMAGES } from "../../constants";
import { Form, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { CartItem } from "../../components";
import { getUniqueJsonArray } from "../../services";
import { store } from "../../redux/store/store";
import { useNavigate } from "react-router-dom";

const Cart = (props) => {
  const navigate = useNavigate();
  const [productId, setProductId] = useState(null);

  useEffect(() => {
    handleGetCart();
  }, []);

  const handleGetCart = async () => {
    const { accessToken } = props.authentication;
    await props
      .getShoppingCart("ShoppingCart", accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const getProductColor = (attributes, product_attribute) => {
    console.log(attributes);
    const result = getUniqueJsonArray(attributes, "color").filter((element) => {
      return (
        (element.color_attribute_id === product_attribute[0].id &&
          element.color_value_id === Number(product_attribute[0].value)) ||
        (element.color_attribute_id === product_attribute[1].id &&
          element.color_value_id === Number(product_attribute[1].value))
      );
    });
    console.log(result);
    return result[0]?.color;
  };
  const getProductLength = (attributes, product_attribute) => {
    const result = getUniqueJsonArray(attributes, "length_inches").filter(
      (element) => {
        return (
          (element.length_attribute_id === product_attribute[0].id &&
            element.length_value_id === Number(product_attribute[0].value)) ||
          (element.length_attribute_id === product_attribute[1].id &&
            element.length_value_id === Number(product_attribute[1].value))
        );
      }
    );
    return result[0]?.length_inches;
  };
  const getProductPrice = (attributes, product_attribute) => {
    const color = getProductColor(attributes, product_attribute);
    const length = getProductLength(attributes, product_attribute);
    const result = attributes?.filter((element) => {
      return element.color === color && element.length_inches === length;
    });

    return result[0]?.price;
  };
  const handleDecreaseQuantity = async (productId, quantity) => {
    setProductId(productId);
    const { accessToken } = props.authentication;
    if (quantity > 1) {
      let qty = quantity - 1;
      await props
        .updateCart(productId, qty, accessToken)
        .then(() => {
          const { updateCartStatus } = store.getState().shoppingCart;
          if (updateCartStatus) {
            toast.success("Cart updated successfully");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  const handleIncreaseQuantity = async (productId, quantity) => {
    setProductId(productId);
    const { accessToken } = props.authentication;
    let qty = quantity + 1;
    await props
      .updateCart(productId, qty, accessToken)
      .then(() => {
        const { updateCartStatus } = store.getState().shoppingCart;
        if (updateCartStatus) {
          toast.success("Cart updated successfully");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getVendorIds = () => {
    const { shoppingCart } = props.shoppingCart;
    const { vendors } = this.state;
    for (let i = 0; i < shoppingCart.length; i++) {
      if (vendors.length === 0) {
        this.setState({
          vendors: [...vendors, shoppingCart[i].product.vendor.customer_id],
        });
      } else {
        for (let j = 0; j < vendors.length; j++) {
          if (shoppingCart[i].product.vendor.customer_id === vendors[j]) {
            this.setState({
              vendors: [...vendors, shoppingCart[i].product.vendor.customer_id],
            });
          }
        }
      }
    }
    // console.log(this.state.vendors);
  };
  const handleDeleteFromCart = async (productId) => {
    const { accessToken } = props.authentication;
    await props
      .deleteFromCart(productId, "ShoppingCart", accessToken)
      .then(() => {
        const { deleteFromCartStatus } = store.getState().shoppingCart;
        if (deleteFromCartStatus) {
          toast.success("Item deleted from successfully");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    console.log("deleted");
  };
  const {
    shoppingCart,
    cartTotal,
    shoppingCartLoading,
    deleteFromCartLoading,
    updateCartLoading,
  } = props.shoppingCart;
  console.log(shoppingCart);
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <BreadCrumb page="Product Details" from="Shop" fromLink="./shop" />
      <Container sx={{ flex: 1 }}>
        <Grid container spacing={4} sx={{ mt: 5, pb: 5 }}>
          <Grid item xs={12} md={8} lg={8}>
            <Grid
              container
              sx={{ borderBottom: 1, py: 2, borderBottomColor: COLORS.border }}
            >
              <Grid
                item
                xs={4}
                md={4}
                lg={4}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 20 }}>
                  Product
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                lg={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 20 }}>
                  Quantity
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                lg={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 20 }}>
                  Total
                </Typography>
              </Grid>
            </Grid>
            {shoppingCart?.map((item, index) => (
              <CartItem
                key={index}
                productName={item?.product?.name}
                image={item?.product?.images[0]?.src}
                quantity={item?.quantity}
                price={getProductPrice(
                  item?.product.attributes,
                  item?.product_attributes
                )}
                increaseQuantity={() =>
                  handleIncreaseQuantity(item?.id, item?.quantity)
                }
                decreaseQuantity={() =>
                  handleDecreaseQuantity(item?.id, item?.quantity)
                }
                handleDelete={() => handleDeleteFromCart(item?.id)}
              />
            ))}
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ mt: 2 }}>
            <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 20 }}>
              PROMO CODE
            </Typography>

            <Stack direction={{ xs: "row", sm: "row", md: "row" }}>
              {/* <TextField
                label="Enter Promo Code"
                fullWidth
                sx={{ my: 2, borderRadius: 0 }}
              /> */}
              <Form>
                <Form.Group
                  className="mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  {/* <Form.Label>Email address</Form.Label> */}
                  <Form.Control
                    // size="lg"
                    type="text"
                    placeholder="Enter promo code"
                    style={{ borderRadius: 0, height: 45, width: 280 }}
                  />
                </Form.Group>
              </Form>
              <Button
                variant="contained"
                sx={{
                  // mx: 0.5,
                  my: 2,
                  borderRadius: 0,
                  height: 45,
                  width: 130,
                }}
                onClick={handleDecreaseQuantity}
              >
                Apply
              </Button>
            </Stack>
            <Box sx={{ background: COLORS.other, p: 3, mt: 5 }}>
              <Typography sx={{ fontFamily: FONTS.subtitle, fontSize: 22 }}>
                CART TOTAL
              </Typography>
              <Stack
                direction={{ sm: "row", md: "row" }}
                sx={{ justifyContent: "space-between", mt: 2 }}
              >
                <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
                  Sub Total
                </Typography>
                <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 18 }}>
                  {cartTotal?.order_total}
                </Typography>
              </Stack>
              <Stack
                direction={{ sm: "row", md: "row" }}
                sx={{ justifyContent: "space-between", mt: 2 }}
              >
                <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
                  Discount
                </Typography>
                <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 18 }}>
                  $0.00
                </Typography>
              </Stack>
              <Stack
                direction={{ sm: "row", md: "row" }}
                sx={{ justifyContent: "space-between", mt: 2 }}
              >
                <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
                  Grand Total
                </Typography>
                <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 18 }}>
                  {cartTotal?.order_total}
                </Typography>
              </Stack>
              <Stack>
                <Button
                  variant="contained"
                  sx={{
                    // mx: 0.5,
                    background: COLORS.primary,
                    my: 2,
                    mt: 4,
                    borderRadius: 0,
                    height: 45,
                    width: 170,
                    alignSelf: "center",
                  }}
                  onClick={() => navigate("/checkout")}
                >
                  Checkout
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  common: state.common,
  authentication: state.authentication,
  customerProducts: state.customerProducts,
  shoppingCart: state.shoppingCart,
});
const mapDispatchToProps = (dispatch) => ({
  getShoppingCart: (cartType, token) =>
    dispatch(getShoppingCart(cartType, token)),
  updateCart: (productId, quantity, token) =>
    dispatch(updateShoppingCart(productId, quantity, token)),
  deleteFromCart: (itemId, cartType, token) =>
    dispatch(deleteProductFromCart(itemId, cartType, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
