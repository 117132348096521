import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";

import GlobalStyles from "@mui/material/GlobalStyles";

import IMAGES from "../../constants/images";
import { COLORS, FONTS } from "../../constants/theme";

import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import BreadCrumb from "../../components/website/BreadCrumb";
import { Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import {
  Box,
  Card,
  Collapse,
  FormControl,
  Grid,
  Input,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import StarBorder from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Product from "../../components/website/Product";
import { getCustomerProducts } from "../../redux/actions/customer/customerProductsActions";
import {
  addToCart,
  getCategories,
  getShoppingCart,
  getSubCategories,
} from "../../redux/actions";
import { Add, Remove } from "@mui/icons-material";
import { CategoriesFilters, SideBarTitle } from "../../components";
import { useNavigate } from "react-router-dom";

const Shop = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    getAllProducts();
    getCategories();
  }, []);
  const getAllProducts = async () => {
    const { accessToken } = props.authentication;
    // const { productSubCategory } = props.setupProduct;

    await props
      .getCustomerProducts(pageSize, page, "", searchText, true, accessToken)
      .then(() => {
        const { products } = props.customerProducts;
        // this.setState({ data: tstate.data.concat(products) });
      });
  };
  const getCategories = async () => {
    const { accessToken } = props.authentication;
    await props
      .getCategories(20, 1, "", "", "", "", "", "", "", "", 0, accessToken)
      .then(() => {
        console.log("Categories found");
      })
      .catch((error) => {
        // Alert.alert("Alert", error.message);
        console.log(error.message);
      });
  };
  const getProductSubCategories = async (id) => {
    const { accessToken } = props.authentication;
    await props
      .getProductSubCategories(
        20,
        1,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        id,
        accessToken
      )
      .then(() => {
        console.log("Sub categories found");
      })
      .catch((error) => {
        console.log(error.message);
        // Alert.alert("Alert", error.message);
      });
  };
  const handleClick = (id) => {
    if (categoryId !== id) {
      setCategoryId(id);
    } else {
      setCategoryId(null);
    }
    getProductSubCategories(id);
  };
  const { products } = props.customerProducts;
  const { categories, subCategories } = props.common;
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <BreadCrumb page="Shop" from="Home" fromLink="./home" />
      <Container>
        <Grid container spacing={4} sx={{ mt: 5, pb: 5 }}>
          <Grid item xs={12} md={3} lg={3}>
            {/* <Typography>Side Bar</Typography> */}
            {/* <TextField
              fullWidth       
              label="Search Product..."
              size="small"
              // sx={{ mt: 2 }} .
            /> */}
            <SideBarTitle title="Categories" />
            <CategoriesFilters
              categories={categories}
              subCategories={subCategories}
            />
            <SideBarTitle title="Shop By Color" />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                my: 2,
              }}
            >
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                1
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                1B
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                4
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                613
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                2
              </Box>
            </Box>
            <SideBarTitle title="Shop By Length" />
            <Box
              sx={{
                // display: "flex",
                // flexWrap: "wrap-reverse",
                my: 2,
              }}
            >
              <Box
                sx={{
                  width: 220,
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                8 inches - 10 inches
              </Box>
              <Box
                sx={{
                  width: 220,
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                10 inches - 12 inches
              </Box>
              <Box
                sx={{
                  width: 220,
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                12 inches - 14 inches
              </Box>
              <Box
                sx={{
                  width: 220,
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                14 inches - 16 inches
              </Box>
              <Box
                sx={{
                  width: 220,
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                16 inches - 18 inches
              </Box>
              <Box
                sx={{
                  width: 220,
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                18 inches - 20 inches
              </Box>
            </Box>
            <SideBarTitle title="Shop By Brand" />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                my: 2,
              }}
            >
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                Grecian Formula
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                Wahl
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                Acovil
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                Zen Wigs
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                Trisha
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                Young Forever
              </Box>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: COLORS.border,
                  my: 1,
                  p: 1,
                  mx: 1,
                  pl: 3,
                  pr: 3,
                }}
              >
                Everyday Fresh
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <Stack
              direction={{ xs: "col", sm: "row" }}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mb: 5,
                background: COLORS.border,
                height: 60,
                p: 2,
              }}
            >
              <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
                Showing 1–12 of 126 results
              </Typography>
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography sx={{ fontFamily: FONTS.subtitle2, mr: 2 }}>
                  Sort By
                </Typography>
                {/* <FormControl> */}
                {/* <InputLabel id="state-select">State</InputLabel> */}
                {/* <Select */}
                {/* variant="standard"   
                    labelId="state-select"
                    id="state-select"
                    label="State"
                    label={null}           
                    sx={{
                      width: 200,
                      height: 40,
                      border: "1px solid black",
                      fontFamily: FONTS.subtitle,
                    }}
                    size="small" 
                    // onChange={(e) => {
                    //   setBusinessState(e.target.value);
                    //   setBusinessStateError(false);
                    //   setBusinessStateHelperText("");
                    // }}
                    // value={businessState}    
                  // >
                    {/* {states?.map((state, index) => ( */}
                {/* <MenuItem       
                      value={"Lowest To Highest"}
                      sx={{ fontFamily: FONTS.subtitle }}
                    >
                      Lowest To Highest
                    </MenuItem>
                    <MenuItem
                      value={"Highest To Lowest"}   
                      sx={{ fontFamily: FONTS.subtitle }}
                    >
                      Highest To Lowest
                    </MenuItem> */}
                {/* ))} */}
                {/* </Select>
                </FormControl> */}
                <Dropdown
                  style={{
                    backgroundColor: COLORS.primary,
                    fontFamily: FONTS.subtitle,
                  }}
                >
                  <Dropdown.Toggle
                    size={200}
                    variant="dark"
                    style={{
                      backgroundColor: COLORS.primary,
                      fontFamily: FONTS.subtitle,
                    }}
                    // id="dropdown-basic"
                  >
                    Newest
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>
            </Stack>
            <Grid container spacing={2} sx={{}}>
              {products.map(
                (product, index) => (
                  console.log(product),
                  (
                    <Product
                      key={index}
                      image={product?.images[0]?.src}
                      productName={product?.name}
                      discountPrice={product?.attributes[0]?.discount_price}
                      price={product?.attributes[0]?.price}
                      brand={product?.vendor?.shop_name}
                      rating={product?.rating_average}
                      onPressProduct={() =>
                        navigate("/product-details", {
                          state: { productId: product?.id },
                        })
                      }
                    />
                  )
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  customerProducts: state.customerProducts,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  getCustomerProducts: (
    pageSize,
    page,
    categoryId,
    keyword,
    showOnHomePage,
    token
  ) =>
    dispatch(
      getCustomerProducts(
        pageSize,
        page,
        categoryId,
        keyword,
        showOnHomePage,
        token
      )
    ),
  getShoppingCart: (cartType, token) =>
    dispatch(getShoppingCart(cartType, token)),
  addToCart: (productId, quantity, cartType, attributes, token) =>
    dispatch(addToCart(productId, quantity, cartType, attributes, token)),
  getCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
  getProductSubCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getSubCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(Shop);
