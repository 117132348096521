import { Box, Grid, Stack, Typography } from "@mui/material";
import { COLORS, FONTS } from "../../constants";
import PersonIcon from "@mui/icons-material/Person";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";

export const Address = ({ name, address, onEdit, onDelete }) => {
  return (
    <Box sx={{ background: COLORS.white, my: 3, boxShadow: 4, p: 3 }}>
      <Grid container>
        <Grid item sx={11} md={11} lg={11}>
          <Stack
            direction={{ xs: "row", sm: "row", lg: "row" }}
            // sx={{ justifyContent: "space-between" }}
          >
            <PersonIcon sx={{ color: COLORS.secondary }} />
            <Typography
              sx={{ ml: 2, fontFamily: FONTS.subtitle2, fontSize: 18 }}
            >
              {name}
            </Typography>
          </Stack>
          <Stack direction={{ xs: "row", sm: "row", lg: "row" }} sx={{ mt: 2 }}>
            <PinDropIcon sx={{ color: COLORS.secondary }} />
            <Typography sx={{ ml: 2, fontFamily: FONTS.subtitle }}>
              {address}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          sm={1}
          md={1}
          lg={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DeleteIcon sx={{ color: COLORS.primary }} onClick={onDelete} />
          <BorderColorIcon
            sx={{ color: COLORS.primary, ml: 2 }}
            onClick={onEdit}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
