export const customerProfileTypes = {
  CUSTOMER_PROFILE_LOADING: "CUSTOMER_PROFILE_LOADING",
  CUSTOMER_PROFILE_SUCCESS: "CUSTOMER_PROFILE_SUCCESS",
  CUSTOMER_PROFILE_FAILURE: "CUSTOMER_PROFILE_FAILURE",

  ADD_CUSTOMER_ADDRESS_LOADING: "ADD_CUSTOMER_ADDRESS_LOADING",
  ADD_CUSTOMER_ADDRESS_SUCCESS: "ADD_CUSTOMER_ADDRESS_SUCCESS",
  ADD_CUSTOMER_ADDRESS_FAILURE: "ADD_CUSTOMER_ADDRESS_FAILURE",

  UPDATE_CUSTOMER_ADDRESS_LOADING: "UPDATE_CUSTOMER_ADDRESS_LOADING",
  UPDATE_CUSTOMER_ADDRESS_SUCCESS: "UPDATE_CUSTOMER_ADDRESS_SUCCESS",
  UPDATE_CUSTOMER_ADDRESS_FAILURE: "UPDATE_CUSTOMER_ADDRESS_FAILURE",

  DELETE_CUSTOMER_ADDRESS_LOADING: "DELETE_CUSTOMER_ADDRESS_LOADING",
  DELETE_CUSTOMER_ADDRESS_SUCCESS: "DELETE_CUSTOMER_ADDRESS_SUCCESS",
  DELETE_CUSTOMER_ADDRESS_FAILURE: "DELETE_CUSTOMER_ADDRESS_FAILURE",

  CUSTOMER_ADDRESSES_LOADING: "CUSTOMER_ADDRESSES_LOADING",
  CUSTOMER_ADDRESSES_SUCCESS: "CUSTOMER_ADDRESSES_SUCCESS",
  CUSTOMER_ADDRESSES_FAILURE: "CUSTOMER_ADDRESSES_FAILURE",

  ENABLE_CUSTOMER_NOTIFICATIONS_LOADING:
    "ENABLE_CUSTOMER_NOTIFICATIONS_LOADING",
  ENABLE_CUSTOMER_NOTIFICATIONS_SUCCESS:
    "ENABLE_CUSTOMER_NOTIFICATIONS_SUCCESS",
  ENABLE_CUSTOMER_NOTIFICATION_FAILURE: "ENABLE_CUSTOMER_NOTIFICATIONS_FAILURE",

  CUSTOMER_ACCOUNTS_LOADING: "CUSTOMER_ACCOUNTS_LOADING",
  CUSTOMER_ACCOUNTS_SUCCESS: "CUSTOMER_ACCOUNTS_SUCCESS",
  CUSTOMER_ACCOUNTS_FAILURE: "CUSTOMER_ACCOUNTS_FAILURE",

  ADD_CUSTOMER_ACCOUNT_LOADING: "ADD_CUSTOMER_ACCOUNT_LOADING",
  ADD_CUSTOMER_ACCOUNT_SUCCESS: "ADD_CUSTOMER_ACCOUNT_SUCCESS",
  ADD_CUSTOMER_ACCOUNT_FAILURE: "ADD_CUSTOMER_ACCOUNT_FAILURE",

  UPDATE_CUSTOMER_ACCOUNT_LOADING: "UPDATE_CUSTOMER_ACCOUNT_LOADING",
  UPDATE_CUSTOMER_ACCOUNT_SUCCESS: "UPDATE_CUSTOMER_ACCOUNT_SUCCESS",
  UPDATE_CUSTOMER_ACCOUNT_FAILURE: "UPDATE_CUSTOMER_ACCOUNT_FAILURE",

  DELETE_CUSTOMER_ACCOUNT_LOADING: "DELETE_CUSTOMER_ACCOUNT_LOADING",
  DELETE_CUSTOMER_ACCOUNT_SUCCESS: "DELETE_CUSTOMER_ACCOUNT_SUCCESS",
  DELETE_CUSTOMER_ACCOUNT_FAILURE: "DELETE_CUSTOMER_ACCOUNT_FAILURE",
};
export const customerProductsTypes = {
  CUSTOMER_PRODUCTS_LOADING: "CUSTOMER_PRODUCTS_LOADING",
  CUSTOMER_PRODUCTS_SUCCESS: "CUSTOMER_PRODUCTS_SUCCESS",
  CUSTOMER_PRODUCTS_FAILURE: "CUSTOMER_PRODUCTS_FAILURE",

  PRODUCT_DETAILS_LOADING: "PRODUCT_DETAILS_LOADING",
  PRODUCT_DETAILS_SUCCESS: "PRODUCT_DETAILS_SUCCESS",
  PRODUCT_DETAILS_FAILURE: "PRODUCT_DETAILS_FAILURE",

  PRODUCT_REVIEWS_LOADING: "PRODUCT_REVIEWS_LOADING",
  PRODUCT_REVIEWS_SUCCESS: "PRODUCT_REVIEWS_SUCCESS",
  PRODUCT_REVIEWS_FAILURE: "PRODUCT_REVIEWS_FAILURE",

  ADD_PRODUCT_REVIEW_LOADING: "ADD_PRODUCT_REVIEW_LOADING",
  ADD_PRODUCT_REVIEW_SUCCESS: "ADD_PRODUCT_REVIEW_SUCCESS",
  ADD_PRODUCT_REVIEW_FAILURE: "ADD_PRODUCT_REVIEW_FAILURE",

  QUERIES_LOADING: "QUERIES_LOADING",
  QUERIES_SUCCESS: "QUERIES_SUCCESS",
  QUERIES_FAILURE: "QUERIES_FAILURE",

  ADD_QUERY_LOADING: "ADD_QUERY_LOADING",
  ADD_QUERY_SUCCESS: "ADD_QUERY_SUCCESS",
  ADD_QUERY_FAILURE: "ADD_QUERY_FAILURE",
};

export const shoppingCartTypes = {
  SHOPPING_CART_LOADING: "SHOPPING_CART_LOADING",
  SHOPPING_CART_SUCCESS: "SHOPPING_CART_SUCCESS",
  SHOPPING_CART_FAILURE: "SHOPPING_CART_FAILURE",

  ADD_TO_CART_LOADING: "ADD_TO_CART_LOADING",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",

  UPDATE_CART_LOADING: "UPDATE_CART_LOADING",
  UPDATE_CART_SUCCESS: "UPDATE_CART_SUCCESS",
  UPDATE_CART_FAILURE: "UPDATE_CART_FAILURE",

  DELETE_FROM_CART_LOADING: "DELETE_FROM_CART_LOADING",
  DELETE_FROM_CART_SUCCESS: "DELETE_FROM_CART_SUCCESS",
  DELETE_FROM_CART_FAILURE: "DELETE_FROM_CART_FAILURE",

  CONFIRM_ORDER_LOADING: "CONFIRM_ORDER_LOADING",
  CONFIRM_ORDER_SUCCESS: "CONFIRM_ORDER_SUCCESS",
  CONFIRM_ORDER_FAILURE: "CONFIRM_ORDER_FAILURE",
};

export const wishlistTypes = {
  WISHLIST_LOADING: "GET_WISHLIST_LOADING",
  WISHLIST_SUCCESS: "GET_WISHLIST_SUCCESS",
  WISHLIST_FAILURE: "GET_WISHLIST_FAILURE",

  ADD_TO_WISHLIST_LOADING: "ADD_TO_WISHLIST_LOADING",
  ADD_TO_WISHLIST_SUCCESS: "ADD_TO_WISHLIST_SUCCESS",
  ADD_TO_WISHLIST_FAILURE: "ADD_TO_WISHLIST_FAILURE",

  DELETE_FROM_WISHLIST_LOADING: "DELETE_FROM_WISHLIST_LOADING",
  DELETE_FROM_WISHLIST_SUCCESS: "DELETE_FROM_WISHLIST_SUCCESS",
  DELETE_FROM_WISHLIST_FAILURE: "DELETE_FROM_WISHLIST_FAILURE",

  FOLLOW_STORE_LOADING: "FOLLOW_STORE_LOADING",
  FOLLOW_STORE_SUCCESS: "FOLLOW_STORE_SUCCESS",
  FOLLOW_STORE_FAILURE: "FOLLOW_STORE_FAILURE",

  FOLLOWED_STORES_LOADING: "FOLLOWED_STORES_LOADING",
  FOLLOWED_STORES_SUCCESS: "FOLLOWED_STORES_SUCCESS",
  FOLLOWED_STORES_FAILURE: "FOLLOWED_STORES_FAILURE",

  STORE_DETAILS_LOADING: "STORE_DETAILS_LOADING",
  STORE_DETAILS_SUCCESS: "STORE_DETAILS_SUCCESS",
  STORE_DETAILS_FAILURE: "STORE_DETAILS_FAILURE",
};

export const customerOrdersTypes = {
  ORDER_HISTORY_LOADING: "ORDER_HISTORY_LOADING",
  ORDER_HISTORY_SUCCESS: "ORDER_HISTORY_SUCCESS",
  ORDER_HISTORY_FAILURE: "ORDER_HISTORY_FAILURE",

  ORDER_DETAILS_LOADING: "ORDER_DETAILS_LOADING",
  ORDER_DETAILS_SUCCESS: "ORDER_DETAILS_SUCCESS",
  ORDER_DETAILS_FAILURE: "ORDER_DETAILS_FAILURE",
};
