import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import OrderCard from "../../../components/OrderCard";
function CustomerCart(props) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <Page title="Dashboard | Orders History">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Cart
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ alignItems: "center", justifyContent: "space-between" }}
              centered
            >
              <Tab label="Cart" />
              <Tab label="WishList" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <OrderCard isDelivered={false} />
            <OrderCard />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <OrderCard />
            <OrderCard />
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}

export default CustomerCart;
