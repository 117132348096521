export const vendorOrdersTypes = {
  VENDOR_ORDERS_LOADING: "VENDOR_ORDERS_LOADING",
  VENDOR_ORDERS_SUCCESS: "VENDOR_ORDERS_SUCCESS",
  VENDOR_ORDERS_FAILURE: "VENDOR_ORDERS_FAILURE",

  VENDOR_ORDERS_COUNT_LOADING: "VENDOR_ORDERS_COUNT_LOADING",
  VENDOR_ORDERS_COUNT_SUCCESS: "VENDOR_ORDERS_COUNT_SUCCESS",
  VENDOR_ORDERS_COUNT_FAILURE: "VENDOR_ORDERS_COUNTS_FAILURE",

  VENDOR_ORDERS_BY_ID_LOADING: "VENDOR_ORDERS_BY_ID_LOADING",
  VENDOR_ORDERS_BY_ID_SUCCESS: "VENDOR_ORDERS_BY_ID_SUCCESS",
  VENDOR_ORDERS_BY_ID_FAILURE: "VENDOR_ORDERS_BY_ID_FAILURE",
};

export const vendorStoreTypes = {
  VENDOR_DASHBOARD_DETAILS_LOADING: "VENDOR_DASHBOARD_DETAILS_LOADING",
  VENDOR_DASHBOARD_DETAILS_SUCCESS: "VENDOR_DASHBOARD_DETAILS_SUCCESS",
  VENDOR_DASHBOARD_DETAILS_FAILURE: "VENDOR_DASHBOARD_DETAILS_FAILURE",

  VENDOR_PRODUCT_DETAILS_LOADING: "VENDOR_PRODUCT_DETAILS_LOADING",
  VENDOR_PRODUCT_DETAILS_SUCCESS: "VENDOR_PRODUCT_DETAILS_SUCCESS",
  VENDOR_PRODUCT_DETAILS_FAILURE: "VENDOR_PRODUCT_DETAILS_FAILURE",

  WORST_SELLING_PRODUCTS_LOADING: "WORST_SELLING_PRODUCTS_LOADING",
  WORST_SELLING_PRODUCTS_SUCCESS: "WORST_SELLING_PRODUCTS_SUCCESS",
  WORST_SELLING_PRODUCTS_FAILURE: "WORST_SELLING_PRODUCTS_FAILURE",

  OUT_OF_STOCK_PRODUCTS_LOADING: "OUT_OF_STOCK_PRODUCTS_LOADING",
  OUT_OF_STOCK_PRODUCTS_SUCCESS: "OUT_OF_STOCK_PRODUCTS_SUCCESS",
  OUT_OF_STOCK_PRODUCTS_FAILURE: "OUT_OF_STOCK_PRODUCTS_FAILURE",

  BEST_SELLING_PRODUCTS_LOADING: "BEST_SELLING_PRODUCTS_LOADING",
  BEST_SELLING_PRODUCTS_SUCCESS: "BEST_SELLING_PRODUCTS_SUCCESS",
  BEST_SELLING_PRODUCTS_FAILURE: "BEST_SELLING_PRODUCTS_FAILURE",

  SALE_PRODUCTS_LOADING: "SALE_PRODUCTS_LOADING",
  SALE_PRODUCTS_SUCCESS: "SALE_PRODUCTS_SUCCESS",
  SALE_PRODUCTS_FAILURE: "SALE_PRODUCTS_FAILURE",

  DELETE_PRODUCT_LOADING: "DELETE_PRODUCT_LOADING",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",

  VENDOR_PROFILE_LOADING: "VENDOR_PROFILE_LOADING",
  VENDOR_PROFILE_SUCCESS: "VENDOR_PROFILE_SUCCESS",
  VENDOR_PROFILE_FAILURE: "VENDOR_PROFILE_FAILURE",

  VENDOR_PRODUCTS_LOADING: "VENDOR_PRODUCTS_LOADING",
  VENDOR_PRODUCTS_SUCCESS: "VENDOR_PRODUCTS_SUCCESS",
  VENDOR_PRODUCTS_FAILURE: "VENDOR_PRODUCTS_FAILURE",

  TOTAL_EARNING_LOADING: "TOTAL_EARNING_LOADING",
  TOTAL_EARNING_SUCCESS: "TOTAL_EARNING_SUCCESS",
  TOTAL_EARNING_FAILURE: "TOTAL_EARNING_FAILURE",

  MONTHLY_EARNING_LOADING: "MONTHLY_EARNING_LOADING",
  MONTHLY_EARNING_SUCCESS: "MONTHLY_EARNING_SUCCESS",
  MONTHLY_EARNING_FAILURE: "MONTHLY_EARNING_FAILURE",
};

export const vendorSetupTypes = {
  SETUP_VENDOR_LOADING: "SETUP_VENDOR_LOADING",
  SETUP_VENDOR_SUCCESS: "SETUP_VENDOR_SUCCESS",
  SETUP_VENDOR_FAILURE: "SETUP_VENDOR_FAILURE",

  VENDOR_TAGS_LOADING: "VENDOR_TAGS_LOADING",
  VENDOR_TAGS_SUCCESS: "VENDOR_TAGS_SUCCESS",
  VENDOR_TAGS_FAILURE: "VENDOR_TAGS_FAILURE",

  SETUP_VENDOR_TAGS_LOADING: "SETUP_VENDOR_TAGS_LOADING",
  SETUP_VENDOR_TAGS_SUCCESS: "SETUP_VENDOR_TAGS_SUCCESS",
  SETUP_VENDOR_TAGS_FAILURE: "SETUP_VENDOR_TAGS_FAILURE",

  SETUP_VENDOR_DELIVERY_PROCESS_LOADING:
    "SETUP_VENDOR_DELIVERY_PROCESS_LOADING",
  SETUP_VENDOR_DELIVERY_PROCESS_SUCCESS:
    "SETUP_VENDOR_DELIVERY_PROCESS_SUCCESS",
  SETUP_VENDOR_DELIVERY_PROCESS_FAILURE:
    "SETUP_VENDOR_DELIVERY_PROCESS_FAILURE",

  SETUP_VENDOR_REFUND_POLICY_LOADING: "SETUP_VENDOR_REFUND_POLICY_LOADING",
  SETUP_VENDOR_REFUND_POLICY_SUCCESS: "SETUP_VENDOR_REFUND_POLICY_SUCCESS",
  SETUP_VENDOR_REFUND_POLICY_FAILURE: "SETUP_VENDOR_REFUND_POLICY_FAILURE",

  SETUP_FRAUD_PROTECTION_LOADING: "SETUP_FRAUD_PROTECTION_LOADING",
  SETUP_FRAUD_PROTECTION_SUCCESS: "SETUP_FRAUD_PROTECTION_SUCCESS",
  SETUP_FRAUD_PROTECTION_FAILURE: "SETUP_FRAUD_PROTECTION_FAILURE",

  SETUP_PAYOUT_ACCOUNT_LOADING: "SETUP_PAYOUT_ACCOUNT_LOADING",
  SETUP_PAYOUT_ACCOUNT_SUCCESS: "SETUP_PAYOUT_ACCOUNT_SUCCESS",
  SETUP_PAYOUT_ACCOUNT_FAILURE: "SETUP_PAYOUT_ACCOUNT_FAILURE",

  SET_IS_UPDATING_SUCCESS: "SET_IS_UPDATING_SUCCESS",
};
export const productSetupTypes = {
  SETUP_PRODUCT_LOADING: "SETUP_PRODUCT_LOADING",
  SETUP_PRODUCT_SUCCESS: "SETUP_PRODUCT_SUCCESS",
  SETUP_PRODUCT_FAILURE: "SETUP_PRODUCT_FAILURE",

  SET_PRODUCT_DETAILS_LOADING: "SET_PRODUCT_DETAILS_LOADING",
  SET_PRODUCT_DETAILS_SUCCESS: "SET_PRODUCT_DETAILS_SUCCESS",
  SET_PRODUCT_DETAILS_FAILURE: "SET_PRODUCT_DETAILS_FAILURE",

  SET_PRODUCT_LENGTHS_LOADING: "SET_PRODUCT_LENGTHS_LOADING",
  SET_PRODUCT_LENGTHS_SUCCESS: "SET_PRODUCT_LENGTHS_SUCCESS",
  SET_PRODUCT_LENGTHS_FAILURE: "SET_PRODUCT_LENGTHS_FAILURE",

  SET_PRODUCT_PRICES_LOADING: "SET_PRODUCT_PRICES_LOADING",
  SET_PRODUCT_PRICES_SUCCESS: "SET_PRODUCT_PRICES_SUCCESS",
  SET_PRODUCT_PRICES_FAILURE: "SET_PRODUCT_PRICES_FAILURE",

  UPDATE_PRODUCT_LOADING: "UPDATE_PRODUCT_LOADING",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_UPDATE_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",

  SET_PRODUCT_CATEGORY_SUCCESS: "SET_PRODUCT_CATEGORY_SUCCESS",

  SET_PRODUCT_SUB_CATEGORY_SUCCESS: "SET_PRODUCT_SUB_CATEGORY_SUCCESS",

  SET_PRODUCT_DIMENSIONS_SUCCESS: "SET_PRODUCT_DIMENSIONS_SUCCESS",

  SET_PRODUCT_CARE_INSTRUCTIONS_SUCCESS:
    "SET_PRODUCT_CARE_INSTRUCTIONS_SUCCESS",
  SET_DENSITY_SIZE_SUCCESS: "SET_DENSITY_SIZE_SUCCESS",

  SET_IS_UPDATING_PRODUCT_SUCCESS: "SET_IS_UPDATING_PRODUCT_SUCCESS",
};

export const vendorSaleTypes = {
  ADD_DISCOUNT_LOADING: "ADD_DISCOUNT_LOADING",
  ADD_DISCOUNT_SUCCESS: "ADD_DISCOUNT_SUCCESS",
  ADD_DISCOUNT_FAILURE: "ADD_DISCOUNT_FAILURE",

  GET_DISCOUNT_LOADING: "GET_DISCOUNT_LOADING",
  GET_DISCOUNT_SUCCESS: "GET_DISCOUNT_SUCCESS",
  GET_DISCOUNT_FAILURE: "GET_DISCOUNT_FAILURE",

  DELETE_DISCOUNT_LOADING: "DELETE_DISCOUNT_LOADING",
  DELETE_DISCOUNT_SUCCESS: "DELETE_DISCOUNT_SUCCESS",
  DELETE_DISCOUNT_FAILURE: "DELETE_DISCOUNT_FAILURE",

  UPDATE_DISCOUNT_LOADING: "UPDATE_DISCOUNT_LOADING",
  UPDATE_DISCOUNT_SUCCESS: "UPDATE_DISCOUNT_SUCCESS",
  UPDATE_DISCOUNT_FAILURE: "UPDATE_DISCOUNT_FAILURE",
};

export const staffTypes = {
  STAFF_LOADING: "STAFF_LOADING",
  STAFF_SUCCESS: "STAFF_SUCCESS",
  STAFF_FAILURE: "STAFF_FAILURE",

  STAFF_MEMBER_LOADING: "STAFF_MEMBER_LOADING",
  STAFF_MEMBER_SUCCESS: "STAFF_MEMBER_SUCCESS",
  STAFF_MEMBER_FAILURE: "STAFF_MEMBER_FAILURE",

  INVITE_STAFF_LOADING: "INVITE_STAFF_LOADING",
  INVITE_STAFF_SUCCESS: "INVITE_STAFF_SUCCESS",
  INVITE_STAFF_FAILURE: "INVITE_STAFF_FAILURE",

  DELETE_STAFF_MEMBER_LOADING: "DELETE_STAFF_MEMBER_LOADING",
  DELETE_STAFF_MEMBER_SUCCESS: "DELETE_STAFF_MEMBER_SUCCESS",
  DELETE_STAFF_MEMBER_FAILURE: "DELETE_STAFF_MEMBER_FAILURE",
};
