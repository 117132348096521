import { Box, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { COLORS } from "../../../constants";
import { connect } from "react-redux";
import { getContactInfo } from "../../../redux/actions";
import { useEffect } from "react";
import { toast } from "react-toastify";

function ContactUs(props) {
  const navigate = useNavigate();

  useEffect(() => {
    getContactInfo();
  }, []);

  const getContactInfo = async () => {
    const { accessToken } = props.authentication;
    await props
      .getContactInfo(accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const { contactInfoLoading, contactInfo } = props.common;
  return (
    <Page title="Dashboard | Terms And Conditions">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          Contact Info
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.primary" }}
          >
            Contact Info
          </Typography>

          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 3, alignItems: "center" }}
          >
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{ alignItems: "center" }}
            >
              <PersonIcon sx={{ color: COLORS.secondary, fontSize: 30 }} />
              <Typography sx={{ ml: 2, fontFamily: "PTSans-Bold" }}>
                Name:
              </Typography>
            </Stack>
            <Stack sx={{ ml: 4 }}>
              <Typography
                sx={{ fontFamily: "PTSans-Regular", color: "text.secondary" }}
              >
                {contactInfo?.name}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 2, alignItems: "center" }}
          >
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{ alignItems: "center" }}
            >
              <EmailIcon sx={{ color: COLORS.secondary, fontSize: 30 }} />
              <Typography sx={{ ml: 2, fontFamily: "PTSans-Bold" }}>
                Email:
              </Typography>
            </Stack>
            <Stack sx={{ ml: 4 }}>
              <Typography
                sx={{ fontFamily: "PTSans-Regular", color: "text.secondary" }}
              >
                {contactInfo?.email}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 2, alignItems: "center" }}
          >
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{ alignItems: "center" }}
            >
              <LocalPhoneIcon sx={{ color: COLORS.secondary, fontSize: 30 }} />
              <Typography sx={{ ml: 2, fontFamily: "PTSans-Bold" }}>
                Phone:
              </Typography>
            </Stack>
            <Stack sx={{ ml: 4 }}>
              <Typography
                sx={{ fontFamily: "PTSans-Regular", color: "text.secondary" }}
              >
                {contactInfo?.phone}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 2, alignItems: "center" }}
          >
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{ alignItems: "center" }}
            >
              <PinDropIcon sx={{ color: COLORS.secondary, fontSize: 30 }} />
              <Typography sx={{ ml: 2, fontFamily: "PTSans-Bold" }}>
                Address:
              </Typography>
            </Stack>
            <Stack sx={{ ml: 4 }}>
              <Typography
                sx={{ fontFamily: "PTSans-Regular", color: "text.secondary" }}
              >
                {contactInfo?.address}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  getContactInfo: (token) => dispatch(getContactInfo(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
