import React from "react";
import { IMAGES } from "../../constants";

export const validateEmail = (text) => {
  console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  if (reg.test(text) === false) {
    console.log("Email is Not Correct");
    return false;
  } else {
    console.log("Email is Correct");
    return true;
  }
};
export const getUniqueArray = (array) => {
  const uniqueArray = [...new Set(array.map((item) => item.length_inches))];
  return uniqueArray;
};
export const getUniqueJsonArray = (array, key) => {
  const uniqueArray = [
    ...new Map(array.map((item) => [item[key], item])).values(),
  ];
  return uniqueArray;
};

export const findElementById = (array, id) => {
  const result = array.filter((element) => {
    return element.id === id;
  });
  return result;
};
export const updateObjectInArray = (array, id, myValue) => {
  let newData = array.map((element) =>
    element.id === id
      ? {
          ...element,
          value: myValue,
        }
      : element
  );
  return newData;
};
export const handleUserBadges = (userBadge) => {
  if (userBadge === "Bronze") {
    return IMAGES.bronze;
  } else if (userBadge === "Silver") {
    return IMAGES.silver;
  } else if (userBadge === "Gold") {
    return IMAGES.gold;
  }
};


export const getLocalStorageKey = (key) => {
  if (typeof window !== 'undefined') {
    var localStorageItem = localStorage.getItem(key)

    // If the item does not exist in localStorage, return null
    if (!localStorageItem) {
      return null
    }

    // Parse the localStorage item from a JSON string to a JavaScript object
    var localStorageObject = (localStorageItem)

    // Return the value associated with the property
    return localStorageObject
  }
}

export const getLocalStorageValue = (key, property) => {
  var localStorageItem = localStorage.getItem(key);

  // If the item does not exist in localStorage, return null
  if (!localStorageItem) {
    return null;
  }

  // Parse the localStorage item from a JSON string to a JavaScript object
  var localStorageObject = JSON.parse(localStorageItem);

  // Return the value associated with the property
  return localStorageObject[property];
};
