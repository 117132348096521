import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { FONTS } from "../../constants";
import { Add, Remove } from "@mui/icons-material";
export const CategoriesFilters = ({
  categories,
  subCategories,
  categoryId,
  handleClick,
}) => {
  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      // component="nav"
      aria-labelledby="nested-list-subheader"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Nested List Items
      //   </ListSubheader>
      // }
    >
      {categories?.map((category, index) => (
        // <Typography
        //   sx={{ fontFamily: FONTS.subtitle2, fontSize: 15, mt: 1 }}
        // >
        //   {category?.name}
        // </Typography>
        <Box key={index}>
          <ListItemButton onClick={() => handleClick(category?.id)}>
            {/* <ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}
            <ListItemText>
              <Typography
                sx={{
                  fontFamily: FONTS.subtitle2,
                  fontSize: 15,
                  mt: 1,
                }}
              >
                {category?.name}
              </Typography>
            </ListItemText>
            {/* <Typography>Inbox</Typography> */}
            {categoryId === category?.id ? <Remove /> : <Add />}
          </ListItemButton>
          <Collapse
            in={categoryId === category?.id ? true : false}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {subCategories?.map((item, index) => (
                <ListItemButton sx={{ pl: 4 }} key={index}>
                  {/* <ListItemIcon><StarBorder /></ListItemIcon> */}
                  <ListItemText>
                    <Typography
                      sx={{
                        fontFamily: FONTS.subtitle,
                        fontSize: 15,
                        mt: 1,
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </Box>
      ))}
    </List>
  );
};
