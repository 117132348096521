import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingModal from "../../../components/modals/LoadingModal";
import Page from "../../../components/Page";
import StaffCard from "../../../components/StaffCard";
import { COLORS } from "../../../constants";
import { inviteStaff } from "../../../redux/actions/vendor/staffActions";
import { store } from "../../../redux/store/store";

function InviteStaff(props) {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleInviteStaff = async () => {
    const { accessToken } = props.authentication;
    props
      .inviteStaff(emailAddress, firstName, lastName, accessToken)
      .then(() => {
        const { inviteStaffStatus } = store.getState().staff;
        if (inviteStaffStatus) {
          toast.success("Invite sent successfully");
          navigate("/dashboard/invited-staff");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const { inviteStaffLoading } = props.staff;
  return (
    <Page title="Dashboard | Invite Staff">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Invite Staff
          </Typography>
          <Typography
            sx={{ fontFamily: "PTSans-Bold", mt: 3, color: "text.secondary" }}
          >
            Here you can add other people to help you manage your store
          </Typography>
          <Typography
            sx={{
              fontFamily: "PTSans-regular",
              fontSize: 14,
              mt: 1,
              color: "text.secondary",
            }}
          >
            Your employee will receive an invite to create their account
          </Typography>
          <Box sx={{ mt: 4 }}>
            <TextField
              fullWidth
              required
              label="Email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <TextField
              fullWidth
              required
              sx={{ mt: 2 }}
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              required
              sx={{ mt: 2 }}
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 5, background: COLORS.primary, width: 150 }}
            onClick={() => handleInviteStaff()}
          >
            Send Invite
          </Button>
        </Box>
        <LoadingModal open={inviteStaffLoading} />
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  staff: state.staff,
});
const mapDispatchToProps = (dispatch) => ({
  inviteStaff: (emailAddress, firstName, lastName, token) =>
    dispatch(inviteStaff(emailAddress, firstName, lastName, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InviteStaff);
