import { toast } from "react-toastify";

export const handleGetApi = async (url, token) => {
  let requestOptions = {};
  if (token) {
    requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
  } else {
    requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  }
  console.log("Get call started...");
  try {
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    // console.log(responseJson);
    if (response.status === 200) {
      const finalResponse = { data: responseJson, status: response.status };
      return finalResponse;
    } else if (response.status === 401) {
      toast.error("Unauthorized Request");
      return null;
    } else if (response.status === 500) {
      toast.error("Internal server error");
      return null;
    } else if (response.status === 403) {
      toast.error("Incorrect Credentials, please verify your credentials");
      return null;
    }
  } catch (error) {
    console.error(error.message);
    toast.error(error.message);
    return null;
  }
};
export const handlePostApi = async (url, body, token) => {
  let requestOptions = {};
  if (token) {
    requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: body,
    };
  } else {
    requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: body,
    };
  }
  console.log("Post call started...");
  try {
    const response = await fetch(url, requestOptions).catch((error) => {
      toast.error(error.message);
    });
    const responseJson = await response.json();
    if (response.status === 200) {
      const finalResponse = { data: responseJson, status: response.status };
      return finalResponse;
    } else if (response.status === 401) {
      toast.error("Unauthorized Request");
      return null;
    } else if (response.status === 500) {
      toast.error("Internal server error");
      return null;
    } else if (response.status === 403) {
      toast.error("Incorrect Credentials, please verify your credentials");
      return null;
    } else if (response.status === 400) {
      toast.error(JSON.stringify(responseJson));
      return null;
    }
  } catch (error) {
    console.error(error.message);
    toast.error(error.message);
    return null;
  }
};

export const handlePutApi = async (url, body, token) => {
  let requestOptions = {};
  if (token) {
    requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: body,
    };
  } else {
    requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: body,
    };
  }
  console.log("Put call started...");
  try {
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    console.log(responseJson);
    if (response.status === 200) {
      const finalResponse = { data: responseJson, status: response.status };
      return finalResponse;
    } else if (response.status === 400) {
      if (responseJson) {
        toast.error(responseJson);
      } else {
        toast.error("Internal server error");
      }
      return null;
    } else if (response.status === 401) {
      toast.error("Unauthorized Request");
      return null;
    } else if (response.status === 500) {
      toast.error("Internal server error");
      return null;
    } else if (response.status === 403) {
      toast.error("Something went wrong");
      return null;
    }
  } catch (error) {
    console.error(error.message);
    toast.error(error.message);
    return null;
  }
};

export const handleFormApi = async (url, body, token) => {
  console.log(JSON.stringify(body));
  let requestOptions = {};
  if (token) {
    requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": undefined,
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: body,
    };
  } else {
    requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": undefined,
        Accept: "application/json",
      },
      body: body,
    };
  }
  console.log("Form call started...");
  try {
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    if (response.status === 200) {
      const finalResponse = { data: responseJson, status: response.status };
      return finalResponse;
    } else if (response.status === 401) {
      toast.error("Unauthorized Request");
      return null;
    } else if (response.status === 500) {
      toast.error("Internal server error");
      return null;
    } else if (response.status === 403) {
      toast.error("Something went wrong");
      return null;
    }
  } catch (error) {
    console.error(error.message);
    toast.error(error.message);
    return null;
  }
};
export const handleDeleteApi = async (url, body, token) => {
  console.log(url, body, token);
  let requestOptions = {};
  if (token) {
    requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: body,
    };
  } else {
    requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: body,
    };
  }
  console.log("Delete call started...");
  try {
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    if (response.status === 200) {
      const finalResponse = { data: responseJson, status: response.status };
      return finalResponse;
    } else if (response.status === 401) {
      toast.error("Unauthorized Request");
      return null;
    } else if (response.status === 500) {
      toast.error("Internal server error");
      return null;
    } else if (response.status === 403) {
      toast.error("Incorrect Credentials, please verify your credentials");
      return null;
    }
  } catch (error) {
    console.error(error.message);
    toast.error(error.message);
    return null;
  }
};
