import { Box, Card, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Banner from "../../../components/Banner";
import Page from "../../../components/Page";
import { ICONS } from "../../../constants";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getSubCategories } from "../../../redux/actions";

function ActiveProductsSubCategories(props) {
  const navigate = useNavigate();

  const getProductSubCategories = async () => {
    const { accessToken } = props.authentication;
    await props
      .getProductSubCategories(
        20,
        1,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        17,
        accessToken
      )
      .then(() => {
        console.log("Sub Categories found");
      })
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };

  useEffect(() => {
    getProductSubCategories();
    console.log(props.common.categories);
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  return (
    <Page title="Dashboard | Sub Categories">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography>
          <Banner image={ICONS.active} title="Active Products" value="12" />
          {props.common.subCategories?.map((item, index) => (
            <Stack
              key={index}
              direction={{ xs: "row", sm: "row" }}
              sx={{
                height: 50,
                my: 4,
                boxShadow: 2,
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
              }}
              onClick={() => navigate("/dashboard/active-products")}
            >
              <Typography variant="p" sx={{ fontFamily: "PTSans-Bold" }}>
                {item.name}
              </Typography>
              <ArrowRightIcon />
            </Stack>
          ))}
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  getProductSubCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getSubCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveProductsSubCategories);
