import { Icon } from "@iconify/react";
import goldFilled from "@iconify/icons-ant-design/gold-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.main,
    0
  )} 0%, ${alpha(theme.palette.primary.main, 0.24)} 100%)`,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",

  justifyContent: "center",
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.dark,
  // backgroundImage: `linear-gradient(135deg, ${alpha(
  //   theme.palette.primary.dark,
  //   0
  // )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 234;

export default function DashboardCell({ image, title, value, onPress }) {
  return (
    <RootStyle onClick={onPress}>
      <IconWrapperStyle>
        <img src={image} width={35} height={35} />
      </IconWrapperStyle>
      <Typography variant="h4">{value}</Typography>
      <Typography
        variant="subtitle2"
        sx={{ opacity: 0.72, fontFamily: "PTSans-Regular" }}
      >
        {title}
      </Typography>
    </RootStyle>
  );
}
