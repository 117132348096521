const IMAGES = {
  logo: "/static/images/logo.png",
  logo_white: "/static/images/logo2.png",
  login: "/static/images/login.png",
  welcome: "/static/images/welcome.png",
  dummy: "/static/images/dummy.png",
  store: "/static/images/store.png",
  visa: "/static/images/visa.png",
  slide1: "/static/images/slider/slide1.jpg",
  slide2: "/static/images/slider/slide2.jpg",
  slide3: "/static/images/slider/slide3.jpg",
  slide4: "/static/images/slider/slide4.jpg",
  slide5: "/static/images/slider/slide5.jpeg",
  slide6: "/static/images/slider/slide6.jpeg",
  hairline: "/static/images/hairlines1.jpg",
  cover: "/static/images/cover.jpg",
  shop: "/static/images/shop.webp",
  product: "/static/images/product.webp",
  product2: "/static/images/product2.webp",
  banner: "/static/images/banner.webp",
  about: "/static/images/about.jpg",
  breadcrumb: "/static/images/breadcrumb.webp",
  breadcrumb_img: "/static/images/product3.jpg",
  about_bg: "/static/images/about-bg.jpg",
  home_bg: "/static/images/home-bg.jpg",
  product1: "/static/images/product1.jpg",
  product2: "/static/images/product2.jpg",
  product3: "/static/images/product3.jpg",
  product4: "/static/images/product4.jpg",
  blog1: "/static/images/blog1.webp",
  blog2: "/static/images/blog2.webp",
  blog3: "/static/images/blog3.webp",
  blog11: "/static/images/blog11.webp",
};
export default IMAGES;
