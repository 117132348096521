import { shoppingCartTypes, wishlistTypes } from "../../types";

const initialState = {
  wishlist: [],
  wishlistLoading: false,

  addToWishlistLoading: false,
  addToWishlistStatus: false,

  deleteFromWishlistLoading: false,
  deleteFromWishlistStatus: false,

  followStoreLoading: false,
  followStoreStatus: false,

  followedStoresLoading: false,
  followedStores: undefined,

  storeDetailsLoading: false,
  storeDetails: undefined,
};

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case wishlistTypes.WISHLIST_LOADING:
      return {
        ...state,
        wishlistLoading: true,
        wishlist: [],
      };
    case wishlistTypes.WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: action.payload,
        wishlistLoading: false,
      };
    case wishlistTypes.WISHLIST_FAILURE:
      return {
        ...state,
        wishlistLoading: false,
        wishlist: [],
      };
    case wishlistTypes.ADD_TO_WISHLIST_LOADING:
      return {
        ...state,
        addToWishlistLoading: true,
        addToWishlistStatus: false,
        wishlist: [],
      };
    case wishlistTypes.ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: action.payload,
        addToWishlistStatus: true,
        addToWishlistLoading: false,
      };
    case wishlistTypes.ADD_TO_WISHLIST_FAILURE:
      return {
        ...state,
        addToWishlistStatus: false,
        addToWishlistLoading: false,
      };
    case wishlistTypes.DELETE_FROM_WISHLIST_LOADING:
      return {
        ...state,
        deleteFromWishlistLoading: true,
        deleteFromWishlistStatus: false,
      };
    case wishlistTypes.DELETE_FROM_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: action.payload,
        deleteFromWishlistStatus: true,
        deleteFromWishlistLoading: false,
      };
    case wishlistTypes.DELETE_FROM_WISHLIST_FAILURE:
      return {
        ...state,
        deleteFromWishlistStatus: false,
        deleteFromWishlistLoading: false,
      };
    case wishlistTypes.FOLLOW_STORE_LOADING:
      return {
        ...state,
        followStoreLoading: true,
        followStoreStatus: false,
      };
    case wishlistTypes.FOLLOW_STORE_SUCCESS:
      return {
        ...state,
        followStoreStatus: true,
        followStoreLoading: false,
      };
    case wishlistTypes.FOLLOW_STORE_FAILURE:
      return {
        ...state,
        followStoreStatus: false,
        followStoreLoading: false,
      };
    case wishlistTypes.FOLLOWED_STORES_LOADING:
      return {
        ...state,
        followedStoresLoading: true,
        // followedStores: undefined,
      };
    case wishlistTypes.FOLLOWED_STORES_SUCCESS:
      return {
        ...state,
        followedStores: action.payload,
        followedStoresLoading: false,
      };
    case wishlistTypes.FOLLOWED_STORES_FAILURE:
      return {
        ...state,
        followedStoresLoading: false,
      };
    case wishlistTypes.STORE_DETAILS_LOADING:
      return {
        ...state,
        storeDetailsLoading: true,
        storeDetails: undefined,
      };
    case wishlistTypes.STORE_DETAILS_SUCCESS:
      return {
        ...state,
        storeDetails: action.payload,
        storeDetailsLoading: false,
      };
    case wishlistTypes.STORE_DETAILS_FAILURE:
      return {
        ...state,
        storeDetailsLoading: false,
      };
    default:
      return state;
  }
};
export default wishlistReducer;
