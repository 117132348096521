import { staffTypes } from "../../types";

const initialState = {
  inviteStaffLoading: false,
  inviteStaffStatus: false,

  staffLoading: false,
  staff: [],

  staffMemberLoading: false,
  staffMember: {},

  deleteMemberLoading: false,
  deleteMemberStatus: false,
};
const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case staffTypes.INVITE_STAFF_LOADING:
      return {
        ...state,
        inviteStaffLoading: true,
        inviteStaffStatus: false,
      };
    case staffTypes.INVITE_STAFF_SUCCESS:
      return {
        ...state,
        inviteStaffStatus: true,
        inviteStaffLoading: false,
      };
    case staffTypes.INVITE_STAFF_FAILURE:
      return {
        ...state,
        inviteStaffStatus: false,
        inviteStaffLoading: false,
      };
    case staffTypes.STAFF_LOADING:
      return {
        ...state,
        staffLoading: true,
        staff: [],
      };
    case staffTypes.STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload,
        staffLoading: false,
      };
    case staffTypes.STAFF_FAILURE:
      return {
        ...state,
        staff: [],
        staffLoading: false,
      };
    case staffTypes.STAFF_MEMBER_LOADING:
      return {
        ...state,
        staffMemberLoading: true,
      };
    case staffTypes.STAFF_MEMBER_SUCCESS:
      return {
        ...state,
        staffMember: action.payload,
        staffMemberLoading: false,
      };
    case staffTypes.STAFF_MEMBER_FAILURE:
      return {
        ...state,
        staffMember: {},
        staffMemberLoading: false,
      };
    case staffTypes.DELETE_STAFF_MEMBER_LOADING:
      return {
        ...state,
        deleteMemberLoading: true,
        deleteMemberStatus: false,
      };
    case staffTypes.DELETE_STAFF_MEMBER_SUCCESS:
      return {
        ...state,
        deleteMemberStatus: true,
        deleteMemberLoading: false,
      };
    case staffTypes.DELETE_STAFF_MEMBER_FAILURE:
      return {
        ...state,
        deleteMemberStatus: false,
        deleteMemberLoading: false,
      };
    default:
      return state;
  }
};
export default staffReducer;
