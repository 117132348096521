import { handleGetApi, handlePutApi } from "../../../services";
import { VENDOR_ORDERS } from "../../../apis/vendor";
import { BASE_URL } from "../../../apis";
import { vendorOrdersTypes } from "../../types";

export const getVendorOrders = (
  limit,
  page,
  orderStatus,
  paymentStatus,
  shippingStatus,
  token
) => {
  return async (dispatch) => {
    dispatch({
      type: vendorOrdersTypes.VENDOR_ORDERS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_ORDERS.getVendorOrders +
        "limit=" +
        limit +
        "&page=" +
        page +
        "&OrderStatus=" +
        orderStatus +
        "&PaymentStatus=" +
        paymentStatus +
        "&ShippingStatus=" +
        shippingStatus,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorOrdersTypes.VENDOR_ORDERS_SUCCESS,
        payload: response.data?.orders,
      });
    } else {
      dispatch({
        type: vendorOrdersTypes.VENDOR_ORDERS_FAILURE,
      });
    }
  };
};

export const getVendorOrdersCount = (
  orderStatus,
  paymentStatus,
  shippingStatus,
  token
) => {
  return async (dispatch) => {
    dispatch({
      type: vendorOrdersTypes.VENDOR_ORDERS_COUNT_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_ORDERS.getVenderOrdersCount +
        "OrderStatus=" +
        orderStatus +
        "&PaymentStatus=" +
        paymentStatus +
        "&ShippingStatus=" +
        shippingStatus,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorOrdersTypes.VENDOR_ORDERS_COUNT_SUCCESS,
        payload: response.data.count,
      });
    } else {
      dispatch({
        type: vendorOrdersTypes.VENDOR_ORDERS_COUNT_FAILURE,
      });
    }
  };
};

export const getVendorOrdersById = (orderId, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorOrdersTypes.VENDOR_ORDERS_BY_ID_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + VENDOR_ORDERS.getVendorOrdersById + "id=" + orderId,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorOrdersTypes.VENDOR_ORDERS_BY_ID_SUCCESS,
        payload: response.data?.orders[0],
      });
    } else {
      dispatch({
        type: vendorOrdersTypes.VENDOR_ORDERS_BY_ID_FAILURE,
      });
    }
  };
};
