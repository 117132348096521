// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Card,
  CardHeader,
  Box,
  Button,
  Typography,
  Avatar,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
// ----------------------------------------------------------------------
// Imports
import { toast, ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { COLORS, IMAGES } from "../../../constants";
import AddMoreProductModal from "../../../components/modals/AddMoreProductModal";
import { connect } from "react-redux";
import { setupNewProduct, uploadImages } from "../../../redux/actions";
import LoadingModal from "../../../components/modals/LoadingModal";
import { useEffect } from "react";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function ProductConfirmation(props) {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const { productPrices, productCareInstructions, isUpdatingProduct } =
      props.setupProduct;

    let attributes = [];
    productPrices.forEach((item, index) => {
      let attribute = {
        length_inches: item.length,
        color: item.color,
        stock: item.stock,
        price: item.price,
        id: index + 1,
      };
      attributes.push(attribute);
    });
    setAttributes(attributes);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  // const handleChangeGallery = function loadFile(event) {
  //   let gallery = [...images];
  //   if (event.target.files.length > 0) {
  //     for (let i = 0; i < event.target.files.length; i++) {
  //       gallery.push(event.target.files[i]);
  //     }
  //     setImages(gallery);
  //   }
  // };
  const handleUploadImages = async (image) => {
    // console.log("Img", image);
    const { accessToken } = props.authentication;
    await props.uploadImages(image, accessToken);
  };

  const handleNewProductSetup = async () => {
    const { accessToken } = props.authentication;
    const {
      productDetails,
      productSubCategory,
      productCareInstructions,
      densitySize,
    } = props.setupProduct;
    productDetails.images.forEach((image, index) => {
      let item = {};
      // console.log(image);
      handleUploadImages(image)
        .then(() => {
          item = {
            id: props.common.uploadImageId,
            picture_id: props.common.uploadImageId,
            position: index,
            src: props.common.uploadImageLink,
            attachment: "",
          };
          setImages((images) => [...images, item]);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    });
    // await props
    //   .setupNewProduct(
    //     productDetails.productName,
    //     productDetails.productDescription,
    //     0,
    //     images,
    //     productDetails?.tags,
    //     [productSubCategory?.subCategoryId],
    //     attributes,
    //     false, // Show on home page
    //     productCareInstructions?.instructions,
    //     productCareInstructions?.duration,
    //     productCareInstructions?.durationType,
    //     densitySize?.density?.name,
    //     densitySize?.capSize?.name,
    //     accessToken
    //   )
    //   .then(() => {
    //     console.log(props.setupProduct.setupProductStatus);

    //     if (props.setupProduct.setupProductStatus === true) {
    //       handleOpen();
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error.message);
    //   });
  };
  const {
    productDetails,
    productLengths,
    productPrices,
    productCategory,
    productSubCategory,
    productDimensions,
    setupProductLoading,
    productCareInstructions,
    densitySize,
    updateProductLoading,
  } = props.setupProduct;
  // console.log(productSubCategory);
  return (
    <RootStyle title="Product Confirmation | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15, mb: 5 }}>
        <Card>
          <CardHeader title="Review Product Details" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}></Stack>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
              overflow="auto"
            >
              {productDetails?.images?.map((item, index) => (
                <Stack
                  key={index}
                  style={{
                    marginRight: 10,
                    borderRadius: 0,
                  }}
                >
                  <img
                    alt="image"
                    src={item.uri}
                    style={{ width: 150, height: 150 }}
                  />
                  {/* <IconButton aria-label="delete">
                    <DeleteIcon />
                  </IconButton> */}
                </Stack>
              ))}
            </Stack>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={2}
              sx={{ justifyContent: "space-between" }}
            >
              <Typography sx={{ fontFamily: "PTSans-Bold", fontSize: 20 }}>
                {productDetails?.productName}
              </Typography>
              <Typography sx={{ color: COLORS.grey }}>
                Rs.{" "}
                <Typography
                  sx={{
                    fontFamily: "PTSans-Bold",
                    fontSize: 20,
                    color: COLORS.secondary,
                  }}
                >
                  $56.60
                </Typography>
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontFamily: "PTSans-Regular",
                fontSize: 16,
                mt: 1,
                color: COLORS.grey,
              }}
            >
              {productDetails?.productDescription}
            </Typography>
            {productLengths &&
              productLengths.map((item, index) => (
                <Stack key={index}>
                  <Typography sx={{ mt: 5 }}>Length {item} Inches</Typography>
                  {productPrices
                    .filter((product) => {
                      return product.length === item;
                    })
                    .map((prod, index) => (
                      <Stack
                        key={index}
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        sx={{ mt: 2, mb: 1 }}
                      >
                        <Stack
                          lg={4}
                          md={4}
                          direction={{ xs: "column", sm: "row" }}
                          sx={{
                            border: 1.5,
                            borderRadius: 1,
                            borderColor: COLORS.lightgrey,
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 1,
                            paddingRight: 1,
                            width: "33.33%",
                            height: 50,
                          }}
                        >
                          <Typography>Color</Typography>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              color: COLORS.secondary,
                            }}
                          >
                            {prod.color}
                          </Typography>
                        </Stack>
                        <Stack
                          lg={4}
                          md={4}
                          direction={{ xs: "column", sm: "row" }}
                          sx={{
                            border: 1.5,
                            borderRadius: 1,
                            borderColor: COLORS.lightgrey,
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 1,
                            paddingRight: 1,
                            width: "33.33%",
                            height: 50,
                          }}
                        >
                          <Typography>Price</Typography>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              color: COLORS.secondary,
                            }}
                          >
                            ${prod.price}
                          </Typography>
                        </Stack>

                        <Stack
                          lg={4}
                          md={4}
                          direction={{ xs: "column", sm: "row" }}
                          sx={{
                            border: 1.5,
                            borderRadius: 1,
                            borderColor: COLORS.lightgrey,
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 1,
                            paddingRight: 1,
                            width: "33.33%",
                            height: 50,
                          }}
                        >
                          <Typography>Stock</Typography>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              color: COLORS.secondary,
                            }}
                          >
                            {prod?.stock}
                          </Typography>
                        </Stack>
                        {/* <TextField type="text" fullWidth required={true} label="Color" /> */}
                      </Stack>
                    ))}
                </Stack>
              ))}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 5, mb: 1 }}
            >
              <Stack
                lg={4}
                md={4}
                direction={{ xs: "column", sm: "row" }}
                sx={{
                  border: 1.5,
                  borderRadius: 1,
                  borderColor: COLORS.lightgrey,
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: 1,
                  paddingRight: 1,
                  width: "50%",
                  height: 50,
                }}
              >
                <Typography>Hair Category</Typography>
                <Typography
                  sx={{
                    fontFamily: "PTSans-Bold",
                    color: COLORS.secondary,
                  }}
                >
                  {productCategory?.categoryName}
                </Typography>
              </Stack>

              <Stack
                lg={4}
                md={4}
                direction={{ xs: "column", sm: "row" }}
                sx={{
                  border: 1.5,
                  borderRadius: 1,
                  borderColor: COLORS.lightgrey,
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: 1,
                  paddingRight: 1,
                  width: "50%",
                  height: 50,
                }}
              >
                <Typography>Hair Type</Typography>
                <Typography
                  sx={{
                    fontFamily: "PTSans-Bold",
                    color: COLORS.secondary,
                  }}
                >
                  {productSubCategory?.subCategoryName}
                </Typography>
              </Stack>
              {/* <TextField type="text" fullWidth required={true} label="Color" /> */}
            </Stack>

            {/* <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ my: 3 }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="processed"
                    control={<Radio />}
                    label="Processed"
                  />
                  <FormControlLabel
                    value="unprocessed"
                    control={<Radio />}
                    label="Un Processed"
                  />
                </RadioGroup>
              </FormControl>
            </Stack> */}
            {productCategory?.categoryName === "Wigs" && (
              <Stack
                lg={4}
                md={4}
                direction={{ xs: "column", sm: "row" }}
                sx={{
                  borderRadius: 1,

                  alignItems: "center",
                  paddingLeft: 1,
                  paddingRight: 1,
                  width: "50%",
                  height: 40,
                }}
              >
                <Typography
                  sx={{ fontFamily: "PTSans-Bold", color: COLORS.secondary }}
                >
                  Hair care Instructions:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PTSans-Regular",
                    color: COLORS.black,
                    marginLeft: 2,
                  }}
                >
                  {productCareInstructions?.instructions}
                </Typography>
              </Stack>
            )}
            {productCategory?.categoryName === "Wigs" && (
              <Stack
              md={4}
              lg={4}
                direction={{ xs: "column", sm: "row" }}
                sx={{
                  borderRadius: 1,
                  alignItems: "center",
                  paddingLeft: 1,
                  paddingRight: 1,
                  width: "50%",
                  height: 40,
                }}
              >
                <Typography
                  sx={{ fontFamily: "PTSans-Bold", color: COLORS.secondary }}
                >
                  Duration:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PTSans-Regular",
                    color: COLORS.black,
                    marginLeft: 2,
                  }}
                >
                  {productCareInstructions?.duration +
                    " " +
                    productCareInstructions.durationType}
                </Typography>
              </Stack>
            )}
          </Box>
          <Box sx={{ mx: 3, my: 3 }}>
            <Button
              sx={{ width: 200, mb: 2, mt: 2 }}
              variant="contained"
              size="large"
              onClick={handleNewProductSetup}
            >
              Next
            </Button>
          </Box>
        </Card>
        <AddMoreProductModal open={open} handleClose={handleClose} />
      </Container>
      <LoadingModal open={setupProductLoading} />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  setupProduct: state.setupProduct,
  common: state.common,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  uploadImages: (image, token) => dispatch(uploadImages(image, token)),
  setupNewProduct: (
    productName,
    productDescription,
    price,
    images,
    tags,
    categoryIds,
    attributes,
    showOnHome,
    careInstruction,
    duration,
    durationType,
    density,
    capSize,
    token
  ) =>
    dispatch(
      setupNewProduct(
        productName,
        productDescription,
        price,
        images,
        tags,
        categoryIds,
        attributes,
        showOnHome,
        careInstruction,
        duration,
        durationType,
        density,
        capSize,
        token
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductConfirmation);
