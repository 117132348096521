import { BASE_URL, CUSTOMER_ORDERS } from "../../../apis";
import { handleGetApi } from "../../../services";
import { customerOrdersTypes } from "../../types";

export const getCustomerOrdersHistory = (pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: customerOrdersTypes.ORDER_HISTORY_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        CUSTOMER_ORDERS.orderHistory +
        "PageSize=" +
        pageSize +
        "&page=" +
        page,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerOrdersTypes.ORDER_HISTORY_SUCCESS,
        payload: response.data.orders,
      });
    } else {
      dispatch({
        type: customerOrdersTypes.ORDER_HISTORY_FAILURE,
      });
    }
  };
};
export const getOrderDetails = async (id, token) => {
    const response = await handleGetApi(
      BASE_URL.baseUrl + CUSTOMER_ORDERS.orderDetails + id,
      token
    );
    return response?.data?.orders[0];
};
