import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Attribute from "../../../components/Attribute";
import Page from "../../../components/Page";
import { COLORS, IMAGES } from "../../../constants";

function ToBeShipped(props) {
  const navigate = useNavigate();

  return (
    <Page title="Dashboard | Terms And Conditions">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.primary" }}
          >
            Product To Be Shipped
          </Typography>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ mt: 5, boxShadow: 2 }}
          >
            <Avatar
              variant="square"
              src={IMAGES.dummy}
              sx={{ height: 180, width: 250 }}
            />
            <Stack sx={{ justifyContent: "center", p: 3 }}>
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ alignItems: "center" }}
              >
                <Typography sx={{ fontFamily: "PTSans-Regular", fontSize: 14 }}>
                  Order #
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PTSans-Bold",
                    fontSize: 16,
                    color: COLORS.secondary,
                    ml: 3,
                  }}
                >
                  123456
                </Typography>
              </Stack>
              <Typography
                sx={{ fontFamily: "PTSans-Bold", fontSize: 24, my: 1 }}
              >
                Straight Hair
              </Typography>
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ alignItems: "center" }}
              >
                <Typography sx={{ fontFamily: "PTSans-Regular", fontSize: 14 }}>
                  Price:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PTSans-Bold",
                    fontSize: 16,
                    color: COLORS.secondary,
                    ml: 3,
                  }}
                >
                  $64.57
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ justifyContent: "center", p: 6 }}>
            <Attribute title="Color" value="Black" />
            <Attribute title="Length" value="10 Inches" />
            <Attribute title="Quantity" value="1" />
            <Attribute title="Hair Category" value="Bundles" />
            <Attribute title="Hair Type" value="Straight" />
            <Attribute title="Customer Address" value="Nob Hill Park" />
            <Attribute title="Customer Street" value="Prospect Avenue" />
            <Attribute title="Customer State" value="Montana" />
            <Attribute title="ZIP Code" value="59001" />
            <Attribute title="Total Payment Amount" value="$80.99" />
          </Stack>
          <Typography
            sx={{ fontFamily: "PTSans-Regular", color: COLORS.grey, my: 3 }}
          >
            Have you shipped this item?
          </Typography>
          <Button sx={{ backgroundColor: "#f5f5f5", width: "50%" }}>Yes</Button>
          <Button variant="contained" sx={{ width: "50%" }}>
            Yes
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default ToBeShipped;
