import { vendorSaleTypes } from "../../types";

const initialState = {
  addDiscountStatus: false,
  addDiscountLoading: false,

  discountsLoading: false,
  discounts: [],

  deleteDiscountStatus: false,
  deleteDiscountLoading: false,

  updateDiscountStatus: false,
  updateDiscountLoading: false,
};

const vendorSaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case vendorSaleTypes.ADD_DISCOUNT_LOADING:
      return {
        ...state,
        addDiscountLoading: true,
        addDiscountStatus: false,
      };
    case vendorSaleTypes.ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        addDiscountStatus: true,
        addDiscountLoading: false,
      };
    case vendorSaleTypes.ADD_DISCOUNT_FAILURE:
      return {
        ...state,
        addDiscountStatus: false,
        addDiscountLoading: false,
      };
    case vendorSaleTypes.GET_DISCOUNT_LOADING:
      return {
        ...state,
        discountsLoading: true,
        discounts: [],
      };
    case vendorSaleTypes.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        discounts: action.payload,
        discountsLoading: false,
      };
    case vendorSaleTypes.GET_DISCOUNT_FAILURE:
      return {
        ...state,
        discounts: [],
        discountsLoading: false,
      };
    case vendorSaleTypes.DELETE_DISCOUNT_LOADING:
      return {
        ...state,
        deleteDiscountLoading: true,
        deleteDiscountStatus: false,
      };
    case vendorSaleTypes.DELETE_DISCOUNT_SUCCESS:
      return {
        ...state,
        deleteDiscountStatus: true,
        deleteDiscountLoading: false,
      };
    case vendorSaleTypes.DELETE_DISCOUNT_FAILURE:
      return {
        ...state,
        deleteDiscountStatus: false,
        deleteDiscountLoading: false,
      };
    case vendorSaleTypes.UPDATE_DISCOUNT_LOADING:
      return {
        ...state,
        updateDiscountLoading: true,
        updateDiscountStatus: false,
      };
    case vendorSaleTypes.UPDATE_DISCOUNT_SUCCESS:
      return {
        ...state,
        updateDiscountStatus: true,
        updateDiscountLoading: false,
      };
    case vendorSaleTypes.UPDATE_DISCOUNT_FAILURE:
      return {
        ...state,
        updateDiscountStatus: false,
        updateDiscountLoading: false,
      };
    default:
      return state;
  }
};
export default vendorSaleReducer;
