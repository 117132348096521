import { Stack, Typography } from "@mui/material";
import { COLORS, ICONS } from "../constants";

export const NoProduct = ({}) => {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",

        width: "100%",
        height: 300,
      }}
    >
      <img src={ICONS.noProduct} style={{ height: 80, width: 80 }} />
      <Typography
        sx={{ textAlign: "center", fontFamily: "PTSans-Bold", marginTop: 3 }}
      >
        No Product Available
      </Typography>
    </Stack>
  );
};
