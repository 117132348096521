import { Box, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardCell from "../../../components/DashboardCell";
import Page from "../../../components/Page";
import { ICONS } from "../../../constants";

function CustomerDashboard(props) {
  const navigate = useNavigate();
  return (
    <Page title="Customer-Dashboard | Hair Scout">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.monthly}
                title="Cart"
                onPress={() => navigate("/dashboard/total-earning")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.monthly}
                title="Favorite Stores"
                onPress={() => navigate("/dashboard/monthly-earning")} 
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.active}
                title="Wishlist"
                onPress={() =>
                  navigate("/dashboard/active-products-categories")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.pending}
                title="Pending Orders"
                onPress={() => navigate("/dashboard/pending-orders")}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
export default CustomerDashboard;
