import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { COLORS, IMAGES } from "../../../constants";
import { connect } from "react-redux";
import { getTermsAndConditions } from "../../../redux/actions";
import { useEffect } from "react";
import { toast } from "react-toastify";

function TermsAndConditions(props) {
  const navigate = useNavigate();

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const getTermsAndConditions = async () => {
    const { accessToken } = props.authentication;
    props
      .getTermsAndConditions(accessToken)
      .then(() => {})
      .catch((error) => {
        toast.alert(error.message);
      });
  };
  const { terms, termsLoading } = props.common;
  console.log(terms);
  return (
    <Page title="Dashboard | Terms And Conditions">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          Policies, Terms & Conditons
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.primary" }}
          >
            Policies
          </Typography>

          <Typography
            sx={{
              fontFamily: "PTSans-Regular",
              color: "text.secondary",
              mt: 1,
              mb: 3,
            }}
          >
            {terms}
          </Typography>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  getTermsAndConditions: (token) => dispatch(getTermsAndConditions(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
