import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import { COLORS, FONTS, ICONS, IMAGES } from "../../../constants";
import StarIcon from "@mui/icons-material/Star";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import OrderCard from "../../../components/OrderCard";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getVendorOrders, getVendorOrdersById } from "../../../redux/actions";
import { NoProduct } from "../../../components";
import Order from "../../../components/Order";
import BreadCrumb from "../../../components/website/BreadCrumb";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment/moment";
const STATUSES = [
  { id: 30, name: "Delivered" },
  { id: 20, name: "Shipped" },
  { id: 10, name: "Not Yet Shipped" },
  { id: 40, name: "Cancelled" },
];
function ProcessOrder(props) {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(dayjs(moment().format("L")));
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [status, setStatus] = useState(0);
  const [cancellationDetails, setCancellationDetails] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [isSameTrackingNumber, setIsSameTrackingNumber] = useState(false);
  const [isRefundIssued, setIsRefundIssued] = useState(false);
  const { state } = useLocation();
  const { orderId } = state;

  const getOrderById = async () => {
    const { accessToken } = props.authentication;
    await props
      .getVendorOrdersById(orderId, accessToken)
      .then(() => {
        const { order, orderLoading } = props.vendorOrders;
        console.log(order);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  useEffect(() => {
    getOrderById();
  }, []);
  const handleChangeStatus = (e) => {
    console.log(e.target.value);
    setStatus(e.target.value);
  };

  const { order, orderLoading } = props.vendorOrders;
  console.log(isSameTrackingNumber);
  return (
    <Page title="Dashboard | Orders History">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          Process Order
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/order-detail"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Order Detail
            </Link>
            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Process Order
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          ></Typography>
          <FormControl fullWidth sx={{ width: 250, ml: 2 }}>
            <InputLabel id="demo-simple-select-label">
              Update Order Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Update Order Status"
              onChange={handleChangeStatus}
            >
              {STATUSES.map((item, index) => (
                <MenuItem key={index} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {status === 40 && (
            <Box sx={{ mt: 3, ml: 2 }}>
              <Typography>Have you issued the customer a refund?</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setIsRefundIssued(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <Stack spacing={3} sx={{ my: 3 }}>
                <TextField
                  multiline
                  //   numberOfLines={4}
                  //   sx={{ width: 250 }}
                  autoComplete="text"
                  type="text"
                  label="Please provide details"
                  onChange={(e) => {
                    setCancellationDetails(e.target.value);
                  }}
                  value={cancellationDetails}
                />
              </Stack>
            </Box>
          )}
          {status === 30 && (
            <Box sx={{ mt: 3, ml: 2 }}>
              <Typography>
                Do all products included in this order have the same tracking
                number?
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setIsSameTrackingNumber(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              {isSameTrackingNumber === "true" ? (
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid item>
                    <TextField
                      sx={{ width: 250 }}
                      autoComplete="text"
                      type="text"
                      label="Tracking Number"
                      onChange={(e) => {
                        setTrackingNumber(e.target.value);
                      }}
                      value={trackingNumber}
                    />
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Shipped Date"
                        inputFormat="YYYY-MM-DD"
                        value={startDate}
                        //   onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <TextField
                      sx={{ width: 250 }}
                      autoComplete="text"
                      type="text"
                      label="Postal Carrier"
                      onChange={(e) => {
                        setTrackingNumber(e.target.value);
                      }}
                      value={trackingNumber}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Stack>
                  {order?.order_items?.map(
                    (item, index) => (
                      console.log("Item", item),
                      (
                        <Stack
                          sx={{
                            background: COLORS.white,
                            my: 1,
                            padding: 2,
                            border: 1,
                            borderColor: COLORS.lightgrey,
                            borderRadius: 1,
                          }}
                        >
                          <Stack
                            key={index}
                            direction="row"
                            sx={{
                              width: "100%",
                              justifyContent: "space-between",
                              my: 2,
                              //   backgroundColor: COLORS.secondary,
                            }}
                          >
                            <img
                              src={item?.product?.images[0]?.src}
                              style={{
                                height: 100,
                                width: 100,
                                borderRadius: 10,
                              }}
                            />
                            <Box
                              sx={{
                                mx: 3,
                                justifyContent: "center",
                                alignItems: "center",
                                width: "60%",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "PTSans-Bold",
                                  fontSize: 18,
                                  color: COLORS.secondary,
                                  mb: 1,
                                }}
                              >
                                {item?.product?.name}
                              </Typography>
                              <Stack direction={{ xs: "row", sm: "row" }}>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Bold",
                                    fontSize: 13,
                                    width: 100,
                                  }}
                                >
                                  Color
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Regular",
                                    fontSize: 13,
                                  }}
                                >
                                  1B
                                </Typography>
                              </Stack>
                              <Stack direction={{ xs: "row", sm: "row" }}>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Bold",
                                    fontSize: 13,
                                    width: 100,
                                  }}
                                >
                                  Length
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Regular",
                                    fontSize: 13,
                                  }}
                                >
                                  8 Inches
                                </Typography>
                              </Stack>
                              <Stack direction={{ xs: "row", sm: "row" }}>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Bold",
                                    fontSize: 13,
                                    width: 100,
                                  }}
                                >
                                  Quantity
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Regular",
                                    fontSize: 13,
                                  }}
                                >
                                  {item?.quantity}
                                </Typography>
                              </Stack>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: COLORS.backgroundDark,
                                height: 100,
                                width: 100,
                                borderRadius: 1,
                                mr: 3,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Bold",
                                    fontSize: 13,
                                    width: 100,
                                    color: COLORS.grey,
                                    textAlign: "center",
                                  }}
                                >
                                  Sub Total
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "PTSans-Bold",
                                    fontSize: 18,
                                    width: 100,
                                    color: COLORS.secondary,
                                    textAlign: "center",
                                  }}
                                >
                                  ${item.price_incl_tax?.toFixed(2)}
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>
                          <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item>
                              <TextField
                                sx={{ width: 250 }}
                                autoComplete="text"
                                type="text"
                                label="Tracking Number"
                                onChange={(e) => {
                                  setTrackingNumber(e.target.value);
                                }}
                                value={trackingNumber}
                              />
                            </Grid>
                            <Grid item>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  label="Shipped Date"
                                  inputFormat="YYYY-MM-DD"
                                  value={startDate}
                                  //   onChange={handleChangeStartDate}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item>
                              <TextField
                                sx={{ width: 250 }}
                                autoComplete="text"
                                type="text"
                                label="Postal Carrier"
                                onChange={(e) => {
                                  setTrackingNumber(e.target.value);
                                }}
                                value={trackingNumber}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      )
                    )
                  )}
                </Stack>
              )}
            </Box>
          )}
          <Button
            variant="contained"
            sx={{ height: 50, width: 200, ml: 2, mt: 3 }}
          >
            Save
          </Button>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorOrders: state.vendorOrders,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  getVendorOrdersById: (orderId, token) =>
    dispatch(getVendorOrdersById(orderId, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrder);
