export const COLORS = {
  background: "#FFFFFF",
  backgroundDark: "#f5f5f5",
  primary: "#4C2D1F",
  secondary: "#CE8E99",
  other: "#F1DEE0",
  white: "#FFFFFF",
  black: "#000000",
  grey: "#707070",
  lightgrey: "#A3A3A3",
  darkgrey: "#454647",
  transparent: "transparent",
  placeholder: "#9c9c9c",
  orange: "#FF9529",
  border: "#e0e0e0",
};

export const SIZES = {
  // font sizes
  title: 24,
  subtitle: 16,
  header: 18,
  subtitle2: 15,
  text: 14,
  label: 15,
  button: 16,
  input: 15,
  note: 12,
  tag: 12,
};

export const FONTS = {
  title: {
    fontFamily: "PlayfairDisplay-Bold",
    fontSize: SIZES.title,
    // color: COLORS.primary,
  },
  subtitle: {
    fontFamily: "PTSans-Regular",
    fontSize: SIZES.subtitle,
  },
  subtitle2: {
    fontFamily: "PTSans-Bold",
    fontSize: SIZES.subtitle2,

    marginVertical: 5,
  },
  header: {
    fontFamily: "PlayfairDisplay-Bold",
    fontSize: SIZES.header,
    lineHeight: 22,
    color: COLORS.black,
  },
  text: { fontFamily: "PTSans-Regular", fontSize: SIZES.text },
  button: {
    fontFamily: "PTSans-Bold",
    fontSize: SIZES.button,
  },
  input: {
    fontFamily: "PTSans-Regular",
    fontSize: SIZES.input,
  },
  special: {
    fontFamily: "PTSans-Bold",
    fontSize: SIZES.text,
  },
  note: { fontFamily: "PTSans-Regular", fontSize: SIZES.note },
  label: { fontFamily: "PTSans-Bold", fontSize: SIZES.label },
};

const appTheme = { COLORS, SIZES, FONTS };

export default appTheme;
