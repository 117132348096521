// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Card,
  CardHeader,
  Box,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
// ----------------------------------------------------------------------
// Imports
import { ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProductTag from "../../../components/ProductTag";
import { connect } from "react-redux";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function ProductDimensions() {
  const navigate = useNavigate();
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);

  const handleIncreaseLength = () => {
    let newLength = length + 1;
    setLength(newLength);
  };
  const handleDecreaseLength = () => {
    if (length > 0) {
      let newLength = length - 1;
      setLength(newLength);
    }
  };
  const handleIncreaseWidth = () => {
    let newWidth = width + 1;
    setWidth(newWidth);
  };
  const handleDecreaseWidth = () => {
    if (width > 0) {
      let newWidth = width - 1;
      setWidth(newWidth);
    }
  };
  const handleIncreaseHeight = () => {
    let newHeight = height + 1;
    setHeight(newHeight);
  };
  const handleDecreaseHeight = () => {
    if (height > 0) {
      let newHeight = height - 1;
      setHeight(newHeight);
    }
  };
  const handleIncreaseWeight = () => {
    let newWeight = weight + 1;
    setWeight(newWeight);
  };
  const handleDecreaseWeight = () => {
    if (weight > 0) {
      let newWeight = weight - 1;
      setWeight(newWeight);
    }
  };
  return (
    <RootStyle title="Product Dimensions | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card>
          <CardHeader title="Setup Product Dimensions" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Box lg={12} md={6} sm={12} xs={12}>
                <Typography>Length</Typography>
                <Stack
                  sx={{
                    justifyContent: "space-between",
                    borderBottom: "2px solid",
                  }}
                  direction={{ xs: "row", sm: "row" }}
                >
                  <Button onClick={handleDecreaseLength}>
                    <RemoveSharpIcon />
                  </Button>
                  <Box
                    sx={{
                      width: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {length}
                    </Typography>
                  </Box>
                  <Button onClick={handleIncreaseLength}>
                    <AddSharpIcon />
                  </Button>
                </Stack>
              </Box>
              <Box lg={4} md={6} sm={12}>
                <Typography>Width</Typography>
                <Stack
                  sx={{
                    justifyContent: "space-between",
                    borderBottom: "2px solid",
                  }}
                  direction={{ xs: "row", sm: "row" }}
                >
                  <Button onClick={handleDecreaseWidth}>
                    <RemoveSharpIcon />
                  </Button>
                  <Box
                    sx={{
                      width: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {width}
                    </Typography>
                  </Box>
                  <Button onClick={handleIncreaseWidth}>
                    <AddSharpIcon />
                  </Button>
                </Stack>
              </Box>
              <Box lg={4} md={6} sm={12}>
                <Typography>Height</Typography>
                <Stack
                  sx={{
                    justifyContent: "space-between",
                    borderBottom: "2px solid",
                  }}
                  direction={{ xs: "row", sm: "row" }}
                >
                  <Button onClick={handleDecreaseHeight}>
                    <RemoveSharpIcon />
                  </Button>
                  <Box
                    sx={{
                      width: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {height}
                    </Typography>
                  </Box>
                  <Button onClick={handleIncreaseHeight}>
                    <AddSharpIcon />
                  </Button>
                </Stack>
              </Box>
              <Box lg={4} md={6} sm={12}>
                <Typography>Weight</Typography>
                <Stack
                  sx={{
                    justifyContent: "space-between",
                    borderBottom: "2px solid",
                  }}
                  direction={{ xs: "row", sm: "row" }}
                >
                  <Button onClick={handleDecreaseWeight}>
                    <RemoveSharpIcon />
                  </Button>
                  <Box
                    sx={{
                      width: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {weight}
                    </Typography>
                  </Box>
                  <Button onClick={handleIncreaseWeight}>
                    <AddSharpIcon />
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ mx: 3, my: 3 }}>
            <Button
              variant="contained"
              sx={{ width: 200, mb: 2, mt: 3, height: 50 }}
              size="large"
              onClick={() => navigate("/product-confirmation")}
            >
              Next
            </Button>
          </Box>
        </Card>
      </Container>
      <ToastContainer />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDimensions);
