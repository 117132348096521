import { Icon } from "@iconify/react";
import { IconButton, Stack, Typography } from "@mui/material";
import cross from "@iconify/icons-eva/close-fill";
function ProductTag() {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      sx={{
        justifyContent: "space-around",
        alignItems: "center",
        p: 1,
        borderRadius: 1,
        boxShadow: 4,
      }}
    >
      <Typography>Curly Hair</Typography>
      <IconButton sx={{ ml: 5, color: "text.primary", alignSelf: "end" }}>
        <Icon icon={cross} />
      </IconButton>
    </Stack>
  );
}
export default ProductTag;
