import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import Page from "../../../components/Page";
import StaffCard from "../../../components/StaffCard";
import { COLORS, FONTS } from "../../../constants";
import {
  deleteStaffMember,
  getInvitedStaffById,
  inviteStaff,
} from "../../../redux/actions/vendor/staffActions";
import { store } from "../../../redux/store/store";

function DeleteStaffMember(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state;
  const [emailAddress, setEmailAddress] = useState("");
  const [name, setName] = useState("");
  const [invitedOn, setInvitedOn] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleGetMemberById = async () => {
    const { accessToken } = props.authentication;
    props
      .getInvitedStaffById(id, accessToken)
      .then(() => {
        const { staffMember } = store.getState().staff;

        setEmailAddress(staffMember?.email);
        setName(staffMember?.name);
        setInvitedOn(staffMember?.invited_on);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleDeleteMember = async () => {
    const { accessToken } = props.authentication;
    props
      .deleteStaffMember(id, accessToken)
      .then(() => {
        const { deleteMemberStatus } = store.getState().staff;
        if (deleteMemberStatus) {
          toast.success("staff member deleted successfully");
          setShowModal(false);
          navigate("/dashboard/invited-staff");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  useEffect(() => {
    handleGetMemberById();
  }, []);
  const { staffMemberLoading, deleteMemberLoading } = props.staff;
  return (
    <Page title="Dashboard | Invite Staff">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: FONTS.title }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Delete Staff Member
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Stack sx={{ mt: 1 }}>
              <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
                Email Address:
              </Typography>
              <Typography
                sx={{ fontFamily: FONTS.subtitle, color: COLORS.grey }}
              >
                {emailAddress}
              </Typography>
            </Stack>
            <Stack sx={{ mt: 1 }}>
              <Typography sx={{ fontFamily: FONTS.subtitle2 }}>Name</Typography>
              <Typography
                sx={{ fontFamily: FONTS.subtitle, color: COLORS.grey }}
              >
                {name}
              </Typography>
            </Stack>
            <Stack sx={{ mt: 1 }}>
              <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
                Invited On
              </Typography>
              <Typography
                sx={{ fontFamily: FONTS.subtitle, color: COLORS.grey }}
              >
                {invitedOn}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 5, background: COLORS.primary, width: 250 }}
            onClick={() => setShowModal(true)}
          >
            Delete Staff Member
          </Button>
        </Box>
        <LoadingModal open={staffMemberLoading || deleteMemberLoading} />
        <ConfirmationModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          onPress={() => handleDeleteMember()}
          title={"Delete Staff Member"}
          text={"Do you really want to delete this staff member?"}
        />
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  staff: state.staff,
});
const mapDispatchToProps = (dispatch) => ({
  getInvitedStaffById: (staffId, token) =>
    dispatch(getInvitedStaffById(staffId, token)),
  deleteStaffMember: (memberId, token) =>
    dispatch(deleteStaffMember(memberId, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteStaffMember);
