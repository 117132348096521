// ----------------------------------------------------------------------
// Material
import { Container, Stack, Typography, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
// ----------------------------------------------------------------------
// Imports
import { ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS, IMAGES } from "../../../constants";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 700,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  //margin: theme.spacing(2, 0, 2, 2),
  borderRadius: 0,
  height: "100vh",
  backgroundImage: `url(${IMAGES.welcome})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(12, 0),
}));
function PrivacyPolicies() {
  return (
    <RootStyle title="Register Customer | Hair Scout">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "center",
                color: COLORS.secondary,
                fontFamily: "PlayfairDisplay-Bold",
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "left",
                fontFamily: "PTSans-Regular",
              }}
            >
              Hair Scout, LLC ("Company" or "We") respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes:
              {"\n"}The types of information we may collect or that you may provide when you purchase, download, install, register with, access, or use Hair Scout (the "App").
              {"\n"}Our practices for collecting, using, maintaining, protecting, and disclosing that information.
              {"\n"}This policy applies only to information we collect in this App, through email, text, and other electronic communications sent through or in connection with this App.
              {"\n"}This policy DOES NOT apply to information that:
              {"\n"}We collect offline or on any other Company apps or websites, including websites you may access through this App.
              {"\n"}You provide to or is collected by any third party.
              {"\n"}Our websites and apps, and these other third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.
              {"\n"}Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time. Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.
              {"\n"}
              {"\n"}
              <b>Children Under the Age of 16</b>
              {"\n"}The App is intended solely for use of adults 18 years of age and older. It is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at contact@hairscoutapp.com.
              {"\n"}
              <b>Information We Collect and How We Collect It</b>
              {"\n"}We collect information from and about users of our App:
              {"\n"}Directly from you when you provide it to us.
              {"\n"}Automatically when you use the App.
              {"\n"}
              <b>Information You Provide to Us</b>
              {"\n"}When you download, register with, or use this App, we may ask you provide information:
              {"\n"}By which you may be personally identified, such as name, postal address, email address, telephone number, or any other identifier by which you may be contacted online or offline ("personal information").
              {"\n"}That is about you but individually does not identify you, such as preferences.
              {"\n"}This information includes:
              {"\n"}Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App, posting material, and requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with the App.
              {"\n"}Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.
              {"\n"}Your responses to surveys that we might ask you to complete for research purposes.
              {"\n"}Details of transactions you carry out through the App and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the App.
              {"\n"}Your search queries on the App.
              {"\n"}You may also provide information for publication or display ("Posted") on public areas of the app (collectively, "User Contributions"). Your User Contributions are Posted and transmitted to others at your own risk. Additionally, we cannot control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
              {"\n"}
              <b>Automatic Information Collection</b>
              {"\n"}When you download, access, and use the App, it may use technology to automatically collect:
              {"\n"}Usage Details. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, logs, and other communication data and the resources that you access and use on or through the App.
              {"\n"}Device Information. We may collect information about your mobile device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, mobile network information, and the device's telephone number.
              {"\n"}Stored Information and Files. The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.
              {"\n"}Location Information. This App does not collect real-time information about the location of your device.
              {"\n"}If you do not want us to collect this information do not download the App or delete it from your device.
              {"\n"}
              <b>Information Collection Technologies</b>
              {"\n"}The technologies we use for automatic information collection may include:
              {"\n"}Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.
              {"\n"}Web Beacons. Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).
              {"\n"}
              <b>Third-Party Information Collection</b>
              {"\n"}When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:
              {"\n"}Advertisers, ad networks, and ad servers.
              {"\n"}Analytics companies.
              {"\n"}Your mobile device manufacturer.
              {"\n"}Your mobile service provider.
              {"\n"}These third parties may use tracking technologies to collect information about you when you use this app. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
              {"\n"}We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
              {"\n"}
              <b>How We Use Your Information</b>
              {"\n"}We use information that we collect about you or that you provide to us, including any personal information, to:
              {"\n"}Provide you with the App and its contents, and any other information, products, or services that you request from us.
              {"\n"}Fulfill any other purpose for which you provide it.
              {"\n"}Give you notices about your account/subscription, including expiration and renewal notices.
              {"\n"}Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
              {"\n"}Notify you when App updates are available, and of changes to any products or services we offer or provide though it.
              {"\n"}The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:
              {"\n"}Estimate our audience size and usage patterns.
              {"\n"}Store information about your preferences, allowing us to customize our App according to your individual interests.
              {"\n"}Speed up your searches.
              {"\n"}Recognize you when you use the App.
              {"\n"}We may also use your information to contact you about our own and third parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account.
              {"\n"}We may use the information we collect to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.
              {"\n"}
              <b>Disclosure of Your Information</b>
              {"\n"}We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
              {"\n"}In addition, we may disclose personal information that we collect or you provide:
              {"\n"}To our subsidiaries and affiliates.
              {"\n"}To contractors, service providers, and other third parties we use to support our business.
              {"\n"}To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Hair Scout's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Hair Scout about our App users is among the assets transferred.
              {"\n"}To fulfill the purpose for which you provide it.
              {"\n"}For any other purpose disclosed by us when you provide the information.
              {"\n"}With your consent.
              {"\n"}To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
              {"\n"}To enforce our rights arising from any contracts entered into between you and us, including the App EULA, and for billing and collection.
              {"\n"}If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Hair Scout, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
              {"\n"}
              <b>Your Choices About Our Collection, Use, and Disclosure of Your Information</b>
              {"\n"}We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.
              {"\n"}Tracking Technologies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.
              {"\n"}We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the NAI's website.
              {"\n"}
              <b>Accessing and Correcting Your Personal Information</b>
              {"\n"}You can review and change your personal information by logging into the App and visiting your account profile page.
              {"\n"}You may also send us an email at contact@hairscoutapp.com to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
              {"\n"}If you delete your User Contributions from the App, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other App users. Proper access and use of information provided on the App, including User Contributions, is governed by our terms of use.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                mt: 1,
                fontFamily: "PTSans-Regular",
              }}
            >
              Your State Privacy Rights
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "left",
                fontFamily: "PTSans-Regular",
              }}
            >
              State consumer privacy laws may provide their residents
              with additional rights regarding our use of their personal
              information. To learn more about California residents'
              privacy rights, visit [LINK TO HAIR SCOUT PRIVACY
              NOTICE FOR CALIFORNIA RESIDENTS].
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                mt: 1,
                fontFamily: "PTSans-Regular",
              }}
            >
              Data Security
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "left",
                fontFamily: "PTSans-Regular",
              }}
            >
              The safety and security of your information also depends
              on you. Where we have given you (or where you have
              chosen) a password for access to certain parts of our App,
              you are responsible for keeping this password
              confidential. We ask you not to share your password with
              anyone. We urge you to be careful about giving out
              information in public areas of the App. The information you
              share in public areas may be viewed by any user of the
              App. Unfortunately, the transmission of information via the
              internet and mobile platforms is not completely secure.
              Although we do our best to protect your personal
              information, we cannot guarantee the security of your
              personal information transmitted through our App. Any
              transmission of personal information is at your own risk.
              We are not responsible for circumvention of any privacy
              settings or security measures we provide.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

        </ContentStyle>
        <ToastContainer />
      </Container>
    </RootStyle>
  );
}

export default PrivacyPolicies;
