// ----------------------------------------------------------------------
// Material
import { Container, Stack, Typography, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
// ----------------------------------------------------------------------
// Imports
import { ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS, IMAGES } from "../../../constants";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 700,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  //margin: theme.spacing(2, 0, 2, 2),
  borderRadius: 0,
  height: "100vh",
  backgroundImage: `url(${IMAGES.welcome})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(12, 0),
}));
function TermsConditions() {
  return (
    <RootStyle title="Register Customer | Hair Scout">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "center",
                color: COLORS.secondary,
                fontFamily: "PlayfairDisplay-Bold",
              }}
            >
              Terms & Conditions
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                textAlign: "left",
                fontFamily: "PTSans-Regular",
              }}
            >
              You can review and change your personal information by
              logging into the App and visiting your account profile
              page. You may also send us an email at
              contact@hairscoutapp.com to request access to, correct,
              or delete any personal information that you have provided
              to us. We cannot delete your personal information except
              by also deleting your user account. We may not
              accommodate a request to change information if we
              believe the change would violate any law or legal
              requirement or cause the information to be incorrect. If
              you delete your User Contributions from the App, copies
              of your User Contributions may remain viewable in cached
              and archived pages, or might have been copied or stored
              by other App users. Proper access and use of information
              Provided on the App, including User Contributions, is
              governed by our terms of use.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                mt:1,
                fontFamily: "PTSans-Regular",
              }}
            >
              Your State Privacy Rights
              </Typography>
              <Typography
              sx={{
                color: "text.secondary",
                textAlign: "left",
                fontFamily: "PTSans-Regular",
              }}
            >
              State consumer privacy laws may provide their residents
              with additional rights regarding our use of their personal
              information. To learn more about California residents'
              privacy rights, visit [LINK TO HAIR SCOUT PRIVACY
              NOTICE FOR CALIFORNIA RESIDENTS].
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                mt:1,
                fontFamily: "PTSans-Regular",
              }}
            >
              Data Security
              </Typography>
              <Typography
              sx={{
                color: "text.secondary",
                textAlign: "left",
                fontFamily: "PTSans-Regular",
              }}
            >
              The safety and security of your information also depends
              on you. Where we have given you (or where you have
              chosen) a password for access to certain parts of our App,
              you are responsible for keeping this password
              confidential. We ask you not to share your password with
              anyone. We urge you to be careful about giving out
              information in public areas of the App. The information you
              share in public areas may be viewed by any user of the
              App. Unfortunately, the transmission of information via the
              internet and mobile platforms is not completely secure.
              Although we do our best to protect your personal
              information, we cannot guarantee the security of your
              personal information transmitted through our App. Any
              transmission of personal information is at your own risk.
              We are not responsible for circumvention of any privacy
              settings or security measures we provide.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

        </ContentStyle>
        <ToastContainer />
      </Container>
    </RootStyle>
  );
}

export default TermsConditions;
