import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardCell from "../../../components/DashboardCell";
import LoadingModal from "../../../components/modals/LoadingModal";
import Page from "../../../components/Page";
import { ICONS } from "../../../constants";
import { getDashboardDetails } from "../../../redux/actions";

const VendorDashboard = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    handleGetDashboardDetails();
  }, []);

  const handleGetDashboardDetails = async () => {
    const { accessToken } = props.authentication;
    await props
      .getDashboardDetails(accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const { vendorDashboardDetails, vendorDashboardDetailsLoading } =
    props.vendorStore;
  return (
    <Page title="Dashboard | Hair Scout">
      <Container maxWidth="xl">
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Stack
            direction={{ sx: "column", md: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
            >
              Store Analytics
            </Typography>

            <Button
              variant="contained"
              onClick={() => navigate("/product-categories")}
            >
              + Add New Product
            </Button>
          </Stack>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.monthly}
                title="Total Earning"
                value={
                  "$" +
                  vendorDashboardDetails?.orders_summary[0]?.all_time
                    ?.sum_amount
                }
                onPress={() => navigate("/dashboard/total-earning")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.monthly}
                title="Monthly Earning"
                value={
                  "$" +
                  vendorDashboardDetails?.orders_summary[0]?.this_month
                    ?.sum_amount
                }
                onPress={() => navigate("/dashboard/monthly-earning")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.active}
                title="Active Products"
                value={vendorDashboardDetails?.active_products}
                onPress={() => navigate("/dashboard/active-products")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.pending}
                title="Pending Orders"
                value={
                  vendorDashboardDetails?.orders_summary[0]?.all_time
                    ?.total_count
                }
                onPress={() => navigate("/dashboard/pending-orders")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.best}
                title="Best Selling"
                value={vendorDashboardDetails?.best_selling}
                onPress={() => navigate("/dashboard/best-selling")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.worst}
                title="Worst Selling"
                value={vendorDashboardDetails?.worst_selling}
                onPress={() => navigate("/dashboard/worst-selling")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.sale}
                title="Products On Sale"
                value={vendorDashboardDetails?.product_on_sale}
                onPress={() => navigate("/dashboard/sale-products")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCell
                image={ICONS.sold}
                title="Out Of Stock"
                value={vendorDashboardDetails?.out_of_stock}
                onPress={() => navigate("/dashboard/out-of-stock")}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* <LoadingModal open={vendorDashboardDetailsLoading} /> */}
    </Page>
  );
};
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  getDashboardDetails: (token) => dispatch(getDashboardDetails(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(VendorDashboard);
