import { Stack } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { COLORS } from "../constants";
export const RatingStars = ({ ratingValue, size, my }) => {
  const star = {
    color: COLORS.orange,
    height: size ? size : 16,
    width: size ? size : 16,
  };
  return (
    <Stack direction={{ xs: "row", sm: "row", md: "row" }} sx={{ my: my }}>
      {ratingValue >= 1 ? <StarIcon sx={star} /> : <StarBorderIcon sx={star} />}
      {ratingValue >= 2 ? <StarIcon sx={star} /> : <StarBorderIcon sx={star} />}
      {ratingValue >= 3 ? <StarIcon sx={star} /> : <StarBorderIcon sx={star} />}
      {ratingValue >= 4 ? <StarIcon sx={star} /> : <StarBorderIcon sx={star} />}
      {ratingValue >= 5 ? <StarIcon sx={star} /> : <StarBorderIcon sx={star} />}
    </Stack>
  );
};
