import { ICONS } from "../../constants";
// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const getImage = (name) => (
  <img src={name} alt="img" style={{ height: 17, width: 17 }} />
);

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/vendor-dashboard",
    icon: getImage(ICONS.dashboard),
  },
  {
    title: "My Profile",
    path: "/dashboard/vendor-profile",
    icon: getImage(ICONS.user),
  },
  {
    title: "Invite Staff",
    path: "/dashboard/invited-staff",
    icon: getImage(ICONS.email),
  },
  // {
  //   title: "Payment Method",
  //   path: "/dashboard/payment-method",
  //   icon: getImage(ICONS.payment),
  // },
  {
    title: "Order History",
    path: "/dashboard/orders-history",
    icon: getImage(ICONS.history),
  },
  // {
  //   title: "Notification Settings",
  //   path: "/dashboard/notification-setting",
  //   icon: getImage(ICONS.notifications),
  // },
  // {
  //   title: "Security Settings",
  //   path: "/dashboard/security-settings",
  //   icon: getImage(ICONS.shield),
  // },
  {
    title: "Change Password",
    path: "/dashboard/change-password",
    icon: getImage(ICONS.lock),
  },
  {
    title: "FAQs",
    path: "/dashboard/faqs",
    icon: getImage(ICONS.faq),
  },
  {
    title: "Policies, Terms & Conditions",
    path: "/dashboard/terms",
    icon: getImage(ICONS.terms),
  },
  {
    title: "Hair Scout Contact Info",
    path: "/dashboard/contact-us",
    icon: getImage(ICONS.notebook),
  },
  {
    title: "Chat With Customer Service",
    path: "/dashboard/review-ads",
    icon: getImage(ICONS.chat),
  },
];

export default sidebarConfig;
