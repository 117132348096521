export const commonTypes = {
  STATES_LOADING: "STATES_LOADING",
  STATES_SUCCESS: "STATES_SUCCESS",
  STATES_FAILURE: "STATES_FAILURE",

  UPLOAD_IMAGE_LOADING: "UPLOAD_IMAGE_LOADING",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

  CATEGORIES_LOADING: "CATEGORIES_LOADING",
  CATEGORIES_SUCCESS: "CATEGORIES_SUCCESS",
  CATEGORIES_FAILURE: "CATEGORIES_FAILURE",

  SUB_CATEGORIES_LOADING: "SUB_CATEGORIES_LOADING",
  SUB_CATEGORIES_SUCCESS: "SUB_CATEGORIES_SUCCESS",
  SUB_CATEGORIES_FAILURE: "SUB_CATEGORIES_FAILURE",

  SET_FCM_LOADING: "SET_FCM_LOADING",
  SET_FCM_SUCCESS: "SET_FCM_SUCCESS",
  SET_FCM_FAILURE: "SET_FCM_FAILURE",

  CHAT_HISTORY_LOADING: "GET_CHAT_HISTORY_LOADING",
  CHAT_HISTORY_SUCCESS: "GET_CHAT_HISTORY_SUCCESS",
  CHAT_HISTORY_FAILURE: "CET_CHAT_HISTORY_FAILURE",

  SEND_MESSAGE_LOADING: "SET_MESSAGE_LOADING",
  SEND_MESSAGE_SUCCESS: "SET_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SET_MESSAGE_FAILURE",

  FAQS_LOADING: "FAQS_LOADING",
  FAQS_SUCCESS: "FAQS_SUCCESS",
  FAQS_FAILURE: "FAQS_FAILURE",

  TERMS_LOADING: "TERMS_LOADING",
  TERMS_SUCCESS: "TERMS_SUCCESS",
  TERMS_FAILURE: "TERMS_FAILURE",

  CONTACT_INFO_LOADING: "CONTACT_INFO_LOADING",
  CONTACT_INFO_SUCCESS: "CONTACT_INFO_SUCCESS",
  CONTACT_INFO_FAILURE: "CONTACT_INFO_FAILURE",

  SET_NETWORK_STATUS: "SET_NETWORK_STATUS",

  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
};
