import { authTypes } from "../../types";

const initialState = {
  registerUserLoading: false,
  registerUserStatus: false,
  loginUserLoading: false,
  loginUserStatus: false,
  accessToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOiIxNjc3NzgyMDgyIiwiZXhwIjoiMTcwOTMxODA4MiIsIkN1c3RvbWVySWQiOiIzMjA1NiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiOGU3YTAwM2UtYzk0Mi00ZTg5LTllNTMtMDBlMTE2OTVmYTA3IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvZW1haWxhZGRyZXNzIjoiQ3VzdEBoYWlyc2NvdXQuY29tIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6IkN1c3RAaGFpcnNjb3V0LmNvbSJ9.9avpB0ia2-DtH9Yssg7hf_q33uHnaLK5iup7eFCFBPw",
  userId: 11912,
  userRoles: [],

  forgotPasswordLoading: false,
  forgotPasswordStatus: false,

  changePasswordLoading: false,
  changePasswordStatus: false,

  logoutLoading: false,
  logoutStatus: false,

  updateProfileStatus: false,
  updateProfileLoading: false,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.REGISTER_USER_LOADING:
      return {
        ...state,
        registerUserLoading: true,
        registerUserStatus: false,
      };
    case authTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        userId: action.payload.userId,
        userRoles: action.payload.userRoles,
        registerUserStatus: true,
        registerUserLoading: false,
      };
    case authTypes.REGISTER_USER_FAILURE:
      return {
        ...state,
        registerUserLoading: false,
        registerUserStatus: false,
      };
    case authTypes.LOGIN_USER_LOADING:
      return {
        ...state,
        loginUserLoading: true,
        loginUserStatus: false,
        userRoles: [],
        userId: 0,
        accessToken: null,
      };
    case authTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        userId: action.payload.userId,
        userRoles: action.payload.userRoles,
        loginUserStatus: true,
        loginUserLoading: false,
      };
    case authTypes.LOGIN_USER_FAILURE:
      return {
        ...state,
        loginUserLoading: false,
        loginUserStatus: false,
        userRoles: [],
        userId: 0,
        accessToken: null,
      };
    case authTypes.FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPasswordStatus: false,
      };
    case authTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordStatus: true,
        forgotPasswordLoading: false,
      };
    case authTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordStatus: false,
        forgotPasswordLoading: false,
      };

    case authTypes.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        changePasswordLoading: true,
        changePasswordStatus: false,
      };
    case authTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordStatus: true,
        changePasswordLoading: false,
      };
    case authTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordStatus: false,
        changePasswordLoading: false,
      };
    case authTypes.LOGOUT_USER_LOADING:
      return {
        ...state,
        logoutLoading: true,
        logoutStatus: false,
      };
    case authTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        logoutStatus: true,
        logoutLoading: false,
      };
    case authTypes.LOGOUT_USER_FAILURE:
      return {
        ...state,
        logoutStatus: false,
        logoutLoading: false,
      };
    case authTypes.UPDATE_PROFILE_LOADING:
      return {
        ...state,
        updateProfileLoading: true,
        updateProfileStatus: false,
      };
    case authTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileStatus: true,
        updateProfileLoading: false,
      };
    case authTypes.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileStatus: false,
        updateProfileLoading: false,
      };
    case authTypes.SET_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload,
      };
    default:
      return state;
  }
};
export default authReducer;
