import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Banner from "../../../components/Banner";
import Page from "../../../components/Page";
import { COLORS, FONTS, ICONS, IMAGES } from "../../../constants";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getBestSellingProducts, getCategories } from "../../../redux/actions";
import { useState } from "react";
import LoadingModal from "../../../components/modals/LoadingModal";
import { NoProduct } from "../../../components";
import { Product } from "../../../components/Product";

function BestSelling(props) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // console.log(props.common.categories);
    handleGetBestSellingProducts();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);

  const handleGetBestSellingProducts = async () => {
    const { accessToken } = props.authentication;
    props
      .getBestSellingProducts(pageSize, page, accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const {
    bestSellingProducts,
    bestSellingProductsTotal,
    bestSellingProductsLoading,
  } = props.vendorStore;
  return (
    <Page title="Dashboard | Best Selling">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>

            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Best Selling Products
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography>
          <Banner
            image={ICONS.best}
            title="Best Selling"
            value={bestSellingProductsTotal}
          />
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {bestSellingProducts?.length === 0 ? (
              <NoProduct />
            ) : (
              bestSellingProducts?.map(
                (item, index) => (
                  console.log(item),
                  (
                    <Product
                      key={index}
                      image={item?.images[0]?.src}
                      name={item?.name}
                      color={item?.attributes[0]?.color}
                      length={item?.attributes[0]?.length_inches}
                      price={item?.attributes[0]?.price}
                      onPress={() =>
                        navigate("/dashboard/product-details", {
                          state: { id: item?.id },
                        })
                      }
                      // onPressDelete={() => handleDelete(item?.id)}
                    />
                  )
                )
              )
            )}
          </Grid>
        </Box>
        <LoadingModal open={bestSellingProductsLoading} />
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  getBestSellingProducts: (pageSize, page, token) =>
    dispatch(getBestSellingProducts(pageSize, page, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BestSelling);
