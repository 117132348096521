// ----------------------------------------------------------------------
// Material
import { Container, Stack, Typography, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS, IMAGES } from "../../../constants";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function RegisterSuccess() {
  const navigate = useNavigate();
  return (
    <RootStyle title="Setup Store | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card sx={{ my: 3 }}>
          <Stack
            sx={{
              mx: 3,
              my: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 28,
                fontFamily: "PlayfairSans-Bold",
                color: COLORS.secondary,
              }}
            >
              Welcome to Hair Scout
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "PTSans-Regular",
                textAlign: "center",
                marginTop: 2,
              }}
            >
              Thank you for applying to become a brand on the Hair Scout app! If
              your brand, aligns with our values, we will reach out to the email
              provided. Until then, use the email and password you created to
              log in as a shopper and take a look around.
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "PTSans-Regular",
                textAlign: "center",
                marginTop: 2,
              }}
            >
              Until then, use the email and password you created to log in as a
              shopper and take a look around.
            </Typography>
            <LoadingButton
              sx={{ mt: 3, width: 200 }}
              variant="contained"
              size="large"
              onClick={() => navigate("/store-policies")}
            >
              Next
            </LoadingButton>
          </Stack>
        </Card>
      </Container>
    </RootStyle>
  );
}

export default RegisterSuccess;
