export const authTypes = {
  REGISTER_USER_LOADING: "REGISTER_USER_LOADING",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",

  LOGIN_USER_LOADING: "LOGIN_USER_LOADING",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",

  FORGOT_PASSWORD_LOADING: "FORGOT_PASSWORD_LOADING",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  SET_TOKEN_SUCCESS: "SET_TOKEN_SUCCESS",

  LOGOUT_USER_LOADING: "LOGOUT_USER_LOADING",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAILURE: "LOGOUT_USER_FAILURE",

  UPDATE_PROFILE_LOADING: "UPDATE_PROFILE_LOADING",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
};
