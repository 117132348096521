import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingModal from "../../../components/modals/LoadingModal";
import Page from "../../../components/Page";
import { COLORS, FONTS } from "../../../constants";
import { store } from "../../../redux/store/store";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment/moment";
import {
  getSubCategories,
  searchCategories,
  searchSubCategories,
} from "../../../redux/actions";
import {
  createDiscount,
  updateDiscount,
} from "../../../redux/actions/vendor/vendorSaleActions";
function UpdateDiscount(props) {
  const navigate = useNavigate();
  const [discounts, setDiscounts] = useState([
    { id: 1, value: "Percentage Off" },
    { id: 1, value: "Dollar Amount Off" },
  ]);
  const { state } = useLocation();
  const { discount } = state;
  console.log(discount);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [discountId, setDiscountId] = useState(discount?.id);
  const [discountName, setDiscountName] = useState(discount?.discount_name);
  const [discountType, setDiscountType] = useState(discount?.discoun_type);
  const [discountAmount, setDiscountAmount] = useState(
    discount?.discount_amount
  );
  const [minimumAmountSpent, setMinimumAmountSpent] = useState(
    discount?.minimumAmoun_spent
  );
  const [startDate, setStartDate] = useState(
    dayjs(moment(discount?.start_date).format("L"))
  );
  const [endDate, setEndDate] = useState(
    dayjs(moment(discount?.end_date).format("L"))
  );
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState(discount?.category_id);
  const [discountCode, setDiscountCode] = useState(discount?.discount_code);

  useEffect(() => {
    searchSubCategories();
  }, []);

  const getProductSubCategories = async (parentCategoryId) => {
    const { accessToken } = props.authentication;
    await props
      .getProductSubCategories(
        pageSize,
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        parentCategoryId,
        accessToken
      )
      .then(() => {
        console.log("Categories found");
      })
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };
  const searchSubCategories = async () => {
    const { accessToken } = props.authentication;
    await props
      .searchSubCategories(
        subCategory,
        pageSize,
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        accessToken
      )
      .then(() => {
        console.log("Categories found");
        const { subCategories } = store.getState().common;
        console.log(subCategories);
        setCategory(subCategories[0]?.parent_category_id);
        getProductSubCategories(subCategories[0]?.parent_category_id);
      })
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };

  const handleValidation = () => {
    if (discountName === "" || discountName === " " || discountName === null) {
      toast.error("Please enter discount name");
      return false;
    } else if (
      discountType === "" ||
      discountType === " " ||
      discountType === null
    ) {
      toast.error("Please enter discount type");
      return false;
    } else if (
      discountAmount === "" ||
      discountAmount === null ||
      discountAmount === 0
    ) {
      toast.error("Please enter discount amount greater than zero");
      return false;
    } else if (
      minimumAmountSpent === "" ||
      minimumAmountSpent === null ||
      minimumAmountSpent === 0
    ) {
      toast.error("Please enter minimum amount spent greater than zero");
      return false;
    } else if (startDate === "" || startDate === null) {
      toast.error("Please select start date");
      return false;
    } else if (endDate === "" || endDate === null) {
      toast.error("Please select end date");
      return false;
    } else if (endDate < startDate) {
      toast.error("Please select end date greater than start date");
      return false;
    } else if (category === "" || category === null) {
      toast.error("Please select a category");
      return false;
    } else if (subCategory === "" || subCategory === null) {
      toast.error("Please select sub category");
      return false;
    } else if (discountCode === "" || discountCode === null) {
      toast.error("Please enter discount code");
      return false;
    } else {
      return true;
    }
  };
  const handleUpdateDiscount = async () => {
    const { accessToken } = props.authentication;
    const isValidated = handleValidation();
    if (isValidated) {
      props
        .updateDiscount(
          subCategory,
          discountName,
          discountType,
          discountAmount,
          minimumAmountSpent,
          discountCode,
          startDate,
          endDate,
          discountId,
          accessToken
        )
        .then(() => {
          const { addDiscountStatus } = props.sale;
          if (addDiscountStatus) {
            toast.success("Discount updated successfully");
            navigate(-1);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  const handleChangeStartDate = (newValue) => {
    console.log(newValue);
    setStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };
  const {
    categories,
    subCategories,
    subCategoriesLoading,
    updateDiscountLoading,
  } = props.common;
  // console.log(subCategories);
  return (
    <Page title="Dashboard | Update Discount">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: FONTS.title }}>
          My Store
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="./sale-products"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Sale & Discount
            </Link>
            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Update Discount
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: FONTS.subtitle2, color: "text.secondary" }}
          >
            Update Discount
          </Typography>

          <Grid container sx={{ mt: 2 }} spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                type="text"
                fullWidth
                label="Discount Name"
                value={discountName}
                onChange={(e) => setDiscountName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl required={true} fullWidth>
                <InputLabel id="state-select">Discount Type</InputLabel>
                <Select
                  required
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                  labelId="state-select"
                  id="state-select"
                  label="Lengths you offer"
                >
                  {discounts.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Discount Amount"
                value={discountAmount}
                onChange={(e) => setDiscountAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Minimum Amount Spent"
                value={minimumAmountSpent}
                onChange={(e) => setMinimumAmountSpent(e.target.value)}
              />
            </Grid>
          </Grid>
          {/* <Grid container sx={{ mt: 1 }} spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField fullWidth label="Discount Amount" />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField fullWidth label="Minimum Amount Spent" />
              </Grid>  
            </Grid> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container sx={{ mt: 1 }} spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="YYYY-MM-DD"
                  value={startDate}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="YYYY-MM-DD"
                  value={endDate}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <Typography sx={{ fontFamily: FONTS.subtitle2, mt: 2 }}>
            Categories
          </Typography>
          <Grid container sx={{ mt: 1, ml: 0.5 }}>
            {categories?.map((item, index) => (
              // <Grid item xs={12} md ={3}>
              <Button
                key={index}
                sx={{
                  backgroundColor:
                    item.id === category ? COLORS.secondary : COLORS.other,
                  borderRadius: 25,
                  mr: 1,
                  mt: 2,
                  p: 0.2,
                }}
                onClick={() => {
                  setCategory(item?.id);
                  getProductSubCategories(item?.id);
                }}
              >
                <img
                  src={item?.image?.src}
                  style={{ height: 50, width: 50, borderRadius: 25 }}
                />
                <Typography sx={{ ml: 1, mr: 3, fontFamily: FONTS.subtitle }}>
                  {item?.name}
                </Typography>
              </Button>
              // </Grid>
            ))}
          </Grid>
          {category && (
            <Box>
              <Typography sx={{ fontFamily: FONTS.subtitle2, mt: 2 }}>
                Sub Categories
              </Typography>
              <Grid container sx={{ mt: 1, ml: 0.5 }}>
                {subCategories?.map((item, index) => (
                  // <Grid item xs={12} md ={3}>
                  <Button
                    key={index}
                    sx={{
                      backgroundColor:
                        item?.id === subCategory
                          ? COLORS.secondary
                          : COLORS.other,
                      borderRadius: 25,
                      mr: 1,
                      mt: 2,
                      p: 0.2,
                    }}
                    onClick={() => setSubCategory(item?.id)}
                  >
                    <img
                      src={item?.image?.src}
                      style={{ height: 50, width: 50, borderRadius: 25 }}
                    />
                    <Typography
                      sx={{ ml: 1, mr: 3, fontFamily: FONTS.subtitle }}
                    >
                      {item?.name}
                    </Typography>
                  </Button>
                  // </Grid>
                ))}
              </Grid>
            </Box>
          )}
          <Grid container sx={{ mt: 2 }} spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                fullWidth
                label="Discount Code"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6}>
                    <TextField fullWidth label="Discount Type" />
                  </Grid> */}
          </Grid>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 4, backgroundColor: COLORS.primary, width: 200 }}
            onClick={() => handleUpdateDiscount()}
          >
            Update Discount
          </Button>
        </Box>
        <LoadingModal open={subCategoriesLoading || updateDiscountLoading} />
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  staff: state.staff,
  common: state.common,
  sale: state.sale,
});
const mapDispatchToProps = (dispatch) => ({
  getProductSubCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getSubCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
  searchCategories: (
    ids,
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      searchCategories(
        ids,
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
  searchSubCategories: (
    ids,
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      searchSubCategories(
        ids,
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
  searchCategory: (
    ids,
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      searchCategories(
        ids,
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),

  updateDiscount: (
    categoryId,
    discountName,
    discountType,
    discountAmount,
    minimumAmountSpent,
    discountCode,
    startDate,
    endDate,
    id,
    accessToken
  ) =>
    dispatch(
      updateDiscount(
        categoryId,
        discountName,
        discountType,
        discountAmount,
        minimumAmountSpent,
        discountCode,
        startDate,
        endDate,
        id,
        accessToken
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateDiscount);
