export const VENDOR_ORDERS = {
  getVendorOrders: "vendor/orders?",
  getVendorOrdersById: "vendor/orderbyid?",
  getVenderOrdersCount: "vendor/orders/count?",
};

export const VENDOR_PRODUCTS = {
  setNewProduct: "vendor/products",
  getAllProducts: "vendor/products?",
  updateProduct: "vendor/products",
  getProductDetails: "vendor/products/",
  getOutOfStockProducts: "vendor/products/outofstock?",
  getBestSellingProducts: "vendor/products/bestseller?",
  getWorstSellingProducts: "vendor/products/worstseller?",
  getSaleProducts: "vendor/products/saleItems?",
  deleteProduct: "vendor/products/",
};

export const VENDOR_STORE = {
  registerVendor: "vendor/register",
  loginVendor: "vendor/login",
  setVendorStore: "vendor/setup",
  getStoreTags: "common/getallvendortags",
  setStoreTags: "vendor/setvendortags",
  setDeliveryProcess: "vendor/setpolicy",
  setRefundPolicy: "vendor/setrefundpolicy",
  setFraudProtection: "vendor/setfraudprotectionrule",
  setPayoutAccount: "vendor/setaccountdetail",
  getVendorDashboardDetails: "vendor/dashboard?",
  getVendorProfile: "vendor/profile?",
  totalEarning: "vendor/products/totalearning?",
  monthlyEarning: "vendor/products/totalearningmonthwise?",
};

export const SALE = {
  createDiscount: "discountandsales/creatediscountandsale",
  getDiscounts: "discountandsales/getdiscountandsale",
  deleteDiscount: "discountandsales/deletediscountandsale?",
  updateDiscount: "discountandsales/updatediscountandsale",
};
