import { Icon } from "@iconify/react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import cross from "@iconify/icons-eva/close-fill";
import add from "@iconify/icons-eva/plus-fill";
import { COLORS } from "../constants";
function StoreTag({ tag, tagId, onAddTag, onDeleteTag, onFindTag }) {
  return (
    <Stack
      direction={{ xs: "row", sm: "row" }}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "center",
        // border: 1,
        p: 2,
        borderRadius: 1,
        boxShadow: 4,
        width: 230,
        background: onFindTag(tagId) ? COLORS.other : COLORS.white,
      }}
    >
      <Typography>{tag}</Typography>
      {onFindTag(tagId) ? (
        <IconButton
          sx={{ color: "text.primary", zIndex: 1 }}
          onClick={onDeleteTag}
        >
          <Icon icon={cross} />
        </IconButton>
      ) : (
        <IconButton
          sx={{ color: "text.primary", zIndex: 1 }}
          onClick={onAddTag}
        >
          <Icon icon={add} />
        </IconButton>
      )}
    </Stack>
  );
}
export default StoreTag;
