// material
import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";
import { COLORS, FONTS, IMAGES } from "../constants";

// ----------------------------------------------------------------------

const RootStyle = {
  p: 2,
  color: COLORS.primary,
  mt: 2,
  borderRadius: 1,
  boxShadow: 2,
};

// ----------------------------------------------------------------------

export default function StaffCard({
  image,
  name,
  invitedOn,
  emailAddress,
  onPress,
}) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Stack direction={{ xs: "row", sm: "row" }} sx={{ boxShadow: 2 }}>
        {/* <Avatar
          variant="square"
          src={IMAGES.dummy}
          sx={{ width: "40%", height: 200 }}
        /> */}
        <Box sx={{ p: 2, width: "100%" }}>
          <Typography sx={{ fontFamily: FONTS.subtitle2 }}>Name</Typography>
          <Typography
            sx={{
              mt: 1,
              width: "100%",
              fontFamily: FONTS.subtitle,
              // borderBottom: 1,
              color: COLORS.grey,
              borderColor: COLORS.lightgrey,
            }}
          >
            {name}
          </Typography>
          <Typography sx={{ fontFamily: FONTS.subtitle2, mt: 1 }}>
            Invited On
          </Typography>
          <Typography
            sx={{
              mt: 1,
              width: "100%",
              fontFamily: FONTS.subtitle,
              // borderBottom: 1,
              color: COLORS.grey,
              borderColor: COLORS.lightgrey,
            }}
          >
            {invitedOn}
          </Typography>
          <Typography sx={{ fontFamily: FONTS.subtitle2, mt: 1 }}>
            Email
          </Typography>
          <Typography
            sx={{
              mt: 1,
              width: "100%",
              fontFamily: FONTS.subtitle,
              // borderBottom: 1,
              color: COLORS.grey,
              borderColor: COLORS.lightgrey,
            }}
          >
            {emailAddress}
          </Typography>
        </Box>
      </Stack>
      <Button
        variant="contained"
        sx={{ width: "100%", borderRadius: 0 }}
        onClick={onPress}
      >
        Manage
      </Button>
    </Grid>
  );
}
