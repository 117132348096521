import { Box, Modal, Button, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../constants";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  justifyContent: "center",
  alignItems: "center",
};

export default function AddInventoryModal({ open, handleClose }) {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);

  const handleIncreaseQuantity = () => {
    let newQuantity = quantity + 1;
    setQuantity(newQuantity);
  };
  const handleDecreaseQuantity = () => {
    if (quantity > 0) {
      let newQuantity = quantity - 1;
      setQuantity(newQuantity);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Stack sx={style}>
        <CloseIcon
          sx={{ position: "absolute", right: 20, top: 20 }}
          onClick={handleClose}
        />
        <Typography
          id="keep-mounted-modal-title"
          variant="h4"
          component="h2"
          sx={{
            textAlign: "center",
            color: COLORS.secondary,
            fontFamily: "PTSans-Bold",
            mt: 5,
          }}
        >
          Add Inventory
        </Typography>
        <Stack
          sx={{
            mt: 2,
            width: 200,
            justifyContent: "space-between",
            borderBottom: "2px solid",
            alignItems: "center",
          }}
          direction={{ xs: "column", sm: "row" }}
        >
          <Button onClick={handleDecreaseQuantity}>
            <RemoveSharpIcon />
          </Button>
          <Box
            sx={{
              width: 100,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>{quantity}</Typography>
          </Box>
          <Button onClick={handleIncreaseQuantity}>
            <AddSharpIcon />
          </Button>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column" }}
          spacing={2}
          sx={{ justifyContent: "center", mt: 4, mb: 4 }}
        >
          <Button
            variant="contained"
            sx={{
              alignSelf: "center",
              mb: 2,
              width: 200,
              backgroundColor: COLORS.secondary,
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
