import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Banner from "../../../components/Banner";
import Page from "../../../components/Page";
import { COLORS, FONTS, ICONS, IMAGES } from "../../../constants";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getWorstSellingProducts } from "../../../redux/actions";
import LoadingModal from "../../../components/modals/LoadingModal";
import { NoProduct } from "../../../components";

function WorstSelling(props) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // console.log(props.common.categories);
    handleGetWorstProducts();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);

  const handleGetWorstProducts = async () => {
    const { accessToken } = props.authentication;
    props
      .getWorstSellingProducts(pageSize, page, accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const {
    worstSellingProducts,
    worstSellingProductsTotal,
    worstSellingProductsLoading,
  } = props.vendorStore;
  return (
    <Page title="Dashboard | Worst Selling">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>

            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Worst Selling Products
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography>
          <Banner
            image={ICONS.worst}
            title="Worst Selling"
            value={worstSellingProducts?.length}
          />
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {worstSellingProducts?.length === 0 ? (
              <NoProduct />
            ) : (
              worstSellingProducts?.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <Box sx={{ boxShadow: 2, borderRadius: 1 }}>
                    <Avatar
                      variant="square"
                      src={item?.images[0]?.src}
                      sx={{
                        width: "100%",
                        height: 200,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    />
                    <Box sx={{ p: 1 }}>
                      <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                        {item?.name}
                      </Typography>
                      <Stack
                        direction={{ xs: "row", sm: "row" }}
                        sx={{ justifyContent: "space-between", my: 1 }}
                        spacing={2}
                      >
                        <Stack direction={{ xs: "row", sm: "row" }}>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Regular",
                              fontSize: 14,
                              color: COLORS.grey,
                            }}
                          >
                            Color:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Regular",
                              fontSize: 14,
                              ml: 2,
                            }}
                          >
                            {item?.attributes[0]?.color}
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: "row", sm: "row" }}>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Regular",
                              fontSize: 14,
                              color: COLORS.grey,
                            }}
                          >
                            price:
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={{ xs: "row", sm: "row" }}
                        sx={{ justifyContent: "space-between" }}
                        spacing={2}
                      >
                        <Stack direction={{ xs: "row", sm: "row" }}>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Regular",
                              fontSize: 14,
                              color: COLORS.grey,
                            }}
                          >
                            Length:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Regular",
                              fontSize: 14,
                              ml: 2,
                            }}
                          >
                            {item?.attributes[0]?.length_inches}"
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: "row", sm: "row" }}>
                          <Typography
                            sx={{
                              fontFamily: "PTSans-Bold",
                              fontSize: 17,
                              color: COLORS.secondary,
                            }}
                          >
                            ${item?.attributes[0]?.price}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{ width: "100%", borderRadius: 0 }}
                      onClick={() =>
                        navigate("/dashboard/product-details", {
                          state: { id: item?.id },
                        })
                      }
                    >
                      View Product Details
                    </Button>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <LoadingModal open={worstSellingProductsLoading} />
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  getWorstSellingProducts: (pageSize, page, token) =>
    dispatch(getWorstSellingProducts(pageSize, page, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WorstSelling);
