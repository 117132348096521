import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS, ICONS, IMAGES } from "../../../constants";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import Page from "../../../components/Page";

function PaymentMethod(props) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <Page title="Dashboard | Payment Method">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Payment Method
          </Typography>
          <Box sx={{ width: "100%", mt: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ alignItems: "center", justifyContent: "space-between" }}
                centered
              >
                <Tab label="Shipping Address" />
                <Tab label="Payment Method" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack
                sx={{
                  boxShadow: 2,
                  padding: 3,
                  my: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  variant="square"
                  src={ICONS.home}
                  sx={{ alignSelf: "center", height: 200, width: 200 }}
                />
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "PlayfairDisplay-Bold", mt: 3 }}
                >
                  No Saved Addresses Yet
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontFamily: "PTSans-Regular", mt: 1 }}
                >
                  Tap below button to add one
                </Typography>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: COLORS.primary, mt: 3 }}
                >
                  + Add New Shipping Address
                </Button>
              </Stack>
              {/* <Box sx={{ boxShadow: 2, padding: 3, my: 2 }}>
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ alignItems: "center" }}
                  >
                    <PersonIcon
                      sx={{ fontSize: 40, color: COLORS.secondary }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 20,
                        color: COLORS.secondary,
                        ml: 3,
                      }}
                    >
                      Oliver
                    </Typography>
                  </Stack>
                  <Stack>
                    <EditIcon sx={{}} />
                  </Stack>
                </Stack>
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ alignItems: "center", mt: 2 }}
                >
                  <HomeIcon sx={{ fontSize: 40, color: COLORS.secondary }} />
                  <Typography sx={{ fontFamily: "PTSans-Regular", ml: 3 }}>
                    3500 Corporate Parkway, Center Valley, PA 18034, U.S.A
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ boxShadow: 2, padding: 3, my: 2 }}>
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ alignItems: "center" }}
                  >
                    <PersonIcon
                      sx={{ fontSize: 40, color: COLORS.secondary }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "PTSans-Bold",
                        fontSize: 20,
                        color: COLORS.secondary,
                        ml: 3,
                      }}
                    >
                      Oliver
                    </Typography>
                  </Stack>
                  <Stack>
                    <EditIcon sx={{}} />
                  </Stack>
                </Stack>
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ alignItems: "center", mt: 2 }}
                >
                  <HomeIcon sx={{ fontSize: 40, color: COLORS.secondary }} />
                  <Typography sx={{ fontFamily: "PTSans-Regular", ml: 3 }}>
                    3500 Corporate Parkway, Center Valley, PA 18034, U.S.A
                  </Typography>
                </Stack>
              </Box>
              <Button
                variant="contained"
                sx={{ backgroundColor: COLORS.primary, mt: 3 }}
              >
                + Add New Shipping Address
              </Button> */}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stack
                sx={{
                  boxShadow: 2,
                  padding: 3,
                  my: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  variant="square"
                  src={ICONS.card}
                  sx={{ alignSelf: "center", height: 200, width: 200 }}
                />
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "PlayfairDisplay-Bold", mt: 3 }}
                >
                  No Card Details Yet!
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontFamily: "PTSans-Regular", mt: 1 }}
                >
                  Tap below button to add one
                </Typography>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: COLORS.primary, mt: 3 }}
                >
                  + Add New Payment Method
                </Button>
              </Stack>
              {/* <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{
                  boxShadow: 2,
                  padding: 3,
                  my: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 24,
                      ml: 3,
                    }}
                  >
                    Wiliam Credit Cart
                  </Typography>

                  <Typography
                    sx={{ fontFamily: "PTSans-Regular", ml: 3, fontSize: 18 }}
                  >
                    Visa*************3456
                  </Typography>
                </Stack>
                <Stack>
                  <Avatar
                    variant="square"
                    src={IMAGES.visa}
                    sx={{ width: 180, height: 100 }}
                  />
                </Stack>
              </Stack>
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{
                  boxShadow: 2,
                  padding: 3,
                  my: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontFamily: "PTSans-Bold",
                      fontSize: 24,
                      ml: 3,
                    }}
                  >
                    Wiliam Credit Cart
                  </Typography>

                  <Typography
                    sx={{ fontFamily: "PTSans-Regular", ml: 3, fontSize: 18 }}
                  >
                    Visa*************3456
                  </Typography>
                </Stack>
                <Stack>
                  <Avatar
                    variant="square"
                    src={IMAGES.visa}
                    sx={{ width: 180, height: 100 }}
                  />
                </Stack>
              </Stack>
              <Button
                variant="contained"
                sx={{ backgroundColor: COLORS.primary, mt: 3 }}
              >
                + Add New Payment Method
              </Button> */}
            </TabPanel>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default PaymentMethod;
