import { BASE_URL, VENDOR_STORE } from "../../../apis";
import { handleGetApi, handlePutApi } from "../../../services";
import { vendorSetupTypes } from "../../types";

export const getVendorTags = (token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorSetupTypes.VENDOR_TAGS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + VENDOR_STORE.getStoreTags,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorSetupTypes.VENDOR_TAGS_SUCCESS,
        payload: response.data.vendortags,
      });
    } else {
      dispatch({
        type: vendorSetupTypes.VENDOR_TAGS_FAILURE,
      });
    }
  };
};

export const setupStore = (
  shopName,
  businessPhone,
  businessAddress,
  businessCity,
  businessZipCode,
  stateId,
  token
) => {
  const body = JSON.stringify({
    shop_name: shopName,
    business_phone: businessPhone,
    business_address: businessAddress,
    business_city: businessCity,
    business_zipcode: businessZipCode,
    state_id: stateId,
  });
  return async (dispatch) => {
    dispatch({
      type: vendorSetupTypes.SETUP_VENDOR_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + VENDOR_STORE.setVendorStore,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_SUCCESS,
        payload: response.data.vendor,
      });
    } else {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_FAILURE,
      });
    }
  };
};

export const setupTags = (tags, token) => {
  const body = JSON.stringify(tags);
  return async (dispatch) => {
    dispatch({
      type: vendorSetupTypes.SETUP_VENDOR_TAGS_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + VENDOR_STORE.setStoreTags,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_TAGS_SUCCESS,
        payload: response.data.vendor,
      });
    } else {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_TAGS_FAILURE,
      });
    }
  };
};

export const setupDeliveryProcess = (deliveryProcess, orderProcess, token) => {
  const body = JSON.stringify({
    delivery_process_policy: deliveryProcess,
    order_process_policy: orderProcess,
  });
  return async (dispatch) => {
    dispatch({
      type: vendorSetupTypes.SETUP_VENDOR_DELIVERY_PROCESS_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + VENDOR_STORE.setDeliveryProcess,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_DELIVERY_PROCESS_SUCCESS,
        payload: response.data.vendor,
      });
    } else {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_DELIVERY_PROCESS_FAILURE,
      });
    }
  };
};

export const setupRefundPolicy = (refundSupported, refundPolicy, token) => {
  const body = JSON.stringify({
    refund_process_policy: refundPolicy,
    refund_supported: refundSupported,
  });
  return async (dispatch) => {
    dispatch({
      type: vendorSetupTypes.SETUP_VENDOR_REFUND_POLICY_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + VENDOR_STORE.setRefundPolicy,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_REFUND_POLICY_SUCCESS,
        payload: response.data.vendor,
      });
    } else {
      dispatch({
        type: vendorSetupTypes.SETUP_VENDOR_REFUND_POLICY_FAILURE,
      });
    }
  };
};
export const setupFraudProtection = (
  creditAuthorizeMode,
  customAmount,
  token
) => {
  console.log("creditAuthorizeMode :", creditAuthorizeMode);
  const body = JSON.stringify({
    credit_authorize_mode: creditAuthorizeMode,
    credit_authorize_mode_custom_amount: customAmount,
  });
  return async (dispatch) => {
    dispatch({
      type: vendorSetupTypes.SETUP_FRAUD_PROTECTION_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + VENDOR_STORE.setFraudProtection,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorSetupTypes.SETUP_FRAUD_PROTECTION_SUCCESS,
        payload: response.data.vendor,
      });
    } else {
      dispatch({
        type: vendorSetupTypes.SETUP_FRAUD_PROTECTION_FAILURE,
      });
    }
  };
};

export const setupPayoutAccount = (
  accountNumber,
  bankName,
  branchCode,
  accountExpiry,
  token
) => {
  const body = JSON.stringify({
    account_no: accountNumber,
    bank_name: bankName,
    branch_code: branchCode,
    account_expiry: accountExpiry,
  });
  console.log(body);
  return async (dispatch) => {
    dispatch({
      type: vendorSetupTypes.SETUP_PAYOUT_ACCOUNT_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + VENDOR_STORE.setPayoutAccount,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: vendorSetupTypes.SETUP_PAYOUT_ACCOUNT_SUCCESS,
        // payload: response.data.vendor,
      });
    } else {
      dispatch({
        type: vendorSetupTypes.SETUP_PAYOUT_ACCOUNT_FAILURE,
      });
    }
  };
};

export const setIsUpdating = (isUpdating) => ({
  type: vendorSetupTypes.SET_IS_UPDATING_SUCCESS,
  payload: isUpdating,
});
