import { handlePostApi } from "../../../services";
import { AUTH, BASE_URL } from "../../../apis";
import { authTypes } from "../../types";

export const registerUser = (
  firstName,
  lastName,
  emailAddress,
  password,
  zipCode,
  registrationType
) => {
  const body = JSON.stringify({
    first_name: firstName,
    last_name: lastName,
    email: emailAddress,
    password: password,
    zip_code: zipCode,
    registration_type: registrationType,
  });
  return async (dispatch) => {
    dispatch({
      type: authTypes.REGISTER_USER_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + AUTH.registerUser,
      body
    );
    console.log(response);
    if (response) {
      dispatch({
        type: authTypes.REGISTER_USER_SUCCESS,
        payload: {
          accessToken: response.data.access_token,
          userId: response.data.customer_id,
          userRoles: response.data.roles,
        },
      });
    } else {
      dispatch({
        type: authTypes.REGISTER_USER_FAILURE,
      });
    }
  };
};

export const loginUser = (emailAddress, password) => {
  const body = JSON.stringify({
    email: emailAddress,
    password: password,
  });
  return async (dispatch) => {
    dispatch({
      type: authTypes.LOGIN_USER_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + AUTH.loginUser,
      body
    );
    // console.log(response);
    if (response) {

      localStorage.setItem("userDetails", JSON.stringify(response?.data));
      dispatch({
        type: authTypes.LOGIN_USER_SUCCESS,
        payload: {
          accessToken: response.data.access_token,
          userId: response.data.customer_id,
          userRoles: response.data.roles,
          ...response
        },
      });
    } else {
      dispatch({
        type: authTypes.LOGIN_USER_FAILURE,
      });
    }
  };
};

export const forgotPassword = (emailAddress) => {
  const body = JSON.stringify({
    email: emailAddress,
  });
  return async (dispatch) => {
    dispatch({
      type: authTypes.FORGOT_PASSWORD_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + AUTH.forgotPassword,
      body
    );
    console.log(response);
    if (response) {
      dispatch({
        type: authTypes.FORGOT_PASSWORD_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: authTypes.FORGOT_PASSWORD_FAILURE,
      });
    }
  };
};

export const changePassword = (currentPassword, newPassword, token) => {
  const body = JSON.stringify({
    old_password: currentPassword,
    new_password: newPassword,
  });
  return async (dispatch) => {
    dispatch({
      type: authTypes.CHANGE_PASSWORD_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + AUTH.changePassword,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: authTypes.CHANGE_PASSWORD_SUCCESS,
      });
    } else {
      dispatch({
        type: authTypes.CHANGE_PASSWORD_FAILURE,
      });
    }
  };
};

export const setAccessToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.SET_TOKEN_SUCCESS,
      payload: token,
    });
  };
};
export const logoutUser = (token) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.LOGOUT_USER_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + AUTH.logoutUser,
      null,
      token
    );
    console.log("Logout Status:", response);
    if (response) {
      dispatch({
        type: authTypes.LOGOUT_USER_SUCCESS,
      });
    } else {
      dispatch({
        type: authTypes.LOGOUT_USER_FAILURE,
      });
    }
  };
};
export const updateProfile = (firstName, lastName, imageId, token) => {
  const body = JSON.stringify({
    first_name: firstName,
    last_name: lastName,
    ProfilePictureId: imageId,
  });
  return async (dispatch) => {
    dispatch({
      type: authTypes.UPDATE_PROFILE_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + AUTH.updateProfile,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: authTypes.UPDATE_PROFILE_SUCCESS,
      });
    } else {
      dispatch({
        type: authTypes.UPDATE_PROFILE_FAILURE,
      });
    }
  };
};
