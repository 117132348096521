import { productSetupTypes } from "../../types";

const initialState = {
  setupProductLoading: false,
  setupProductStatus: false,
  products: undefined,
  productCategory: undefined,
  productSubCategory: undefined,

  productDetails: undefined,
  productLengths: undefined,
  productPrices: undefined,
  productDimensions: undefined,
  productCareInstructions: undefined,
  densitySize: undefined,

  updateProductStatus: false,
  updateProductLoading: false,
  isUpdatingProduct: false,
};
const setupProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case productSetupTypes.SETUP_PRODUCT_LOADING:
      return {
        ...state,
        setupProductLoading: true,
        setupProductStatus: false,
      };
    case productSetupTypes.SETUP_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
        setupProductStatus: true,
        setupProductLoading: false,
      };
    case productSetupTypes.SETUP_PRODUCT_FAILURE:
      return {
        ...state,
        setupProductStatus: false,
        setupProductLoading: false,
      };

    case productSetupTypes.SET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetails: action.payload,
      };
    case productSetupTypes.SET_PRODUCT_LENGTHS_SUCCESS:
      return {
        ...state,
        productLengths: action.payload,
      };
    case productSetupTypes.SET_PRODUCT_PRICES_SUCCESS:
      return {
        ...state,
        productPrices: action.payload,
      };
    case productSetupTypes.SET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: action.payload,
      };
    case productSetupTypes.SET_PRODUCT_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        productSubCategory: action.payload,
      };
    case productSetupTypes.SET_PRODUCT_DIMENSIONS_SUCCESS:
      return {
        ...state,
        productDimensions: action.payload,
      };
    case productSetupTypes.SET_PRODUCT_CARE_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        productCareInstructions: action.payload,
      };
    case productSetupTypes.SET_DENSITY_SIZE_SUCCESS:
      return {
        ...state,
        densitySize: action.payload,
      };
    case productSetupTypes.UPDATE_PRODUCT_LOADING:
      return {
        ...state,
        updateProductLoading: true,
        updateProductStatus: false,
      };
    case productSetupTypes.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        updateProductStatus: true,
        updateProductLoading: false,
      };
    case productSetupTypes.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        updateProductStatus: false,
        updateProductLoading: false,
      };
    case productSetupTypes.SET_IS_UPDATING_PRODUCT_SUCCESS:
      return {
        ...state,
        isUpdatingProduct: action.payload,
      };
    default:
      return state;
  }
};
export default setupProductReducer;
