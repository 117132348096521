import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Stack,
  Breadcrumbs,
  Link,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Banner from "../../../components/Banner";
import Earning from "../../../components/Earning";
import { NoItem } from "../../../components/NoItem";
import Page from "../../../components/Page";
import { FONTS, ICONS } from "../../../constants";
import { getTotalEarning } from "../../../redux/actions";

function TotalEarning(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [startDate, setStartDate] = useState(moment().format("L"));
  const [endDate, setEndDate] = useState(moment().format("L"));
  const handleGetTotalEarning = () => {
    const { accessToken } = props.authentication;
    if (startDate > endDate) {
      toast.error("Please select end date greater than start date");
    } else {
      props
        .getTotalEarning(startDate, endDate, pageSize, page, accessToken)
        .then(() => {})
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  // useEffect(() => {
  //   handleGetTotalEarning();
  // }, []);
  const { totalEarning, totalEarningLoading } = props.vendorStore;
  return (
    <Page title="Dashboard | Total Earning">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: FONTS.title }}>
          My Store
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>

            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Total Earning
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography>
          <Banner
            image={ICONS.monthly}
            title="Total Earning"
            value="$"
            withDate={true}
            onChangeStartDate={(value) =>
              setStartDate(moment(new Date(value)).format("YYYY-MM-DD"))
            }
            onChangeEndDate={(value) =>
              setEndDate(moment(new Date(value)).format("YYYY-MM-DD"))
            }
            onPressSearch={() => handleGetTotalEarning()}
          />
          {totalEarningLoading ? (
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </Stack>
          ) : totalEarning?.length === 0 ? (
            // console.log(item),
            <Stack>
              <NoItem image={ICONS.noProduct} title="No Invoice Available" />
            </Stack>
          ) : (
            totalEarning?.map((item, index) => (
              // console.log(item),
              <Earning
                key={index}
                orderId={item?.order_id}
                orderAmount={(item?.order_grand_total).toFixed(2)}
                dateCreated={moment(item?.order_date).format("LLL")}
                orderStatus={item?.order_status}
                onPress={() =>
                  navigate("/dashboard/order-details", {
                    state: { orderId: item?.order_id },
                  })
                }
              />
            ))
          )}
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  getTotalEarning: (startDate, endDate, pageSize, page, token) =>
    dispatch(getTotalEarning(startDate, endDate, pageSize, page, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TotalEarning);
