// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Typography,
  Card,
  CardHeader,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------
// Imports
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import cross from "@iconify/icons-eva/close-fill";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS, IMAGES } from "../../../constants";
import StoreTag from "../../../components/StoreTag";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  getVendorTags,
  setupDeliveryProcess,
  setupFraudProtection,
  setupRefundPolicy,
  setupTags,
} from "../../../redux/actions";
import LoadingModal from "../../../components/modals/LoadingModal";
import { store } from "../../../redux/store/store";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function StorePolicies(props) {
  const navigate = useNavigate();
  const [storeTags, setStoreTags] = useState([]);
  const [deliveryProcess, setDeliveryProcess] = useState("");
  const [orderProcess, setOrderProcess] = useState("");
  const [isRefundAvailable, setIsRefundAvailable] = useState(true);
  const [refundPolicy, setRefundPolicy] = useState("");
  const [refundPolicyError, setRefundPolicyError] = useState(false);
  const [refundPolicyHelperText, setRefundPolicyHelperText] = useState("");
  const [fraudPolicy, setFraudPolicy] = useState("");
  const [customAmount, setCustomAmount] = useState(0);
  const [customAmountError, setCustomAmountError] = useState(false);
  const [customAmountHelperText, setCustomAmountHelperText] = useState("");
  useEffect(() => {
    handleGetTags();
  }, []);
  const handleValidation = () => {
    if (refundPolicy === "" || refundPolicy === null || refundPolicy === " ") {
      setRefundPolicyError(true);
      setRefundPolicyHelperText("Please enter your refund policy");
      return false;
    } else if (fraudPolicy === "Custom" && customAmount === 0) {
      setCustomAmountError(true);
      setCustomAmountHelperText("Please enter a custom amount");
      return false;
    } else {
      return true;
    }
  };
  const handleGetTags = async () => {
    const { accessToken } = props.authentication;
    await props
      .getTags(accessToken)
      .then(() => {
        const { tags } = props.setupVendor;
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const addTag = (tagId) => {
    setStoreTags([...storeTags, tagId]);
    console.log(storeTags);
  };
  const findTag = (tagId) => {
    return storeTags.find((item) => {
      return item === tagId;
    });
  };
  const removeTag = (tagId) => {
    var lists = storeTags.filter((item) => {
      return item !== tagId;
    });
    setStoreTags(lists);
  };
  const handleSetupTags = async () => {
    const { accessToken } = props.authentication;
    await props
      .setupTags(storeTags, accessToken)
      .then(() => {
        const { setupTagsStatus } = props.setupVendor;
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleDeliveryProcess = async () => {
    const { accessToken } = props.authentication;
    await props
      .setupDeliveryProcess(deliveryProcess, orderProcess, accessToken)
      .then(() => {
        const { setupDeliveryProcessStatus } = props.setupVendor;
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleRefundPolicy = async () => {
    const { accessToken } = props.authentication;
    await props
      .setupRefundPolicy(isRefundAvailable, refundPolicy, accessToken)
      .then(() => {
        const { setupRefundPolicyStatus } = props.setupVendor;
      })
      .catch((error) => {
        toast.message(error.message);
      });
  };

  const handleSetupFraudProtection = () => {
    const { accessToken } = props.authentication;
    props
      .setupFraudProtection(fraudPolicy, customAmount, accessToken)
      .then(() => {
        const { setupFraudProtectionStatus } = props.setupVendor;
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleStorePolicies = async () => {
    const isValidated = handleValidation();
    if (isValidated) {
      await handleSetupFraudProtection();
      await handleSetupTags().then(async () => {
        await handleDeliveryProcess().then(async () => {
          await handleRefundPolicy().then(async () => {
            const {
              setupTagsStatus,
              setupDeliveryProcessStatus,
              setupRefundPolicyStatus,
              setupFraudProtectionStatus,
            } = store.getState().setupVendor;
            console.log(
              setupTagsStatus,
              setupDeliveryProcessStatus,
              setupRefundPolicyStatus,
              setupFraudProtectionStatus
            );
            if (
              setupTagsStatus &&
              setupDeliveryProcessStatus &&
              setupRefundPolicyStatus &&
              setupFraudProtectionStatus
            ) {
              navigate("/product-categories");
            }
          });
        });
      });
    }
  };
  const { tags } = props.setupVendor;
  const {
    setupTagsLoading,
    setupDeliveryProcessLoading,
    setupRefundPolicyLoading,
    setupFraudProtectionLoading,
  } = props.setupVendor;
  return (
    <RootStyle title="Setup Store | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card sx={{ my: 3 }}>
          <CardHeader
            title="Store Tags"
            subheader="Please select at least three tags"
          />
          <Grid
            container
            sx={{
              my: 3,
              mx: 3,
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            {tags.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ my: 1 }}
              >
                <StoreTag
                  tag={item.name}
                  tagId={item.id}
                  onAddTag={() => addTag(item.id)}
                  onDeleteTag={() => removeTag(item.id)}
                  onFindTag={findTag}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
        <Card sx={{ my: 3 }}>
          <CardHeader title="Delivery And Order Process" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <TextField
                type="text"
                required={false}
                multiline={true}
                rows={3}
                fullWidth
                label="Delivery Process"
                onChange={(e) => setDeliveryProcess(e.target.value)}
                value={deliveryProcess}
              />
              <TextField
                type="text"
                required={false}
                multiline={true}
                rows={3}
                fullWidth
                label="Order Process"
                onChange={(e) => setOrderProcess(e.target.value)}
                value={orderProcess}
              />
            </Stack>
          </Box>
        </Card>
        <Card sx={{ my: 3 }}>
          <CardHeader title="Refund Policy" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Note that sellers who offer refunds will rank higher on search
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setIsRefundAvailable(e.target.value)}
                  value={isRefundAvailable}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <TextField
                error={refundPolicyError}
                type="text"
                required={true}
                multiline={true}
                rows={3}
                fullWidth
                label="What is your refund policy?"
                onChange={(e) => setRefundPolicy(e.target.value)}
                value={refundPolicy}
                helperText={refundPolicyHelperText}
              />
            </Stack>
          </Box>
        </Card>
        <Card sx={{ my: 3 }}>
          <CardHeader title="Fraud Protection" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "column" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Orders that have billing and shipping addresses that don't
                  match may be a sign of customer fraud. How do you want to
                  handle that scenario?
                </FormLabel>

                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setFraudPolicy(e.target.value)}
                  value={fraudPolicy}
                >
                  <FormControlLabel
                    value="DontAcceptTheseOrder"
                    control={<Radio />}
                    label="I don’t want to accept these orders"
                  />
                  <FormControlLabel
                    value="AllOrder"
                    control={<Radio />}
                    label="Require a credit authorization for all these types of orders"
                  />
                  <FormControlLabel
                    value="Custom"
                    control={<Radio />}
                    label="Require a credit authorization form for orders over a certain amount"
                  />
                  <FormControlLabel
                    value="None"
                    control={<Radio />}
                    label="Do not require a credit authorization form"
                  />
                </RadioGroup>
              </FormControl>
              {fraudPolicy === "Custom" && (
                <TextField
                  error={customAmountError}
                  type="number"
                  required={false}
                  sx={{ width: 300 }}
                  label="Custom Amount"
                  onChange={(e) => setCustomAmount(e.target.value)}
                  value={customAmount}
                  helperText={customAmountHelperText}
                />
              )}
            </Stack>
            <LoadingButton
              variant="contained"
              size="large"
              sx={{ width: 200, mb: 2 }}
              // onClick={() => navigate("/payout-account")}
              onClick={() => handleStorePolicies()}
            >
              Next
            </LoadingButton>
          </Box>
        </Card>
        <LoadingModal
          open={
            setupTagsLoading ||
            setupDeliveryProcessLoading ||
            setupRefundPolicyLoading ||
            setupFraudProtectionLoading
          }
        />
      </Container>
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  setupVendor: state.setupVendor,
});
const mapDispatchToProps = (dispatch) => ({
  getTags: (accessToken) => dispatch(getVendorTags(accessToken)),
  setupTags: (tags, token) => dispatch(setupTags(tags, token)),
  setupDeliveryProcess: (deliveryProcess, orderProcess, token) =>
    dispatch(setupDeliveryProcess(deliveryProcess, orderProcess, token)),
  setupRefundPolicy: (refundSupported, refundPolicy, token) =>
    dispatch(setupRefundPolicy(refundSupported, refundPolicy, token)),
  setupFraudProtection: (creditAuthorizeMode, customAmount, token) =>
    dispatch(setupFraudProtection(creditAuthorizeMode, customAmount, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StorePolicies);
