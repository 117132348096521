import {
  BASE_URL,
  CUSTOMER_ACCOUNTS,
  CUSTOMER_PROFILE,
  CUSTOMER_STORES,
} from "../../../apis";
import {
  handleDeleteApi,
  handleGetApi,
  handlePostApi,
  handlePutApi,
} from "../../../services";
import { customerProfileTypes } from "../../types/customerTypes";

export const getCustomerProfile = (token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.CUSTOMER_PROFILE_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + CUSTOMER_PROFILE.getCustomerProfile,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerProfileTypes.CUSTOMER_PROFILE_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: customerProfileTypes.CUSTOMER_PROFILE_FAILURE,
      });
    }
  };
};

export const getCustomerAddresses = (token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.CUSTOMER_ADDRESSES_LOADING,
    });

    const response = await handleGetApi(
      BASE_URL.baseUrl + CUSTOMER_PROFILE.getCustomerAddresses,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerProfileTypes.CUSTOMER_ADDRESSES_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: customerProfileTypes.CUSTOMER_ADDRESSES_FAILURE,
      });
    }
  };
};

export const updateCustomerAddress = (
  id,
  name,
  email,
  phone,
  stateId,
  zipCode,
  address,
  token
) => {
  const body = JSON.stringify({
    id: id,
    name: name,
    email: email,
    phone: phone,
    state_id: stateId,
    zipcode: zipCode,
    address: address,
  });
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.UPDATE_CUSTOMER_ADDRESS_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + CUSTOMER_PROFILE.updateCustomerAddress,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerProfileTypes.UPDATE_CUSTOMER_ADDRESS_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: customerProfileTypes.UPDATE_CUSTOMER_ADDRESS_FAILURE,
      });
    }
  };
};

export const addCustomerAddress = (
  name,
  email,
  phone,
  stateId,
  zipCode,
  address,
  token
) => {
  const body = JSON.stringify({
    id: 0,
    name: name,
    email: email,
    phone: phone,
    state_id: stateId,
    zipcode: zipCode,
    address: address,
  });
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.ADD_CUSTOMER_ADDRESS_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + CUSTOMER_PROFILE.addCustomerAddress,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerProfileTypes.ADD_CUSTOMER_ADDRESS_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: customerProfileTypes.ADD_CUSTOMER_ADDRESS_SUCCESS,
      });
    }
  };
};

export const deleteCustomerAddress = (id, token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.DELETE_CUSTOMER_ADDRESS_LOADING,
    });
    const response = await handleDeleteApi(
      BASE_URL.baseUrl + CUSTOMER_PROFILE.deleteCustomerAddress + id,
      null,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerProfileTypes.DELETE_CUSTOMER_ADDRESS_SUCCESS,
        payload: {},
      });
    } else {
      dispatch({
        type: customerProfileTypes.DELETE_CUSTOMER_ADDRESS_FAILURE,
      });
    }
  };
};

export const enableCustomerNotifications = (
  firstName,
  lastName,
  status,
  token
) => {
  const body = JSON.stringify({
    first_name: firstName,
    last_name: lastName,
    enable_notification: status,
  });
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.ENABLE_CUSTOMER_NOTIFICATIONS_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + CUSTOMER_PROFILE.enableCustomerNotifications,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: customerProfileTypes.ENABLE_CUSTOMER_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: customerProfileTypes.ENABLE_CUSTOMER_NOTIFICATION_FAILURE,
      });
    }
  };
};
export const getCustomerAccounts = (token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.CUSTOMER_ACCOUNTS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + CUSTOMER_ACCOUNTS.getAccounts,
      token
    );
    console.log("Bank account details: ", response?.data);
    if (response) {
      dispatch({
        type: customerProfileTypes.CUSTOMER_ACCOUNTS_SUCCESS,
        payload: response?.data?.BankAccountList,
      });
    } else {
      dispatch({
        type: customerProfileTypes.CUSTOMER_ACCOUNTS_FAILURE,
      });
    }
  };
};
export const addCustomerAccount = (
  cardNumber,
  key,
  expiryDate,
  cvv,
  name,
  addressOne,
  addressTwo,
  city,
  province,
  zipCode,
  country,
  isDefault,
  token
) => {
  const body = JSON.stringify({
    CardNumberHash: cardNumber,
    CardNumberSalt: key,
    CVV: cvv,
    ExpiryDate: expiryDate,
    Name: name,
    AddressOne: addressOne,
    AddressTwo: addressTwo,
    CityName: city,
    StateName: province,
    ZipCode: zipCode,
    CountryName: country,
    IsDefault: isDefault,
  });
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.ADD_CUSTOMER_ACCOUNT_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + CUSTOMER_ACCOUNTS.addAccount,
      body,
      token
    );
    if (response) {
      dispatch({
        type: customerProfileTypes.ADD_CUSTOMER_ACCOUNT_SUCCESS,
      });
    } else {
      dispatch({ type: customerProfileTypes.ADD_CUSTOMER_ACCOUNT_FAILURE });
    }
  };
};

export const updateCustomerAccount = (
  cardNumber,
  key,
  expiryDate,
  cvv,
  name,
  addressOne,
  addressTwo,
  city,
  province,
  zipCode,
  country,
  isDefault,
  accountId,
  token
) => {
  const body = JSON.stringify({
    CardNumberHash: cardNumber,
    CardNumberSalt: key,
    CVV: cvv,
    ZipCode: zipCode,
    ExpiryDate: expiryDate,
    Name: name,
    AddressOne: addressOne,
    AddressTwo: addressTwo,
    CityName: city,
    StateName: province,
    CountryName: country,
    IsDefault: isDefault,
    id: accountId,
  });
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.UPDATE_CUSTOMER_ACCOUNT_LOADING,
    });

    const response = await handlePutApi(
      BASE_URL.baseUrl + CUSTOMER_ACCOUNTS.updateAccount,
      body,
      token
    );
    if (response) {
      dispatch({
        type: customerProfileTypes.UPDATE_CUSTOMER_ACCOUNT_SUCCESS,
      });
    } else {
      dispatch({
        type: customerProfileTypes.UPDATE_CUSTOMER_ACCOUNT_FAILURE,
      });
    }
  };
};

export const deleteCustomerAccount = (accountId, token) => {
  return async (dispatch) => {
    dispatch({
      type: customerProfileTypes.DELETE_CUSTOMER_ACCOUNT_LOADING,
    });
    const response = await handleDeleteApi(
      BASE_URL.baseUrl + CUSTOMER_ACCOUNTS.deleteAccount + "id=" + accountId,
      token
    );
    if (response) {
      dispatch({
        type: customerProfileTypes.DELETE_CUSTOMER_ACCOUNT_SUCCESS,
      });
    } else {
      dispatch({
        type: customerProfileTypes.DELETE_CUSTOMER_ACCOUNT_FAILURE,
      });
    }
  };
};
