import React, { useState } from 'react';
import { Box, Button, Grid, Stack, Typography, Drawer, TextField, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from "../constants";
const RootStyle = {
  p: 2,
  color: COLORS.primary,
  mt: 2,
  borderRadius: 1,
  boxShadow: 2,
};

// ----------------------------------------------------------------------

export default function CustomerOrder({
  orderId,
  orderStatus,
  dateCreated,
  totalAmount,
  discount,
  onPress,
  onLeaveReview,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLeaveReview = () => {
    setDrawerOpen(true);
    if (onLeaveReview) {
      onLeaveReview();
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Stack sx={RootStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography>Order Number: {orderId}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ justifyContent: "flex-end" }}>
            <Typography sx={{ textAlign: "end" }}>
              Order Total: ${totalAmount}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Typography>Date: {dateCreated}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ justifyContent: "flex-end" }}>
            {orderStatus === "Pending" ? (
              <Typography sx={{ textAlign: "end" }}>
                Order Status: {orderStatus}
              </Typography>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%'
                }}
              >
                <Button
                  onClick={handleLeaveReview}
                  variant="contained"
                  sx={{ height: 50 }}
                >
                  Leave a Review
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box sx={{ my: 2 }}>
          <Button
            onClick={onPress}
            variant="contained"
            sx={{ height: 50, width: 200 }}
          >
            View Details
          </Button>
        </Box>
      </Stack>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 300, p: 2, position: 'relative' }}>
          <IconButton 
            onClick={handleDrawerClose} 
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ mt: 4 }}>Leave a Review</Typography>
          <TextField
            label="Please write your comments."
            multiline
            rows={4}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleDrawerClose}
          >
            Submit Review
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
