// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Typography,
  Card,
  CardHeader,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------
// Imports
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import cross from "@iconify/icons-eva/close-fill";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS, IMAGES } from "../../../constants";
import StoreTag from "../../../components/StoreTag";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getStates } from "../../../redux/actions";
import { useEffect, useState } from "react";
import { setupStore } from "../../../redux/actions/vendor/setupVendorActions";
import { store } from "../../../redux/store/store";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function SetupStore(props) {
  const navigate = useNavigate();
  const [brandName, setBrandName] = useState("");
  const [brandNameError, setBrandNameError] = useState(false);
  const [brandNameHelperText, setBrandNameHelperText] = useState("");
  const [productLink, setProductLink] = useState("");
  const [productLinkError, setProductLinkError] = useState(false);
  const [productLinkHelperText, setProductLinkHelperText] = useState("");
  const [mediaHandle, setMediaHandle] = useState("");
  const [qualitySteps, setQualitySteps] = useState("");
  const [qualityStepsError, setQualityStepsError] = useState(false);
  const [qualityStepsHelperText, setQualityStepsHelperText] = useState("");
  const [isSampleAvailable, setIsSampleAvailable] = useState("yes");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessAddressError, setBusinessAddressError] = useState(false);
  const [businessAddressHelperText, setBusinessAddressHelperText] =
    useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessStateError, setBusinessStateError] = useState(false);
  const [businessStateHelperText, setBusinessStateHelperText] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [cityHelperText, setCityHelperText] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState(false);
  const [zipCodeHelperText, setZipCodeHelperText] = useState("");
  useEffect(() => {
    handleGetStates();
  }, []);

  const handleGetStates = async () => {
    const { accessToken } = props.authentication;
    props
      .getStates(accessToken)
      .then(() => {
        const { states } = props.common;
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleValidation = () => {
    if (brandName === null || brandName === "" || brandName === " ") {
      setBrandNameError(true);
      setBrandNameHelperText("Please enter your brand name");
      return false;
    } else if (
      productLink === null ||
      productLink === "" ||
      productLink === " "
    ) {
      setProductLinkError(true);
      setProductLinkHelperText("Please enter a link to your products");
      return false;
    } else if (
      qualitySteps === null ||
      qualitySteps === "" ||
      qualitySteps === " "
    ) {
      setQualityStepsError(true);
      setQualityStepsHelperText(
        "Please enter steps you take to ensure your product is higher quality"
      );
      return false;
    } else if (
      businessAddress === null ||
      businessAddress === "" ||
      businessAddress === " "
    ) {
      setBusinessAddressError(true);
      setBusinessAddressHelperText("Please enter your business address");
      return false;
    } else if (
      phoneNumber === null ||
      phoneNumber === "" ||
      phoneNumber === " "
    ) {
      setPhoneNumberError(true);
      setPhoneNumberHelperText("Please enter your phone number");
      return false;
    } else if (
      businessState === null ||
      businessState === "" ||
      businessState === " "
    ) {
      setBusinessStateError(true);
      setBusinessStateHelperText("Please select your business state");
      return false;
    } else if (city === null || city === "" || city === " ") {
      setCityError(true);
      setCityHelperText("Please enter your business city");
      return false;
    } else if (zipCode === null || zipCode === "" || zipCode === " ") {
      setZipCodeError(true);
      setZipCodeHelperText("Please enter your business zip code");
      return false;
    } else {
      return true;
    }
  };
  const handleSetupBrand = async () => {
    const { accessToken } = props.authentication;
    const isValidated = handleValidation();
    if (isValidated) {
      props
        .setupStore(
          brandName,
          phoneNumber,
          businessAddress,
          city,
          zipCode,
          businessState,
          accessToken
        )
        .then(() => {
          const { setupVendorStatus } = store.getState().setupVendor;
          if (setupVendorStatus) {
            toast.success("Store");
            navigate("/register-success");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  const { states } = props.common;
  return (
    <RootStyle title="Setup Store | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card sx={{ my: 3 }}>
          <CardHeader title="Apply to Become a Brand" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <TextField
                error={brandNameError}
                type="text"
                fullWidth
                required={true}
                label="What is name of your brand"
                onChange={(e) => {
                  setBrandName(e.target.value);
                  setBrandNameError(false);
                  setBrandNameHelperText("");
                }}
                value={brandName}
                helperText={brandNameHelperText}
              />
              <TextField
                error={productLinkError}
                type="text"
                required={true}
                fullWidth
                label="Please provide a link to your products(Website/Instagram/facebook/Blog)"
                onChange={(e) => {
                  setProductLink(e.target.value);
                  setProductLinkError(false);
                  setProductLinkHelperText("");
                }}
                value={productLink}
                helperText={productLinkHelperText}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <TextField
                type="text"
                required={false}
                fullWidth
                label="What is your brand's social media handle?(Write N/A if you don't have one"
                onChange={(e) => setMediaHandle(e.target.value)}
                value={mediaHandle}
              />
              <TextField
                error={qualityStepsError}
                type="text"
                required={true}
                fullWidth
                label="What steps do you take to ensure your product is high quality for your customers?"
                onChange={(e) => {
                  setQualitySteps(e.target.value);
                  setQualityStepsError(false);
                  setQualityStepsHelperText("");
                }}
                value={qualitySteps}
                helperText={qualityStepsHelperText}
              />
              {/* <FormControl required={true} fullWidth>
                <InputLabel id="state-select">State</InputLabel>
                <Select labelId="state-select" id="state-select" label="State">
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{ color: COLORS.black }}
                >
                  ​​Are you willing to providing a hair sample from at least 2
                  products that you plan to sell on Hair Scout? *This is not
                  required, but increases your chances to be a Verified Seller
                  on the app and helps us reduce fraud
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setIsSampleAvailable(e.target.value)}
                  value={isSampleAvailable}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Of course!"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="Unfortunately, no"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Box>
        </Card>
        <Card sx={{ my: 3 }}>
          <CardHeader title="Store Details" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              {/* <TextField
                type="text"
                fullWidth
                required={true}
                label="Store Name"
              /> */}
              <TextField
                error={businessAddressError}
                type="text"
                required={true}
                fullWidth
                label="Business Address"
                onChange={(e) => {
                  setBusinessAddress(e.target.value);
                  setBusinessAddressError(false);
                  setBusinessAddressHelperText("");
                }}
                value={businessAddress}
                helperText={businessAddressHelperText}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <TextField
                error={phoneNumberError}
                type="number"
                required={true}
                fullWidth
                label="Business Phone Number"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setPhoneNumberError(false);
                  setPhoneNumberHelperText("");
                }}
                value={phoneNumber}
                helperText={phoneNumberHelperText}
              />

              <FormControl required={true} fullWidth error={businessStateError}>
                <InputLabel id="state-select">State</InputLabel>
                <Select
                  labelId="state-select"
                  id="state-select"
                  label="State"
                  onChange={(e) => {
                    setBusinessState(e.target.value);
                    setBusinessStateError(false);
                    setBusinessStateHelperText("");
                  }}
                  value={businessState}
                >
                  {states?.map((state, index) => (
                    <MenuItem key={index} value={state?.id}>
                      {state?.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{businessStateHelperText}</FormHelperText>
              </FormControl>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <TextField
                error={cityError}
                type="text"
                required={true}
                fullWidth
                label="Business City"
                onChange={(e) => {
                  setCity(e.target.value);
                  setCityError(false);
                  setCityHelperText("");
                }}
                value={city}
                helperText={cityHelperText}
              />
              <TextField
                error={zipCodeError}
                type="text"
                required={true}
                fullWidth
                label="Business ZIP Code"
                inputProps={{ maxLength: 5 }}
                onChange={(e) => {
                  setZipCode(e.target.value.replace(/[^0-9]/g, ""));
                  setZipCodeError(false);
                  setZipCodeHelperText("");
                }}
                value={zipCode}
                helperText={zipCodeHelperText}
              />
            </Stack>
            <LoadingButton
              variant="contained"
              size="large"
              sx={{ width: 200 }}
              // onClick={() => navigate("/store-policies")}
              onClick={() => handleSetupBrand()}
            >
              Next
            </LoadingButton>
          </Box>
        </Card>
        {/* <Card sx={{ my: 3 }}>   
          <CardHeader
            title="Store Tags"
            subheader="Please select at least three tags"
          />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <StoreTag /> <StoreTag /> <StoreTag />
            </Stack>
          </Box>
        </Card> */}
        {/* <Card sx={{ my: 3 }}>
          <CardHeader title="Delivery And Order Process" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <TextField
                type="text"
                required={true}
                multiline={true}
                rows={3}
                fullWidth
                label="Delivery Process"
              />
              <TextField
                type="text"
                required={true}
                multiline={true}
                rows={3}
                fullWidth
                label="Order Processing"
              />
            </Stack>
          </Box>
        </Card> */}
        {/* <Card sx={{ my: 3 }}>
          <CardHeader title="Refund Policy" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Not that sellers who offer refunds will rank higher on search
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <TextField
                type="text"
                required={true}
                multiline={true}
                rows={3}
                fullWidth
                label="What is your refund policy?"
              />
            </Stack>
          </Box>
        </Card> */}
        {/* <Card sx={{ my: 3 }}>
          <CardHeader title="Fraud Protection" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Orders that have billing and shipping addresses that don't
                  match may be a sign of customer fraud
                </FormLabel>
                <Typography sx={{ color: "text.secondary", mt: 2 }}>
                  How do you want to handle that scenario?
                </Typography>
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Require a credit authorization for all these types of orders"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Require a credit authorization form for orders over a certain amount"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Do not require a credit authorization form"
                />
              </FormControl>
            </Stack>
            <LoadingButton
              variant="contained"
              size="large"
              onClick={() => navigate("/product-categories")}
            >
              Next
            </LoadingButton>
          </Box>
        </Card> */}
      </Container>
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  setupVendor: state.setupVendor,
});
const mapDispatchToProps = (dispatch) => ({
  getStates: (token) => dispatch(getStates(token)),
  setupStore: (
    shopName,
    businessPhone,
    businessAddress,
    businessCity,
    businessZipCode,
    stateId,
    token
  ) =>
    dispatch(
      setupStore(
        shopName,
        businessPhone,
        businessAddress,
        businessCity,
        businessZipCode,
        stateId,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(SetupStore);
