import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import { COLORS, ICONS, IMAGES } from "../../../constants";
import StarIcon from "@mui/icons-material/Star";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import OrderCard from "../../../components/OrderCard";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getVendorOrders } from "../../../redux/actions";
import { NoProduct } from "../../../components";
import Order from "../../../components/Order";
function OrdersHistory(props) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    handleActiveOrders();
    handleDeliveredOrders();
    handleCancelledOrders();
  }, []);

  const handleActiveOrders = async () => {
    const { accessToken } = props.authentication;
    

    await props
      .getVendorOrders(pageSize, page, "", "Pending", "", accessToken)
      .then(() => {
        console.log(props.vendorOrders.vendorOrders);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleDeliveredOrders = async () => {
    const { accessToken } = props.authentication;
    await props
      .getVendorOrders(pageSize, page, "Complete", "Pending", "", accessToken)
      .then(() => {
        console.log(props.vendorOrders.vendorOrders);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleCancelledOrders = async () => {
    const { accessToken } = props.authentication;
    await props
      .getVendorOrders(
        pageSize,
        page,
        "Cancelled",
        "Pending",
        "ShippingNotRequired",
        accessToken
      )
      .then(() => {
        console.log(props.vendorOrders.vendorOrders);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const { vendorOrders, vendorOrdersLoading } = props.vendorOrders;
  return (
    <Page title="Dashboard | Orders History">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Orders
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Order History
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ alignItems: "center", justifyContent: "space-between" }}
              centered
            >
              <Tab label="Active" />
              <Tab label="Delivered" />
              <Tab label="Returned" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {vendorOrders?.length === 0 ? (
              <NoProduct />
            ) : (
              vendorOrders?.map((item, index) => (
                <Order
                  key={index}
                  orderId={item?.id}
                  orderAmount={item?.order_total}
                  date={item?.created_on_utc}
                  address={item?.billing_address?.address1}
                  onPress={() =>
                    navigate("/dashboard/order-details", {
                      state: { orderId: item?.id },
                    })
                  }
                />
              ))
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {vendorOrders?.length === 0 ? (
              <NoProduct />
            ) : (
              vendorOrders?.map((item, index) => (
                <Order
                  key={index}
                  orderId={item?.id}
                  orderAmount={item?.order_total}
                  date={item?.created_on_utc}
                  address={item?.billing_address?.address1}
                  onPress={() =>
                    navigate("/dashboard/order-details", {
                      state: { orderId: item?.id },
                    })
                  }
                />
              ))
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {vendorOrders?.length === 0 ? (
              <NoProduct />
            ) : (
              vendorOrders?.map((item, index) => (
                <Order
                  key={index}
                  orderId={item?.id}
                  orderAmount={item?.order_total}
                  date={item?.created_on_utc}
                  address={item?.billing_address?.address1}
                  onPress={() =>
                    navigate("/dashboard/order-details", {
                      state: { orderId: item?.id },
                    })
                  }
                />
              ))
            )}
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorOrders: state.vendorOrders,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  getVendorOrders: (
    limit,
    page,
    orderStatus,
    paymentStatus,
    shippingStatus,
    token
  ) =>
    dispatch(
      getVendorOrders(
        limit,
        page,
        orderStatus,
        paymentStatus,
        shippingStatus,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistory);
