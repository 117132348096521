import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import GlobalStyles from "@mui/material/GlobalStyles";

import IMAGES from "../../constants/images";

import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";

import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import BreadCrumb from "../../components/website/BreadCrumb";
import { COLORS, FONTS } from "../../constants";
import CardView from "../../components/website/CardView";

const Brands = (props) => {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <BreadCrumb page="Our Brands" from="Home" fromLink="/brands" />
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          mt: 7,
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          mb: 7,
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{ fontFamily: "PlayfairDisplay-Bold", textAlign: "center" }}
        >
          Our Brands
        </Typography>
        <Typography
          sx={{ fontFamily: FONTS.subtitle, textAlign: "center", fontSize: 18 }}
        >
          At Hair Scout, we carefully hand select brands who are committed to
          <br />
          delivering high quality products, empathetic customer service, and
          <br />
          infinite good hair days to their customers.
        </Typography>

        <Grid container>
          <Grid xs={12} sm={12} md={3} lg={3}>
            <img src={IMAGES.product1} style={{ width: 280 }} />
            <Typography
              sx={{
                fontFamily: FONTS.subtitle2,
                textAlign: "center",
                fontSize: 18,
                mt: 3,
              }}
            >
              Brand 1
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={3} lg={3}>
            <img src={IMAGES.product1} style={{ width: 280 }} />
            <Typography
              sx={{
                fontFamily: FONTS.subtitle2,
                textAlign: "center",
                fontSize: 18,
                mt: 3,
              }}
            >
              Brand 2
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={3} lg={3}>
            <img src={IMAGES.product1} style={{ width: 280 }} />
            <Typography
              sx={{
                fontFamily: FONTS.subtitle2,
                textAlign: "center",
                fontSize: 18,
                mt: 3,
              }}
            >
              Brand 3
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={3} lg={3}>
            <img src={IMAGES.product1} style={{ width: 280 }} />
            <Typography
              sx={{
                fontFamily: FONTS.subtitle2,
                textAlign: "center",
                fontSize: 18,
                mt: 3,
              }}
            >
              Brand 4
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Brands);
