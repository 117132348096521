// material
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../utils/formatNumber";
import { COLORS } from "../constants";

// ----------------------------------------------------------------------

const RootStyle = {
  p: 2,
  color: COLORS.primary,
  //   backgroundImage: `linear-gradient(135deg, ${alpha(
  //     COLORS.secondary,
  //     0
  //   )} 0%, ${alpha(COLORS.secondary, 0.24)} 100%)`,
  mt: 2,

  borderRadius: 1,
  boxShadow: 2,
};

// ----------------------------------------------------------------------

export default function Earning({
  orderId,
  orderAmount,
  dateCreated,
  balance,
  orderStatus,
  onPress,
}) {
  return (
    <Stack sx={RootStyle}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography>Order No: {orderId}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ justifyContent: "flex-end" }}>
          <Typography sx={{ textAlign: "end" }}>
            Order Amount: ${orderAmount}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography>Date: {dateCreated}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ justifyContent: "flex-end" }}>
          <Typography sx={{ textAlign: "end" }}>
            Order Status: {orderStatus}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ my: 2 }}>
        <Button sx={{ height: 45 }} variant="contained" onClick={onPress}>
          View Details
        </Button>
      </Box>
    </Stack>
  );
}
