import { vendorOrdersTypes } from "../../types/venderTypes";

const initialState = {
  vendorOrders: [],
  vendorOrdersLoading: false,

  vendorOrdersCountLoading: false,
  vendorOrdersCount: 0,

  order: {},
  oderLoading: false,
};
const vendorOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case vendorOrdersTypes.VENDOR_ORDERS_LOADING:
      return {
        ...state,
        vendorOrders: [],
        vendorOrdersLoading: true,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_SUCCESS:
      return {
        ...state,
        vendorOrders: action.payload,
        vendorOrdersLoading: false,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_FAILURE:
      return {
        ...state,
        vendorOrdersLoading: false,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_COUNT_LOADING:
      return {
        ...state,
        vendorOrdersCountLoading: true,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_COUNT_SUCCESS:
      return {
        ...state,
        vendorOrdersCount: action.payload,
        vendorOrdersCountLoading: false,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_COUNT_FAILURE:
      return {
        ...state,
        vendorOrdersCountLoading: false,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_BY_ID_LOADING:
      return {
        ...state,
        order: {},
        orderLoading: true,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_BY_ID_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orderLoading: false,
      };
    case vendorOrdersTypes.VENDOR_ORDERS_BY_ID_FAILURE:
      return {
        ...state,
        orderLoading: false,
      };
    default:
      return state;
  }
};
export default vendorOrdersReducer;
