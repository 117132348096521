import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//
import { toast } from "react-toastify";
import { IMAGES } from "../../constants";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions";
import LoadingModal from "../../components/modals/LoadingModal";
import { store } from "../../redux/store/store";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: homeFill,
    linkTo: "/",
  },
  {
    label: "Profile",
    icon: personFill,
    linkTo: "#",
  },
  {
    label: "Settings",
    icon: settings2Fill,
    linkTo: "#",
  },
];

// ----------------------------------------------------------------------

function AccountPopover(props) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    const { accessToken } = props.authentication;
    props
      .logoutUser(accessToken)
      .then(() => {
        const { logoutStatus } = store.getState().authentication;
        if (logoutStatus) {
          console.log("logged out");
          navigate("/login");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const { vendorProfile } = props.vendorStore;
  const { logoutLoading } = props.authentication;

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
        
      >
        <Avatar src={IMAGES.dummy} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {vendorProfile?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {vendorProfile?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))} */}

        {/* <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined">
            Logout
          </Button>
        </Box> */}
        
        <MenuItem
              variant="body2"
              onClick={() => navigate("/dashboard/orders-history")}
            >
              Order History
              
            </MenuItem>
            <MenuItem
              variant="body2"
              onClick={() => navigate("/dashboard/orders-history")}
            >
              Change Password
              
            </MenuItem>
            <MenuItem
              variant="body2"
              onClick={() => navigate("/dashboard/orders-history")}
            >
              Order History
              
            </MenuItem>
            <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </Box>
        <LoadingModal open={logoutLoading} />

      </MenuPopover>
    </>
  );
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: (accessToken) => dispatch(logoutUser(accessToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountPopover);
