import { handleDeleteApi, handleGetApi } from "../../../services";
import { BASE_URL, VENDOR_PRODUCTS, VENDOR_STORE } from "../../../apis";
import { vendorStoreTypes } from "../../types";

export const getOutOfStockProducts = (pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.OUT_OF_STOCK_PRODUCTS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_PRODUCTS.getOutOfStockProducts +
        "pageSize=" +
        pageSize +
        "&page=" +
        page,
      token
    );
    // console.log(response);
    if (response) {
      dispatch({
        type: vendorStoreTypes.OUT_OF_STOCK_PRODUCTS_SUCCESS,
        payload: {
          products: response.data.products,
          totalCount: response.data.total_count,
        },
      });
    } else {
      dispatch({
        type: vendorStoreTypes.OUT_OF_STOCK_PRODUCTS_FAILURE,
      });
    }
  };
};

export const getBestSellingProducts = (pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.BEST_SELLING_PRODUCTS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_PRODUCTS.getBestSellingProducts +
        "pageSize=" +
        pageSize +
        "&page=" +
        page,
      token
    );
    console.log("Best Selling Response", response?.data);
    if (response) {
      dispatch({
        type: vendorStoreTypes.BEST_SELLING_PRODUCTS_SUCCESS,
        payload: {
          products: response.data.products,
          totalCount: response.data.total_count,
        },
      });
    } else {
      dispatch({
        type: vendorStoreTypes.BEST_SELLING_PRODUCTS_FAILURE,
      });
    }
  };
};
export const getWorstSellingProducts = (pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.WORST_SELLING_PRODUCTS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_PRODUCTS.getWorstSellingProducts +
        "pageSize=" +
        pageSize +
        "&page=" +
        page,
      token
    );
    // console.log(response);
    if (response) {
      dispatch({
        type: vendorStoreTypes.WORST_SELLING_PRODUCTS_SUCCESS,
        payload: {
          products: response.data.products,
          totalCount: response.data.total_count,
        },
      });
    } else {
      dispatch({
        type: vendorStoreTypes.WORST_SELLING_PRODUCTS_FAILURE,
      });
    }
  };
};
export const getSaleProducts = (pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.SALE_PRODUCTS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_PRODUCTS.getSaleProducts +
        "pageSize=" +
        pageSize +
        "&page=" +
        page,
      token
    );
    // console.log(response);
    if (response) {
      dispatch({
        type: vendorStoreTypes.SALE_PRODUCTS_SUCCESS,
        payload: {
          products: response.data.products,
          totalCount: response.data.total_count,
        },
      });
    } else {
      dispatch({
        type: vendorStoreTypes.SALE_PRODUCTS_FAILURE,
      });
    }
  };
};
export const deleteVendorProduct = (productId, token) => {
  console.log("Product Id", productId);
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.DELETE_PRODUCT_LOADING,
    });
    const response = await handleDeleteApi(
      BASE_URL.baseUrl + VENDOR_PRODUCTS.deleteProduct + productId,
      null,
      token
    );
    // console.log(response);
    if (response) {
      dispatch({
        type: vendorStoreTypes.DELETE_PRODUCT_SUCCESS,
      });
    } else {
      dispatch({ type: vendorStoreTypes.DELETE_PRODUCT_FAILURE });
    }
  };
};
export const getVendorProfile = (token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.VENDOR_PROFILE_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + VENDOR_STORE.getVendorProfile,
      token
    );
    console.log("Vendor Profile Response", response?.data);
    if (response) {
      dispatch({
        type: vendorStoreTypes.VENDOR_PROFILE_SUCCESS,
        payload: {
          profile: response.data.vendor,
        },
      });
    } else {
      dispatch({ type: vendorStoreTypes.VENDOR_PROFILE_FAILURE });
    }
  };
};
export const getAllProductsVendor = (pageSize, page, category, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.VENDOR_PRODUCTS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_PRODUCTS.getAllProducts +
        "PageSize=" +
        pageSize +
        "&Page=" +
        page +
        "&CategoryId=" +
        category,
      token
    );
    console.log("Active Products Response", response?.data);
    if (response) {
      dispatch({
        type: vendorStoreTypes.VENDOR_PRODUCTS_SUCCESS,
        payload: {
          products: response.data.products,
          totalCount: response.data.total_count,
        },
      });
    } else {
      dispatch({
        type: vendorStoreTypes.VENDOR_PRODUCTS_FAILURE,
      });
    }
  };
};
export const getProductDetails = (id, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.VENDOR_PRODUCT_DETAILS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + VENDOR_PRODUCTS.getProductDetails + id,
      token
    );
    console.log(response?.data);
    if (response) {
      dispatch({
        type: vendorStoreTypes.VENDOR_PRODUCT_DETAILS_SUCCESS,
        payload: response?.data?.products,
      });
    } else {
      dispatch({
        type: vendorStoreTypes.VENDOR_PRODUCT_DETAILS_FAILURE,
      });
    }
  };
};
export const getDashboardDetails = (token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.VENDOR_DASHBOARD_DETAILS_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl + VENDOR_STORE.getVendorDashboardDetails,
      token
    );
    console.log("Dashboard Response", response.data);
    if (response) {
      dispatch({
        type: vendorStoreTypes.VENDOR_DASHBOARD_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: vendorStoreTypes.VENDOR_DASHBOARD_DETAILS_FAILURE,
      });
    }
  };
};

export const getTotalEarning = (startDate, endDate, pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.TOTAL_EARNING_LOADING,
    });

    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_STORE.totalEarning +
        "StartDate=" +
        startDate +
        "&EndDate=" +
        endDate +
        "&PageSize=" +
        pageSize +
        "&Page=" +
        page,
      token
    );
    console.log("Total Earning Response", response?.data?.vendor_earning);
    if (response) {
      dispatch({
        type: vendorStoreTypes.TOTAL_EARNING_SUCCESS,
        payload: response?.data?.vendor_earning,
      });
    } else {
      dispatch({
        type: vendorStoreTypes.TOTAL_EARNING_FAILURE,
      });
    }
  };
};

export const getMonthlyIncome = (month, year, pageSize, page, token) => {
  return async (dispatch) => {
    dispatch({
      type: vendorStoreTypes.MONTHLY_EARNING_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        VENDOR_STORE.monthlyEarning +
        "Month=" +
        month +
        "&Year=" +
        year +
        "&PageSize=" +
        pageSize +
        "&Page=" +
        page,
      token
    );
    console.log("Monthly Income Response", response?.data?.vendor_earning);
    if (response) {
      dispatch({
        type: vendorStoreTypes.MONTHLY_EARNING_SUCCESS,
        payload: response?.data?.vendor_earning,
      });
    }
  };
};
