import { BASE_URL, VENDOR_PRODUCTS } from "../../../apis";
import { handlePostApi, handlePutApi } from "../../../services";
import { productSetupTypes } from "../../types";

export const setProductCategory = (id, name) => ({
  type: productSetupTypes.SET_PRODUCT_CATEGORY_SUCCESS,
  payload: { categoryId: id, categoryName: name },
});

export const setProductSubCategory = (id, name) => ({
  type: productSetupTypes.SET_PRODUCT_SUB_CATEGORY_SUCCESS,
  payload: { subCategoryId: id, subCategoryName: name },
});
export const setProductDetails = (
  productName,
  productDescription,
  images,
  tags,
  processed,
  oldImages,
  newImages
) => ({
  type: productSetupTypes.SET_PRODUCT_DETAILS_SUCCESS,
  payload: {
    productName: productName,
    productDescription: productDescription,
    images: images,
    tags: tags,
    processed: processed,
    oldImages: oldImages,
    newImages: newImages,
  },
});

export const setProductLengths = (lengths) => ({
  type: productSetupTypes.SET_PRODUCT_LENGTHS_SUCCESS,
  payload: lengths,
});
export const setProductPrices = (prices) => ({
  type: productSetupTypes.SET_PRODUCT_PRICES_SUCCESS,
  payload: prices,
});
export const setProductCareInstructions = (
  instructions,
  duration,
  durationType
) => ({
  type: productSetupTypes.SET_PRODUCT_CARE_INSTRUCTIONS_SUCCESS,
  payload: {
    instructions: instructions,
    duration: duration,
    durationType,
    durationType,
  },
});
export const setDensitySize = (density, capSize) => ({
  type: productSetupTypes.SET_DENSITY_SIZE_SUCCESS,
  payload: { density: density, capSize: capSize },
});
export const setProductDimensions = (weight, height, width, length) => ({
  type: productSetupTypes.SET_PRODUCT_DIMENSIONS_SUCCESS,
  payload: { weight: weight, height: height, width: width, length: length },
});
export const setupNewProduct = (
  productName,
  productDescription,
  price,
  images,
  tags,
  categoryIds,
  attributes,
  showOnHome,
  careInstruction,
  duration,
  durationType,
  density,
  capSize,
  token
) => {
  const body = JSON.stringify({
    name: productName,
    short_description: productDescription,
    price: 0,
    images: images,
    tags: tags,
    category_ids: categoryIds,
    attributes: attributes,
    show_on_home_page: showOnHome,
    care_instruction_no: duration,
    care_instruction_period: durationType,
    care_instruction_desc: careInstruction,
    density: density,
    cap_size: capSize,
    id: 0,
  });
  return async (dispatch) => {
    dispatch({
      type: productSetupTypes.SETUP_PRODUCT_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + VENDOR_PRODUCTS.setNewProduct,
      body,
      token
    );

    if (response) {
      console.log(response);
      dispatch({
        type: productSetupTypes.SETUP_PRODUCT_SUCCESS,
        payload: response.data.products,
      });
    } else {
      dispatch({
        type: productSetupTypes.SETUP_PRODUCT_FAILURE,
      });
    }
  };
};

export const updateProduct = (
  productName,
  productDescription,
  id,
  images,
  tags,
  categoryIds,
  attributes,
  showOnHome,
  careInstruction,
  duration,
  durationType,
  density,
  capSize,
  token
) => {
  return async (dispatch) => {
    const body = JSON.stringify({
      name: productName,
      short_description: productDescription,
      price: 0,
      images: images,
      tags: tags,
      category_ids: categoryIds,
      attributes: attributes,
      show_on_home_page: showOnHome,
      care_instruction_no: duration,
      care_instruction_period: durationType,
      care_instruction_desc: careInstruction,
      density: density,
      cap_size: capSize,
      id: id,
    });
    dispatch({
      type: productSetupTypes.UPDATE_PRODUCT_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + VENDOR_PRODUCTS.updateProduct + "/" + id,
      body,
      token
    );
    console.log("Response :", response);
    if (response) {
      dispatch({
        type: productSetupTypes.UPDATE_PRODUCT_SUCCESS,
      });
    } else {
      dispatch({
        type: productSetupTypes.UPDATE_PRODUCT_FAILURE,
      });
    }
  };
};
export const setIsUpdatingProduct = (isUpdating) => ({
  type: productSetupTypes.SET_IS_UPDATING_PRODUCT_SUCCESS,
  payload: isUpdating,
});
