// ----------------------------------------------------------------------
// Material
import {
  Container,
  Card,
  CardHeader,
  Typography,
  Grid,
  CardContent,
  CircularProgress,
  Box,
} from "@mui/material";

// material
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------
// Imports
import { toast, ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS } from "../../../constants";
import { connect } from "react-redux";
import { getCategories, setProductCategory } from "../../../redux/actions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = {
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  borderRadius: 0,
  height: 350, // Increase height of the cards
  width: 250, // Increase width of the cards
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

function ProductCategories(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const getProductCategories = async () => {
    const { accessToken } = props.authentication;
    await props
      .getProductCategories(
        pageSize,
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        accessToken
      )
      .then(() => {
        console.log("Categories found");
      })
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };

  const handleSetCategory = (id, name) => {
    props.setProductCategory(id, name);
    navigate("/product-sub-categories");
  };
  useEffect(() => {
    getProductCategories();
    console.log(props.common.categories);
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  return (
    <RootStyle title="Product Categories | Hair Scout">
      <Container maxWidth="lg" sx={{ mt: 15, mb: 10 }}>
        <Card>
          <CardHeader title="Product Categories" />
          <CardContent>
            <Grid container spacing={3}> 
              {props.common.categoriesLoading ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100vh",
                  }}
                >
                  <CircularProgress sx={{ color: COLORS.primary }} />
                </Box>
              ) : (
                props.common.categories?.map(
                  (category, index) => (
                    console.log(category),
                    (
                      <Grid key={index} item xs={12} sm={8} md={4}> 
                        <Card
                          key={index}
                          style={{
                            ...SectionStyle,
                            backgroundImage: `url(${category?.image?.src})`,
                          }}
                          // onClick={() => navigate("/product-sub-categories")}
                          onClick={() =>
                            handleSetCategory(category?.id, category?.name)
                          }
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: COLORS.white,
                              fontFamily: "PlayfairDisplay-Bold",
                              textAlign: "left",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              background: "rgba(0,0,0,0.5)",
                              height: 50,
                              width: "100%",
                              paddingTop: 1,
                              paddingLeft: 1,
                            }}
                          >
                            {category.name}
                          </Typography>
                        </Card>
                      </Grid>
                    )
                  )
                )
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <ToastContainer />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  setProductCategory: (categoryId, CategoryName) =>
    dispatch(setProductCategory(categoryId, CategoryName)),

  getProductCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductCategories);
