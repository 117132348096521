import {
  Box,
  Button,
  Container,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import { COLORS } from "../../../constants";

function NotificationSetting(props) {
  const navigate = useNavigate();

  return (
    <Page title="Dashboard | Notification Setting">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Notification Setting
          </Typography>
          <Stack
            direction={{
              xs: "row",
              sm: "row",
            }}
            sx={{
              mt: 5,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontFamily: "PTSans-Bold" }}>
              Allow Notifications
            </Typography>
            <Switch defaultChecked />
          </Stack>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 4, backgroundColor: COLORS.primary, width: 150 }}
          >
            Save
          </Button>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  customerProfile: state.customerProfile,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSetting);
