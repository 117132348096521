import { customerProductsTypes } from "../../types";

const initialState = {
  products: [],
  productsLoading: false,
  productsCount: 0,

  customerProductDetailsLoading: false,
  customerProductDetails: {},

  addReviewStatus: false,
  addReviewLoading: false,

  reviewsLoading: false,
  reviews: [],

  queriesLoading: false,
  queries: [],

  addQueryLoading: false,
  addQueryStatus: false,
};
const customerProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerProductsTypes.CUSTOMER_PRODUCTS_LOADING:
      return {
        ...state,
        productsLoading: true,
        products: [],
        productsCount: 0,
      };
    case customerProductsTypes.CUSTOMER_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        productsLoading: false,
        productsCount: action.payload.total,
      };
    case customerProductsTypes.CUSTOMER_PRODUCTS_FAILURE:
      return {
        ...state,
        products: [], 
        productsLoading: false,
        productsCount: 0,
      };
    case customerProductsTypes.PRODUCT_DETAILS_LOADING:
      return {
        ...state,
        customerProductDetailsLoading: true,
      };
    case customerProductsTypes.PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        customerProductDetails: action.payload,
        customerProductDetailsLoading: false,
      };
    case customerProductsTypes.PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        customerProductDetailsLoading: false,
      };
    case customerProductsTypes.ADD_PRODUCT_REVIEW_LOADING:
      return {
        ...state,
        addReviewLoading: true,
        addReviewStatus: false,
      };
    case customerProductsTypes.ADD_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        addReviewStatus: true,
        addReviewLoading: false,
      };
    case customerProductsTypes.ADD_PRODUCT_REVIEW_FAILURE:
      return {
        ...state,
        addReviewStatus: false,
        addReviewLoading: false,
      };
    case customerProductsTypes.PRODUCT_REVIEWS_LOADING:
      return {
        ...state,
        reviewsLoading: true,
        reviews: [],
      };
    case customerProductsTypes.PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
        reviewsLoading: false,
      };
    case customerProductsTypes.PRODUCT_REVIEWS_FAILURE:
      return {
        ...state,
        reviewsLoading: false,
      };
    case customerProductsTypes.QUERIES_LOADING:
      return {
        ...state,
        queriesLoading: true,
      };
    case customerProductsTypes.QUERIES_SUCCESS:
      return {
        ...state,
        queries: action.payload,
        queriesLoading: false,
      };
    case customerProductsTypes.QUERIES_FAILURE:
      return {
        ...state,
        queriesLoading: false,
      };
    case customerProductsTypes.ADD_QUERY_LOADING:
      return {
        ...state,
        addQueryLoading: true,
        addQueryStatus: false,
      };
    case customerProductsTypes.ADD_QUERY_SUCCESS:
      return {
        ...state,
        addQueryStatus: true,
        addQueryLoading: false,
      };
    case customerProductsTypes.ADD_QUERY_FAILURE:
      return {
        ...state,
        addQueryStatus: false,
        addQueryLoading: false,
      };
    default:
      return state;
  }
};
export default customerProductsReducer;
