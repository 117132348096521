// ----------------------------------------------------------------------
// Material
import {
  Container,
  Stack,
  Card,
  CardHeader,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Avatar,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Icon } from "@iconify/react";
import cross from "@iconify/icons-eva/close-fill";
// ----------------------------------------------------------------------
// Imports
import { toast, ToastContainer } from "react-toastify";
// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProductTag from "../../../components/ProductTag";
import { connect } from "react-redux";
import {
  setProductCareInstructions,
  setProductDetails,
} from "../../../redux/actions";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function ProductCare(props) {
  const navigate = useNavigate();

  const [durationTypes, setDurationTypes] = useState([
    { id: "Days", name: "Days" },
    { id: "Weeks", name: "Weeks" },
    { id: "Months", name: "Months" },
    { id: "Years", name: "Years" },
  ]);

  const [careInstructions, setCareInstructions] = useState("");
  const [duration, setDuration] = useState("");
  const [durationType, setDurationType] = useState("");

  const handleSetProductCareInstructions = async () => {
    await props.setProductCareInstructions(
      careInstructions,
      duration,
      durationType
    );
    navigate("/product-lengths");
  };
  return (
    <RootStyle title="Care Instructions | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card sx={{ mb: 5 }}>
          <CardHeader title="Product Listing" />
          <Box sx={{ mx: 3, my: 3 }} dir="ltr">
            <Typography sx={{ mb: 2 }}>
              Please Instruct your customers on how they should care for the
              hair
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              marginBottom={2}
            >
              <TextField
                // error={productNameError}
                type="text"
                fullWidth
                required={true}
                label="Care Instructions"
                onChange={(e) => {
                  setCareInstructions(e.target.value);
                }}
                value={careInstructions}
                // helperText={productNameHelperText}
              />
            </Stack>
            {/* <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                marginBottom={2}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="processed"
                      control={<Radio />}
                      label="Processed"
                    />
                    <FormControlLabel
                      value="unprocessed"  
                      control={<Radio />}
                      label="Un Processed"
                    />
                  </RadioGroup>
                </FormControl>  
              </Stack> */}

            {/* <Typography sx={{ mb: 2 }}>Product Tags</Typography> */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mb: 5 }}
            >
              <TextField
                type="number"
                fullWidth
                // required={true}
                label="Duration"
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                value={duration}
              />
              <FormControl fullWidth>
                <InputLabel id="state-select">Duration Type</InputLabel>
                <Select
                  value={durationType}
                  onChange={(e) => setDurationType(e.target.value)}
                  labelId="state-select"
                  id="state-select"
                  label="Duration Type"
                >
                  {durationTypes.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>{lengthsHelperText}</FormHelperText> */}
              </FormControl>
            </Stack>
            <Button
              sx={{ width: 200, mb: 2, height: 50 }}
              variant="contained"
              onClick={() => handleSetProductCareInstructions()}
            >
              Continue
            </Button>
          </Box>
        </Card>
      </Container>
      <ToastContainer />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  setupProduct: state.setupProduct,
});
const mapDispatchToProps = (dispatch) => ({
  setProductCareInstructions: (instructions, duration, durationType) =>
    dispatch(setProductCareInstructions(instructions, duration, durationType)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductCare);
