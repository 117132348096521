const ICONS = {
  dashboard: "/static/icons/dashboard.png",
  bell: "/static/icons/bell.png",
  camera: "/static/icons/camera.png",
  camera_white: "/static/icons/camera-white.png",
  card: "/static/icons/card.png",
  chat: "/static/icons/chat.png",
  clock: "/static/icons/clock.png",
  edit: "/static/icons/edit.png",
  email: "/static/icons/email.png",
  facebook: "/static/icons/facebook.png",
  faq: "/static/icons/faq.png",
  gallery: "/static/icons/gallery.png",
  heart_filled: "/static/icons/heart-filled.png",
  heart_outline: "/static/icons/heart-outline.png",
  history: "/static/icons/history.png",
  home: "/static/icons/home.png",
  image: "/static/icons/image.png",
  instagram: "/static/icons/instagram.png",
  location: "/static/icons/location.png",
  lock: "/static/icons/lock.png",
  notebook: "/static/icons/notebook.png",
  notifications: "/static/icons/notifications.png",
  order_success: "/static/icons/order-success.png",
  package: "/static/icons/package.png",
  payment: "/static/icons/payment.png",
  pencil: "/static/icons/pencil.png",
  person: "/static/icons/person.png",
  phone: "/static/icons/phone.png",
  rating: "/static/icons/rating.png",
  rating1: "/static/icons/rating1.png",
  rating2: "/static/icons/rating2.png",
  shield: "/static/icons/shield.png",
  shop: "/static/icons/shop.png",
  shopping_bag: "/static/icons/shopping_bag.png",
  store: "/static/icons/store.png",
  terms: "/static/icons/terms.png",
  tick: "/static/icons/tick.png",
  user: "/static/icons/user.png",
  users: "/static/icons/users.png",
  van: "/static/icons/van.png",
  whatsapp: "/static/icons/whatsapp.png",
  monthly: "/static/icons/monthly.png",
  active: "/static/icons/active.png",
  best: "/static/icons/best.png",
  pending: "/static/icons/pending.png",
  sale: "/static/icons/sale.png",
  worst: "/static/icons/worst.png",
  sold: "/static/icons/sold.png",
  whatsapp: "/static/icons/whatsapp.png",
  instagram: "/static/icons/instagram.png",
  facebook: "/static/icons/facebook.png",
  variety: "/static/icons/shop_4.webp",
  chat: "/static/icons/shop_3.webp",
  look: "/static/icons/shop_2.webp",
  love: "/static/icons/shop_1.webp",
  network: "/static/icons/network.png",
  chat: "/static/icons/chat-bubbles.png",
  shopping: "/static/icons/shopping-cart.png",
  verified: "/static/icons/verified.png",

  apple: "/static/icons/apple.png",
  playstore: "/static/icons/playstore.png",

  noProduct: "/static/icons/no-product.png",
  avatar: "/static/icons/avatar.png",
  question: "/static/icons/question.png",
  answer: "/static/icons/answer.png",
};
export default ICONS;
