import { Button, Grid, Stack, Typography } from "@mui/material";
import { COLORS, FONTS, IMAGES } from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
export const CartItem = ({
  image,
  productName,
  price,
  quantity,
  increaseQuantity,
  decreaseQuantity,
  handleDelete,
}) => {
  return (
    <Grid
      container
      sx={{ borderBottom: 1, py: 2, borderBottomColor: COLORS.border }}
    >
      <Grid
        item
        xs={4}
        md={4}
        lg={4}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          container
          // spacing={0.5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={5} md={5} lg={5}>
            <img src={image} style={{ height: 80, width: 80 }} />
          </Grid>
          <Grid item xs={7} md={7} lg={7}>
            <Typography sx={{ fontFamily: FONTS.subtitle2 }}>
              {productName}
            </Typography>
            <Typography
              sx={{ fontFamily: FONTS.subtitle2, fontSize: 20, mt: 1 }}
            >
              ${price.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        md={4}
        lg={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          direction={{ xs: "row", md: "row", lg: "row" }}
          sx={{ alignItems: "center" }}
        >
          <Button
            variant="contained"
            sx={{
              // mx: 0.5,
              my: 0.3,
              borderRadius: 0,
              height: 35,
              w: 35,
            }}
            onClick={decreaseQuantity}
          >
            -
          </Button>
          <Typography
            sx={{
              fontFamily: FONTS.subtitle2,
              textAlign: "center",
              p: 0.7,
              px: 3,
              border: 1,
              borderColor: COLORS.border,
              height: 35,
            }}
          >
            {quantity}
          </Typography>
          <Button
            variant="contained"
            sx={{
              // mx: 0.5,
              my: 0.3,
              borderRadius: 0,
              height: 35,
              width: 35,
            }}
            onClick={increaseQuantity}
          >
            +
          </Button>
        </Stack>
      </Grid>
      <Grid
        item
        xs={4}
        md={4}
        lg={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontFamily: FONTS.subtitle2, fontSize: 20 }}>
          ${(quantity * price).toFixed(2)}
        </Typography>
        <DeleteIcon
          sx={{ ml: 2, color: COLORS.primary }}
          onClick={handleDelete}
        />
      </Grid>
    </Grid>
  );
};
