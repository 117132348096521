import { applyMiddleware, combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "../reducers/authentication/authReducer";
import commonReducer from "../reducers/common/commonReducer";
import setupVendorReducer from "../reducers/vendor/setupVendorReducer";
import setupProductReducer from "../reducers/vendor/setupProductReducer";
import vendorStoreReducer from "../reducers/vendor/vendorStoreReducer";
import vendorOrdersReducer from "../reducers/vendor/vendorOrdersReducer";
import staffReducer from "../reducers/vendor/staffReducer";
import vendorSaleReducer from "../reducers/vendor/vendorSaleReducer";
import customerProductsReducer from "../reducers/customer/customerProductsReducer";
import shoppingCartReducer from "../reducers/customer/shoppingCartReducer";
import wishlistReducer from "../reducers/customer/wishlistReducer";
import customerProfileReducer from "../reducers/customer/customerProfileReducer";
import customerOrdersReducer from "../reducers/customer/customerOrdersReducer";
import cartReducer from "../Slice/CartSlice";

const rootReducer = combineReducers({
  authentication: authReducer,
  common: commonReducer,
  setupVendor: setupVendorReducer,
  setupProduct: setupProductReducer,
  vendorStore: vendorStoreReducer,
  vendorOrders: vendorOrdersReducer,
  staff: staffReducer,
  sale: vendorSaleReducer,
  customerProducts: customerProductsReducer,
  shoppingCart: shoppingCartReducer,
  wishlist: wishlistReducer,
  customerProfile: customerProfileReducer,
  customerOrdersHistory: customerOrdersReducer,
  cart:cartReducer

});

const persistenceConfigs = {
  key: "eventTracker", // whatever you want to keep as your key
  storage,
};
const persistedReducer = persistReducer(persistenceConfigs, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistedStore = persistStore(store);
