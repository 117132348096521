export const COMMON = {
  getStates: "common/getallstates",
  uploadImage: "vendor/uploadpicture",
  getCategories: "categories?",

  setFcmToken: "messaging/firebasetoken",
  testNotification: "messaging/notification/test/",
  sendMessage: "messaging/history/add",
  getChatHistory: "messaging/history?",
  termsAndConditions: "common/gettermsandpolicy",
  getFaqs: "common/getallfaq",
  contactInfo: "common/gethscontactinfo",
};
