import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import BreadCrumb from "../../components/website/BreadCrumb";
import { confirmOrder } from "../../redux/actions";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { COLORS, FONTS, IMAGES } from "../../constants";
import { Form, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { getUniqueJsonArray } from "../../services";
import { store } from "../../redux/store/store";
import PersonIcon from "@mui/icons-material/Person";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  deleteCustomerAccount,
  deleteCustomerAddress,
  getCustomerAccounts,
  getCustomerAddresses,
} from "../../redux/actions/customer/customerProfileActions";
import { Address, Payment } from "../../components";
import ConfirmationModal from "../../components/modals/ConfirmationModal";

const Checkout = (props) => {
  const [open, setOpen] = useState(false);
  const [addressId, setAddressId] = useState("");
  useEffect(() => {
    handleGetCustomerAddresses();
    handleGetCustomerAccounts();
  }, []);

  const handleGetCustomerAddresses = async () => {
    const { accessToken } = props.authentication;

    await props
      .getCustomerAddresses(accessToken)
      .then(() => {
        const { customerAddresses, customerAddressesLoading } =
          store.getState().customerProfile;
        console.log(customerAddressesLoading);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const deleteAddress = () => {
    setOpen(true);
  };
  const handleDeleteAddress = async (id) => {
    console.log("Id", id);
    const { accessToken } = props.authentication;

    await props
      .deleteCustomerAddress(id, accessToken)
      .then(() => {
        const { deleteCustomerAddressStatus } = props.customerProfile;
        if (deleteCustomerAddressStatus) {
          toast.success("Shipping address deleted successfully");
          //   this.handleGetCustomerAddresses();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleGetCustomerAccounts = async () => {
    const { accessToken } = props.authentication;
    await props
      .getCustomerAccounts(accessToken)
      .then(() => {
        const { customerAccounts } = props.customerProfile;
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error.message);
      });
  };
  const { customerAddresses, customerAccounts } = props.customerProfile;
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <BreadCrumb page="Checkout" from="Cart" fromLink="./cart" />
      <Container>
        <Stack direction={{ md: "row" }} sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ mt: 3, height: 45, borderRadius: 0 }}
          >
            + Add New Address
          </Button>
        </Stack>
        {customerAddresses?.map(
          (item, index) => (
            console.log(item),
            (
              <Address
                key={index}
                name={item?.name}
                address={item?.address}
                onDelete={() => deleteAddress()}
              />
            )
          )
        )}
        <Stack direction={{ md: "row" }} sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ mt: 3, height: 45, borderRadius: 0 }}
          >
            + Add Payment Method
          </Button>
        </Stack>
        {customerAccounts?.map((item, index) => (
          <Payment />
        ))}
      </Container>

      <Footer />
      <ConfirmationModal
        title="Delete Address"
        text="Do you really want to delete this address"
        open={open}
        handleClose={() => setOpen(false)}
        onPress={() => handleDeleteAddress(addressId)}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  common: state.common,
  authentication: state.authentication,
  customerProducts: state.customerProducts,
  shoppingCart: state.shoppingCart,
  customerProfile: state.customerProfile,
});
const mapDispatchToProps = (dispatch) => ({
  getCustomerAddresses: (token) => dispatch(getCustomerAddresses(token)),
  confirmCustomerOrder: (
    shippingAddressId,
    billingAddressId,
    paymentMethodName,
    shippingMethodName,
    token
  ) =>
    dispatch(
      confirmOrder(
        shippingAddressId,
        billingAddressId,
        paymentMethodName,
        shippingMethodName,
        token
      )
    ),
  deleteCustomerAddress: (id, token) =>
    dispatch(deleteCustomerAddress(id, token)),
  getCustomerAccounts: (token) => dispatch(getCustomerAccounts(token)),
  deleteCustomerAccount: (accountId, token) =>
    dispatch(deleteCustomerAccount(accountId, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
