import { Box, Modal, Button, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../constants";
import { useNavigate } from "react-router-dom";

export default function SignUpModal({ open, handleClose }) {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={container}>
        <CloseIcon sx={icon} onClick={handleClose} />
        <Typography
          id="keep-mounted-modal-title"
          variant="h4"
          component="h2"
          sx={title}
        >
          Sign Up
        </Typography>
        <Typography id="keep-mounted-modal-description" sx={text}>
          You are ready to sell and much more
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "column" }}
          spacing={2}
          sx={{ justifyContent: "center", mt: 4, mb: 4 }}
        >
          <Button
            variant="contained"
            sx={button}
            onClick={() => navigate("/register-customer")}
          >
            Register As A Customer
          </Button>
          <Button
            variant="contained"
            sx={{ ...button, background: COLORS.secondary }}
            onClick={() => navigate("/register-vendor")}
          >
            Apply To Become A Brand
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
const container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  justifyContent: "center",
  alignItems: "center",
};
const icon = {
  position: "absolute",
  right: 20,
  top: 20,
  background: COLORS.other,
  borderRadius: 10,
};
const title = {
  textAlign: "center",
  color: COLORS.secondary,
  fontFamily: "PTSans-Bold",
  mt: 5,
};
const text = { mt: 2, textAlign: "center", fontFamily: "PTSans-Regular" };
const button = {
  alignSelf: "center",
  bgcolor: COLORS.primary,
  width: 300,
  height: 50,
};
