import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { COLORS } from "../../constants";

export default function LoadingModal({ open }) {
  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={container}>
        <CircularProgress />
        <Typography id="keep-mounted-modal-description" sx={text}>
          Loading ...
        </Typography>
      </Box>
    </Modal>
  );
}
const container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const text = { ml: 2, textAlign: "center", fontFamily: "PTSans-Regular" };
