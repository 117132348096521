// import logo from "./logo.svg";
import "./App.css";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import Router from "./navigation/navigation";
import ScrollToTop from "./components/ScrollToTop";
import "./pages/website/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <Router />
    </ThemeConfig>
  );
}

export default App;
