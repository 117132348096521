import { BASE_URL, SHOPPING_CART } from "../../../apis";
import {
  handleDeleteApi,
  handleGetApi,
  handlePostApi,
  handlePutApi,
} from "../../../services";
import { shoppingCartTypes } from "../../types";

export const getShoppingCart = (cartType, token) => {
  return async (dispatch) => {
    dispatch({
      type: shoppingCartTypes.SHOPPING_CART_LOADING,
    });
    const response = await handleGetApi(
      BASE_URL.baseUrl +
        SHOPPING_CART.getShoppingCart +   
        "ShoppingCartType=" +
        cartType,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: shoppingCartTypes.SHOPPING_CART_SUCCESS,
        payload: {
          cart: response.data.shopping_carts,
          total: response.data.order_total,
        },
      });
    } else {
      dispatch({
        type: shoppingCartTypes.SHOPPING_CART_FAILURE,
      });
    }
  };
};

export const addToCart = (productId, quantity, cartType, attributes, token) => {
  const body = JSON.stringify({
    product_id: productId,
    quantity: quantity,
    shopping_cart_type: cartType,
    product_attributes: attributes,
  });
  console.log(body);
  return async (dispatch) => {
    dispatch({
      type: shoppingCartTypes.ADD_TO_CART_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + SHOPPING_CART.addToCart,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: shoppingCartTypes.ADD_TO_CART_SUCCESS,
        payload: {
          cart: response.data.shopping_carts,
          total: response.data.order_total,
        },
      });
    } else {
      dispatch({
        type: shoppingCartTypes.ADD_TO_CART_FAILURE,
      });
    }
  };
};

export const updateShoppingCart = (productId, quantity, token) => {
  const body = JSON.stringify({
    quantity: quantity,
    id: productId,
  });
  return async (dispatch) => {
    dispatch({
      type: shoppingCartTypes.UPDATE_CART_LOADING,
    });
    const response = await handlePutApi(
      BASE_URL.baseUrl + SHOPPING_CART.updateCart,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: shoppingCartTypes.UPDATE_CART_SUCCESS,
        payload: {
          cart: response.data.shopping_carts,
          total: response.data.order_total,
        },
      });
    } else
      dispatch({
        type: shoppingCartTypes.UPDATE_CART_FAILURE,
      });
  };
};

export const deleteProductFromCart = (itemId, cartType, token) => {
  const body = JSON.stringify({
    shopping_cart_type: cartType,
    id: itemId,
  });
  return async (dispatch) => {
    dispatch({
      type: shoppingCartTypes.DELETE_FROM_CART_LOADING,
    });
    const response = await handleDeleteApi(
      BASE_URL.baseUrl + SHOPPING_CART.deleteFromCart,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: shoppingCartTypes.DELETE_FROM_CART_SUCCESS,
        payload: {
          cart: response.data.shopping_carts,
          total: response.data.order_total,
        },
      });
    } else {
      dispatch({
        type: shoppingCartTypes.DELETE_FROM_CART_FAILURE,
      });
    }
  };
};
export const confirmOrder = (
  shippingAddressId,
  billingAddressId,
  paymentMethodName,
  shippingMethodName,
  token
) => {
  const body = JSON.stringify({
    shipping_address_id: shippingAddressId,
    billing_address_id: billingAddressId,
    payment_method_name: paymentMethodName,
    shipping_method_name: shippingMethodName,
  });
  return async (dispatch) => {
    dispatch({
      type: shoppingCartTypes.CONFIRM_ORDER_LOADING,
    });
    const response = await handlePostApi(
      BASE_URL.baseUrl + SHOPPING_CART.confirmOrder,
      body,
      token
    );
    console.log(response);
    if (response) {
      dispatch({
        type: shoppingCartTypes.CONFIRM_ORDER_SUCCESS,
        payload: response.data.orders,
      });
    } else {
      dispatch({
        type: shoppingCartTypes.CONFIRM_ORDER_FAILURE,
      });
    }
  };
};
