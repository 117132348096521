import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Banner from "../../../components/Banner";
import Page from "../../../components/Page";
import { COLORS, FONTS, ICONS, IMAGES } from "../../../constants";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  deleteVendorProduct,
  getAllProductsVendor,
  getCategories,
} from "../../../redux/actions";
import LoadingModal from "../../../components/modals/LoadingModal";
import { NoProduct } from "../../../components";
import { Product } from "../../../components/Product";
import { store } from "../../../redux/store/store";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

function ActiveProducts(props) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [open, setOpen] = useState("");
  const [productId, setProductId] = useState("");

  useEffect(() => {
    // console.log(props.common.categories);
    handleGetActiveProducts();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  const handleGetActiveProducts = () => {
    const { accessToken } = props.authentication;
    props
      .getAllProductsVendor(pageSize, page, category, accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleDelete = (id) => {
    setProductId(id);
    setOpen(true);
  };
  const handleDeleteProduct = () => {
    const { accessToken } = props.authentication;
    props
      .deleteProduct(productId, accessToken)
      .then(() => {
        const { deleteProductStatus } = store.getState().vendorStore;
        if (deleteProductStatus) {
          toast.error("Product is deleted successfully");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const { vendorProducts, vendorProductsTotal, vendorProductsLoading } =
    props.vendorStore;
  return (
    <Page title="Dashboard | Product Categories">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          Store Analytics
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>

            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Active Products
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          {/* <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography> */}
          <Banner
            image={ICONS.active}
            title="Active Products"
            value={vendorProductsTotal}
          />
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {vendorProducts?.length === 0 ? (
              <NoProduct />
            ) : (
              vendorProducts?.map((item, index) => (
                <Product
                  key={index}
                  image={item?.images[0]?.src}
                  name={item?.name}
                  color={item?.attributes[0]?.color}
                  length={item?.attributes[0]?.length_inches}
                  price={item?.attributes[0]?.price}
                  onPress={() =>
                    navigate("/dashboard/product-details", {
                      state: { id: item?.id },
                    })
                  }
                  onPressDelete={() => handleDelete(item?.id)}
                />
              ))
            )}
          </Grid>
        </Box>
        <ConfirmationModal
          open={open}
          handleClose={() => setOpen(false)}
          title="Delete Product"
          text="Do you really want to delete this product?"
          onPress={() => handleDeleteProduct()}
        />
        <LoadingModal open={vendorProductsLoading} />
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  vendorStore: state.vendorStore,
});
const mapDispatchToProps = (dispatch) => ({
  getAllProductsVendor: (pageSize, page, category, token) =>
    dispatch(getAllProductsVendor(pageSize, page, category, token)),
  deleteProduct: (productId, token) =>
    dispatch(deleteVendorProduct(productId, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ActiveProducts);
