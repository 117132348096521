import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NoProduct } from "../../../components";
import Banner from "../../../components/Banner";
import Earning from "../../../components/Earning";
import Order from "../../../components/Order";
import Page from "../../../components/Page";
import { COLORS, FONTS, ICONS, IMAGES } from "../../../constants";
import { getCategories } from "../../../redux/actions";
import { getVendorOrders } from "../../../redux/actions/vendor/vendorOrdersActions";

function PendingOrders(props) {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // console.log(props.common.categories);
    handleGetPendingOrders();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);

  const handleGetPendingOrders = () => {
    const { accessToken } = props.authentication;
    props
      .getVendorOrders(limit, page, "Pending", "", "", accessToken)
      .then(() => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const { vendorOrders, vendorOrdersLoading } = props.vendorOrders;
  return (
    <Page title="Dashboard | Pending Orders">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold" }}>
          My Store
        </Typography>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/vendor-dashboard"
              sx={{ fontFamily: FONTS.subtitle }}
            >
              Home
            </Link>

            <Typography
              color="text.primary"
              sx={{ fontFamily: FONTS.subtitle2 }}
            >
              Pending Orders
            </Typography>
          </Breadcrumbs>
        </div>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            Store Analytics
          </Typography>
          <Banner
            image={ICONS.pending}
            title="Pending Orders"
            value={vendorOrders?.length}
          />
          {/* <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ boxShadow: 2 }}>
                <Avatar
                  variant="square"
                  src={IMAGES.dummy}
                  sx={{ width: "100%", height: 200 }}
                />
                <Box sx={{ p: 1 }}>
                  <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                    Straight Hair
                  </Typography>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between", my: 1 }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Color:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        Brown
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        price:
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Length:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        10"
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Bold",
                          fontSize: 17,
                          color: COLORS.secondary,
                        }}
                      >
                        $64.89
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: "100%", borderRadius: 0 }}
                  onClick={() => navigate("/dashboard/to-be-shipped")}
                >
                  View Order Details
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ boxShadow: 2 }}>
                <Avatar
                  variant="square"
                  src={IMAGES.dummy}
                  sx={{ width: "100%", height: 200 }}
                />
                <Box sx={{ p: 1 }}>
                  <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                    View Product Details
                  </Typography>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between", my: 1 }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Color:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        Brown
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        price:
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Length:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        10"
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Bold",
                          fontSize: 17,
                          color: COLORS.secondary,
                        }}
                      >
                        $64.89
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: "100%", borderRadius: 0 }}
                >
                  View Order Detail
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ boxShadow: 2 }}>
                <Avatar
                  variant="square"
                  src={IMAGES.dummy}
                  sx={{ width: "100%", height: 200 }}
                />
                <Box sx={{ p: 1 }}>
                  <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                    Straight Hair
                  </Typography>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between", my: 1 }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Color:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        Brown
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        price:
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Length:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        10"
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Bold",
                          fontSize: 17,
                          color: COLORS.secondary,
                        }}
                      >
                        $64.89
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: "100%", borderRadius: 0 }}
                >
                  View Order Detail
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ boxShadow: 2 }}>
                <Avatar
                  variant="square"
                  src={IMAGES.dummy}
                  sx={{ width: "100%", height: 200 }}
                />
                <Box sx={{ p: 1 }}>
                  <Typography sx={{ mt: 2, fontFamily: "PTSans-Bold" }}>
                    Straight Hair
                  </Typography>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between", my: 1 }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Color:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        Brown
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        price:
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    sx={{ justifyContent: "space-between" }}
                    spacing={2}
                  >
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          color: COLORS.grey,
                        }}
                      >
                        Length:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Regular",
                          fontSize: 14,
                          ml: 2,
                        }}
                      >
                        10"
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "row", sm: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "PTSans-Bold",
                          fontSize: 17,
                          color: COLORS.secondary,
                        }}
                      >
                        $64.89
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: "100%", borderRadius: 0 }}
                >
                  View Order Detail
                </Button>
              </Box>
            </Grid>
          </Grid> */}

          {vendorOrders?.length === 0 ? (
            <NoProduct />
          ) : (
            vendorOrders?.map((item, index) => (
              <Order
                key={index}
                orderId={item?.id}
                orderAmount={item?.order_total}
                date={item?.created_on_utc}
                address={item?.billing_address?.address1}
                onPress={() =>
                  navigate("/dashboard/order-details", {
                    state: { orderId: item?.id },
                  })
                }
              />
            ))
          )}
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  vendorOrders: state.vendorOrders,
});
const mapDispatchToProps = (dispatch) => ({
  getVendorOrders: (
    limit,
    page,
    orderStatus,
    paymentStatus,
    shippingStatus,
    token
  ) =>
    dispatch(
      getVendorOrders(
        limit,
        page,
        orderStatus,
        paymentStatus,
        shippingStatus,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(PendingOrders);
