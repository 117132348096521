import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { NavStrip } from "../../../components/website/Navbar";
import Footer from "../../../components/website/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "../../../apis";
import { handleGetApi } from "../../../services";
import LoadingModal from "../../../components/modals/LoadingModal";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

function SubCategoyList() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const parentdid = location?.state?.id;

  const navigate = useNavigate();

  useEffect(() => {
    getcategories();
    setLoading(true)
  }, [parentdid]);
  const getcategories = async () => {
    let url = `${BASE_URL.baseUrl}categories?Limit=100&Page=1&PublishedStatus=true&ParentCategoryId=${parentdid}`;
    try {
      const response = await handleGetApi(url, token);
      setAllProducts(response.data.categories);
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };
  const handleClick = (item) => {
    navigate("/dashboard-list", { state: item });
  };

  return (
    <>
      <NavStrip />
      <RootStyle>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ mb: 4, mt: 5 }}>
            Sub Categories : {location?.state?.name}
          </Typography>
          <Grid container spacing={3}>
            {loading ? (
               <LoadingModal open={loading} />
            ) : (
              <>
                {allProducts.length > 0 ? (
                  allProducts.map(
                    (category, index) =>
                      category?.image?.src && (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={6}
                          md={4}
                          style={{ margin: "10px auto" }}
                        >
                          <Card sx={{ height: "100%" }}>
                            <Box
                              sx={{
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                backgroundImage: category?.image?.src
                                  ? `url(${category.image.src})`
                                  : "none",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "400px", 
                                width: "100%",
                              }}
                              onClick={() => handleClick(category)}
                            >
                              {!category?.image?.src && (
                                <img
                                  src={category.image.src}
                                  alt={category.name}
                                  style={{
                                    objectFit: "cover",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </Box>
                            <CardHeader
                              title={category.name}
                              titleTypographyProps={{
                                sx: 12,
                              }}
                              sx={{ mb: 2 }}
                            />
                          </Card>
                        </Grid>
                      )
                  )
                ) : (
                  <Typography variant="body1" style={{width:"100%",textAlign:"center" ,fontSize:"30px"}}>No data found</Typography>
                )}
              </>
            )}
          </Grid>
        </Container>
      </RootStyle>
      <Footer />
    </>
  );
}

export default SubCategoyList;
