import { Stack, Typography } from "@mui/material";
import { COLORS } from "../constants";

function Attribute({ title, value }) {
  return (
    <Stack
      direction={{ xs: "row", sm: "row" }}
      sx={{ alignItems: "center", my: 1 }}
    >
      <Stack sx={{ width: "20%" }}>
        <Typography
          sx={{
            fontFamily: "PTSans-Regular",
            fontSize: 18,
            color: COLORS.grey,
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack sx={{ width: "70%" }}>
        <Typography
          sx={{
            fontFamily: "PTSans-Bold",
            fontSize: 18,
            color: COLORS.black,
            ml: 10,
          }}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
}
export default Attribute;
