import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
  TextField,
  IconButton
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import { COLORS, ICONS, IMAGES } from "../../../constants";
import StarIcon from "@mui/icons-material/Star";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import OrderCard from "../../../components/OrderCard";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getVendorOrders } from "../../../redux/actions";
import { getCustomerOrdersHistory } from "../../../redux/actions";
import { NoProduct } from "../../../components";
import Order from "../../../components/Order";
import ordersHistory from "../../vendor/orders/ordersHistory";
import CustomerOrder from "../../../components/CustomerOrder";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

function CustomerOrderHistory(props) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOptions, setFilterOptions] = useState({});
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    handleActiveOrders();
    handleDeliveredOrders();
    handleCancelledOrders();
  }, []);

  const handleActiveOrders = async () => {
    const { accessToken } = props.authentication;

    await props
      .getCustomerOrdersHistory(pageSize, page, accessToken)
      .then(() => {
        console.log(props.customerOrdersHistory.customerOrdersHistory);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleDeliveredOrders = async () => {
    const { accessToken } = props.authentication;
    await props
      .getCustomerOrdersHistory(pageSize, page, accessToken)
      .then(() => {
        console.log(props.customerOrdersHistory.customerOrdersHistory);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleCancelledOrders = async () => {
    const { accessToken } = props.authentication;
    await props
      .getCustomerOrdersHistory(
        pageSize,
        page,
        accessToken
      )
      .then(() => {
        console.log(props.customerOrdersHistory.customerOrdersHistory);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    handleOrders();
  }, [page, pageSize]);

  useEffect(() => {
    filterOrders();
  }, [searchQuery, props.customerOrdersHistory.customerOrdersHistory]);

  const handleOrders = async () => {
    const { accessToken } = props.authentication;
    try {
      await props.getCustomerOrdersHistory(pageSize, page, accessToken);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const filterOrders = () => {
    const { customerOrdersHistory } = props.customerOrdersHistory;
    if (searchQuery) {
      const filtered = customerOrdersHistory.filter(order => 
        order && order.id && (typeof order.id === 'number' || typeof order.id === 'string') &&
        order.id.toString().includes(searchQuery)
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(customerOrdersHistory);
    }
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterClick = () => {
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const { customerOrdersLoading } = props.customerOrdersHistory;

  return (
    <Page title="Dashboard | Orders History">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontFamily: "PlayfairDisplay-Bold", mt: 5 }}>
          My Orders
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <TextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search Orders by ID"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              sx={{ width: '30%', mt:2 }}
            />
          </Stack>
        <Box sx={{ boxShadow: 4, mt: 3, p: 3 }}>
          
          <Typography
            variant="h6"
            sx={{ fontFamily: "PTSans-Bold", color: "text.secondary" }}
          >
            All Orders
          </Typography>
          <TabPanel value={value} index={0}>
            {filteredOrders?.length === 0 ? (
              <NoProduct />
            ) : (
              filteredOrders?.map((item, index) => (
                <CustomerOrder
                  key={index}
                  orderId={item?.id}
                  orderStatus={item?.order_status}
                  dateCreated={item?.created_on_utc}
                  totalAmount={item?.order_total}
                  discount={item?.order_discount}
                  onPress={() =>
                    navigate("/order-history", {
                      state: { orderId: item?.id },
                    })
                  }
                />
              ))
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {filteredOrders?.length === 0 ? (
              <NoProduct />
            ) : (
              filteredOrders?.map((item, index) => (
                <CustomerOrder
                  key={index}
                  orderId={item?.id}
                  orderStatus={item?.order_status}
                  dateCreated={item?.created_on_utc}
                  totalAmount={item?.order_total}
                  discount={item?.order_discount}
                  onPress={() =>
                    navigate("/order-history", {
                      state: { orderId: item?.id },
                    })
                  }
                />
              ))
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {filteredOrders?.length === 0 ? (
              <NoProduct />
            ) : (
              filteredOrders?.map((item, index) => (
                <CustomerOrder
                  key={index}
                  orderId={item?.id}
                  orderStatus={item?.order_status}
                  dateCreated={item?.created_on_utc}
                  totalAmount={item?.order_total}
                  discount={item?.order_discount}
                  onPress={() =>
                    navigate("/order-history", {
                      state: { orderId: item?.id },
                    })
                  }
                />
              ))
            )}
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  customerOrdersHistory: state.customerOrdersHistory,
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerOrdersHistory: (
    limit,
    page,
    token
  ) =>
    dispatch(
      getCustomerOrdersHistory(
        limit,
        page,
        token
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderHistory);
