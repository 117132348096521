import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import GlobalStyles from "@mui/material/GlobalStyles";

import IMAGES from "../../constants/images";

import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import BreadCrumb from "../../components/website/BreadCrumb";
import { COLORS, FONTS } from "../../constants";
import CardView from "../../components/website/CardView";
import { useNavigate } from "react-router-dom";

const Blog = (props) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />

      <BreadCrumb page="Blog" from="Home" fromLink="/blog" />
      <Box className="container" sx={{ mt: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Card sx={{ maxWidth: 345, borderRadius: 0 }}>
              <CardMedia
                component="img"
                height="200"
                image={IMAGES.blog1}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={[FONTS.title]}
                >
                  How To Start Your Hair Business
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The idea of starting a hair business can be both exciting and
                  overwhelming. While a passion for hair may give you the push
                  you need to get started...
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Share</Button>
                <Button size="small" onClick={() => navigate("/blog-details")}>
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Card sx={{ maxWidth: 345, borderRadius: 0 }}>
              <CardMedia
                component="img"
                height="200"
                image={IMAGES.blog2}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={[FONTS.title]}
                >
                  It's Your First Wig, Now What?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  So you’ve decided to dip your toes into the wonderful world of
                  wigs, but where do you begin? We are going to help you
                  navigate by giving some wig wearing knowledge...
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Share</Button>
                <Button size="small" onClick={() => navigate("/blog-details")}>
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Card sx={{ maxWidth: 345, borderRadius: 0 }}>
              <CardMedia
                component="img"
                height="200"
                image={IMAGES.blog3}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={[FONTS.title]}
                >
                  7 Questions to Ask Your Hair Vendor
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  When it comes to hair extensions, quality is everything. You
                  should be selective when considering vendors to purchase
                  quality hair extensions from...
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Share</Button>
                <Button size="small" onClick={() => navigate("/blog-details")}>
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Blog);
