// ----------------------------------------------------------------------
// Material
import {
  Container,
  Card,
  CardHeader,
  Typography,
  Grid,
  CardContent,
  CircularProgress,
  Box,
} from "@mui/material";

// material
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------
// Imports
import { toast, ToastContainer } from "react-toastify";

// ----------------------------------------------------------------------
// Components
import Page from "../../../components/Page";
import { COLORS } from "../../../constants";
import { connect } from "react-redux";
import {
  getCategories,
  getSubCategories,
  setProductSubCategory,
} from "../../../redux/actions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = {
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  margin: (10, 0, 10, 10),
  borderRadius: 0,
  height: 300,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
function ProductSubCategories(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const getProductSubCategories = async (parentCategoryId) => {
    const { accessToken } = props.authentication;
    await props
      .getProductSubCategories(
        pageSize,
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        parentCategoryId,
        accessToken
      )
      .then(() => {
        console.log("Categories found");
      })
      .catch((error) => {
        toast.error("Alert", error.message);
      });
  };
  const handleSetSubCategory = async (categoryId, CategoryName) => {
    await props.setProductSubCategory(categoryId, CategoryName);
    navigate("/product-form");
  };
  useEffect(() => {
    const { productCategory } = props.setupProduct;
    console.log(productCategory);
    getProductSubCategories(productCategory.categoryId);
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  return (
    <RootStyle title="Product Sub Categories | Hair Scout">
      <Container maxWidth="100%" sx={{ mt: 15 }}>
        <Card sx={{ mb: 5 }}>
          <CardHeader title="Product Sub Categories" />
          <CardContent>
            <Grid container>
              {props.common.subCategoriesLoading ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100vh",
                  }}
                >
                  <CircularProgress sx={{ color: COLORS.primary }} />
                </Box>
              ) : (
                props.common.subCategories?.map(
                  (category, index) => (
                    console.log(category),
                    (
                      <Grid xs={12} sm={6} md={2} key={index}>
                        <Card
                          style={{
                            ...SectionStyle,
                            backgroundImage: `url(${category?.image.src})`,
                          }}
                          onClick={() =>
                            handleSetSubCategory(category?.id, category?.name)
                          }
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: COLORS.white,
                              fontFamily: "PlayfairDisplay-Bold",
                              textAlign: "left",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: 50,
                              background: "rgba(0,0,0,0.5)",
                              paddingTop: 1,
                              paddingLeft: 1,
                            }}
                          >
                            {category.name}
                          </Typography>
                        </Card>
                      </Grid>
                    )
                  )
                )
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <ToastContainer />
    </RootStyle>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
  setupProduct: state.setupProduct,
});
const mapDispatchToProps = (dispatch) => ({
  getProductSubCategories: (
    limit,
    page,
    sinceId,
    fields,
    createdAtMin,
    createdAtMax,
    updatedAtMin,
    updatedAtMax,
    publishedStatus,
    productId,
    parentCategoryId,
    token
  ) =>
    dispatch(
      getSubCategories(
        limit,
        page,
        sinceId,
        fields,
        createdAtMin,
        createdAtMax,
        updatedAtMin,
        updatedAtMax,
        publishedStatus,
        productId,
        parentCategoryId,
        token
      )
    ),
  setProductSubCategory: (categoryId, CategoryName) =>
    dispatch(setProductSubCategory(categoryId, CategoryName)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSubCategories);
