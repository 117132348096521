import { customerProfileTypes } from "../../types";

const initialState = {
  customerProfileLoading: false,
  customerProfileDetails: undefined,

  customerAddressesLoading: undefined,
  customerAddresses: undefined,

  addCustomerAddressLoading: false,
  addCustomerAddressStatus: false,

  updateCustomerAddressLoading: false,
  updateCustomerAddressStatus: false,

  deleteCustomerAddressLoading: false,
  deleteCustomerAddressStatus: false,

  enableCustomerNotificationsLoading: false,
  enableCustomerNotificationsStatus: false,

  customerAccounts: [],
  customerAccountsLoading: false,

  addCustomerAccountLoading: false,
  addCustomerAccountStatus: false,

  updateCustomerAccountLoading: false,
  updateCustomerAccountStatus: false,

  deleteCustomerAccountLoading: false,
  deleteCustomerAccountStatus: false,
};
const customerProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerProfileTypes.CUSTOMER_PROFILE_LOADING:
      return {
        ...state,
        customerProfileLoading: true,
        customerProfileDetails: undefined,
      };
    case customerProfileTypes.CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        customerProfileDetails: action.payload,
        customerProfileLoading: false,
      };
    case customerProfileTypes.CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        customerProfileDetails: undefined,
        customerProfileLoading: false,
      };
    case customerProfileTypes.CUSTOMER_ADDRESSES_LOADING:
      return {
        ...state,
        customerAddressesLoading: true,
        customerAddresses: undefined,
      };
    case customerProfileTypes.CUSTOMER_ADDRESSES_SUCCESS:
      return {
        ...state,
        customerAddresses: action.payload,
        customerAddressesLoading: false,
      };
    case customerProfileTypes.CUSTOMER_ADDRESSES_FAILURE:
      return {
        ...state,
        customerAddresses: undefined,
        customerAddressesLoading: false,
      };
    case customerProfileTypes.UPDATE_CUSTOMER_ADDRESS_LOADING:
      return {
        ...state,
        updateCustomerAddressLoading: true,
        updateCustomerAddressStatus: false,
      };
    case customerProfileTypes.UPDATE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        updateCustomerAddressStatus: true,
        updateCustomerAddressLoading: false,
      };

    case customerProfileTypes.UPDATE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        updateCustomerAddressStatus: false,
        updateCustomerAddressLoading: false,
      };
    case customerProfileTypes.ADD_CUSTOMER_ADDRESS_LOADING:
      return {
        ...state,
        addCustomerAddressLoading: true,
        addCustomerAddressStatus: false,
      };
    case customerProfileTypes.ADD_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        addCustomerAddressStatus: true,
        addCustomerAddressLoading: false,
      };
    case customerProfileTypes.ADD_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        addCustomerAddressStatus: false,
        addCustomerAddressLoading: false,
      };
    case customerProfileTypes.DELETE_CUSTOMER_ADDRESS_LOADING:
      return {
        ...state,
        deleteCustomerAddressLoading: true,
        deleteCustomerAddressStatus: false,
      };
    case customerProfileTypes.DELETE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteCustomerAddressStatus: true,
        deleteCustomerAddressLoading: false,
      };
    case customerProfileTypes.DELETE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        deleteCustomerAddressStatus: false,
        deleteCustomerAddressLoading: false,
      };
    case customerProfileTypes.ENABLE_CUSTOMER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        enableCustomerNotificationsLoading: true,
        enableCustomerNotificationsStatus: false,
      };
    case customerProfileTypes.ENABLE_CUSTOMER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        enableCustomerNotificationsStatus: true,
        enableCustomerNotificationsLoading: false,
        customerProfileDetails: action.payload,
      };
    case customerProfileTypes.ENABLE_CUSTOMER_NOTIFICATION_FAILURE:
      return {
        ...state,
        enableCustomerNotificationsStatus: false,
        enableCustomerNotificationsLoading: false,
      };
    case customerProfileTypes.CUSTOMER_ACCOUNTS_LOADING:
      return {
        ...state,
        customerAccountsLoading: true,
      };
    case customerProfileTypes.CUSTOMER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        customerAccounts: action.payload,
        customerAccountsLoading: false,
      };
    case customerProfileTypes.CUSTOMER_ACCOUNTS_FAILURE:
      return {
        ...state,
        customerAccountsLoading: false,
      };
    case customerProfileTypes.ADD_CUSTOMER_ACCOUNT_LOADING:
      return {
        ...state,
        addCustomerAccountLoading: true,
      };
    case customerProfileTypes.ADD_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        addCustomerAccountStatus: true,
        addCustomerAccountLoading: false,
      };
    case customerProfileTypes.ADD_CUSTOMER_ACCOUNT_FAILURE:
      return {
        addCustomerAccountStatus: false,
        addCustomerAccountLoading: false,
      };
    case customerProfileTypes.UPDATE_CUSTOMER_ACCOUNT_LOADING:
      return {
        updateCustomerAccountLoading: true,
      };
    case customerProfileTypes.UPDATE_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        updateCustomerAccountStatus: true,
        updateCustomerAccountLoading: false,
      };
    case customerProfileTypes.UPDATE_CUSTOMER_ACCOUNT_FAILURE:
      return {
        updateCustomerAccountStatus: false,
        updateCustomerAccountLoading: false,
      };
    case customerProfileTypes.DELETE_CUSTOMER_ACCOUNT_LOADING:
      return {
        deleteCustomerAccountLoading: true,
      };
    case customerProfileTypes.DELETE_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        deleteCustomerAccountStatus: true,
        deleteCustomerAccountLoading: false,
      };
    case customerProfileTypes.DELETE_CUSTOMER_ACCOUNT_FAILURE:
      return {
        deleteCustomerAccountStatus: false,
        deleteCustomerAccountLoading: false,
      };
    default:
      return state;
  }
};
export default customerProfileReducer;
