import { useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Link,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { connect } from "react-redux";
import { getStates, registerUser } from "../../../redux/actions";
import { validateEmail } from "../../../services";
import LoadingModal from "../../modals/LoadingModal";
import { store } from "../../../redux/store/store";

// ----------------------------------------------------------------------

function RegisterForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameHelperText, setLastNameHelperText] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
    useState("");
  const [zipCode, setZipCode] = useState("");

  const handleValidation = () => {
    if (
      firstName === null ||
      firstName === "" ||
      firstName === " " ||
      firstName.length < 3
    ) {
      setFirstNameError(true);
      setFirstNameHelperText("Please enter first name more than 3 characters");
      return false;
    } else if (
      lastName === null ||
      lastName === "" ||
      lastName === " " ||
      lastName.length < 3
    ) {
      setLastNameError(true);
      setLastNameHelperText("Please enter last name more than 3 characters");
    } else if (!validateEmail(emailAddress)) {
      setEmailError(true);
      setEmailHelperText("Please enter a valid email address");
    } else if (
      password === null ||
      password === "" ||
      password === " " ||
      password.length < 6
    ) {
      setPasswordError(true);
      setPasswordHelperText("Please enter a password more than 5 characters");
      return false;
    } else if (
      confirmPassword === null ||
      confirmPassword === "" ||
      confirmPassword === "" ||
      confirmPassword.length < 6 ||
      password !== confirmPassword
    ) {
      setConfirmPasswordError(true);
      setConfirmPasswordHelperText("Please enter same value as password");
      return false;
    } else {
      return true;
    }
  };
  const handleRegisterUser = async () => {
    const isValidated = handleValidation();
    if (isValidated) {
      await props
        .registerUser(
          firstName,
          lastName,
          emailAddress,
          password,
          zipCode,
          "vendor"
        )
        .then(() => {
          const { registerUserStatus } = store.getState().authentication;
          if (registerUserStatus) {
            toast.success("Vendor registered successfully");
            navigate("/setup-store");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  const { registerUserLoading } = props.authentication;
  return (
    <Container>
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            error={firstNameError}
            fullWidth
            label="First name"
            type="text"
            onChange={(e) => {
              setFirstName(e.target.value);
              setFirstNameError(false);
              setFirstNameHelperText("");
            }}
            value={firstName}
            helperText={firstNameHelperText}
          />
          <TextField
            error={lastNameError}
            fullWidth
            label="Last name"
            type="text"
            onChange={(e) => {
              setLastName(e.target.value);
              setLastNameError(false);
              setLastNameHelperText("");
            }}
            value={lastName}
            helperText={lastNameHelperText}
          />
        </Stack>

        <TextField
          error={emailError}
          fullWidth
          autoComplete="emailAddress"
          type="email"
          label="Email address"
          onChange={(e) => {
            setEmailAddress(e.target.value);
            setEmailError(false);
            setEmailHelperText("");
          }}
          value={emailAddress}
          helperText={emailHelperText}
        />

        <TextField
          error={passwordError}
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(false);
            setPasswordHelperText("");
          }}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={passwordHelperText}
        />
        <TextField
          error={confirmPasswordError}
          fullWidth
          autoComplete="current-password"
          type={showConfirmPassword ? "text" : "password"}
          label="Confirm Password"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setConfirmPasswordError(false);
            setConfirmPasswordHelperText("");
          }}
          value={confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={confirmPasswordHelperText}
        />

        {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Age</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}
        <TextField
          fullWidth
          type="text"
          label="Zip Code"
          inputProps={{ maxLength: 5 }}
          onChange={(e) => setZipCode(e.target.value.replace(/[^0-9]/g, ""))}
          value={zipCode}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={() => navigate("/setup-store")}
          // onClick={() => navigate("/product-form")}
          // onClick={() => handleRegisterUser()}
        >
          Register
        </LoadingButton>
        <Typography
          variant="body2"
          sx={{
            mt: { md: 2 },
            textAlign: "center",
            fontFamily: "PTSans-Regular",
            fontSize: 16,
            color: "text.secondary",
          }}
        >
          Already have an account? &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/login"
            sx={{ fontSize: 16 }}
          >
            Sign In
          </Link>
        </Typography>
      </Stack>
      <LoadingModal open={registerUserLoading} />
    </Container>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});
const mapDispatchToProps = (dispatch) => ({
  registerUser: (
    firstName,
    lastName,
    emailAddress,
    password,
    zipCode,
    registrationType
  ) =>
    dispatch(
      registerUser(
        firstName,
        lastName,
        emailAddress,
        password,
        zipCode,
        registrationType
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
