import { shoppingCartTypes } from "../../types";

const initialState = {
  shoppingCart: [],
  cartTotal: {},
  shoppingCartLoading: false,

  addToCartLoading: false,
  addToCartStatus: false,

  updateCartLoading: false,
  updateCartStatus: false,

  deleteFromCartLoading: false,
  deleteFromCartStatus: false,

  orders: [],
  confirmOrderStatus: false,
  confirmOrderLoading: false,
};

const shoppingCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case shoppingCartTypes.SHOPPING_CART_LOADING:
      return {
        ...state,
        shoppingCartLoading: true,
        shoppingCart: [],
        cartTotal: {},
      };
    case shoppingCartTypes.SHOPPING_CART_SUCCESS:
      return {
        ...state,
        shoppingCart: action.payload.cart,
        cartTotal: action.payload.total,
        shoppingCartLoading: false,
      };
    case shoppingCartTypes.SHOPPING_CART_FAILURE:
      return {
        ...state,
        shoppingCartLoading: false,
        shoppingCart: [],
        cartTotal: {},
      };
    case shoppingCartTypes.ADD_TO_CART_LOADING:
      return {
        ...state,
        addToCartLoading: true,
        addToCartStatus: false,
        shoppingCart: [],
      };
    case shoppingCartTypes.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        shoppingCart: action.payload.cart,
        cartTotal: action.payload.total,
        addToCartStatus: true,
        addToCartLoading: false,
      };
    case shoppingCartTypes.ADD_TO_CART_FAILURE:
      return {
        ...state,
        addToCartStatus: false,
        addToCartLoading: false,
      };
    case shoppingCartTypes.UPDATE_CART_LOADING:
      return {
        ...state,
        updateCartLoading: true,
        updateCartStatus: false,
      };
    case shoppingCartTypes.UPDATE_CART_SUCCESS:
      return {
        ...state,
        shoppingCart: action.payload.cart,
        cartTotal: action.payload.total,
        updateCartStatus: true,
        updateCartLoading: false,
      };
    case shoppingCartTypes.UPDATE_CART_FAILURE:
      return {
        ...state,
        updateCartStatus: false,
        updateCartLoading: false,
      };
    case shoppingCartTypes.DELETE_FROM_CART_LOADING:
      return {
        ...state,
        deleteFromCartLoading: true,
        deleteFromCartStatus: false,
      };
    case shoppingCartTypes.DELETE_FROM_CART_SUCCESS:
      return {
        ...state,
        shoppingCart: action.payload.cart,
        cartTotal: action.payload.total,
        deleteFromCartStatus: true,
        deleteFromCartLoading: false,
      };
    case shoppingCartTypes.DELETE_FROM_CART_FAILURE:
      return {
        ...state,
        deleteFromCartStatus: false,
        deleteFromCartLoading: false,
      };
    case shoppingCartTypes.CONFIRM_ORDER_LOADING:
      return {
        ...state,
        confirmOrderLoading: true,
        confirmOrderStatus: false,
        orders: [],
      };
    case shoppingCartTypes.CONFIRM_ORDER_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        confirmOrderStatus: true,
        confirmOrderLoading: false,
      };
    case shoppingCartTypes.CONFIRM_ORDER_FAILURE:
      return {
        ...state,
        confirmOrderStatus: false,
        confirmOderLoading: false,
        orders: [],
      };
    default:
      return state;
  }
};
export default shoppingCartReducer;
